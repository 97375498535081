<div
  [ngClass]="'card ' + screen"
  *ngIf="
    (enqueteAbonnement == true && this.formtype === 'survey') ||
    (quizAbonnement == true && this.formtype === 'quiz') ||
    (pollAbonnement == true && this.formtype === 'poll')
  "
>
  <div class="main">
    <wm-manage-surveys
      *ngIf="isReady && this.screen !== 'preview-survey' && formtype && authToken && userId"
      (selectSurvey)="this.screen = 'edit-survey'"
      (backToManageScreen)="this.screen = 'manage-survey'"
      (previewSurvey)="previewSurvey($event)"
      (showResults)="showResults($event.detail)"
      (deletedSurveyEvent)="deletedSurvey($event.detail)"
      [lang]="lang"
      style="--color_coffee: #967e73; --color_choco: #8c746a; --color_honey: #d8aa72"
      (screenChanged)="screenChanged($event)"
      [owner]="owner"
      [formtype]="formtype"
      [authToken]="authToken"
      [userId]="userId"
    >
    </wm-manage-surveys>
  </div>
</div>
