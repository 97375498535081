<ng-container *ngIf="isActive">
  <ng-container *ngIf="activeDirection === 'asc'">
    <span
      [inlineSVG]="'./assets/media/svg/icons/Navigation/Up-2.svg'"
      [cacheSVG]="true"
      class="svg-icon svg-icon-sm svg-icon-primary ml-1"
    >
    </span>
  </ng-container>
  <ng-container *ngIf="activeDirection === 'desc'">
    <span
      [inlineSVG]="'./assets/media/svg/icons/Navigation/Down-2.svg'"
      [cacheSVG]="true"
      class="svg-icon svg-icon-sm svg-icon-primary ml-1"
    >
    </span>
  </ng-container>
</ng-container>

<ng-container *ngIf="!isActive">
  <span
    [inlineSVG]="'./assets/media/svg/icons/Shopping/Sort1.svg'"
    [cacheSVG]="true"
    class="svg-icon svg-icon-sm svg-icon-primary ml-1 svg-icon-sort"
  >
  </span>
</ng-container>
