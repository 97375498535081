import { quizService } from './../_services/quiz.service'
import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-reset-stats',
  templateUrl: './reset-stats.component.html',
  styleUrls: ['./reset-stats.component.scss'],
})
export class ResetStatsComponent implements OnInit {
  @Input() public game_id
  constructor(
    public modal: NgbActiveModal,
    private squiz: quizService,
    public cdr: ChangeDetectorRef
  ) {
    this.cdr = cdr
  }

  ngOnInit(): void {}

  resetStat() {
    this.game_id = this.formatData(JSON.stringify(this.game_id))
    this.squiz
      .miseAzero(this.game_id)
      .then(result => {
        this.cdr.detectChanges()
      })
      .catch(err => {
        console.log(err)
      })
    this.modal.close(1)
  }

  formatData(data) {
    data = data.replace(/"\"/g, '').replace(/\""/g, '')

    return data
  }

  close() {
    this.modal.close(0)
  }
}
