import ADMINPARKING from './adminParking/fr'
import AUTH from './auth/fr'
import CALENDAR from './calendar/fr'
import CHAT from './chat/fr'
import COMMUNITY from './community/fr'
import DOCUMENT from './document/fr'
import EVENT from './event/fr'
import FICHEPOSTE from './ficheposte/fr'
import FORMATIONS from './formations/fr'
import GENERAL from './general/fr'
import IDEA from './idea/fr'
import LEAVE from './leave/fr'
import NEWSLETTER from './newsletter/fr'
import ONBOARDING from './onboarding/fr'
import OSPLANNER from './osplanner/fr'
import QUESTION_REPONSE from './question-reponse/fr'
import SURVEY from './surveys/fr'
import TACHEADMINSTRATIVE from './tache_administrative/fr'
import TRANSPORT from './transport/fr'
import USER_PROFILE from './user-profile/fr'
import USERDETAILS from './user/fr'

export const locale = {
  lang: 'fr',
  data: {
    TRANSLATOR: {
      SELECT: 'choisissez votre langue',
    },
    CV: {
      UPLOADED_BY: 'Telechargé par',
      HIST_DOC: 'Historique des documents',
      DETAILSCANDIDATE: 'Détails candidat',
      METIER: 'Métier',
      POST: 'Poste Appliqué',
      CORRESPONDANCE: 'Correspondance',
      MAJ: 'MISE À JOUR ',
      VOIR: 'Voir',
      PROFIL: 'PROFIL DU CANDIDAT',
      ANNEXP: 'Années d’expérience ',
      OFFER: "Ajouter une offre d'emploi",
      VOIRDETAILS: 'Détails et modification du contrat',
      TYPECONTRAT: 'Type de contrat',
      NOMENTREPRISE: "Nom de l'entreprise",
      ADRESSEENTREPRISE: "Adresse de l'entreprise",
      MOTIFDEBUTCONTRAT: 'Motif de début du contrat',
      Temps: 'Temps',
      NOMBREHEURES: "Nombre d'heures",
      CATEGORIE: 'Catégorie socio-professionnelle',
      Matricule: 'Matricule',
      MOTIFFINCONTRAT: 'Motif de fin du contrat',
      GROUPECONGE: 'Groupe de congés',
      ADDCONTRAT: 'Ajouter un contrat',
      CONTRAT: 'Contrat',
      MESOFFRESEMPLOI: "Mes Offres d'emploi",
      Titre: 'Titre',
      FICHEPOSTE: 'Fiche de poste',
      DATEDEBUT: 'Date de début',
      DATEFIN: 'Date de fin',
      CANDIDATURERECU: 'Nombre de candidatures Reçues',
      PUBLIER: "Publier l'offre dans le portail",
      COPIER: 'Copier le lien du portail',
      NAVIGATE: 'ouvrir la page du portail',
    },

    MENU: {
      ENREGISTRER: 'Enregistrer',
      PAUSE: 'Pause',
      TRAVAIL: 'Travail',
      DURRETRAVAIL: 'durée de travail',
      HEURETRAVAIL: 'heure travaillé',
      DETAILS: 'Details',

      ET: 'et',
      DUREE: 'durée',
      JOUR: 'Jour',
      NOM: 'nom',
      PRENOM: 'prenom',
      IMAGE: 'image',
      NONVALID: 'Non valide',
      ENATTENTE: 'En attente',
      VALIDER: 'Valide',
      SUIVITEMPS: 'Suivi des temps',
      SELECTUSER: 'Choisir le collaborateur',
      ADDSUIVITEMPS: 'Déclarer une date',
      ADDSUIVITEMPSSUCCESS: 'date ajoutée avec succès',
      DATEFIN: 'Date de fin',
      SUIVITEMPSMSG:
        'Es-tu sûr(e) de vouloir terminer ta journée? En confirmant, le temps sera enregistré et tu ne pourras plus redémarrer le compteur.',
      TEMPS: 'Temps',
      GESTIONTEMPS: 'Temps',
      PARAMETRE: 'Paramètres',
      FLEXISPACE: 'Flexispace',
      TASKS: 'Mes tâches',
      MY_FLEXISPACE: 'Mon Flexispace',
      MESCOLLEGUES: 'Mes collègues',
      MESCONGES: 'Congés',
      Annuaire: 'Annuaire',
      MY_EVENT: 'Mes événements',
      Events: 'Événements',
      Useridea: 'Boîte à idées',
      AdminIdea: 'Idées',
      Entretiens: 'Entretiens',
      PREONBOARDING: 'Pré-boarding & Onboarding',
      SUIVRE: 'Suivre',
      FORMATIONDASH: 'Formation',
      PLANNIFIERDASH: 'Planifier',
      NEW: 'Nouveau',
      ACTIONS: 'Actes',
      CREATE_POST: 'Créer un nouveau Post',
      PAGES: 'Pages',
      FEATURES: 'Fonctionnalités',
      BUILDER: 'Applications',
      DASHBOARD: 'Tableau de Bord',
      USER_PROFIL: 'Profil du collaborateur',
      COLLAB: 'Collaborateurs',
      ENTRE: 'Entreprise',
      SITE: 'Sites',
      SERVICE: 'Services',
      EQUIPE: 'Equipes',
      EQUIPETR: 'Equipes transverses',
      ACTIVITY: 'Rendez-vous',
      MATERIAL: 'Matériels',
      MY_ACTIVITY: 'Mes Rendez-vous',
      MesEnquetes: 'Mes Enquêtes',
      STRATEGIE: 'Libre service',
      COMMUNITY: 'Communautés',
      MY_COMMUNITIES: 'Mes Communautés',
      REPORTING: 'Reporting',
      ALERTS: 'Alertes',
      GERER: 'Gérer',
      Catalogue: 'Catalogue',
      GERERMAJ: 'GERER',
      RENSEIGNER: 'SE RENSEIGNER',
      RECRUTER: 'RECRUTER',
      OFFRES: "Offres d'emploi",
      FICHES_POSTE: 'Fiches de poste',
      LEAVE: 'Congés',
      Cibler: 'Cibler',
      Attributions: 'Attributions',
      Enquetes: 'Enquêtes',
      Quizs: 'Quizs',
      Sondages: 'Sondages',
      AGIR: 'AGIR ET ORGANISER',
      Communications: 'Communications',
      Alertes_des_collaborateurs: 'Alertes des collaborateurs',
      ANALYZE: 'ANALYSER ET ANTICIPER',
      Prev: 'Prévisions',
      FORMATIONS: 'Formations',
      onboarding: 'Mon onboarding',
      LIEN: 'Liens entre entités',
      GENERAL: 'Général',
      NEW_COLLABORATOR: 'Nouveau collaborateur',
      STRATEGIES: 'Stratégies',
      AIDE: 'AIDE',
      MANAGE: 'To manage',
      NEWS: 'Nouveautés ✨',
      CONTACT: 'Contacter le support',
      DOCUMANTATION: 'Support and Documentation',
      JEUX: 'Jeux',
      CAMPAGNES: 'Campagnes',
      ListeDesJeux: 'Liste des jeux',
      sondage: 'Sondage',
      MES_QUESTIONS: 'Mes Questions',
      RECEIVED_QUESTIONS: 'Questions Reçues',
      MES_FORMATIONS: 'Mes Formations',
      INTEGRATION_EXTERNE: 'Intégrations externes',
      MODELISATION: 'Gestion des espaces',
      OSP_RULES: 'Gestion des règles',
      PARKING: 'Gestion des parkings',
      OSP_RESV_ADMIN: 'Gestion des emplacements',
      ATTENTEVALIDATION: 'En attente de validation',
    },
    AUTH,
    USER: {
      DETAILS: {
        PERSO_INFO: 'Informations personnelles',
        presentation: 'Presentation',
        CHANGE_PASS: 'Changer le mot de passe',
        CONGE: 'Congés',
        NOM: 'Nom',
        PRENOM: 'Prénom',
        AVAIL: 'Disponibilités',
        PLANNING: 'Planning des déplacements',
        PLANNING_WORK_MODE: 'Modes de travail',
        succ: 'Profil modifié avec succès',
        allow: 'Types de fichiers autorisés',
        btn: 'Modifier',
        COLLAB: 'Collaborateur',
        ENTRE: 'Entreprise',
        EQUIPE: 'Equipe',
        SITE: 'Site',
        SERVICE: 'Service',
        RESERVATION: 'le nombre maximal de rendez-vous reservables pour ce mois-ci est atteint.',
        FORMATIONS: 'Formations',
        COMPETENCES: 'Compétences',
      },
    },
    EuipeTransverse: {
      Gerer: {
        Nom: 'Nouvelle Équipe Transverse ',
        List: 'Liste des équipes transverses',
      },
      DELETE: {
        TITLE: ' Supprimer équipe transverse',
        DESC_1: 'suppression équipe transverse ...',
        DESC_2: 'Êtes-vous sûr de supprimer définitivement cette équipe transverse ?',
        BTN_DELETE: 'Supprimer',
        BTN_CANCEL: 'Annuler',
      },
    },
    COLLAB: {
      DETAILS: {
        TITLE: 'Liste des collaborateurs',
        NEW_COLLABORATOR: 'Nouveau collaborateur',
        inscription: 'Inscription par lien',
        SEARCH: 'Rechercher',
        FIELD: 'dans tous les domaines',
        PRENOM: 'Prenom',
        NOM: 'Nom',
        Responsable: 'Responsable',
        Fonction: 'Fonction',
        Site: 'Site',
        Service: 'Service',
        Equipe: 'Equipe',
        ACTIONS: 'ACTIONS',
        IMG: 'Image',
        IMPORT_COLLABS_BTN: 'Importer',
        IMPORT_COLLABS_FROM_FILE_TITLE: 'Ajouter des collaborateurs depuis un fichier',
        IMPORT_COLLABS_FROM_FILE_DESC: 'Veuillez importer un fichier .csv, .xls, ou xlsx suivant le format: ',
        IMPORT_COLLABS_FROM_FILE_DESC_FORMAT: 'email, prénom, nom, fonction, service',
        TAG_AS_AMBASSADOR: 'Marquer comme ambassadeur',
        MARKED_AS_AMBASSADOR: 'a été marqué comme ambassadeur',
        UNMARKED_AS_AMBASSADOR: "a n'est plus un ambassadeur",
        ACTIF: 'Actif',
        ALUMNI: 'Alumni',
        DESACTIVER: 'Désactivé',
        EN_ATTENTE: "En attente d'inscription",
        SITE: 'Site',
        FILTRE: 'Filtre',
        ALL: 'Tout',
        TITREALUMNI: 'Transférer dans la liste des alumnis',
        SOUSTITREALUMNI: 'Transférer dans la liste des alumnis ...',
        BODYALUMNI:
          'Êtes vous sûre de vouloir désactiver le compte utilisateur et le transférer dans la liste des alumnis ?',
        BODYALUMNI2: 'confirmation de la date effective de sortie',
        TITREACTIF: 'Réactiver  compte',
        SOUSTITREACTIF: ' Réactivation  compte ...',
        BODYACTIF: 'Êtes vous sure de vouloir réactiver ce compte ?',
        CANCEL: 'Annuler',
        SAVE: 'sauvegarder',
        ENVOIEMAILBIENVENUE: "Paramétrer l'envoie d'email de Bienvenue",
        ENVOYERMAILBIENVENUEINSCRIT: 'Envoyer le mail de bienvenue automatiquement à chaque nouvel inscrit',
        PASENVOYERMAILBIENVENUEINSCRIT: 'Ne pas envoyer de mail de bienvenue automatiquement',
        ENTREPRISESTRUCTURE: "Structure de l'entreprise",
        MONEQUIPE: 'Mon équipe',
        CONFIRMATIONEMAIL: "Confirmation l'envoi de mail de bienvenue",
        MSGCONFIRMATIONEMAIL:
          "Souhaitez-vous envoyer un mail aux collaborateurs déjà ajoutés et n'ayant pas reçu de mail de bienvenue ?",
        OUI: 'Oui',
        NON: 'Non',
        SENDEMAILSALLCOLLABS:
          "Les mails de bienvenue seront envoyés à tous les collaborateurs déjà ajoutés et n'ayant pas reçu de mail.",
        SENDEMAILSALLCOLLABS2:
          'Seuls les nouveaux collaborateurs ajoutés à partir de ce moment recevront le mail de bienvenue.',
        SUCCESSPARAMETRAGE: 'Paramétrage effectué avec succès',
        ERRPARAMETRAGE: 'Erreur lors du paramétrage',
        ListCollegues: {
          LIST: 'Liste des collègues',
          p1: "Si vous voulez pouvoir proposer des rendez-vous à vos collègues, vous devez accepter l'option permettant à vos collègues de réserver un rendez-vous avec vous",
          Profil: 'Profil',
          p2: "Vous devez accepter d'être visible par d'autres afin d'accéder à l'annuaire.",
          p3: "Votre entreprise doit activer l'option annuaire pour les collaborateurs",
          interet: "Centre d'interret:",
        },
      },

      EDIT: {
        Edit: 'Modifier',
        Create: 'Ajouter un ',
        customer: 'collaborateur',
        Nom: 'Nom',
        Name: 'Nom',
        Prenom: 'Prenom',
        FIrstName: 'Prenom',
        fonction: 'Fonction',
        fon: 'Fonction',
        Email: 'E-mail professionnel',
        mail: 'E-mail personnel',
        password: 'Mot de passe',
        m2p: 'Mot de passe',
        Equipe: 'Sélectionner Equipe',
        Role: 'Sélectionner Role',
        ADMIN: 'Administrateur',
        USER: 'Collaborateur',
        BTN_SAVE: 'Sauvegarder',
        BTN_SAVEBROUILLON: 'Sauvegarder en brouillon',
        BTN_SAVEINTERNE: 'Sauvegarder et publier en interne',
        BTN_SAVEPORTAIL: 'Sauvegarder et publier dans le portail',

        BTN_CANCEL: 'Annuler',
        ERR: 'Ce e-mail existe déjà. Veuillez utiliser une autre adresse e-mail',
        ERR1: 'Ce e-mail est invalide. Veuillez utiliser une autre adresse e-mail',
        dateentree: "Date d'entrée",
        dateSortie: 'Date de sortie',
      },
      SELFSERVICE: {
        TITLE: 'Consulter Disponibilité',
      },
      RECONNAISSANCE: {
        TITRE: 'Envoyer une Reconnaissance',
        CHOIXIMAGE: 'Choisissez une image',
        MSGTEXT: 'Message de reconnaissance',
        BTN_SEND: 'Envoyer',
        ADDSUCCESS: 'Reconnaissance ajoutée avec succès',
        SELECTIMAGESUCCES: 'Image sélectionnée avec succès !',
        ERREURSELECTIMAGESUCCES: 'Veuillez sélectionner une image et ecrire un message',
      },
      RESERVER: {
        TITLE: ' Réserver rendez-vous',
        DESC_1: 'Réservation de rendez-vous ...',
        DESC_2: 'Êtes-vous sûr de vouloir réserver  ce rendez-vous ?',
        BTN_DELETE: 'Réserver',
        BTN_CANCEL: 'Annuler',
      },
      DELETE: {
        TITLE: ' Désactiver account',
        DESC_1: ' Désactivation account ...',
        DESC_2: 'Êtes vous sûre de vouloir désactiver le compte utilisateur ?',
        BTN_NETOYER: 'Nettoyer',
        BTN_DELETE: 'Desactiver',
        BTN_CANCEL: 'Annuler',
      },
    },

    ONBOARDINGTEXT: {
      SUIVREONB: "Suivi de l'onboarding_Offboarding",
      ADDBUDDYONB: 'Ajouter buddy',
      ADDFORMATIONONB: 'Ajouter règle de formation',
      ADDACCEUILONB: "Ajouter règle d'accueil",
      ADDTACHEONB: 'Ajouter règle tâche',
      ADDENQUETEONB: 'Ajouter règle enquete',
      ADDDEBRIEFONB: 'Ajouter règle de debriefing',
      ADDRDVONB: 'Ajouter rendez-vous',
      ADDVIDEOONB: 'Ajouter vidéo',
      ACTIVERONB: 'Activer onboarding',
      DESACTIVERONB: 'Désactiver onboarding',
      ACTIFOFFBOARDING: 'Activer offboarding',
      DESACTIFOFFBOARDING: 'Désactiver offboarding',
      ADDDOCONB: 'Ajouter un document',
      TELECHARDERDOCONB: 'Télécharger',
      VOIRDOCONB: 'Voir le document',
      PLUSONB: 'Plus',
      MOINSONB: 'Moins',
      TELECHARGEDOC: 'Télécharger le document',
      TOUTONB: 'Tout',
      FAITONB: 'Fait',
      AFAIREONB: 'À faire',
      INDEXERONB: 'Indexer',
    },
    APIEXTERNE: {
      description: "Génerer une clé d'API externe pour ton entreprise",
      descriptionsupprimer: "Supprimer le  clé d'API externe pour ton entreprise",
      button: 'Génerer clé',
      buttonsupprimer: 'Supprimer clé',
    },
    ANNUAIRE: {
      TITLE: 'Annuaire',
      p0: "Pour prendre en compte les changements il est nécessaire de rafraichir l'application",
      p100: 'Pour prendre en compte les changements il est nécessaire de cliquer sur button sauvegarder apres remplir les champs ci dessous',
      button: "Rafraichir l'application",
      p: "Activer l'annuaire pour les collaborateurs",
      p1: 'Activer les communautés pour les collaborateurs',
      p2: 'Activer les événements pour les collaborateurs',
      p3: 'Activer les questions et réponses pour les collaborateurs',
      p4: 'Activer les  boîtes à idées pour les collaborateurs',
      ponboarding: "Activer l'onboarding pour les collaborateurs",
      Annuaire: "Apparaître dans l'annuaire",
      Accept: 'Accepter de recevoir des propositions de rendez-vous de collègues',
      abn: 'Votre abonnement est',
      parametre_msg1:
        'Gérez vos modules ici en les activant ou désactivant selon votre abonnement pour adapter la plateforme à vos besoins',
    },
    MYACTVITY: {
      status: {
        att: 'Attente de votre confirmation',
        att1: 'Attente de confirmation de ',
        att2: 'Attente de confirmation des deux collaborateurs ',
        rdvConfirme: 'Le rendez-vous est confirmé',
        rdvDecline: 'Vous avez décliné le rdv',
        rdvDecline1: ' a décliné le rdv',
        annule: 'Annulé',
        terminer: 'Terminer',
      },
      DETAILS: {
        detail: 'Détails du rendez-vous',
        restoDetail: 'Détails du restaurant',
        Lieu: 'Lieu',
        ACTIVITY_DETAILS: ' Details du Rendez-vous',
        COFFE: 'Café',
        LUNCH: 'Déjeuner',
        RESTAURANT: 'Restaurant',
        BACK: 'Retour',
        CANCEL: 'Annuler',
        ACCEPT: 'Accepter',
        REFUSE: 'Refuser',
        TERMINER: 'Terminer et Noter',
        ACTIVITY_TYPE: 'type de rendez-vous',
        MADE_BY: 'Organisé par',
        ACTIVITY_STATUS: 'Status du rdv',
        MEETING_IS_WITH: 'Collaborateur',
        MY_STATUS: 'Mon status',
        MY_COMMENT: 'Mon commentaire',
        MY_COMMENT2: 'commentaire de collaborateur',
        RESTAURANT_NAME: 'Nom du restaurant',
        JOIN: 'Rejoindre',
        LEAVE: 'Abandonner',
        SEND: 'Envoyer',
        WELCOME: 'discutez avec ',
        ACCEPTED: 'Accepté',
        REFUSED: 'Refusé',
        CANCELED: 'Annulé',
        CREATED: 'Creé',
        ATTENTE: 'Attente de confirmation',
        FINISHED: 'Terminé',
        RATING: ' Ma note',
        RAITING2: 'collaborateur Note',
        FILE: 'importez votre fichier',
        RATE_COMMENT: 'Note',
        DEATAILLS: 'Detailles',
        note: 'Ma note',
        newMess: 'Communiquer avec votre collaborateur',
        avecQui: 'Avec qui?',
        Avis: 'Avis',
        ser: 'Service: ',
        Avis1: 'Avis:',
        Nom: 'Nom: ',
        Adresse: 'Adresse: ',
        Horraires: 'Horraires: ',
      },
      MENU: {
        TLF: 'Téléphone',
        Cuisine: 'Cuisine',
        Services: 'Services',
        Livraison: 'Livraison',
        Self: 'Self service',
        tearace: 'terrasse',
        vegetariens: 'Convient au vegetariens',
        bio: 'Produits bio',
        Halal: 'Plats Halal',
        gluten: 'Plats sans gluten',
        diabetiques: 'Plats pour diabetiques',
        vegans: 'Convient aux vegans',
      },
      note: {
        head: "Est-ce que votre rendez-vous s'est bien passé ?",
        body: 'Laissez une note',
        comnt: 'Laissez un commentaire',
      },
      LIST: {
        TITLE: 'Gérer les rendez-vous de vos équipes',
        BTN_ADD: 'Nouvelle activité',
        SEARCH: ' Rechercher ',
        FIELD: ' dans tous les domaines',
        start_date: 'Date de début',
        status_global: ' Statut globale',
        user_1: 'Collaborateur 1',
        status_1: 'Statut 1',
        user_2: 'Collaborateur 2',
        status_2: 'Statut 2',
        ACTIONS: 'Actions',
      },
      EDIT: {
        TITLEADD: 'un rendez-vous',

        TITLEMODIF: 'le rendez-vous',
        CREATE: 'Ajouter',
        UPDATE: 'Modifier',
        TITLE: 'Rendez-vous',
        Date: 'Date de début',
        Restaurant: 'Entrez le nom du restaurant',
        USER_1: 'Sélectionnez le convive 1',
        User1: 'Convive 1',
        USER_2: 'Sélectionnez le convive 2',
        User2: 'Convive 2',
        Description: 'Déscription',
        type: 'Type de rendez-vous',
        caffee: 'Café',
        meal: 'Repas',
        BTN_SAVE: 'Sauvegarder',
        BTN_CANCEL: 'Annuler',
        ERRERRDV: 'Un rendez vous existe pour les users dans cette date',
      },
      DELETE: {
        TITLE: ' Supprimer Rendez-vous',
        DESC_1: 'suppression Rendez-vous ...',
        DESC_2: 'Êtes-vous sûr de supprimer définitivement ce rendez-vous ?',
        BTN_DELETE: 'Supprimer',
        BTN_CANCEL: 'Annuler',
      },

      ACTIVITY: {
        TITLE: 'Liste des rendez-vous',
        SEARCH: ' Rechercher ',
        FIELD: ' dans tous les domaines',
        start_date: 'date de début',
        Disponible: 'Liste des personnes disponibles',
      },
      RATING: {
        TITLE: "Est-ce que votre rendez-vous s'est bien passé ?",
        COMMENT: 'Laissez un commentaire',
        NOTE: 'Laissez une note',
        no: 'Note',
        BTN_SAVE: 'Sauvegarder',
        BTN_CANCEL: 'Annuler',
        BTN_SAVESEND: 'Sauvegarder et envoyer',
      },
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: "Nombre d'enregistrements sélectionnés: ",
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields',
      },
      ECOMMERCE: 'éCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Les clients',
        CUSTOMERS_LIST: 'Liste des clients',
        NEW_CUSTOMER: 'Nouveau client',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Suppression du client',
          DESCRIPTION: 'Êtes-vous sûr de supprimer définitivement ce client?',
          WAIT_DESCRIPTION: 'Le client est en train de supprimer ...',
          MESSAGE: 'Le client a été supprimé',
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Supprimer les clients',
          DESCRIPTION: 'Êtes-vous sûr de supprimer définitivement les clients sélectionnés?',
          WAIT_DESCRIPTION: 'suppression clients ...',
          MESSAGE: 'Les clients sélectionnés ont été supprimés',
        },
        UPDATE_STATUS: {
          TITLE: 'Le statut a été mis à jour pour les clients sélectionnés',
          MESSAGE: 'Le statut des clients sélectionnés a été mis à jour avec succès',
        },
        EDIT: {
          UPDATE_MESSAGE: 'Le client a été mis à jour',
          ADD_MESSAGE: 'Le client a été créé',
        },
      },
    },
    SITE: {
      LIST: {
        TITLE: 'Liste des sites',
        BTN_ADD: 'Nouveau site',
        SEARCH: 'Rechercher ',
        FIELD: 'dans tous les domaines',
        NAME: 'Nom',
        ADDRESS: 'Adresse',
        VILLE: 'Ville',
        PAYS: 'Pays',
        ACTIONS: 'Actions',
      },
      EDIT: {
        res: 'Responsable',
        ressite: 'Responsable du site',

        Edit: 'Modifier',
        Create: 'Créer un nouveau site',
        SITE: ' le site',
        Nom: 'Nom du site',
        Name: 'Nom',
        Address: 'Adresse',
        add: 'Addresse',
        ville: 'Ville',
        city: 'Ville',
        pays: 'Pays',
        country: 'Pays',
        BTN_SAVE: 'Sauvegarder',
        BTN_CANCEL: 'Annuler',
        AMBASSADEUR: 'Ambassadeur du site',
      },
      DELETE: {
        TITLE: '  Supprimer Site',
        DESC_1: ' suppression Site ...',
        DESC_2: 'Êtes-vous sûr de supprimer définitivement ce Site?',
        BTN_DELETE: 'Supprimer',
        BTN_CANCEL: 'Annuler',
      },
    },
    INDICATOR: {
      DELETE: {
        TITLE: '  Supprimer Indicateur',
        DESC_1: ' suppression indicateur ...',
        DESC_2: 'Êtes-vous sûr de supprimer définitivement cet indicateur pour ce site?',
        BTN_DELETE: 'Supprimer',
        BTN_CANCEL: 'Annuler',
      },
    },
    GROUP: {
      LIST: {
        TITLE: 'Liste des équipes',
        BTN_ADD: 'Nouvelle équipe',
        SEARCH: 'Rechercher ',
        FIELD: 'dans tous les domaines',
        NAME: "Nom de l'équipe",
        MSG1: 'ATTENTION, vous ne pouvez pas le supprimer,',
        MSG2: 'Des salariés sont attachés à cette équipe!',
        NBR: 'N° MEMBRES',
        SERVICE: ' nom du Service ',
        ACTIONS: 'Actions',
        USERS: 'Utilisateurs',
        AFFECTER: 'Affecter',
        MODIFIEREQUIPE: 'Modifier',
        SUPPRIMEREQUIPE: 'Supprimer',
        HISTORIQUE: 'Historique',
      },
      EDIT: {
        Edit: 'Modifier',
        Create: 'Créer une nouvelle équipe',
        GROUP: 'équipe',
        GROUPREQUIS: " Nom de l'équipe",
        Service: 'Sélectionnez le Service',
        BTN_SAVE: 'Sauvegarder',
        BTN_CANCEL: 'Annuler',
        NOMGROUP: 'Nom de l équipe',
        GROUPedit: "l'équipe",
        RESPONSABLE_EQUIPE: "Responsable de l'équipe",
        AMBASSADEUR_EQUIPE: "Ambassadeur de l'équipe",
      },
      EDITTRANSVERSE: {
        Edit: 'Modifier',
        Create: 'Créer une équipe transverse',
        GROUP: 'Équipe Transverse',
        GROUPREQUIS: " Nom de l'équipe ",
        Service: 'Sélectionnez le Service',
        BTN_SAVE: 'Sauvegarder',
        BTN_CANCEL: 'Annuler',
      },
      DELETE: {
        TITLE: '  Supprimer Equipe',
        DESC_1: 'Suppression équipe ...',
        DESC_2: 'Êtes-vous sûr de supprimer définitivement cette equipe?',
        BTN_DELETE: 'Supprimer',
        BTN_CANCEL: 'Annuler',
      },
    },
    SERVICE: {
      LIST: {
        TITLE: 'Liste des services',
        BTN_ADD: 'Nouveau service',
        SEARCH: 'Rechercher ',
        FIELD: 'dans tous les domaines',
        NAME: 'Nom du service',
        SITE: 'nom du site',
        ACTIONS: 'Actions',
      },
      EDIT: {
        Edit: 'Modifier',
        Create: 'Créer un nouveau service',
        SERVICE: ' le service',
        NAME: 'Nom du service',
        SITE: 'Sélectionnez le Site',
        si: 'Site',
        BTN_SAVE: 'Sauvegarder',
        BTN_CANCEL: 'Annuler',
        RESP_SERVICE: 'Responsable de service',
        AMBASSADEUR_SERVICE: 'Ambassadeur de service',
      },
      DELETE: {
        TITLE: '  Supprimer service',
        DESC_1: 'suppresion Service...',
        DESC_2: 'Êtes-vous sûr de supprimer définitivement ce service?',
        BTN_DELETE: 'Supprimer',
        BTN_CANCEL: 'Annuler',
      },
    },
    USER_PROFILE,
    DASHBOARD: {
      MYACTIVITY: 'Taux implication Global',
      RDVFINICHED: 'Rendez-vous terminés',
      RDVREFUSED: 'Rendez-vous refusés',
      NOTEMOY: 'Note moyenne',
      RDVACCEPTED: 'Rendez-vous acceptés',
    },
    DASHBOARD1: {
      MESRDV: 'Mes rendez-vous',
      MESRDVonboarding: 'Tes rendez-vous',

      ABSCENCERDV: 'Pas de rendez-vous pour le moment',
      VOIR: 'Voir tout',
      EQUIPE: 'Ton équipe',
      ABSEQUIPE: 'Pas de membres dans ton équipe pour le moment.',
      BUDDY: 'Ton buddy',
      ABSBUDDY: 'Aucun buddy',
      INFORMATIONSCONTACT: 'Informations de contact',
      ABSINFORMATIONSCONTACT: 'Pas encore existant',
      ACCEUILPREMIERJOUR: "La personne qui t'accueillera",
      ABSACCEUILPREMIERJOUR: "La personne qui t'accueillera n'est pas encore attribuée.",
      INCONNU: 'Inconnu',
      Compte: 'Compte à rebours',
      Jours: 'jours',
      Heures: 'heures',
      Minutes: 'minutes',

      Precedent: 'Précédent',
      Suivant: 'Suivant',
      CONGRAT: 'Envoyer les félicitations',
      ANNIVERSAIRE: "Aujourd'hui, c'est l'anniversaire de",
      CARTEDEJOUR: 'Tes cartes du jour',
      ABSCENCERECRUE: 'Pas de nouveaux arrivants',
      DEMANDECAFE: 'Offrez lui un café',
      RECONNAISSANCE: 'Reconnaissance',
      RDVPROCHAIN: 'Vos prochains Rendez-vous',
      WITH: 'Avec',
      Restaurant: 'Restaurant',
      GROWTITLE: 'Fais grandir ta carrière',
      GROWMSG1: 'Partage des moments de convivialité avec tes collègues et élargis ton réseau !',
      GROWMSG2: "Bravo 👏 ton réseau s'étoffe, continue d'accroitre ta visibilité !",
      GROWMSG3: "Bravo 👏 tu as bien développé ton réseau, continue à l'entretenir ! ",
      POTENTIELTITLE: 'Le potentiel de ton profil',
      POTENTIELMSG1: 'Renseigne ton profil pour briser plus facilement la glace.',
      POTENTIELMSG2: 'Bravo 👏 ton profil est au maximum de son potentiel!',
      POTENTIELMSG3: 'Vous êtes à la  position ',
      POTENTIELMSG4: '  des top miameurs!',
      POTENTIELMSG5: 'Merci de faire des rendez-vous pour augmenter votre position!',
      PROCHAINRDV: 'Prochains rendez-vous',
      ABSRESTAURANT: 'Lieu à définir',
      ABSRDV: 'Pas de rendez-vous planifiés',
      ABSRDV2: "Vous n 'avez pas de rendez-vous planifiés pour le moment",
      ABSNOTIF: "Vous n'avez pas de notifications pour le moment",

      ABSEQUIPEMOIS: 'Pas de top  équipes pour ce  mois',
      ABSMIAMEURSSEMAINE: 'Pas de top miameurs cette semaine',
      ABSRDV3: 'Pas de top services pour ce mois',
      ABSEVENT: "Pas d'événement planifiés",
      ABSEVENT2: "Vous n 'avez pas  des événements planifiés pour le moment",
      ABSIDEA: "Pas d'idée planifiés",
      ABSIDEA2: "Vous n 'avez pas  d'idée planifiés pour le moment",

      TOPSERVICES: 'Top services du mois',
      ABSSERVICE: 'Classement bientôt disponible',
      RDV: 'Rendez-vous',
      TOP_MIAM_SEMAINE: 'Top miameurs de la semaine',
      JOURSFERIES: 'Jours fériés',
      TOP_MOIS_EQUIPE: 'Top Équipes du mois',
      EVENNEMENT: 'Evenements',
      IDEA: 'Idée collaborateur',
      PARTICIPATION: 'Participation des collaborateurs      ',
      BAROMETRE0: 'Prochaine action:',
      BAROMETRE: 'Lancer le Barométre',
      GO: "j'y vais",
      COLLABS: 'Collaborateurs',
      ACTIVE: 'Actifs',
      GENRE: 'Genre',
      SORTIE: 'Sorties',
      ENTREE: 'Entrées',
      ALERT: 'Alertes',
      QUESTION: 'Questions en attente',
      IDEAOUVERTES: "Nombre d'idées ouvertes",
      DETAILS: 'Plus de détails',
      SELECT: 'Selectionner site ici',
      PARTICIPATIONSER: 'Participation par service',
      ABSSERVICEdash: 'pas des services pour ce site',
      EVOLUTIONQVCT: 'Evolution de la QVCT',
      BAROMETREDASH: 'Barométre QVCT',
      BAROMETRERELATION: 'Relations',
      BAROMETRECONTENU: 'Contenu',
      BAROMETRESANTE: 'Santé',
      BAROMETREEGALITE: 'Egalité',
      BAROMETREMANAGEMENT: 'Management',
      BAROMETRECOMPETENCES: 'Compétences',
      RendezvousDASH: 'Rendez-vous',
      EvenementsDASH: 'Evenements',
      NewsletterDASH: 'Newsletter',
      FormationDASH: 'Formation',
      ideeDASH: 'Boite  à idée',
      SAVEDASH: 'Sauvegarder',
      BIENVENUERECRU: 'Bienvenue aux nouvelles recrues ',
      BIENVENUERECRU1: 'Nous souhaitons la bienvenue à ',
      BIENVENUERECRU2: 'qui intègre notre entreprise dans le service ',
      BIENVENUERECRU3: 'Voir plus',
      BIENVENUERECRU4: 'Offrez lui un café',
      PARRAINEPERSONNE: 'Les personnes que tu parraines',
      ABSPARRAINEPERSONNE: 'aucune personne à parrainer',
      ANNIF: 'Anniversaires à venir',
      ABSANNIF: "Vous n'avez pas d'anniversaires à venir pour le moment",
      FERIER: 'Jours fériés à venir',
      TEMPSTRAVAILLE: 'Temps travaillé',
      PAUSEDEPEPUIS: 'En pause depuis',
      COMMENCER: 'Commencer ma journée',
      REPREND: 'Je reprends',
      PAUSE: 'Je fais une pause',
      TERMINERJOURNE: 'Terminer ma journée',
      BIENVENUE: 'Bienvenue',
      BIENVENUEEQUIPE: 'Nous sommes ravis de te compter dans notre équipe!',
      COMPLETEPROFIL: 'Complète ton profil',
      VOICI: 'Voici tes actions à réaliser :',
      VOIRVIDEO: "Voir la video d'accueil",
      SUIVREFORMATION: 'Suivre les formations demandées',
      COMMENCERONB: 'Commencer',
    },
    PARAMETRENOTIFICATION: {
      PARAMETRENOTIF: 'Paramétrage des préférences de notifications',
      NOTIFEMAIL: 'Recevoir les notifications par email',
      NOTIFSLACK: 'Recevoir les notifications par slack',
      NOTIFTEAMS: 'Recevoir les notifications par teams',
    },
    INDICATEUR: {
      BLOCK: "bloquer la valeur de l' indicateur",
      TITLE:
        'Pour prendre en compte les changements il est nécessaire de cliquer sur button save apres remplir les champs ci dessous',
      CONFLITS: 'taux de conflit',
      GESTIONCONFLIS: 'taux de gestion de conflits',
      SATISFACTION: 'taux de satisfaction',
      ENGAGEMENT: 'taux d engagement',
      AUTONOMIE: "taux d'autonomie",
      VARIETETACHE: 'taux de variété de tâches',
      PRODUCTIVITE: 'taux de productivité',
      ERREUR: 'taux d erreur',
      SANTE: "taux d'absentéisme santé",
      STRESS: 'taux de stress professionnel',
      BIENETRE: 'indice de bien_etre',
      BURNOUT: 'taux de burnout',
      MOBILITE: 'taux de mobilité professionnelle',
      PROMOTION: 'taux de promotion interne',
      SCORE: 'score global',
    },
    DASHBOARD2: {
      RDVACC: 'RDVS Acceptés',
      RDVRED: 'RDVS Refusés',
      RSVCAN: 'RDVS Annulés',
    },
    WIZARDS: {
      Bienvenue: 'Bienvenue',
      ADD: 'Adresse',
      ADDSIEGE: 'Adresse du siège social',
      ACCOUNTTYPE: 'Type de compte',
      Organization: 'Organisation',
      Service: 'Service',
      Siege: 'Siège',
      Team: 'Équipe',
      Ended: 'Terminé',
      CYTOA: 'Choisissez votre type de compte',
      IFYOUNEED: "Si vous avez besoin de plus d'informations vous pouvez consulter",
      help: "L'aide en ligne",
      Société: 'Société',
      Équipe: 'Équipe',
      GRP: "Groupe d'amis",
      ENTER: 'Entrez les détails de votre société',
      NOMSOS: 'Nom de votre société',
      SILNOMSOS: "S'il vous plait tapez votre nom de société.",
      Country: 'Pays',
      Ville: 'Ville',
      ENTERCITY: "S'il vous plait tapez votre ville.",
      ENTEREQ: 'Taper les détails de votre équipe',
      ENTERNOMEQ: 'Nom de votre équipe',
      SILENTERNOMEQ: "S'il vous plait tapez votre nom de l'équipe.",
      ENTERGRP: "Taper les détails de votre groupe d'amis",
      ENTERADD: "S'il vous plait tapez votre adresse",
      taille: 'Quelle est la taille de votre entreprise: ',
      ENterGRPNAME: 'Nom de votre groupe',
      SILENTERNOMSos: "S'il vous plait tapez votre nom de société.",
      ServiceDetails:
        "Pour fournir un service intelligent et personnalisé pour chaque collaborateur nous avons besoin de connaitre la position de chacun dans l'entreprise.",
      nomService: 'à quel service êtes vous rattaché(e)',
      SILService: "S'il vous plait tapez votre service.",
      DETAILSDESER: 'Les détails de votre service',
      NOMSERVICE: 'Nom de service',
      TAPEREQ:
        "Pour fournir un service intelligent et personnalisé pour chaque collaborateur nous avons besoin de connaitre la position de chacun dans l'entreprise.",
      NomEQ: ' à quelle équipe êtes vous rattaché(e) ?',
      SILEQ: "S'il vous plait tapez votre équipe.",
      DetailsEQ: 'Les détails de votre équipe',
      REVIEW: 'Vérifiez vos détails et soumettez',
      FELICITATION: 'Félicitations vous êtes prêt à démarrer',
      REQ1: 'Maintenant ajoutez des salariés à:votre entreprise',
      REQ2: 'Membres à votre equipe',
      REQ3: 'Membres à votre groupe',
      Previous: 'Précédent',
      Next: 'Étape suivante',
      Equipe_Trans: 'Équipe transverse qui mène le suivi',
      SITE_Talend: 'Site des collaborateurs bénéficiaires',
      Submit: 'Soumettre',
    },
    DISPO: {
      EMI: {
        title: 'Comment puis-je vous aider ?',
        send: 'Envoyer',
        historique: 'Mon historique',
        poser: 'Poser la question',
        p: "Attention les réponses de l'assistant sont à but informatif et ne peuvent pas se substituer aux conseils d'un expert. WeMiam ne porte aucune responsabilité vis à vis de l'application ou non des conseils et des indications fournies par l'IA qui alimente cet assistant. Si vous avez besoin de réponses plus pointues ou d'assistance, nos experts sont disponibles pour vous aider.",
        contacter: 'Contacter un Expert',
        question: 'Mes questions',
        placeholderquestion:
          'Il est important de préciser votre demande afin que nous puissions vous offrir une réponse adéquate. Veuillez nous donner plus de détails sur ce que vous souhaitez afin que nous puissions comprendre vos besoins et vous aider de la meilleure manière possible.',
      },
      demander: 'Demander à ',
      btn: 'Je suis disponible',
      btnDej: 'Déjeuner',
      btnAfter: 'Après le travail',
      alert: 'Vous êtes déjà enregistré(e) pour cet évènement. ',
      att: 'Attention',
    },
    STRATEGIE: {
      GERER: {
        list: 'Liste des stratégies',
        new: 'Nouvelle stratégie',
        icons: 'Icônes',
        NAME: 'NOM',
        DESCRIPTION: 'DESCRIPTION',
      },
      EDIT: {
        DETAILSSTRATEGIE: 'Détails de la stratégie',
        EDIT: 'Modifier la stratégie',
        event_id: 'Evénement',
        nom: "Nom d'événement",
        HEURE: 'Heure de generation',
        jour_mois: 'Jour de mois',
        jour_semaine: 'Jour de semaine',
        type_frequence: 'Fréquence de génération',
        equipe_cible1: 'Équipe qui bénéficie du Service',
        equipe_cible2: 'Équipe qui met à disposition son calendrier',
        site_cible: ' Site concernée',
        service_cible: 'Service concerné',
        equipetrans_cible: 'Equipe transverse concerné',
        delai: 'Délai de prevenance',
        delai_minimum: "Délai minimum entre deux reservations d'un même membre",
        nbre_mois: 'Nombre de reservations autorisées par mois',
        jours: 'Tous les jours',
        mois: 'Tous les mois',
        semaines: 'Toutes les semaines',
        datedemarrage: 'Date de démarrage',
        type: "Type d'evenement",
        dej: 'Dejeuner',
        petitdej: 'Petit déjeuner',
        after: 'Après le travail',
        diner: 'Dîner',
      },
      DELETE: {
        TITLE: '  Supprimer stratégie',
        DESC_1: 'Suppression stratégie ...',
        DESC_2: 'Êtes-vous sûr de vouloir supprimer définitivement cette stratégie?',
        BTN_DELETE: 'Supprimer',
        BTN_CANCEL: 'Annuler',
      },
      ACTIVATE: {
        TITLE: 'Activer stratégie',
        TITLE1: 'Désactiver stratégie',
        DESC_1: 'Êtes-vous sûr de vouloir activer temporairement cette stratégie?',
        DESC_2: 'Êtes-vous sûr de vouloir désactiver temporairement cette stratégie?',
        BTN_ACTIVATE: 'Activer',
        BTN_DESACTIVATE: 'Désactiver',
        BTN_CANCEL: 'Annuler',
      },
      WIZARDS: {
        taille: 'Quelle est la taille de votre entreprise: ',
        Param: 'Paramétrer votre stratégie',
        ACCOUNTTYPE: 'Type de stratégie',
        Organization: 'Détails',
        Service: 'Date de lancement',
        Detailssp: 'Détails spécifiques',
        Ended: 'Terminé',
        CYTOA: 'Choisissez votre stratégie',
        IFYOUNEED: "Si vous avez besoin de plus d'informations vous pouvez consulter",
        help: "L'aide en ligne",
        ENTER: 'Personnalisez votre stratégie',
        Société: 'Société',
        FREQUENCY: 'Fréquence',
        TYPEEVENT: "Type d'événement",
        JOUR: 'Jour de génération',
        HOUR: "Heure de l'activité",
        site: 'Collaborateurs ciblès',
        plus: 'À quelle date cette stratégie doit-elle démarrer ?',
        demarrage: 'Date de démarrage',
        Previous: 'Précédent',
        Next: 'Étape suivante',
        Submit: 'Soumettre',
        FELICITATION: 'Félicitation, votre stratégie est prête!',
        name: 'Nom de la stratégie',
        duree_min: 'Durée minimum entre deux activités avec le même collaborateur (en mois)',
        duree_min1: 'Durée minimale',
        duree_max: 'Durée maximum entre deux activités avec le même collaborateur (en mois)',
        duree_max1: 'Durée maximale',
        delaiPrev: 'Délai de prévenance minimum (En jours)',
        delaiPrev1: 'Délai de prévenance',
        nbrAct: 'Nombre maximum des rendez-vous',
        nbrAct1: 'Nombre des rendez-vous',
        typeAct: "Type d'activités",
        pas: 'Pas de paramètre spécifique pour cette stratégie',
        delaiMin: "Délai minimum entre deux reservations d'un même membre",
        nbrAu: 'Nombre de reservations autorisées par mois',
        list1: 'Périmètre des collaborateurs qui bénéficient du self service',
        list2: 'Équipe bénéficiant du self service',
        list3: 'Équipe transverse proposant son calendrier',
      },
      DETAILS: {
        nettoyage: 'Nettoyer les rendez-vous',
        reginerer: 'Génèrer les rendez-vous',
        Refresh: 'Rafraîchir',
        generer: 'Génèrer',
        strategieCible: 'Stratégie',
        Collaborateur: 'Collaborateur',
        Status: 'Statut',
        ListRDV: 'Liste des rendez vous',
        ListUSER: 'Liste des utilisateurs bénéficiaires',
        ListUSER1: 'Liste des utilisateurs ouvrant leur calendrier',
        DESC_1: 'Annuler tous les rendez-vous qui ne sont pas terminés quel que soit leur statut',
        DESC_2: 'Régénérer les rendez-vous et envoi des invitations aux collaborateurs',
        DESC_3: 'Êtes-vous sûr de vouloir lancer maintenant',
        minute: "Le régénération des rendez-vous peut prendre jusqu'à une minute",
        BTN_NETOYER: 'Nettoyer',
      },
    },
    CONTROLEDESAISIE: {
      dateInFuture: 'La date ne doit pas être dans le futur.',
      startBeforeEnd: 'La date de début doit être antérieure à la date de fin.',
      sameDate: 'La date de début et de fin ne peuvent pas être identiques.',
      VALID: ' a été saisi correctement',
      required: ' est requis',
      min: ' doit avoir au moins 3 symboles',
      min2: ' doit avoir au moins 2 symboles',
      max: ' devrait avoir un maximum de 500 symboles',
      email: ' format est incorrect',
      min0: ' devrait avoir un nombre superieur a 0',
      max100: ' devrait avoir un nombre inferieur a 100',

      datemin: ' devrait avoir un nombre superieur a 1',
      datemax: ' devrait avoir un nombre inferieur a 32',
      notemin: ' devrait avoir un note superieur ou egal a 0',
      notemax: ' devrait avoir un note inferieur a 6',
      notmatch: ' ne correspondait pas',
      semaineMin: ' devrait avoir un nombre de jours superieur a 1',
      semaineMax: ' devrait avoir un nombre de jours inferieur a 60',
      semaineMin1: 'devrait avoir un nombre de semaines superieur a 1',
      semaineMax1: ' devrait avoir un nombre de semaines inferieur a 5',
      monthMin: 'devrait avoir un nombre de mois inferieur a 1',
      logicDate: 'la date de fin doit être supérieure à la date de début',
      minvalue: 'devrait avoir un nombre superieur ou egal a 0',
      maxvalue: 'devrait avoir un nombre inferieur ou egal a 100',
    },
    GERER: {
      Title: 'Votre organisation ',
      IMAGE: 'Photo',
      nom: 'Nom',
      pays: 'Pays',
      ville: 'Ville',
      update: 'mettre à jour',
    },
    motdepasse: {
      titre1: 'Changer le mot de passe ',
      titre2: 'Modifier le mot de passe de votre compte',
      btnSave: 'Mettre à jour',
      btnCancel: 'Annuler',
      err1: "Configurez les mots de passe des utilisateurs pour qu'ils expirent périodiquement. Les utilisateurs devront être avertis que leurs mots de passe vont expirer,",
      err2: ' ou ils pourraient être bloqués par inadvertance hors du système !',
      succes: 'Le mot de passe a été changé avec succès',
      verify: 'Vérifiez votre mot de passe actuel',
      currentpwd: 'Mot de passe actuel',
      newpass: 'Nouveau mot de passe',
      verifypass: 'vérifier le mot de passe',
      passwordconfirm: 'Password confirmation',
    },
    disponibilite: {
      dispo: 'Disponibilités',
      new: 'Nouvelle disponibilité',
      Search: 'Chercher une date',
      all: 'dans tous les domaines',
      jour: 'Jour',
      EVENT: 'Evenement',
    },
    deleteDispo: {
      title: 'Supprimer la disponibilité',
      msg1: 'suppression disponibilité...',
      msg2: 'Êtes-vous sûr de supprimer définitivement cette disponibilité ?',
      btnCancel: 'Annuler',
      btnDelete: 'Supprimer',
    },
    createDisponibilite: {
      title: 'Créer disponibilité',
      new: 'Nouvelle disponibilité',
      date: 'Date',
      créneaux: 'Créneaux',
    },
    status: {
      created: 'créé',
      accepted: 'accepté',
      refused: 'refusé',
      finished: 'achevé',
      canceled: 'annulé',
      notified: 'notifié',
    },
    acceptMyAct: {
      title: 'Activité acceptée',
      info: 'Êtes-vous sûr de vouloir accepter cette activité ?',
      btnCancel: 'Annuler',
      btnAccept: 'Accepter',
    },
    EditMyAct: {
      Edit: 'Éditer',
      Create: 'Créer',
      comment: 'commenter/noter',
      labelComment: 'Commenter',
      valid1: 'Le commentaire a été saisi correctement',
      valid2: 'Le commentaire doit comporter au moins 3 symboles',
      valid3: 'Le commentaire doit avoir un maximum de 10000 symboles',
      btnCancel: 'Annuler',
      btnSave: 'sauvegarder',
    },
    refuseMyAct: {
      title: "Refus d'activité",
      info: 'Êtes-vous sûr de vouloir refuser cette activité ?',
      btnCancel: 'Annuler',
      btnAccept: 'Refuser',
    },
    cancelAct: {
      title: "Annulation de l'activité",
      info: "Êtes-vous sûr d'annuler cette activité ?",
      btnCancel: 'Annuler',
      btnAccept: 'sauvegarder',
    },
    dialogInsc: {
      insc: 'Inscription par lien',
      btn: 'Copier',
    },
    jour: {
      importe: "N'import",
      dimanche: 'Dimanche',
      lundi: 'Lundi',
      mardi: 'Mardi',
      mercredi: 'Mercredi',
      jeudi: 'Jeudi',
      vendredi: 'Vendredi',
      samedi: 'Samedi',
    },
    typeDispo: {
      petitDej: 'Petit déjeuner',
      dej: 'Déjeuner     ',
      afterwork: 'Après le travail',
      diner: 'Diner     ',
    },
    editDispo: {
      Edit: 'Edit',
      Create: 'Créer',
      dispo: 'disponibilité',
      err1: 'Verifier votre donnée ,',
      err2: 'Ouups! disponibilité déjà existe!',
      selectDay: 'Sélectionnez le jour',
      day: 'Jour',
      selectType: 'Sélectionner le genre',
      type: 'Type',
      Cancel: 'Annuler',
      Save: 'Sauvegarder',
    },
    ListeDesJeux: {
      ListJeux: 'liste des jeux',
      jeuxAssoc: 'Jeux associés',
      pasAssoc: 'Jeux non associés',
      joueurs: 'joueurs',
    },
    gameOne: {
      ListJeux: 'liste des jeux ',
      gereAssoc: 'gérer les stratégies associés',
      rein: 'réinisitialiser les stats',
      ListJeuxM: 'retour',
      gereAssocM: 'gérer',
      reinM: 'relancer',
      desc: 'description complète de jeux ',
      points: 'points',
      numFois: 'nombre de fois joué ',
      temp: 'temp de jeux moyen',
      collab: 'les collaborateurs les plus actifs',
      date: 'date de dernière participation',
      numDePart: 'nombre de participation',
      serviceActif: 'les services les plus actifs',
      taux: 'taux de participation',
      derparticipation: 'date de dernière participation',
    },
    popupstart: {
      liste: 'liste des Stratégies',
      assoc: 'associer',
      dissoc: 'dissocier',
      activer: 'activer',
      des: 'désactiver',
      garde: "garde les résultats secret jusqu'a la fin de jeux",
    },
    assocStrat: {
      assoc: 'associer à une stratégie',
      dateD: 'date de début',
      dateF: 'date de fin',
      validate: 'valider',
      annuler: 'annuler',
    },
    sondage: {
      title: 'Liste des sondages',
      icon: 'ICÔNES',
      nom: 'NOM',
      description: 'DESCRIPTION',
      action: 'ACTIONS',
    },
    jeux: {
      play: 'jouer',
      played: 'terminé',
      title: 'Titre du jeu :',
      quit: 'quitter',
      actif: 'inactif',
      timer: 'se termine dans',
      timerDays: 'jours',
    },
    reset: {
      resettitle: 'êtes-vous sûr de vouloir supprimer les statistiques de ce jeu ?',
      resetdesc:
        "AVERTISSEMENT : cela supprimera les statistiques du jeu, y compris le score, le temps joué, le nombre de fois joué pour tous les joueurs appartenant à l'entreprise",
      confirm: 'confirmer',
    },
    wsregister: {
      wsr: "L'INTERFACE DU JEUX EST TEMPORAIREMENT INDISPONIBLE  !",
      wsolution:
        "il semble que l'interface du jeu ne soit pas accessible pour le moment, veuillez patienter jusqu'à ce que le problème soit résolu ou vous pouvez essayer de résoudre ce problème en vous reconnectant à notre site Web. Merci pour votre compréhension",
      annuler: 'annuler',
      déconnecter: 'déconnecter',
    },
    HAUTPOTENTIEL: {
      head: 'Marquer "Haut Potentiel"',
      body: 'Date du fin de tag',
      comnt: 'Laissez un commentaire',
    },
    DETAG: {
      TITLE: ' Supprimer tag collaborateur',

      DESC_2: 'Êtes-vous sûr de supprimer définitivement tag de ce collaborateur ?',
      BTN_DELETE: 'Supprimer',
      BTN_CANCEL: 'Annuler',
    },
    SUIVIPAR: {
      head: 'Marquer "Suivi particulier"',
      body: 'Date du fin de tag',
      comnt: 'Laissez un commentaire',
    },
    DETAGSUIVIPAR: {
      TITLE: ' Supprimer tag collaborateur',

      DESC_2: 'Êtes-vous sûr de supprimer définitivement tag de ce collaborateur ?',
      BTN_DELETE: 'Supprimer',
      BTN_CANCEL: 'Annuler',
    },
    presentation: {
      PERSO_INFO: 'Informations personnelles',
      presentation: 'Presentation',
      CHANGE_PASS: 'Changer le mot de passe',
      parag: 'Presentation',
      date: "Date d'anniversaire",
      hobies: 'Occupations sélectionnés',
      AVAIL: 'Disponibilités',
      succ: 'Profil modifié avec succès',
      allow: 'Types de fichiers autorisés',
      btn: 'Mise à jour',
      COLLAB: 'Collaborateur',
      ENTRE: 'Entreprise',
      EQUIPE: 'Equipe',
      SITE: 'Site',
      SERVICE: 'Service',
      msg: 'To validate your profile click on the update button',
    },
    toastr: {
      evnt: 'Vous êtes enregistré(e) pour cet évènement.',
      link: 'Le lien a ete copier correctement.',
      key: 'La clé a ete copier correctement.',
      gnrl: 'Votre donner enregistrer avec succés',
      pres_err: 'Veillez selectionner une video de taille moin de 10M!',
      pres_err1: 'Veillez selectionner une video!',
      pres_success: 'Votre donner enregistrer avec succés',
      idea: "Aucune idée n'est en cours de validation",
      eventinscrit: "Vous êtes inscrit pour l'événement",
      eventinscritdate: 'pour la date du',
      titreevent: 'Evénement',
      absabonnementevent: 'Les évènements sont disponible dans une offre supérieure',
      absabonnementidea: 'Les idées des  collaborateurs sont disponible dans une offre supérieure',
    },
    Notification: {
      newnotification: 'Nouvelle Notification',
      rendezvosmeeting: 'Rendez-vous ',
    },
    ANNONCE: {
      NVLANN: 'Nouvelle Annonce',
      ANN: 'Annonces',
      LISTANNONCE: 'Liste des annonces',
      MODIFANNONCE: 'Modifier annonce',
      SUPPRIMERANNONCE: 'Supprimer annonce',
    },
    CONGE: {
      conge: 'Congés',
      new: 'Nouveaux congés',
      dateDebut: ' Date debut',
      dateFin: ' Date fin',
      EDIT: {
        NEW: 'Nouveaux congés',
        date_début: 'Date de début',
        debut_ms: 'Debut',
        date_fin: 'Date de fin',
        fin_ms: 'Fin',
      },
      DELETE: {
        TITLE: ' Supprimer congés',

        DESC_2: 'Êtes-vous sûr de vouloir supprimer définitivement ce congé?',
      },
    },
    PRESENTAION: {
      info: 'Informations sur ',
      VIDEO: 'Vidéo',
      Presentation: 'Présentation',
      HOBIES: 'Passe-temps',
      NOTAVAILBLE: ' indisponible',
    },
    Planning: {
      LIST: {
        TITLE: 'Planning',
        SITE: 'Site ',
        DT_BEBUT: 'Date de début',
        DT_FIN: 'Date de fin',
        BTN: 'Nouveau déplacement',
      },
      EDIT: {
        TITLE: 'Créer planning',
        TITLE1: 'Modifier planning',
        SITE: 'Select Site',
        DT_BEBUT: 'Date de début',
        DT_FIN: 'Date de fin',
      },
      DELETE: {
        TITLE: 'Supprimer le planning',
        CONTENT: ' Suppression déplacement ...',
        CONTENT2: 'Êtes-vous sûr de supprimer définitivement ce déplacement ?',
      },
    },
    TopBar: {
      ESPACE1: 'Espace Administrateur',
      ESPACE2: 'Espace Salarié',
    },
    CHAT,
    COMMUNITY,
    GENERAL,
    CALENDAR,
    USERDETAILS,
    ONBOARDING,
    QUESTION_REPONSE,
    EVENT,
    FORMATIONS,
    NEWSLETTER,
    SURVEY,
    OSPLANNER,
    IDEA,
    ADMINPARKING,
    TRANSPORT,
    TACHEADMINSTRATIVE,
    DOCUMENT,
    FICHEPOSTE,
    LEAVE,
  },
}
