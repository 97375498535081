import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { AdminParkingService } from '../admin-parking.service'

@Component({
  selector: 'app-modal-edit-parking',
  templateUrl: './modal-edit-parking.component.html',
  styleUrls: ['./modal-edit-parking.component.scss'],
})
export class ModalEditParkingComponent implements OnInit {
  formGroup: FormGroup
  type: any
  electricCar: number = 0
  handicapCar: number = 0
  covCar: number = 0
  nonReservable: number = 0
  @Input() parking: any

  constructor(
    public parkingService: AdminParkingService,
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    @Optional() public cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.cdr = cdr
  }

  ngOnInit(): void {

    const selectElement = document.getElementById('mySelect') as HTMLSelectElement
    this.type = this.parking.type
    this.covCar = this.parking.covoiturage
    this.electricCar = this.parking.electrique
    this.handicapCar = this.parking.handicap
    this.nonReservable = this.parking.non_reservable

    // Iterate through options and set 'selected' attribute if value matches
    for (let i = 0; i < selectElement.options.length; i++) {
      const option = selectElement.options[i]
      if (option.value === this.parking.type) {
        option.selected = true
        //this.type = this.parking.type
        break
      }
    }

    this.formGroup = this.fb.group({
      code: [
        this.parking.code,
        Validators.compose([Validators.required, Validators.minLength(0), Validators.maxLength(100)]),
      ],
      type: [null, Validators.compose([Validators.required])],
      /*type: [this.parking.type, Validators.compose([Validators.required])],*/
    })
  }

  isControlValid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName]
    return control.valid && (control.dirty || control.touched)
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName]
    return control.invalid && (control.dirty || control.touched)
  }

  controlHasError(validation, controlName): boolean {
    const control = this.formGroup.controls[controlName]
    return control.hasError(validation) && (control.dirty || control.touched)
  }

  updateParking() {
    if (this.type == 3 || this.type == 2) {
      this.covCar = 0
      this.handicapCar = 0
      this.electricCar = 0
      this.nonReservable = 0
    }
    let parking = {
      code: this.formGroup.controls['code'].value,
      type: this.type,
      handicap: this.handicapCar,
      electric: this.electricCar,
      cov: this.covCar,
      reservable: this.nonReservable,
      parkId: this.parking.park_uuid,
    }

    let result = this.parkingService.updateParking(parking).subscribe(res => {
      this.toastr.success(this.translate.instant('EVENT.toast.toastDesc')), this.modal.close()
    })
  }

  setElectricCar() {
    if (this.electricCar == 0) {
      this.electricCar = 1
    } else {
      this.electricCar = 0
    }
  }

  setHandicapCar() {
    if (this.handicapCar == 0) {
      this.handicapCar = 1
    } else {
      this.handicapCar = 0
    }
  }

  setCovCar() {
    if (this.covCar == 0) {
      this.covCar = 1
    } else {
      this.covCar = 0
    }
  }

  setNonReservable() {
    if (this.nonReservable == 0) {
      this.nonReservable = 1
    } else {
      this.nonReservable = 0
    }
  }

  closeModal() {
    this.modal.close()
  }
}
