import { Component, OnInit, ChangeDetectorRef, Optional } from '@angular/core'
import { quizService } from '../_services/quiz.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ToastrService } from 'ngx-toastr'
import { JouerComponent } from '../jouer/jouer.component'
import { AuthHTTPService } from '../../auth/_services/auth-http'
import { AuthService } from '../../auth'
import { WsService } from '../_services/wsquiz.service'

@Component({
  selector: 'app-trier-quiz',
  templateUrl: './trier-quiz.component.html',
  styleUrls: ['./trier-quiz.component.scss'],
})
export class TrierQuizComponent implements OnInit {
  key: any
  jeuxDispo: any
  strategy_id: any
  user_email: any
  //variable pour vérifier si le jeu a été joué avec succès ou non
  // toute erreur avec jwt gardera sa valeur à false
  isPlayed = false
  source: any
  user_id: any

  constructor(
    private squiz: quizService,
    private authservice: AuthHTTPService,
    private auth: AuthService,
    private wservice: WsService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    @Optional() public cdr: ChangeDetectorRef
  ) {
    this.cdr = cdr
  }

  ngOnInit() {
    this.user_email = localStorage.getItem('email')

    //validate token
    this.authservice.validateJwt().subscribe(res => {
      if (res.success == false) {
        // if it's invalid refresh token
        this.authservice.refreshJwt().subscribe(res => {
          // changed from sucess to success
          if (res.success == false) {
            // if token is invalid and refreshing token is unsuccessful logout user
            this.auth.logout()
          }
        })
      } else {
      }
    })

    //get quiz id,strategy,title and game_id of all games player can play
    this.squiz.getAvailableGames().subscribe(data => {
      this.jeuxDispo = data
      // si l'utilisateur n'a pas planifié de rendez-vous, il sera redirigé vers la page d'activity
      if (this.jeuxDispo == 0) {
        this.toastr.error('aucun rendez-vous généré pour vous !')
        // this.router.navigate(['activity/myactivity'])
      }
      this.cdr.detectChanges()
    })

    // get ws user_id (we need it later in quiz pop-up to save score since it's more reliable than user email)
    this.wservice.getUserwsId(this.user_email).subscribe(data => {
      //convert data to its original type (bigint)
      this.user_id = Number(data)
    })
  }

  //create pop-up if play game button clicked
  create(gameid, strategyid, quizid) {
    this.edit(gameid, strategyid, quizid)
  }

  edit(gameid, strategyid, quizid) {
    let playid = {
      gameid: gameid,
      strategyid: strategyid,
      quizid: quizid,
      user_email: this.user_email,
      user_id: this.user_id,
    }

    const modalRef = this.modalService.open(JouerComponent, {
      size: 'l',
      animation: true,
      backdrop: 'static',
      keyboard: false,
      centered: true,
    })

    // pass game, strategy and quiz id to pop-up to identify whish game user is playing
    modalRef.componentInstance.playId = playid
    modalRef.result.then(
      data => {
        // post stat to following quiz session
        //refresh parent page "jeux.component" after closing modal
        // location.reload();
      },
      error => {
        console.log(error)
      }
    )
  }
}
