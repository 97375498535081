<div class="card card-custom gutter-b">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">{{ "OSPLANNER.RESV_RULES.LIST" | translate }}</h3>
    </div>
    <div class="card-toolbar">
      <button type="button" class="btn btn-primary" (click)="create()">{{ "OSPLANNER.RESV_RULES.ADD" | translate }}</button>
    </div>
  </div>
  <div class="card-body" *ngIf="flexiAbonnement == true">
    <div class="form form-label-right">
      <div class="form-group row">
        <div class="col-lg-4">
          <div class="row" [formGroup]="filterGroup"></div>
        </div>
        <div class="col-lg-2" [formGroup]="searchGroup">
          <input
            type="text"
            class="form-control"
            name="searchText"
            placeholder="{{ 'GROUP.LIST.SEARCH' | translate }}"
            value=""
            formControlName="searchTerm"
          />
        </div>
      </div>
    </div>

    <div class="table-responsive angular-bootstrap-table">
      <table class="table table-head-custom table-vertical-center overflow-hidden">
        <thead>
          <th>
            {{ "OSPLANNER.RESV_RULES.NAME" | translate }}
            <app-sort-icon
              column="rule_code"
              [activeColumn]="sorting.column"
              [activeDirection]="sorting.direction"
              (sort)="sort($event)"
            ></app-sort-icon>
          </th>

          <th>
            {{ "OSPLANNER.RESV_RULES.VALUE" | translate }}
            <app-sort-icon
              column="nom_service"
              [activeColumn]="sorting.column"
              [activeDirection]="sorting.direction"
              (sort)="sort($event)"
            ></app-sort-icon>
          </th>
          <th>
            {{ "OSPLANNER.RESV_RULES.TARGET" | translate }}
            <app-sort-icon
              column="date_creation"
              [activeColumn]="sorting.column"
              [activeDirection]="sorting.direction"
              (sort)="sort($event)"
            ></app-sort-icon>
          </th>
          <th>
            {{ "GENERAL.CREATED_AT" | translate }}&#125;
            <app-sort-icon
              column="date_creation"
              [activeColumn]="sorting.column"
              [activeDirection]="sorting.direction"
              (sort)="sort($event)"
            ></app-sort-icon>
          </th>
          <th>{{ "GROUP.LIST.ACTIONS" | translate }}</th>
        </thead>
        <tbody>
          <tr *ngFor="let rule of items$ | async">
            <td [style.maxWidth]="'100px'">{{ "OSPLANNER.RESV_RULES." + rule.code | translate }}</td>
            <td>{{ rule.value }}</td>
            <td>
              {{ "OSPLANNER.RESV_RULES.APPLIED" | translate }} {{ getTargetLabel(rule.targetType) }}
              <span *ngIf="rule.targetName"> ({{ rule.targetName }})</span>
            </td>
            <td>{{ rule.createdAt | localizedDate }}</td>
            <td>
              <!-- <a title="Edit" class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3" (click)="edit(rule.id)">
                <span
                  style="pointer-events: none"
                  [inlineSVG]="'./assets/media/svg/icons/Communication/Write.svg'"
                  [cacheSVG]="true"
                  class="svg-icon svg-icon-md svg-icon-primary"
                >
                </span>
              </a> -->

              <a title="Delete" class="btn btn-icon btn-light btn-hover-danger btn-sm" (click)="delete(rule.id)">
                <span
                  style="pointer-events: none"
                  [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'"
                  [cacheSVG]="true"
                  class="svg-icon svg-icon-md svg-icon-danger"
                >
                </span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- end: Table -->
    <app-paginator
      class="d-flex justify-content-between align-items-center flex-wrap"
      [paginator]="paginator"
      [isLoading]="isLoading"
      (paginate)="paginate($event)"
    ></app-paginator>
  </div>
</div>
