import { environment } from 'src/environments/environment'

function getSessionFromLocalStorage(): any {
  try {
    const authData = JSON.parse(
      localStorage.getItem(`${environment.appVersion}-${environment.USERDATA_KEY}`)
    )
    return authData
  } catch (error) {
    return undefined
  }
}

function getUserUuidFromLocalStorage(): string {
  try {
    return localStorage.getItem('uuid').slice(1, -1)
  } catch (error) {
    return undefined
  }
}

function getEntIdFromLocalStorage() {
  return +localStorage.getItem('ent_id')
}

export { getSessionFromLocalStorage, getUserUuidFromLocalStorage, getEntIdFromLocalStorage }
