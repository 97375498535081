<div class="modal-content">
  <div class="modal-header">
      <div class="modal-title h4" id="example-modal-sizes-title-lg">
          <!--pop-up header block-->
          <div>
              <span class="popUpTitle">{{"popupstart.liste" | translate }}</span>
          </div>
      </div>
      <div class="card-toolbar" style="float:right">
          <a class="btn btn-primary" (click)="create()">{{"popupstart.assoc" | translate }}</a>
      </div>
  </div>






<!--liste des stratégies déja associé à un jeu-->
  <div class="card card-custom card-stretch gutter-b">
      <!-- Body -->
      <div class="card-body">
        <!--begin::Table-->
        <div class="table-responsive">
          <table class="table table-borderless table-vertical-center">
            <thead>
              <tr>
                <th class="p-0" [style.min-width]="'50px'"></th>
                <th class="p-0" [style.min-width]="'50px'"></th>
                <th class="p-0" [style.min-width]="'100px'"></th> 
                <th class="p-0" [style.min-width]="'50px'"></th>
              </tr>
            </thead>
            <tbody>
             
              <tr *ngFor="let row of strategies; let i = index">
                
              <td>
                  <div class="form-check form-check-sm form-check-custom form-check-solid">
                      <input class="form-check-input widget-13-check" type="checkbox" (change)="onChange(row.param_strat_uuid, $event.target.checked)"/>      
                      </div>
              </td>
          
                <td class="pl-0 py-4">
                  <div class="symbol symbol-50 symbol-light mr-1">
                    <span class="symbol-label">
                      <img
                        alt=""
                        src="./assets/media/svg/misc/006-plurk.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  <a
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg cursor-pointer"
                    ><span [ngClass]="{'actif':row.actif == 0}">{{row.nom}}</span> </a
                  >
                </td>
                <td class="text-right pr-0">
                  <a href="https://testapp.wemiam.co/strategie" class="btn btn-icon btn-light btn-sm mx-3 cursor-pointer">
                    <span
                      [inlineSVG]="
                        './assets/media/svg/icons/Communication/Write.svg'
                      "
                      class="svg-icon svg-icon-md svg-icon-primary"
                    ></span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
      </div>
    </div>
  







    


<!-- strategy options (dissocier activer désactiver)--> 
  <div class="modal-footer">
          <div class="card-toolbar" >
          <button class="btn btn-warning" [disabled]='validate()' (click)="dissasociateStrategy()">{{"popupstart.dissoc" | translate }}</button>
          </div>
          <div class="card-toolbar">
          <button class="btn btn-success" [disabled]='validate()' (click)="activateStrategy()">{{"popupstart.activer" | translate }}</button>
          </div>
          <div class="card-toolbar">
          <button class="btn btn-danger" [disabled]='validate()' (click)="deactivateStrategy()">{{"popupstart.des" | translate }}</button>
          </div>    
  </div>
       <!--option pour garder les résultats secrets -->
       <div class="overlay overlay-block cursor-default modal-body">
        <div style="margin:5px;">  
          <a class="btn btn-light btn-elevate" style="float:right" (click)="close()">{{"assocStrat.annuler" | translate }}</a>  
            <input class="form-check-input" type="checkbox" value="1" name="secret" style="float: left;" (change)="gardScore($event)"  />    
        <div style="margin-left: 5px;">{{"popupstart.garde" | translate }}</div><!--garde les résultats secrets jusqu'a la fin du jeux-->
    </div>
    </div>

</div> 
