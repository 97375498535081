import { HttpClient, HttpParams } from '@angular/common/http'
import { AfterViewInit, Component, Input, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { ToastrService } from 'ngx-toastr'
import { getSessionFromLocalStorage, getUserUuidFromLocalStorage } from 'src/app/utils/getSessionFromLocalStorage'
import { SurveyService } from '../_services/surveys.service'
import { environment } from './../../../../environments/environment'

@Component({
  selector: 'app-survey-modal',
  templateUrl: './survey-modal.component.html',
  styleUrls: ['./survey-modal.component.scss'],
})
export class SurveyModalComponent implements OnInit, AfterViewInit {
  @Input() surveyId!: string
  @Input() targetId!: string
  @Input() previewMode: boolean = false

  ngAfterViewInit(): void {
    // hide the modal's unwanted white background
    const modal = document.getElementsByClassName('modal-content')[0] as HTMLElement
    // modal.style.backgroundColor = 'transparent'
  }

  constructor(
    public modal: NgbActiveModal,
    private surveyService: SurveyService,
    private toastr: ToastrService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
  }

  get authToken() {
    return localStorage.getItem('QSP_AUTH_TOKEN')
  }

  get userId() {
    return getUserUuidFromLocalStorage()
  }

  submitResults(results: any) {
    if (this.previewMode) {
      this.modal.dismiss()
      return
    }
    this.http
      .get<any>(environment.apiUrl + '/survey/target/' + this.targetId, {
        params: new HttpParams().set('sessionid', getSessionFromLocalStorage()),
      })
      .subscribe({
        next: target => {
          this.surveyService.submitResults(results, this.targetId, this.surveyId, target.anonymous).subscribe({
            next: () => {
              this.toastr.success('Réponses Envoyées!')
              this.modal.close()
            },
            error: err => {
              if (err.status === 403) {
                // 403 Forbidden déjà répondu
                this.toastr.error('Vous avez déjà répondu à ce questionnaire!')
              } else if (err.status === 410) {
                // 410 Gone clôturé
                this.toastr.error('Ce questionnaire a été clôturé!')
              } else {
                this.toastr.error('Une erreur est survenue!')
              }
              console.log(err)
            },
          })
        },
        error: () => {
          this.toastr.error("Une erreur s'est produite!")
        },
      })
  }
}
