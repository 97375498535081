export default {
  CIBLES: {
    Attributions: 'Training assignments',
    TITRE: 'Target List of Trainings',
    ACTIVER: 'Enable Targeting',
    RECHERCHER: 'Search',
    PAR_NOM: 'by name',
    NOM_FORMATION: 'Training Name',
    STATISTIQUE: 'Statistic',
    CIBLE: 'Target',
    DATE_DEBUT: 'Start Date',
    DATE_FIN: 'End Date',
    TYPE: 'Type',
    ACTIONS: 'ACTIONS',
    PERSONNE: 'Targeted people',
    ACHEVE: 'Completed',
    DEACTIVE: 'Deactivated',
    ACTIVE: 'Activated',
    EXPIRE: 'Expired',
    RESULTAT: 'Results',
    DEACTIVER: 'Deactivate',
    REACTIVER: 'Reactivate',
    ACTIVERCIBLEFORMATION: 'Assign Training',
  },
  FORMATION_COLLABORATEUR: {
    FILTRES: 'Filters',
    RECHERCHE: 'Search',
    RECHERCHE_TITRE_PLACEHOLDER: 'Search by title',
    PARTICIPANTS: 'Participants',
    DOMAINE: 'Field',
    TOUS_DOMAINES: 'All fields',
    CERTIFICAT: 'Certificate',
    TOUS: 'All',
    AVEC_CERTIF: 'With certification',
    SANS_CERTIF: 'Without certification',
    STATUTS: 'Status',
    TOUS_STATUTS: 'All statuses',
    PRESENTIEL: 'On-site',
    EN_LIGNE: 'Online',
    FILTRER: 'Filter',
    REINITIALISER: 'Reset',
    MES_FORMATIONS: 'My Trainings',
    DUREE: 'Duration',
    COMPETENCES: 'Acquired skills',
    VOIR_PLUS: 'See more',
    OUVRIR: 'Open',
    INSCRIRE: 'Sign up',
    COMMENCER: 'Begin',
    TERMINER: 'Finish',
    TERMINE: 'Finished',
    EXPIRED: 'Expired',
  },
  CATALOGUE_FORMATION: {
    ACTIF: 'Active',
    INACTIF: 'Inactive',
    TITRE: 'Training Catalogue',
    AJOUTER_FORMATION: 'Add a training',
    AUCUN_FORMATION: 'No training available at the moment.',
    MODIFIER: 'Modify',
    DEACTIVER: 'Disable',
    REACTIVER: 'Enable',
  },
  RESULTAT_CIBLAGE: {
    RESULTAT: 'Result',
    NOM_PRENOM: 'Name and Surname',
    DATE_DETBUT: 'Start Date',
    DATE_FIN: 'End Date',
    STATUS: 'Status',
    CERTIFICAT: 'Certificate',
    EN_COURS: 'In progress',
    TERMINE: 'Finished',
    PAS_ENCORE: 'Not finished yet',
    VERIFIER: 'Check',
  },
  MES_INTEGRATION_EXTERNE: {
    TITLE: 'My External Integrations',
    AJOUTER_OUTILS_EXTERNE: 'Add external tool',
    DOMAIN: 'Domain',
    API_KEY: 'API Key',
    DERNIERE_ACTUALISATION: 'Last update',
    UTILISATEURS: 'Users',
    FORMATIONS: 'Courses',
    AVANCEMENT: 'Progress',
    CONGES: 'Leaves',
    SYNC_USERS: 'Syncronize users',
    SYNC_COURSES: 'Syncronize courses',
    SYNC_COURSE_COMPLETION: 'Syncronize course completion',
    SYNC_CONGES: 'Syncronize leaves',
    NO_INTEGRATIONS_MESSAGE: "You haven't integrated any tools yet!",
    MSG1: 'Integrate external tools and synchronize their data with our platform',
    MSG2: 'Select an indicator and enter its value, we will analyze the data to calculate rates and identify trends in the QVCT axes',
    MSG3: 'View and customize the recruitment email templates according to your needs, they will then be used as templates for sending throughout the process',
  },
  MES_COMPETENCES: {
    TITRE: 'My skills',
    AJOUTER_COMPETENCE: 'Add a skill',
    PAS_DE_COMPETENCES: 'No existing skills',
    COMMENCER_FORMATION: 'Currently, you have no skills. Please start a training.',
    VOIR_FORMATIONS_DISPONIBLES: 'See available trainings',
    RECHERCHE_PAR_NOM: 'Search by name',
    FILTRER_PAR_DOMAINE: 'Filter by domain',
    SUPPRIMER: 'Delete',
    MODIFIER: 'Edit',
  },
  MES_PROGRESSONS: {
    CIBLERFORMATION: 'Assign training',

    ESTCIBLE: 'Training already assigned',
    TITRE: 'My progress',
    AUCUNE_PROGRESSION: 'No courses for the moment',
    COMMENCER_FORMATION: 'There is currently no progress, please start a training.',
    VOIR_FORMATIONS_DISPONIBLES: 'View available trainings',
    RECHERCHE_PAR_NOM: 'Search by name',
    FILTRER_PAR_STATUS: 'Filter by status',
    FILTRER: 'Filter',
    RECOMANDE: 'Recommend',
    TERMINÉ: 'Completed',
    EN_COURS: 'In progress',
    NON_COMMENCÉ: 'Not started',
    RECOMNDER: 'Recommend',
    FORMATION_TERMINE: 'Congratulations! You have completed 100% of this course',
    FORMATION_EN_COURS1: 'You have completed',
    FORMATION_EN_COURS2: 'of this course',
    TOTAL_UNITÉS: 'Total units',
    TEMPS_PASSÉ: 'Time spent',
    MOYENNE_SCORE: 'Average Score',
    VOIR_LA_PROGRESSION: 'View progress',
  },
  MODAL_AJOUTER_FORMATION: {
    TITRE: 'Add a training',
    IMAGE: 'Image',
    TITRE_FORMATION: 'Title',
    TYPE_FORMATION: 'Type of training',
    TYPE_FORMATION_EN_LIGNE: 'Online',
    TYPE_FORMATION_PRESENTIEL: 'On-site',
    URL_FORMATION: 'Training URL',
    LIEU: 'Location',
    DESCRIPTION: 'Description',
    DUREE: 'Duration',
    DUREE_MINUTES: 'minutes',
    DOMAINE: 'Field',
    CHOISIR_DOMAINE: 'Choose a field',
    LIST_COMPETENCES: 'List of skills',
    PRE_REQUIS: 'Prerequisites',
    DIFFICULTE: 'Difficulty',
    CERTIFICATION: 'Required certification',
    TITRE_PLACEHOLDER: 'Title of the training',
    URL_PLACEHOLDER: 'URL of the training',
    LIEU_PLACEHOLDER: 'Location of the training',
    DESCRIPTION_PLACEHOLDER: 'Description of the training',
    DUREE_PLACEHOLDER: 'Duration of the training in minutes',
    PRE_REQUIS_PLACEHOLDER: 'Prerequisites of the training',
    ERREUR_TITRE_REQUIS: 'The title is required',
    ERREUR_TITRE_LONGUEUR_MIN: 'The title must be at least 5 characters long',
    ERREUR_TITRE_LONGUEUR_MAX: 'The title must be no more than 50 characters long',
    ERREUR_TYPE_FORMATION_REQUIS: 'The type of training is required',
    ERREUR_URL_REQUIS: 'The URL is required',
    ERREUR_URL_INVALIDE: 'The URL is invalid',
    ERREUR_LIEU_REQUIS: 'The location is required',
    ERREUR_LIEU_LONGUEUR_MIN: 'The location must be at least 5 characters long',
    ERREUR_LIEU_LONGUEUR_MAX: 'The location must be no more than 50 characters long',
    ERREUR_DESCRIPTION_REQUIS: 'The description is required',
    ERREUR_DESCRIPTION_LONGUEUR_MIN: 'The description must be at least 30 characters long',
    ERREUR_DESCRIPTION_LONGUEUR_MAX: 'The description must be no more than 500 characters long',
    ERREUR_DUREE_REQUIS: 'The duration is required',
    ERREUR_DUREE_MIN: 'The duration must be greater than 20 minutes',
    ERREUR_DOMAINE_REQUIS: 'The field is required',
    ERREUR_COMPETENCES_REQUIS: 'Skills are required',
    ERREUR_PRE_REQUIS_LONGUEUR_MIN: 'The prerequisites must be at least 3 characters long',
    ERREUR_PRE_REQUIS_LONGUEUR_MAX: 'The prerequisites must be no more than 500 characters long',
    FERMER: 'Close',
    EN_COURS: 'In progress',
    AJOUTER: 'Add',
    PAS_COMPETENCES: 'No skills for this field',
    DEBUTANT: 'Beginner',
    INTERMEDIAIRE: 'Intermediate',
    EXPERT: 'Expert',
    SUCCESS_UPLOAD_TS: 'Image uploaded successfully',
    ERROR_UPLOAD_TS: 'Error uploading image',
    SUCCESS_ADD_TS: 'Training added successfully',
    ERROR_ADD_TS: 'Error adding training',
    AUTRES_INFORMATIONS: 'Additional information',
  },
  MODAL_MODIFIER_FORMATION: {
    TITRE: 'Edit a training',
    IMAGE: 'Image',
    TITRE_FORMATION: 'Title',
    TYPE_FORMATION: 'Type of training',
    TYPE_FORMATION_EN_LIGNE: 'Online',
    TYPE_FORMATION_PRESENTIEL: 'On-site',
    URL_FORMATION: 'Training URL',
    LIEU: 'Location',
    DESCRIPTION: 'Description',
    DUREE: 'Duration',
    DUREE_MINUTES: 'minutes',
    DOMAINE: 'Field',
    CHOISIR_DOMAINE: 'Choose a field',
    LIST_COMPETENCES: 'List of skills',
    PRE_REQUIS: 'Prerequisites',
    DIFFICULTE: 'Difficulty',
    CERTIFICATION: 'Required certification',
    TITRE_PLACEHOLDER: 'Title of the training',
    URL_PLACEHOLDER: 'URL of the training',
    LIEU_PLACEHOLDER: 'Location of the training',
    DESCRIPTION_PLACEHOLDER: 'Description of the training',
    DUREE_PLACEHOLDER: 'Duration of the training in minutes',
    PRE_REQUIS_PLACEHOLDER: 'Prerequisites of the training',
    ERREUR_TITRE_REQUIS: 'The title is required',
    ERREUR_TITRE_LONGUEUR_MIN: 'The title must be at least 5 characters long',
    ERREUR_TITRE_LONGUEUR_MAX: 'The title must be no more than 50 characters long',
    ERREUR_TYPE_FORMATION_REQUIS: 'The type of training is required',
    ERREUR_URL_REQUIS: 'The URL is required',
    ERREUR_URL_INVALIDE: 'The URL is invalid',
    ERREUR_LIEU_REQUIS: 'The location is required',
    ERREUR_LIEU_LONGUEUR_MIN: 'The location must be at least 5 characters long',
    ERREUR_LIEU_LONGUEUR_MAX: 'The location must be no more than 50 characters long',
    ERREUR_DESCRIPTION_REQUIS: 'The description is required',
    ERREUR_DESCRIPTION_LONGUEUR_MIN: 'The description must be at least 30 characters long',
    ERREUR_DESCRIPTION_LONGUEUR_MAX: 'The description must be no more than 500 characters long',
    ERREUR_DUREE_REQUIS: 'The duration is required',
    ERREUR_DUREE_MIN: 'The duration must be greater than 20 minutes',
    ERREUR_DOMAINE_REQUIS: 'The field is required',
    ERREUR_COMPETENCES_REQUIS: 'Skills are required',
    ERREUR_PRE_REQUIS_LONGUEUR_MIN: 'The prerequisites must be at least 3 characters long',
    ERREUR_PRE_REQUIS_LONGUEUR_MAX: 'The prerequisites must be no more than 500 characters long',
    FERMER: 'Close',
    EN_COURS: 'In progress',
    MODIFIER: 'Edit',
    PAS_COMPETENCES: 'No skills for this field',
    DEBUTANT: 'Beginner',
    INTERMEDIAIRE: 'Intermediate',
    EXPERT: 'Expert',
    SUCCESS_UPLOAD_TS: 'Image uploaded successfully',
    ERROR_UPLOAD_TS: 'Error uploading image',
    SUCCESS_UPDATE_TS: 'Training modified successfully',
    ERROR_UPDATE_TS: 'Error modifying training',
  },
  MODAL_DEACTIVER_FORMATION: {
    TITRE: 'Disable a training',
    CONFIRMATION: 'Are you sure you want to disable this training?',
    FERMER: 'Close',
    DEACTIVER: 'Disable',
    SUCCESS_DEACTIVER_TS: 'Training deleted successfully',
    ERROR_DEACTIVER_TS: 'Error deleting training',
  },
  MODAL_ACTIVER_FORMATION: {
    TITRE: 'Enable a training',
    CONFIRMATION: 'Are you sure you want to enable this training?',
    FERMER: 'Close',
    ACTIVER: 'Enable',
    SUCCESS_ACTIVATE_TS: 'Training enabled successfully',
    ERROR_ACTIVATE_TS: 'Error enabling training',
  },
  COMPONENT_AUCUNE_FORMATION: {
    TITRE: 'No training available',
    MESSAGE1: 'We are sorry for the inconvenience, but there are no courses available at the moment.',
    MESSAGE2: 'Please wait for the administrator to add a new course.',
    REDIRECT: 'Go to the dashboard',
  },
  MODAL_DETAILS_FORMATION: {
    DOMAINE: 'Field',
    DUREE: 'Duration',
    NIVEAU: 'Level',
    DEBUTANT: 'Beginner',
    INTERMEDIAIRE: 'Intermediate',
    EXPERT: 'Expert',
    CERTIFICATION: 'Certification',
    OUI: 'Yes',
    NON: 'No',
    DESCRIPTION: 'Description',
    NOM_FORMATEUR: "Trainer's Name",
    LIEU: 'Location',
    URL_FORMATION: 'Training URL',
    COMPETENCES: 'Skills',
    FERMER: 'Close',
  },
  MODAL_CHOISIR_INTEGRATION: {
    TITRE: 'Add an integration',
    FERMER: 'Close',
  },
  MODAL_AJOUTER_INTEGRATION: {
    TITLE: 'Integration Options for',
    API_KEY_LABEL: 'API Key',
    API_KEY_PLACEHOLDER: 'Enter API Key',
    API_KEY_TOOLTIP:
      'This is where you can enter your API key. The API key is required to access the API and must remain confidential. You will find it in your account settings.',
    API_KEY_REQUIRED_ERROR: 'API Key is required.',
    DOMAIN_LABEL: 'Domain',
    DOMAIN_PLACEHOLDER: 'Enter',
    DOMAIN_REQUIRED_ERROR: 'Domain is required.',
    SYNCHRONISATIONS_LABEL: 'Synchronizations',
    USERS_LABEL: 'Users',
    COURSES_LABEL: 'Courses',
    COURSE_PROGRESS_LABEL: 'Course Progress',
    CONGES_LABEL: 'Leaves',
    CHECK_CONNECTION_BUTTON: 'Check Connection',
    ADD_BUTTON: 'Add',
    CLOSE_BUTTON: 'Close',
  },
  MODAL_MODIFIER_INTEGRATION: {
    TITLE: 'Modify integration of',
    API_KEY_LABEL: 'API Key',
    API_KEY_PLACEHOLDER: 'Enter API Key',
    API_KEY_REQUIRED_ERROR: 'API Key is required.',
    DOMAIN_LABEL: 'Domain',
    DOMAIN_PLACEHOLDER: 'Enter',
    DOMAIN_REQUIRED_ERROR: 'Domain is required.',
    SYNCHRONISATIONS_LABEL: 'Synchronizations',
    USERS_LABEL: 'Users',
    COURSES_LABEL: 'Courses',
    COURSE_PROGRESS_LABEL: 'Course Progress',
    CONGES_LABEL: 'Leaves',
    CHECK_CONNECTION_BUTTON: 'Check Connection',
    EDIT_BUTTON: 'Edit',
    CLOSE_BUTTON: 'Close',
  },
  MODAL_AJOUTER_COMPETENCE: {
    COMPETENCE_ADDED: 'Skill added successfully',
    ADDEDERROR: 'An error has occurred',
    MODIFIER: 'Edit',
    NIVEAUCOMPETENCE: 'Skill Level:',
    NIVEAUCOMPETENCEATTRIBUE: 'Skill level given by manager:',
    NVCOMPTENCE: 'Skill Level',
    NIVEAU: 'Skill Level(1: Beginner, 2: Intermediate, 3: Advanced, 4: Expert, 5: Absolute Expert)',
    TITRE: 'Add a skill',
    TITREEDIT: 'Edit a skill',
    CHOISIR: 'What do you want to add?',
    ACQUISES: 'Acquired skills',
    SOUHAITEES: 'Desired skills',
    CHOISISSEZ: 'Choose',
    DOMAINE: 'Field',
    COMPETENCE: 'Skill',
    CHOISIR_DOMAINE: 'Choose a field',
    CHOISIR_COMPETENCE: 'Choose a skill',
    FERMER: 'Close',
    AJOUTER: 'Add',
  },
  MODAL_CONFIRM_DESACTIVATION: {
    TITLE: 'Are you sure you want to deactivate this targeting?',
    DEACTIVATE: 'Deactivate',
    CLOSE: 'Close',
  },
  MODAL_SUPPRESSION: {
    TITRE: 'Are you sure you want to delete?',
    MESSAGE:
      'This action cannot be undone. Deleting this integration will permanently remove it from your account. Are you sure you want to continue?',
    FERMER: 'Close',
    SUPPRIMER: 'Delete',
  },
  MODAL_INSCRIPTION_FORMATION: {
    TITRE_MODAL: 'Sign up for the Training',
    CONFIRMATION: 'Are you sure you want to join this training?',
    FERMER: 'Close',
    INSCRIRE: 'Sign up',
  },
  MODAL_LOADING: {
    TITRE: 'Your integration request is in progress, thank you for your patience.',
  },
  MODAL_REACTIVATION_CIBLAGE: {
    TITRE: 'Are you sure you want to reactivate this targeting?',
    REACTIVER: 'Reactivate',
    FERMER: 'Close',
  },
  MODAL_TERMINER_FORMATION: {
    TITRE: 'Finish this training',
    EVALUER_FORMATION: 'Evaluate this training',
    RATING_REQUIRED: 'The rating is required',
    AJOUTER_COMMENTAIRE: 'Add a comment',
    COMMENTAIRE_REQUIRED: 'The comment is required',
    TELECHARGER_CERTIFICAT:
      'This training requires downloading a certificate at the end of the training, please select the file.',
    FERMER: 'Close',
    TERMINER: 'Finish',
  },
  MODAL_UNIT_PROGRESS: {
    RESULTAT: 'Results',
    UNITS: 'Units',
    TOTAL_UNITS: 'Total Units',
    COMPLETED_UNITS: 'Completed Units',
    PROGRESS_UNITS: 'Units in Progress',
    NOT_STARTED_UNITS: 'Units Not Started',
    PROGRESSION: 'Progress',
    TOTAL_SCORE: 'Total Score',
    TOTAL_TIME: 'Total Time',
    DATE_FIN: 'End Date',
    CERTIFICATE: 'Certificate',
    COMPLETED: 'Completed',
    NOT_STARTED: 'Not Started',
    IN_PROGRESS: 'In Progress',
    COMPLETION_DATE: 'Completion Date',
    DURATION: 'Duration',
    SCORE: 'Score',
    CLOSE: 'Close',
  },
}
