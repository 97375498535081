<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">{{ "ADMINPARKING.modalParking.title" | translate }}</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="formGroup">
      <div>
        <div>
          <label>Site</label>
        </div>
        <div>
          <select
            class="form-control"
            formControlName="site"
            style="width: 100%"
            [(ngModel)]="idSite"
            [class.is-invalid]="isControlInvalid('site')"
            [class.is-valid]="isControlValid('site')"
          >
            <option *ngFor="let s of listSites" [value]="s.ids">
              {{ s.name }}
            </option>
          </select>

          <div class="invalid-feedback" *ngIf="controlHasError('required', 'site')">Site{{ "CONTROLEDESAISIE.required" | translate }}</div>
        </div>
      </div>

      <div>
        <label for="titre">{{ "ADMINPARKING.modalParking.input1" | translate }}</label>
        <input
          id="code"
          class="form-control"
          type="text"
          formControlName="code"
          [class.is-invalid]="isControlInvalid('code')"
          [class.is-valid]="isControlValid('code')"
        />

        <div class="invalid-feedback" *ngIf="controlHasError('required', 'code')">
          {{ "ADMINPARKING.modalParking.input1" | translate }}{{ "CONTROLEDESAISIE.required" | translate }}
        </div>
        <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'code')">
          {{ "ADMINPARKING.modalParking.input1" | translate }}{{ "EVENT.control.threeCharMin" | translate }}
        </div>
        <div class="invalid-feedback" *ngIf="controlHasError('maxlength', 'code')">
          {{ "ADMINPARKING.modalParking.input1" | translate }}{{ "CONTROLEDESAISIE.max" | translate }}
        </div>
      </div>

      <div>
        <label>{{ "ADMINPARKING.modalParking.input2" | translate }}</label>
        <select
          class="form-control"
          [(ngModel)]="type"
          formControlName="type"
          style="width: 100%"
          [class.is-invalid]="isControlInvalid('type')"
          [class.is-valid]="isControlValid('type')"
        >
          <option value="1">{{ "ADMINPARKING.modalParking.option1" | translate }}</option>
          <option value="2">{{ "ADMINPARKING.modalParking.option2" | translate }}</option>
          <option value="3">{{ "ADMINPARKING.modalParking.option3" | translate }}</option>
        </select>

        <div class="invalid-feedback" *ngIf="controlHasError('required', 'type')">
          {{ "ADMINPARKING.modalParking.input2" | translate }}{{ "CONTROLEDESAISIE.required" | translate }}
        </div>
      </div>

      <div class="row" style="margin-top: 10px">
        <div class="col-lg-4" *ngIf="type == 1">
          <input type="checkbox" id="vehicle1" name="vehicle1" value="1" (click)="setElectricCar()" />
          <label for="vehicle1" style="margin-left: 5px"> {{ "ADMINPARKING.modalParking.input3" | translate }}</label
          ><br />
        </div>
        <div class="col-lg-4" *ngIf="type == 1">
          <input type="checkbox" id="vehicle2" name="vehicle2" value="1" (click)="setHandicapCar()" />
          <label for="vehicle2" style="margin-left: 5px"> {{ "ADMINPARKING.modalParking.input4" | translate }}</label
          ><br />
        </div>
        <div class="col-lg-4" *ngIf="type == 1">
          <input type="checkbox" id="vehicle3" name="vehicle3" value="1" (click)="setCovCar()" />
          <label for="vehicle3" style="margin-left: 5px"> {{ "ADMINPARKING.modalParking.input5" | translate }}</label
          ><br /><br />
        </div>
        <div class="col-lg-4">
          <input type="checkbox" id="vehicle3" name="vehicle3" value="1" (click)="setNonReservable()" />
          <label for="vehicle3" style="margin-left: 5px">{{ "ADMINPARKING.modalParking.input6" | translate }}</label
          ><br /><br />
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-primary" (click)="closeModal()"><i class="fas fa-times"></i>{{ "IDEA.add.button1" | translate }}</button>
    <button class="btn btn-primary" (click)="addParking()" [disabled]="formGroup.invalid">
      <span> <i class="fas fa-paper-plane"></i>{{ "IDEA.add.button2" | translate }}</span>
    </button>
  </div>
</div>
