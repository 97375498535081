import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { getSessionFromLocalStorage } from 'src/app/utils/getSessionFromLocalStorage'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class ParkingService {
  constructor(private http: HttpClient) {}

  private _startTime: string
  private _endTime: string
  private _date: string

  get startTime() {
    return this._startTime
  }

  set startTime(value: string) {
    this._startTime = value
  }

  get endTime() {
    return this._endTime
  }

  set endTime(value: string) {
    this._endTime = value
  }

  get resvDate() {
    return this._date
  }

  set resvDate(value: string) {
    this._date = value
  }

  getCurrentUserReservations() {
    return this.http.get(`${environment.apiUrl}/osplanner/parking/reservations`, {
      params: {
        sessionid: getSessionFromLocalStorage(),
        startTime: `${this._date} ${this._startTime}`,
        endTime: `${this._date} ${this._endTime}`,
      },
    })
  }
}
