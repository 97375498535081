import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { debounceTime, distinctUntilChanged, Observable, Subscription } from 'rxjs'
import {
  GroupingState,
  ICreateAction,
  IDeleteAction,
  IFilterView,
  IGroupingView,
  ISearchView,
  ISortView,
  PaginatorState,
  SortState,
} from 'src/app/_metronic/shared/crud-table'
import { UserService } from '../../collaborateurs/_services'
import { ClosedModalComponent } from '../../communities/gerer/closed-modal/closed-modal.component'
import { PromptModalComponent } from '../../shared/prompt-modal/prompt-modal.component'
import { CreateResvRuleModalComponent } from './create-resv-rule-modal/create-resv-rule-modal.component'
import { ListResvRulesService } from './list-resv-rules.service'
import { ResvRuleT } from './os-modeling-service.service'

@Component({
  selector: 'app-osp-reservation-rules',
  templateUrl: './osp-reservation-rules.component.html',
  styleUrls: ['./osp-reservation-rules.component.scss'],
})
export class OspReservationRulesComponent
  implements
    OnInit,
    OnDestroy,
    ICreateAction,
    IDeleteAction,
    ISortView,
    IFilterView,
    IGroupingView,
    ISearchView,
    IFilterView
{
  paginator: PaginatorState
  sorting: SortState
  grouping: GroupingState
  isLoading: boolean
  filterGroup: UntypedFormGroup
  searchGroup: UntypedFormGroup
  private subscriptions: Subscription[] = []

  constructor(
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    public tableSvc: ListResvRulesService,
    private cdr: ChangeDetectorRef,
    public userService: UserService,
    public t: TranslateService
  ) {}

  get items$(): Observable<ResvRuleT[]> {
    return this.tableSvc.items$
  }

  getTargetLabel(targetType: number) {
    switch (targetType) {
      case 1:
        return this.t.instant('OSPLANNER.RESV_RULES.TARGET_LABELS.1')
      case 2:
        return this.t.instant('OSPLANNER.RESV_RULES.TARGET_LABELS.2')
      case 3:
        return this.t.instant('OSPLANNER.RESV_RULES.TARGET_LABELS.3')
      case 4:
        return this.t.instant('OSPLANNER.RESV_RULES.TARGET_LABELS.4')
      case 5:
        return this.t.instant('OSPLANNER.RESV_RULES.TARGET_LABELS.5')
    }
  }
  tableOption: any = []
  flexiAbonnement = false

  // angular lifecircle hooks
  ngOnInit(): void {
    this.filterForm()
    this.searchForm()

    this.userService.getEntrepriseAbonnement().subscribe(data => {
      for (let i = 0; i < data.length; i++) {
        this.tableOption.push(data[i].code_option)
        this.cdr.detectChanges()
      }

      if (this.tableOption.includes('MOSP')) {
        this.flexiAbonnement = true
        this.tableSvc.fetch()
        const sb = this.tableSvc.isLoading$.subscribe(res => (this.isLoading = res))
        this.subscriptions.push(sb)
      } else {
        this.closedModal()
      }

      this.cdr.detectChanges()
    })
    this.grouping = this.tableSvc.grouping
    this.paginator = this.tableSvc.paginator
    this.sorting = this.tableSvc.sorting
  }

  closedModal() {
    this.modalService.open(ClosedModalComponent)
  }
  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe())
    this.search('')
  }

  // filtration
  filterForm() {
    this.filterGroup = this.fb.group({
      status: [''],
      type: [''],
      searchTerm: [''],
    })
    this.subscriptions.push(this.filterGroup.controls.status.valueChanges.subscribe(() => this.filter()))
    this.subscriptions.push(this.filterGroup.controls.type.valueChanges.subscribe(() => this.filter()))
  }

  filter() {
    const filter = {}
    const status = this.filterGroup.get('status').value
    if (status) {
      filter['status'] = status
    }

    const type = this.filterGroup.get('type').value
    if (type) {
      filter['type'] = type
    }
    this.tableSvc.patchState({ filter })
  }

  // search
  searchForm() {
    this.searchGroup = this.fb.group({
      searchTerm: [''],
    })
    const searchEvent = this.searchGroup.controls.searchTerm.valueChanges
      .pipe(
        /*
  The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator,
  we are limiting the amount of server requests emitted to a maximum of one every 150ms
  */
        debounceTime(150),
        distinctUntilChanged()
      )
      .subscribe(val => this.search(val))
    this.subscriptions.push(searchEvent)
  }

  search(searchTerm: string) {
    this.tableSvc.patchState({ searchTerm })
  }

  // sorting
  sort(column: string) {
    const sorting = this.sorting
    const isActiveColumn = sorting.column === column
    if (!isActiveColumn) {
      sorting.column = column
      sorting.direction = 'asc'
    } else {
      sorting.direction = sorting.direction === 'asc' ? 'desc' : 'asc'
    }
    this.tableSvc.patchState({ sorting })
  }

  // pagination
  paginate(paginator: PaginatorState) {
    this.tableSvc.patchState({ paginator })
  }

  // form actions
  create() {
    const modalRef = this.modalService.open(CreateResvRuleModalComponent, {
      size: 'xl',
    })
    modalRef.result.then(
      () => this.tableSvc.fetch(),
      () => {}
    )
    modalRef.closed.subscribe(() => {
      this.tableSvc.fetch()
    })
    modalRef.dismissed.subscribe(() => {
      this.tableSvc.fetch()
    })
  }

  delete(id: any) {
    const modalRef = this.modalService.open(PromptModalComponent)
    modalRef.componentInstance.data = {
      message: 'Voulez-vous vraiment supprimer cette règle de réservation ?',
      title: 'Supprimer une règle de réservation',
      confirmText: 'Supprimer',
      cancelText: 'Annuler',
      confirmColor: 'warn',
      cancelColor: 'primary',
    }

    modalRef.result.then(
      () =>
        this.tableSvc.delete(id).subscribe({
          next: () => this.tableSvc.fetch(),
          error: err => {
            console.log(err)
          },
        }),
      () => {}
    )
  }
}
