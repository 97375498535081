import { HttpClient } from '@angular/common/http'
import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { getUserUuidFromLocalStorage } from 'src/app/utils/getSessionFromLocalStorage'
import { environment } from 'src/environments/environment'
import { SurveyService } from '../_services/surveys.service'

@Component({
  selector: 'app-survey-fill',
  templateUrl: './survey-fill.component.html',
  styleUrls: ['./survey-fill.component.scss'],
})
export class SurveyFillComponent implements OnInit {
  surveyTargetId: string
  surveyId: string

  constructor(
    private route: ActivatedRoute,
    private surveyService: SurveyService,
    private toastr: ToastrService,
    private http: HttpClient,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.surveyTargetId = this.route.snapshot.paramMap.get('targetId')
    this.surveyId = this.route.snapshot.paramMap.get('surveyId')
  }

  isLoading = true
  ngOnInit(): void {
    this.http.get(environment.apiUrlQSP + '/survey/' + this.surveyId + '/questions').subscribe({
      next: () => {
        this.isLoading = false
        this.cdr.detectChanges()
      },
      error: err => {
        this.router.navigate(['/404'])
      },
    })
  }

  get authToken() {
    return localStorage.getItem('QSP_AUTH_TOKEN')
  }

  get userId() {
    return getUserUuidFromLocalStorage()
  }

  submitResults(results: any) {
    this.surveyService.submitResults(results, this.surveyTargetId, this.surveyId, true).subscribe({
      next: () => {
        this.toastr.success('Réponses Envoyées!')
      },
      error: err => {
        if (err.status === 403) {
          // 403 Forbidden déjà répondu
          this.toastr.error('Vous avez déjà répondu à ce questionnaire!')
        } else if (err.status === 410) {
          // 410 Gone clôturé
          this.toastr.error('Ce questionnaire a été clôturé!')
        } else {
          this.toastr.error('Une erreur est survenue!')
        }
        console.log(err)
      },
    })
  }
}
