export default {
  lien: {
    list: {
      title: 'List des liens entre entités',
      btn_link_team: 'Ajouter lien entre équipes',
      btn_link_service: 'Ajouter lien entre services',
      equipe: 'Équipes',
      service: 'Services',
      tous: 'Tous',
      type: 'Type',
      entite: 'Entité',
      organigrame: 'Organigramme',
      Representant: 'Représentant',
      nimorte: "N'importe",
      Ambassadeur: 'Ambassadeur',
      Responsable: 'Responsable',
      ServiceLie: 'Service lié',
      EquipeLie: 'Equipe lié',
    },
    equipe: {
      title: 'Ajouter lien équipe',
      title1: 'lien équipe',
      equipe: 'Sélectionner équipe',
      nameEquipe: "Nom d'équipe",
      representant: 'Sélectionner les représentants',
      nameRep: 'Les représentants',
      existe: 'Le lien existe déjà',
    },
    service: {
      title: 'Ajouter lien service',
      title1: 'lien service',
      equipe: 'Sélectionner service',
      nameEquipe: 'Nom de service',
      representant: 'Sélectionner les représentants',
      nameRep: 'Les représentants',
    },
    DELETE: {
      TITLE: '  Supprimer lien',
      DESC_1: ' suppression lien ...',
      DESC_2: 'Êtes-vous sûr de supprimer définitivement ce lien?',
      BTN_DELETE: 'Supprimer',
      BTN_CANCEL: 'Annuler',
    },
  },
  onboarding: {
    title: "Processus d'Onboarding",
    heure: 'temps',
    btn_add: 'Nouvelle règle',
    TYPE: 'TYPE',
    nom: 'nom',
    organigrame: 'Organigramme',
    Cible: 'Type cible',
    evenement: 'événement',
    Periorite: 'Priorité',
    Haut: 'Haut',
    Moyen: 'Moyen',
    Bas: 'Bas',
  },
  newOnboarding: {
    title: 'Nouvelle règle',
    event: 'Événement',
    collab: "Avec qui l'organiser",
    periorite: 'Priorité de la règle',
    Cible: "Type d'entité d'accueil",
    Site: 'Site',
    Service: 'Service',
    Equipe: 'Équipe',
    acc: "Entité d'accueil",
    horaire: "Horaire de l'événement",
    ques1: "1) Sélectionnez l'entité d'accueil du nouveau collaborateur...",
    ques2: "2) Définissez le type d'évènement et avec qui l'organiser...",
    ques3: '3) Personnaliser la règle...',
    ques4: '2) Les détails du doccument',
  },
  DELETE: {
    TITLE: '  Supprimer onboarding',
    DESC_1: ' suppression onboarding ...',
    DESC_2: 'Êtes-vous sûr de supprimer définitivement ce regle?',
    BTN_DELETE: 'Supprimer',
    BTN_CANCEL: 'Annuler',
  },
}
