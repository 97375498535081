export default {
  DELETED_MSG: 'message supprimé',
  ADD_MEMBERS: 'Ajouter des membres',
  DEL_MEMBERS: 'Supprimer des membres',
  DEL_GROUP_CHAT: 'Supprimer la discussion',
  SEARCH_BY_NAME: 'Rechercher par nom...',
  START_SEARCH_BY_USER_NAME: "Commencez une recherche d'utilisateur par nom...",
  GROUP_CHAT: 'Discussion',
  CHANGE_ADMIN: "Changer l'Admin",
  PROMPT_DEL_ROOM: 'Êtes-vous sûr de vouloir supprimer cette discussion une fois pour toutes ?',
  USER_ACTIVE: 'Actif',
  USER_INACTIVE: 'Inactif',
  SEND: 'Envoyer',
}
