import { CommonModule } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { AdminParkingService } from 'src/app/modules/admin-parking/admin-parking.service'
import { TranslationModule } from 'src/app/modules/i18n/translation.module'
import { imgEncoder } from 'src/app/utils/imgEncoder'
import { ParkingService } from '../../_services/parking.service'
import { PromptModalComponent } from './../../../shared/prompt-modal/prompt-modal.component'

@Component({
  selector: 'app-user-carpool-list-modal',
  standalone: true,
  imports: [CommonModule, TranslationModule, InlineSVGModule],
  templateUrl: './user-carpool-list-modal.component.html',
  styleUrls: ['./user-carpool-list-modal.component.scss'],
})
export class UserCarpoolListModalComponent implements OnInit {
  @Input() parkId: any
  @Input() date: any
  listReservations: any = []
  @Input() refresh
  constructor(
    public parkSvc: ParkingService,
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    public adminParking: AdminParkingService
  ) {}

  ngOnInit(): void {
    this.getAllReservations()
  }

  openModalCreate() {
    // const modalRef = this.modalService.open(ModalEmplacementComponent)
    // modalRef.result.then(
    //   () => this.parkSvc.fetch(),
    //   () => {}
    // )
  }

  openModalReserve() {
    // const modalRef = this.modalService.open(ModalCalendarComponent, {
    //   size: 'xl',
    // })
    // modalRef.result
    //   .then
    //   //() => this.eventService.fetch(),
    //   //() => {}
    //   ()
  }
  closeModal() {
    this.modal.close()
  }
  getAllReservations() {
    this.parkSvc.getCurrentUserReservations().subscribe(res => (this.listReservations = res))
  }
  encoder(s: any) {
    return imgEncoder(s)
  }
  openReservationModal() {
    // const modalRef = this.modalService.open(ModalReservationSecondComponent)
    // modalRef.componentInstance.date = this.date
    // modalRef.componentInstance.parkId = this.parkId
    // modalRef.result.then(
    //   () => this.getAllReservations(),
    //   () => {}
    // )
  }
  openDeleteReservationModal(reservationId) {
    const modalRef = this.modalService.open(PromptModalComponent)
    /*  data: {
    message: string
    title: string
    confirmText: string
    cancelText: string
    confirmColor: string
    cancelColor: string
  }
/*/
    modalRef.componentInstance.data = {
      message: 'Étes-vous sûr de vouloir supprimer cette réservation ?',
      title: 'Supprimer la réservation',
      confirmText: 'Supprimer',
      cancelText: 'Annuler',
      confirmColor: 'danger',
      cancelColor: 'secondary',
    }
    modalRef.result.then(
      () => {
        this.adminParking.deleteReservation(reservationId, '').subscribe(res => {
          this.getAllReservations()
        })
      },
      () => {}
    )
  }
}
