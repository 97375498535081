import { Component } from '@angular/core'

@Component({
  selector: 'photo-gallery',
  // an existing module is imported directly into a standalone component

  template: ` <router-outlet></router-outlet> `,
})
export class UserParkingrouterOutletComponent {
  // logic
}
