import { AuthModel } from './auth.model';
import { v4 as uuidv4 } from 'uuid';

export class UserModel extends AuthModel {
  uuid: typeof uuidv4;
  login_email: string;
  login_password: string;
  sessionid: any;
  nom: any;
  prenom: any;
  firstname: any;
  lastname: any;
  IdRole_Role: any;

  emailSettings: {
    emailNotification: boolean
    sendCopyToPersonalEmail: boolean
    activityRelatesEmail: {
      youHaveNewNotifications: boolean
      youAreSentADirectMessage: boolean
      someoneAddsYouAsAsAConnection: boolean
      uponNewOrder: boolean
      newMembershipApproval: boolean
      memberRegistration: boolean
    }
  };

  setUser(user: any) {
    this.uuid = user.uuid;
    this.login_email = user.login_email || '';
    this.login_password = user.login_password || '';
    this.login_password = user.login_password || '';
    this.sessionid = user.sessionid || '';
    this.nom = user.nom || '';
    this.prenom = user.prenom || '';
    this.IdRole_Role = user.IdRole_Role || [];
  }
}
