import { Component } from '@angular/core';

@Component({
  selector: 'app-on-boarding-pre-boarding',
  templateUrl: './on-boarding-pre-boarding.component.html',
  styleUrls: ['./on-boarding-pre-boarding.component.scss']
})
export class OnBoardingPreBoardingComponent {

}
