import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Observable, of } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { environment } from '../../../../../environments/environment'
import { UserModel } from '../../_models/user.model'

const API_USERS_URL = `${environment.apiUrl}`

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient, private route: ActivatedRoute) {}
  getJwt(email, password) {
    const params = new HttpParams().set('email', email).set('password', password)

    return this.http.post<any>(`${environment.simpleUrl}`, params).pipe(
      catchError(err => {
        console.error('ERROR ! couldnt get requested data', err)
        if (err.status == 400) {
          window.location.reload()
        }
        return of(err.status)
      })
    )
  }

  // validate user token and check if its expired or not
  validateJwt() {
    let JWT = localStorage.getItem('JWT')
    JWT = JWT.replace(/"/g, '')
    const params = new HttpParams().set('JWT', JWT)

    return this.http.get<any>(`${environment.simpleUrl}/validate`, { params }).pipe(
      catchError(err => {
        console.error('ERROR ! couldnt get requested data', err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      })
    )
  }

  refreshJwt() {
    let JWT = localStorage.getItem('JWT')
    JWT = JWT.replace(/"/g, '')
    const params = new HttpParams().set('JWT', JWT)

    return this.http.post<any>(`${environment.simpleUrl}/refresh`, params).pipe(
      catchError(err => {
        console.error('ERROR ! couldnt get requested data', err)
        if (err.status == 400) {
          window.location.reload()
        }
        return of(err.status)
      })
    )
  }

  // CREATE =>  POST: add a new user to wordpress
  createWsUser(user: UserModel): Promise<any> {
    let email = user.login_email
    let password = user.login_password
    let AUTH_KEY = environment.AUTH_KEY
    let display_name = user.nom
    let first_name = user.nom
    let last_name = user.prenom
    let user_login = user.nom

    const params = new HttpParams()
      .set('email', email)
      .set('password', password)
      .set('AUTH_KEY', AUTH_KEY)
      .set('display_name', display_name)
      .set('first_name', first_name)
      .set('last_name', last_name)
      .set('user_login', user_login)

    let promise = new Promise<any>((resolve, reject) => {
      const url = `${environment.registerUser}`
      this.http
        .post(url, params)
        .toPromise()
        .then(
          res => {
            // success
            resolve(res)
          },
          msg => {
            // Error
            reject(msg)
          }
        )
    })
    return promise
  }

  verifCode(login_email: any, validation_code: any): Observable<any> {
    const params = new HttpParams().set('login_email', login_email)

    return this.http.post<any>(API_USERS_URL + '/todo/verif_code', { validation_code }, { params })
  }
  // public methods
  login(login_email: string, login_password: string): Observable<any> {
    const params = new HttpParams().set('user_langue', navigator.language.split('-')[0])

    return this.http.post<any>(API_USERS_URL + '/todo/login', { login_email, login_password }, { params })
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<any> {
    //modules opened to user
    let modules
    this.route.queryParams.subscribe(parametres => {
      modules = parametres['modules']
    })
    if (modules === undefined) {
    } else {
    }
    if (modules !== undefined) {
      const params = new HttpParams().set('optionAbonnement', modules)
      return this.http.post<UserModel>(`${API_USERS_URL}/todo/register`, user, { params })
    } else {
      return this.http.post<UserModel>(`${API_USERS_URL}/todo/register`, user)
    }
  }
  //create user by link
  createUserLink(user: UserModel, token: any): Observable<any> {
    const params = new HttpParams().set('token', token)
    return this.http.post<UserModel>(`${API_USERS_URL}/todo/registerlien`, user, { params })
  }
  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(login_email: any): Observable<any> {
    return this.http.put<any>(`${API_USERS_URL}/todo/forgotpasswordlogin`, {
      login_email,
    })
  }
  getUserbySession(sessionid) {
    return this.http.get<UserModel>(`${API_USERS_URL}/todo/user`, sessionid)
  }
  getUserbyEmail(login_email: any): Observable<any> {
    const params = new HttpParams().set('login_email', login_email)

    return this.http.get<any>(`${API_USERS_URL}/todo/getbyemail`, { params })
  }
  
}
