export default {
  gnrl: {
    Sauvegarder: 'Save',
    SauvegarderModel: 'Save as model',
    ProgrammerPub: 'Schedule the publication',
    QUITER: 'Quit',
  },
  SAVEASMODEL: {
    TITLE: 'Save as template',
    TITLE1: 'Be careful it is irreversible',
    p1: 'Are you sure to save this newsletter as a template?',
    btn: 'Save',
  },
  PLANIFICATION: {
    TITLE: 'Schedule release date',
    p1: 'Activation date',
    btn: 'Schedule',
  },
  LIST: {
    TITLE: 'List of newsletters',
    BTN: 'New newsletter',
    TITRE: 'Title',
  },
  DELETE: {
    TITLE: 'Delete newsletter',
    DESC_1: 'newsletter deletion...',
    DESC_2: 'Are you sure you want to permanently delete this newsletter?',
    BTN_DELETE: 'Delete',
    BTN_CANCEL: 'Cancel',
  },
  CHART: {
    TITLE: 'Newsletter Statistics',
    FILTER: 'Filter',
    TMP_MIN: 'Minimum reading time',
    TMP_MAX: 'Maximum reading time',
    SECOND: 'Seconds',
    TEAUX: 'Opening rate',
    DETAILS_PAR_BLOCK: 'Newsletter details per block',
    NUM: 'block Number',
    NBR_30: 'number of people who read more than 30 sec',
    MOY: 'average reading in s',
  },
}
