import { HttpClient, HttpParams } from '@angular/common/http'
import { Inject, Injectable, OnDestroy } from '@angular/core'
import { map, Observable } from 'rxjs'
import { baseFilter } from 'src/app/common/helpers/http-extenstions'
import { ITableState, TableResponseModel } from 'src/app/_metronic/shared/crud-table'
import { TableResvRulesService } from 'src/app/_metronic/shared/crud-table/services/table.resvRules.service'
import { environment } from 'src/environments/environment'
import { ResvRuleT } from './os-modeling-service.service'

@Injectable({
  providedIn: 'root',
})
export class ListResvRulesService extends TableResvRulesService<ResvRuleT> implements OnDestroy {
  // protected authLocalStorageToken = ${environment.appVersion}-${environment.USERDATA_KEY};
  API_URL = `${environment.apiUrl}/osplanner/reservation-rules`
  API_URLAddMember = `${environment.apiUrl}/equipe/addmemebrs_To_equipe_Transverse`
  API_URLAddMemberTeam = `${environment.apiUrl}/equipe/addmemebrs_To_equipe`
  API_URLAmbassadeur = `${environment.apiUrl}/equipe/getAmbassadeurForEquipe`
  constructor(@Inject(HttpClient) http) {
    super(http)
  }

  find(TableEquipeService: ITableState): Observable<TableResponseModel<ResvRuleT>> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(`${this.API_URL}`, { params }).pipe(
      map((response: ResvRuleT[]) => {
        const filteredResult = baseFilter(response, TableEquipeService)
        const result: TableResponseModel<ResvRuleT> = {
          items: filteredResult.items,
          total: filteredResult.total,
        }
        return result
      })
    )
  }

  delete(id: string): Observable<any> {
    const params = new HttpParams().set('sessionid', this.getAuthFromLocalStorage())
    return this.http.delete<any>(`${this.API_URL}/${id}`, { params })
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe())
  }
}
