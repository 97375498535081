import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { LocalizedDatePipe } from 'src/app/pipes/localized-date.pipe'
import { TimeAgoPipe } from 'src/app/pipes/time-ago.pipe'
import { CapitalizePipe } from '../../formations/pipes/first-letter-capitalize.pipe'
import { TimeConvertPipe } from '../../formations/pipes/time-convert.pipe'

@NgModule({
  declarations: [LocalizedDatePipe, TimeAgoPipe, TimeConvertPipe, CapitalizePipe],
  imports: [CommonModule],
  exports: [LocalizedDatePipe, TimeAgoPipe, TimeConvertPipe, CapitalizePipe],
})
export class CustomPipesModule { }
