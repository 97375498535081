export default {
  EVENT: 'event',
  DELETE_EVENT_PROMPT: 'Are you sure you want to delete the event',
  DELETE_EVENT: 'Cancel Event',
  EVENT_WAS_ADDED: 'Event successfully scheduled!',
  ERROR_WHILE_EVENT_ADDING: 'Error while scheduling the event!',
  EVENT_WAS_DELETED: 'Event was canceled!',
  ERROR_WHILE_EVENT_DELETE: 'Error while trying to cancel the event!',
  EVENT_ALREADY_EXISTS: 'The same event is already scheduled on that day!',
  DROP_CARD_TO_ADD_EVENT: 'Drag and drop one of the cards on the left to add an event.',
  CLICK_EVENT_TO_DELETE: 'Click on an event in the calendar to delete it.',
}
