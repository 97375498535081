<div class="modal-header">
  <div class="modal-title h4">Proposition envoyée</div>
  <span aria-hidden="true">
    <i class="ki ki-close" (click)="close()"></i>
  </span>
</div>
<div class="modal-body">
  <ng-container>
    <span
      >Votre proposition a été envoyée avec succès. N'hésitez pas à prendre contact avec votre collègue pour discuter ensemble des modalités du
      covoiturage.
    </span>
  </ng-container>
</div>
<div class="modal-footer">
  <div><button type="button" class="btn btn-light btn-elevate mr-1" (click)="modal.dismiss()">OK</button>&nbsp;</div>
</div>
