<div class="main">
  <ng-container *ngIf="!isLoading && surveyId">
    <wm-fill-survey style="width: 100%" [surveyId]="surveyId" preview-mode="false" state="closed" (submitResults)="submitResults($event.detail)">
    </wm-fill-survey
  ></ng-container>
  <div class="d-flex justify-content-center align-items-center w-100 h-100" *ngIf="isLoading">
    <div class="spinner-grow text-primary" style="width: 3rem; height: 3rem" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
