<div class="card card-custom gutter-b" style="overflow: hidden">
  <div
    class="card-header"
    style="
      background-color: rgba(255, 255, 255, 0.5);
      background-image: url('/assets/test_nouveaux_logos/survey.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
    "
  >
    <div style="flex: 5"></div>
    <div class="card-title" style="flex-grow: 5; text-align: center">
      <h1 class="card-label h3">{{ "Mes enquêtes" | translate }}</h1>
    </div>
  </div>

  <!--tableau de collab-->
  <div class="card-body">
    <!-- begin:Table -->

    <ng-container *ngIf="isLoading">
      <!-- bootstrap 4 spinner -->
      <div class="d-flex justify-content-center" style="min-height: 50vh; display: flex; justify-content: center">
        <div class="spinner spinner-primary spinner-lg mr-15"></div>
      </div>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <div
        *ngIf="!(svc.items$ | async).length; else dTable"
        class="text-center mt-15"
        style="min-height: 50vh; display: flex; justify-content: center"
      >
        <h3 class="text-muted" style="width: 700px">
          Nous n'avons pas d'enquêtes sollicitant votre avis pour le moment, Mais dès que ce sera le cas, nous vous enverrons une notification ! 🙂
        </h3>
      </div>
      <ng-template #dTable>
        <div class="table-responsive angular-bootstrap-table" style="min-height: 50vh">
          <table class="table table-head-custom table-vertical-center overflow-hidden">
            <thead>
              <th>Titre de l'enquête</th>
              <th>Date de début</th>
              <th>Date de clôture</th>

              <th>Actions</th>
            </thead>
            <tbody *ngFor="let target of svc.items$ | async">
              <td>
                {{ target?.survey?.title || ("Sans Titre" | translate) }}
              </td>
              <td>
                <input
                  [value]="dateForInput(target.dt_start)"
                  [defaultValue]="dateForInput(target.dt_start)"
                  type="datetime-local"
                  class="form-control form-control-sm form-control-solid"
                  style="max-width: 160px"
                  disabled
                />
              </td>
              <td>
                <input
                  [value]="dateForInput(target.dt_end)"
                  [defaultValue]="dateForInput(target.dt_end)"
                  type="datetime-local"
                  class="form-control form-control-sm form-control-solid"
                  style="max-width: 160px"
                  disabled
                />
              </td>
              <td>
                <a *ngIf="!target.result && !target.closed" class="btn btn-primary btn-sm mr-5" (click)="openSurvey(target)"> Répondre </a>
                <!-- if target.result show boostrap badge: already responded: -->
                <span *ngIf="target.result" class="badge badge-success mr-2"> Déjà répondu </span>
                <!-- if target.closed show boostrap badge: closed: -->
                <span *ngIf="target.closed" class="badge badge-danger"> Clôturée </span>
              </td>
            </tbody>
          </table>
        </div>
        <!-- end: Table -->
        <app-paginator
          *ngIf="paginator"
          class="d-flex justify-content-between align-items-center flex-wrap"
          [paginator]="paginator"
          [isLoading]="isLoading"
          (paginate)="paginate($event)"
        ></app-paginator>
      </ng-template>
    </ng-container>
  </div>
</div>
