export default {
  'Fiches de poste': 'Job Descriptions',
  'Fiche de poste': 'Job Description',
  'Nouvelle fiche de poste': 'New Job Description',
  'Assigner à des collaborateurs': 'Assign to employees',
  'Aptitudes et compétences essentielles :': 'Essential skills and competencies :',
  'Connaissances essentielles :': 'Essential knowledge :',
  'Aptitudes et compétences optionnelles :': 'Optional skills and competencies :',
  'Connaissances optionnelles :': 'Optional knowledge :',
  'Compétences essentielles :': 'Essential skills :',
  'Compétences optionnelles :': 'Optional skills :',
  'Créer une fiche poste': 'Create a job description',
  'Modifier la fiche poste': 'Edit the job description',
  'Supprimer la fiche poste': 'Delete the job description',
  'Détails de la fiche poste': 'Job description details',
  'Êtes-vous sûr de vouloir supprimer cette fiche poste ?': 'Are you sure you want to delete this job description?',
  'Aucun collaborateur assigné à cette fiche de poste. Tapez dans le champ de recherche pour commencer.':
    'No employee assigned to this job description. Type in the search field to start.',
}
