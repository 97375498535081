import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatChipsModule } from '@angular/material/chips'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatRadioModule } from '@angular/material/radio'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { ClipboardModule } from 'ngx-clipboard'
import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs'
import { ToastrModule } from 'ngx-toastr'
import { environment } from 'src/environments/environment'
import { SplashScreenModule } from './_metronic/partials/layout/splash-screen/splash-screen.module'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AuthService } from './modules/auth/_services/auth.service'
import { DocumentationComponent } from './modules/documentation/documentation.component'
import { NouveautesComponent } from './modules/nouveautes/nouveautes.component'
import { SupportComponent } from './modules/support/support.component'

// locale register for date pipes
import { DatePipe, registerLocaleData } from '@angular/common'
import localeEn from '@angular/common/locales/en'
import localeFr from '@angular/common/locales/fr'
import { GeneralInterceptor } from './common/interceptors/generalInterceptor'
registerLocaleData(localeEn)
registerLocaleData(localeFr)

import { MatSelectModule } from '@angular/material/select'
import { FullCalendarModule } from '@fullcalendar/angular'
import { CmAngularOrgChartModule } from 'cm-angular-org-chart'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown'
import { NgxEditorModule } from 'ngx-editor'
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io'
import { AdminParkingComponent } from './modules/admin-parking/admin-parking.component'
import { ListReservationsComponent } from './modules/admin-parking/list-reservations/list-reservations.component'
import { ModalCalendarComponent } from './modules/admin-parking/modal-calendar/modal-calendar.component'
import { ModalDeleteReservationComponent } from './modules/admin-parking/modal-delete-reservation/modal-delete-reservation.component'
import { ModalEditParkingComponent } from './modules/admin-parking/modal-edit-parking/modal-edit-parking.component'
import { ModalEmplacementComponent } from './modules/admin-parking/modal-emplacement/modal-emplacement.component'
import { ModalReservationSecondComponent } from './modules/admin-parking/modal-reservation-second/modal-reservation-second.component'
import { ModalReservationComponent } from './modules/admin-parking/modal-reservation/modal-reservation.component'
import { UserParkingrouterOutletComponent } from './modules/admin-parking/user-parking/router-outlet.component'
import { UserParkingComponent } from './modules/admin-parking/user-parking/user-parking.component'
import { UserReservationComponent } from './modules/admin-parking/user-parking/user-reservation/user-reservation.component'
import { OnBoardingPreBoardingComponent } from './modules/on-boarding-pre-boarding/on-boarding-pre-boarding.component'
import { OsplannerModule } from './modules/osplanner/osplanner.module'
import { QuestionnairesModule } from './modules/qsp/questionnaires.module'
import { AssocierStrategieComponent } from './modules/quiz/associer-strategie/associer-strategie.component'
import { AuthQuizComponent } from './modules/quiz/auth-quiz/auth-quiz.component'
import { GererQuizComponent } from './modules/quiz/gerer-quiz/gerer-quiz.component'
import { GererStrategieComponent } from './modules/quiz/gerer-strategie/gerer-strategie.component'
import { JouerComponent } from './modules/quiz/jouer/jouer.component'
import { QuizComponent } from './modules/quiz/quiz.component'
import { ResetStatsComponent } from './modules/quiz/reset-stats/reset-stats.component'
import { TrierQuizComponent } from './modules/quiz/trier-quiz/trier-quiz.component'
import { CustomPipesModule } from './modules/shared/custom-pipes/custom-pipes.module'
import { SondageComponent } from './modules/sondage/sondage.component'
import { SafeQuizPipe } from './pipes/safe-quiz.pipe'

const socketIoConfig: SocketIoConfig = {
  url: environment.socketUrl,
  options: {
    transports: ['websocket', 'polling'],
    // Add any additional options here
  },
}

function appInitializer(authService: AuthService) {
  return () => {
    return new Promise<void>(resolve => {
      authService.getUserByToken().subscribe().add(resolve)
    })
  }
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http)
}

@NgModule({
  declarations: [
    AppComponent,
    NouveautesComponent,
    SupportComponent,
    DocumentationComponent,
    QuizComponent,
    GererQuizComponent,
    GererStrategieComponent,
    AssocierStrategieComponent,
    JouerComponent,
    ResetStatsComponent,
    TrierQuizComponent,
    AuthQuizComponent,
    SafeQuizPipe,
    SondageComponent,
    OnBoardingPreBoardingComponent,
    ModalEmplacementComponent,
    ModalReservationComponent,
    ModalCalendarComponent,
    ListReservationsComponent,
    ModalReservationSecondComponent,
    ModalDeleteReservationComponent,
    ModalEditParkingComponent,
    AdminParkingComponent,
    UserParkingComponent,
    UserParkingrouterOutletComponent,
    UserReservationComponent,
  ],
  imports: [
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    // RouterModule.forRoot(routes, {
    //   preloadingStrategy: PreloadAllModules
    // }),
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    TranslateModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    BrowserModule,
    FullCalendarModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    HighlightModule,
    ClipboardModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    MatCardModule,
    MatAutocompleteModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatExpansionModule,
    SocketIoModule.forRoot(socketIoConfig),
    QuestionnairesModule,
    OsplannerModule,
    NgMultiSelectDropDownModule.forRoot(),
    CustomPipesModule,
    CmAngularOrgChartModule,
    NgxEditorModule,
  ],
  providers: [
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },

    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
          json: () => import('highlight.js/lib/languages/json'),
        },
      },
    },
    { provide: HTTP_INTERCEPTORS, useClass: GeneralInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
