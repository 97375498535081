export default {
  DETAILS: {
    TERMS_OF_USE: 'Terms of Service',
    PRIVACY_POLICY: 'Privacy Policy',
  },
  GENERAL: {
    OR: 'Or',
    SUBMIT_BUTTON: 'Submit',
    NO_ACCOUNT: "Don't have an account?",
    SIGNUP_BUTTON: 'Sign Up',
    FORGOT_BUTTON: 'Forgot Password',
    BACK_BUTTON: 'Back',
    PRIVACY: 'Privacy',
    LEGAL: 'Legal',
    CONTACT: 'Contact',
  },
  LOGIN: {
    SLOGAN: 'Creating the joy of working together',
    WELCOME: 'Welcome',
    TITLE: 'Login Account',
    BUTTON: 'Sign In',
    NEW_HERE: 'Create your company at EmyeHR',
    MESSAGE: 'Use your email and password to continue',
    MESSAGE1: 'your account is deactivated please contact the manager',
    ERROR: 'The login details are incorrect',
    PASSWORD: 'Password',
    FORGOTPASS: 'Forgot Password?',
    SIGNUP_BUTTON: 'Register',
  },
  FORGOT: {
    TITLE: 'Forgotten Password?',
    DESC: 'Enter your email to reset your password',
    mailEx:
      'If your email is registered with us you will soon receive an email with reset instructions.',
    SUCCESS: 'Your account has been successfully reset.',
    CANCEL: 'Cancel',
    EMAIL_IS_CORRECT: 'Email is correct!',
    MSG_SUCCESS_01: 'We have emailed your password reset instructions!',
    GOT_IT: 'Ok, got it!',
  },
  REGISTER: {
    TITLE: 'Sign Up',
    DESC: 'Enter your details to create your account',
    PASSWORD: 'Mot de passe',
    CONFIRMPASS: 'Confirm Password',
    MESSAGECONFIRMPASS: '"Passsword" and "Confirm Password" didn t match.',
    AGREE: 'I Agree the',
    TERMS: 'terms and conditions',
    CANCEL: 'Cancel',
    Nom: 'Last name',
    Prenom: 'First name',
    ERR: 'This email already exists. Please use another email or try to recover a new password, if you forgot it.',
    ERR1: 'This token is already expired',
    MAILERR: 'This email is not professional. Please use a professional email .',
    SUBMIT: ' Submit',
  },
  ACTIVE: {
    TITLE: 'Active Account ?',
    DESC: 'Enter your code to active your account',
    CANCEL: 'Cancel',
    ERROR: 'The code details are incorrect',
    CODE: 'code is required',
  },
  INPUT: {
    EMAIL: 'Email',
    FULLNAME: 'Fullname',
    PASSWORD: 'Password',
    CONFIRM_PASSWORD: 'Confirm Password',
    USERNAME: 'Username',
  },
  VALIDATION: {
    INVALID: '{{name}} is not valid',
    REQUIRED: '{{name}} is required',
    MIN_LENGTH: '{{name}} minimum length is {{min}}',
    AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
    NOT_FOUND: 'The requested {{name}} is not found',
    INVALID_LOGIN: 'The login detail is incorrect',
    REQUIRED_FIELD: 'Required field',
    MIN_LENGTH_FIELD: 'Minimum field length:',
    MAX_LENGTH_FIELD: 'Maximum field length:',
    INVALID_FIELD: 'Field is not valid',
    INVALID_EMAIL: 'Email is invalid',
  },
}
