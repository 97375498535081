import { Component } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-prompt-modal',
  templateUrl: './prompt-modal.component.html',
  styleUrls: ['./prompt-modal.component.scss'],
})
export class PromptModalComponent {
  data: {
    message: string
    title: string
    confirmText: string
    cancelText: string
    confirmColor: string
    cancelColor: string
  }

  constructor(public modal: NgbActiveModal) {}
}
