export default {
  DELETED_MSG: 'message was deleted',
  ADD_MEMBERS: 'Add members',
  DEL_MEMBERS: 'Remove members',
  DEL_GROUP_CHAT: 'Delete group chat',
  SEARCH_BY_NAME: 'Search by name...',
  START_SEARCH_BY_USER_NAME: 'Start searching users by name...',
  GROUP_CHAT: 'Group Chat',
  CHANGE_ADMIN: 'Change Admin',
  PROMPT_DEL_ROOM: 'Are you sure you want to permanently delete this room?',
  USER_ACTIVE: 'Active',
  USER_INACTIVE: 'Inactive',
  SEND: 'Send',
}
