export default {
  header: {
    title: 'set up your new event',
    button: 'Save',
  },

  control: {
    tenCharMin: 'should have at least 10 characters',
    threeCharMin: 'should have at least 3 characters',
    thousandCharMin: ' should have at most 1000 characters',
    monthCheckMax: "Shouldn't be greater than 31",
    monthCheckMin: "Shouldn't be less than 1",
    monthCheckLengthMin: 'should have at least 1 characters',
    invDateCheck: "Start date of the event can't after notification send date",
    maxMinInvCheck: 'Maximum number of participant should be more than minimum number of participant',
    groupCheck: 'Two groups should have different names',
    suivantCheck: 'Fill all the required field before advancing to the next page',
    sum: 'sum of max group participants should not pass max number of event participants',
    reach: 'Maximum number of registrations reached',
  },

  wizard: {
    stepOne: 'Event Details',
    stepTwo: 'Date and Time',
    stepThree: 'Participants Details',
    stepFour: 'Team Details',
    stepFive: 'Notification Details',
  },

  wizardOne: {
    title: 'Event Title',
    placement: 'Event Placement',
    iconInput: 'Event type',
    image: 'Event Image',
    description: 'Event Description',
    referrer: 'referrer',
    searchInputRef: 'Search',
  },

  wizardTwo: {
    duration: 'Duration',
    frequency: 'Frequency',
    time: 'Time',
    frequencyFirst: 'Everyday',
    frequencySecond: 'Every Week',
    frequencyThird: 'Every Month',
    frequencyFourth: 'Once',
    frequencyFifth: 'Every XX day of the month',
    day: 'Day',
    firstDay: 'Sunday',
    secondDay: 'Monday',
    thirdDay: 'Tuesday',
    fourthDay: 'Wednesday',
    fifthDay: 'Thursday',
    sixthDay: 'Friday',
    seventhDay: 'Saturday',
    month: 'Day of the Month',
    startDate: 'Start Date',
  },

  wizardThree: {
    min: 'Minimum Number Of Participants',
    cancelDuration: 'Duration Before Cancel',
    max: 'Maximum Number Of Participants',
    target: 'Target',
    team: 'Target Team',
    firstChoice: 'Site',
    secondChoice: 'Service',
    thirdChoice: 'Team',
    fourthChoice: 'Transverse team',
    checkboxOne: 'High potential',
    checkboxTwo: 'Special follow-up',
    cibleCheck: 'Target invited should not be empty',
  },

  wizardFour: {
    inputOne: 'Team Configuration',
    inputOneDesc: 'if you need more information visit our',
    inputOneLink: 'online help',
    choiceOne: 'one team',
    choiceTwo: 'more than one team',
    choiceOneDesc: 'if your event only needs one team',
    choiceTwoDesc: 'if your event requires more than one team to compete',
    inputTwo: 'Number Of Teams',
    inputThree: 'Name Of The Team',
    inputFour: 'Maximum number of participants',
  },

  wizardFive: {
    date: 'Notification send Date',
    time: 'Notification send Time',
    remind: 'Set notification before XX days for future events',
    cloture: 'Registration ends XX days before event',
  },

  footer: {
    previous: 'Previous',
    submit: 'Submit',
    next: 'Next',
  },

  listHeader: {
    title: 'Events list',
    addButton: 'add new event',
  },

  tools: {
    search: 'Search',
    option: 'any field',
  },

  table: {
    columnOne: 'Event Title',
    columnTwo: 'duration',
    coumnThree: 'Minimum number of participants',
    columnFour: 'number of registered persons',
    columnFive: 'Event Status',
    ColumnSix: 'Actions',
    button1: 'Edit event',
    button2: 'Delete event',
    button3: 'add future events',
  },

  eventStatus: {
    status: 'Canceled',
    status1: 'Created',
    status2: 'Invitation sent',
    status3: 'Registration closed',
    status4: 'In progress',
    status5: 'Terminated',
  },

  modify: {
    title: 'Modify event',
  },

  subEvent: {
    titleDetails: 'Recurrent event details',
    modifyRecurrentEvent: 'Modify recurrent event',
    inputOne: 'Recurring event location',
    inputTwo: 'A notification before XX days for the recurring event',
    inputThree: 'Recurring event start date',
    inputFour: 'Recurring event start time',
  },

  delete: {
    title: 'Delete event',
    desc: 'Are you sure you want to delete this event permanently?',
  },

  listGroup: {
    button: 'Add new group',
    column1: 'Group name',
    column2: 'Maximum number of persons in the group',
    column3: 'Number of participants in the group',
    column4: 'actions',
  },

  myEvent: {
    button1: 'Yes',
    button2: 'Maybe',
    button3: 'No',
    status0: 'You did not answer',
    status1: 'You are registered',
    status2: "You haven't decided yet",
    status3: 'You declined',
    statusEvent1: 'Finished',
    statusEvent2: 'Coming',
    statusEvent3: 'Canceled',
    groupPopUpTitle: 'Group choice',
    groupPopUpDesc: 'Select the group you want to join',
    groupPopUpButton1: 'Register',
    groupPopUpButton2: 'Cancel',
    groupPopUp2Title: 'Event Registration',
    groupPopUp2Desc: 'Are you sure you want to join group ',
    groupZoneTitle: 'List of groups',
    toastDesc: 'Your choice has succesfully treated',
    groupZoneButton: 'Register',
    groupZoneColumn1: 'group name',
    groupZoneColumn2: 'maximum number of persons',
    groupZoneColumn3: 'number of registered persons',
    groupZoneColumn4: 'ACTIONS',
    titreGroupModal: 'Group members list',
    contraint1: 'Registration in this event is closed',
    contraint2: 'This event has been cancelled',
    eventEtatFilter: "Filtrer sur l'état de l'événement",
    userEtatFilter: "Filtrer sur l'état de votre invitation",
    word: 'Filtrer',
    filterOneOptOne: 'All',
    filterOneOptTwo: 'Not Answered',
    filterOneOptThree: 'registered',
    filterOneOptFour: 'Maybe',
    filterOneOptFive: 'Declined',
    filterTwoOptOne: 'All',
    filterTwoOptTwo: 'Ended',
    filterTwoOptThree: 'Coming',
    filterTwoOptFour: 'Canceled',
  },

  listEvents: {
    title: 'List of events',
    column1: 'event name',
    column2: 'duration',
    column3: 'location',
    column4: 'start date',
    column5: 'status of your invitation',
    column6: 'actions',
  },

  tab1: {
    title: 'List of registred',
    graph1opt1: 'Yes',
    graph1opt2: 'Maybe',
    graph1opt3: 'No',
    graph1opt4: 'No answer',
    graph2desc:
      'percentage of people who are registered compared to the minimum number of registered in the event to take place',
    info1: 'People invited',
    info2: 'Person registred',
    info3: 'Registration closes on',
    column1: 'first name',
    column2: 'Last name',
    column3: 'Date of registration',
    column4: 'Invitation status',
  },

  tab2: {
    title: 'List of groups',
    modalTitel: 'Change group of ',
    inputText: 'The new group for ',
  },

  tab3: {
    title: 'Message et rappels',
    title1: 'Send prepared mails',
    button1: 'Remind who has not yet decided',
    button2: 'Remind who has not yet answered',
    button3: 'Remind the registered',
    button4: 'Remind who has declined',
    button5: 'Remind all invited persons',
    title2: 'Send personalised mails',
    input1: 'Mail title',
    input2: 'Mail description',
    input3: "Who's concerned by the mail",
    option1: 'All invites',
    option2: 'Invites who are registered for the event',
    option3: 'Invites who has not yet decided',
    option4: 'Invites who have declined the event',
    option0: 'Invites who has not eyt answered',
    button6: 'Send',
  },

  icons: {
    editEvent: 'Edit',
    deleteEvent: 'Cancel event',
    detailsSubEvent: 'Recurring event details',
    deleteSubEvent: 'Cancel recurring event',
    detailsGroup: 'Group details',
    changeGroup: 'Change user group',
    eventDetails: 'Event details',
    addSubEvent: 'Add recurring event',
  },

  modalAjouterEventFils: {
    title: 'Add recurring events',
    input1: 'Number of created events',
    input2: 'First event date',
  },
  graph: {
    opt1: 'Did not answer',
    opt2: 'Yes',
    opt3: 'Maybe',
    opt4: 'No',
  },
  toast: {
    peutEtreToast: 'you changed your registration status to maybe',
    nonToast: 'you have canceled your registration in this event',
    ouiToast: 'you are registered in this event',
    toastDesc: 'Your change has been processed successfully',
    toastchamps: 'Missing fields,please complete all fields correctly',
    ADDEVENT: 'Event add successfully',
    SENDEMAIL: 'Email sent successfully',
  },
}
