<div class="card card-custom gutter-b">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">{{ "sondage.title" | translate }}</h3>
    </div>
  </div>
  <div class="card-body">

    <!-- begin:Table -->
    <div class="table-responsive angular-bootstrap-table">
      <table
        class="table table-head-custom table-vertical-center overflow-hidden"
      >
        <thead>
          <th>{{ "sondage.icon" | translate }}</th>
          <th>{{ "sondage.nom" | translate }}</th>
          <th>{{ "sondage.description" | translate }}</th> 
          <th>{{ "sondage.action" | translate }}</th>
        </thead>
        <tbody>
          <tr>

            <td>
              <img
                src="../../../../assets/media/svg/icons/General/Thunder-move.svg"
                class="img1"
              />
            </td>

            <td>sondage nom </td>

            <td>sondage description </td>

            <td>
              <a
                title="Activer"
                class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                <span
                  [inlineSVG]="'./assets/media/svg/icons/Media/Play.svg'"
                  [cacheSVG]="true"
                  class="svg-icon svg-icon-md svg-icon-primary"
                >
                </span>
              </a>
            </td>

          </tr>
        </tbody>
      </table>
    </div>
    <!-- end: Table -->
  </div>
</div>
