export default {
  TRANSPORT: {
    Transport: 'Transport',
    Type: 'Type',
    Spécificité: 'Spécificité',
    Matricule: 'Matricule',
    Marque: 'Marque',
    Modèle: 'Modèle',
    txt: 'Je ne suis pas intéressé par le covoiturage',
  },
  RESERVATION: {
    disablePopup: 'Désactiver la popup',
    txt1: 'Tes collègues qui proposent leur voiture pour le covoiturage',
    Photo: 'Photo',
    Nom_Prénom: 'Nom Prénom',
    Places_restantes: 'Places restantes',
    Distance: 'Distance (km)',
    Action: 'Action',
    RESERVER: 'Réserver',
    txt2: 'Tes collègues à proximité qui pourraient covoiturer',
    Proposer: 'Proposer',
    txt3: "j'accepte de mettre a disposition ma voiture pour le covoiturage ",
    txt31: 'et je réserve une place de parking',
    proposer_reserver: 'Proposer et réserver',
    txt4: 'je veux seulement reserve une place de parking',
    Reserver: 'Réserver',
    SELECT: 'Choisir une date de covoiturage',
    NON_COVOITURAGE: "Il n'y a pas de covoiturage pour cette date",
    NON_PROPOSEE: "Il n'y a pas de personne a proximité pour proposer un covoiturage",
    proposer_voiture: 'Proposé ma voiture',
    CLOSE: 'Fermer',
  },
  COVOITURAGE: {
    Covoiturage: 'Covoiturage',
    Date: 'Date',
    Status: 'Status',
    attent: 'attente réponse',
    acceptée: 'acceptée',
    refusée: 'refusée',
    annulée: 'annulée',
    terminée: 'terminée',
  },
}
