<div>
  <div class="modal-header modal-title h4">{{ "OSPLANNER.ADD_VISITOR" | translate }}</div>

  <div class="modal-body">
    <ng-container>
      <div style="display: grid; align-items: center; grid-template-columns: auto auto; row-gap: 25px">
        <span>{{ "OSPLANNER.FNAME" | translate }} <b>*</b> </span>
        <input [(ngModel)]="firstName" type="text" class="form-control" placeholder="{{ 'OSPLANNER.FNAME' | translate }}" />
        <span>{{ "OSPLANNER.LNAME" | translate }} <b>*</b> </span>
        <input [(ngModel)]="lastName" type="text" class="form-control" placeholder="{{ 'OSPLANNER.LNAME' | translate }}" />
        <span>{{ "OSPLANNER.EMAIL" | translate }}</span>
        <input [(ngModel)]="email" type="text" class="form-control" placeholder="{{ 'OSPLANNER.EMAIL' | translate }}" />
        <span>{{ "OSPLANNER.PHONE" | translate }}</span>
        <input [(ngModel)]="phone" type="text" class="form-control" placeholder="{{ 'OSPLANNER.PHONE' | translate }}" />
      </div>
      <br />
      <div *ngIf="errMsg" class="alert-danger text-center py-3">{{ errMsg }}</div>
    </ng-container>

    <div class="modal-footer">
      <div style="display: flex">
        <button type="button" class="btn btn-danger btn-elevate mr-1" (click)="modal.dismiss()">
          {{ "GENERAL.BTN_CANCEL" | translate }}
        </button>
        <button [disabled]="isLoading" type="button" class="btn btn-primary btn-elevate" (click)="submit()">
          {{ "GENERAL.BTN_ADD" | translate }}
          <div *ngIf="isLoading" class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
