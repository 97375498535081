import { DatePipe } from '@angular/common'
import { ChangeDetectorRef, Component, Input, Optional } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { NgbActiveModal, NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { AdminParkingService } from '../admin-parking.service'

@Component({
  selector: 'app-modal-delete-reservation',
  templateUrl: './modal-delete-reservation.component.html',
  styleUrls: ['./modal-delete-reservation.component.scss'],
})
export class ModalDeleteReservationComponent {
  @Input() reservationId: any

  constructor(
    public parkingService: AdminParkingService,
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    @Optional() public cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private translate: TranslateService,
    public datePipe: DatePipe,
    private timepickerConfig: NgbTimepickerConfig
  ) {}

  closeModal() {
    this.modal.close()
  }

  deleteReservation() {
    const reason = document.getElementById('reason') as HTMLTextAreaElement
    this.parkingService.deleteReservation(this.reservationId, reason.value).subscribe(res => {
      this.toastr.success(this.translate.instant('EVENT.toast.toastDesc')), this.modal.close()
    })
  }

}
