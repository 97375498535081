export default {
  REMOTE: 'Remote',
  IN_OFFICE: 'In-Office',
  COWORKING: 'Coworking',
  ABSENT: 'Absent',
  WORK_MODE: 'Work mode',
  ERROR_ADD_ON_DAY_OFF: "Can't add a work mode on a day off",
  CALENDAR_NOTES:
    'Click on a work mode badge to change it, or add a new one by clicking on an empty date.',
}
