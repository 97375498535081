import { DatePipe } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { AdminParkingService } from 'src/app/modules/admin-parking/admin-parking.service'
import { AuthService } from 'src/app/modules/auth/_services/auth.service'
import { UserService } from 'src/app/modules/collaborateurs/_services/user.service'
import { CovServiceService } from 'src/app/modules/covoiturage/my-covoiturage/service/cov-service.service'
import { SiteService } from 'src/app/modules/entreprise/_services/site.service'
import { imgEncoder } from 'src/app/utils/imgEncoder'
import { CovoiturageService } from '../service/covoiturage.service'
import { ModalInfoComponent } from './modal-info/modal-info.component'

@Component({
  selector: 'app-reservation-modal',
  templateUrl: './reservation-modal.component.html',
  styleUrls: ['./reservation-modal.component.scss'],
})
export class ReservationModalComponent implements OnInit {
  //date from flexi
  @Input() resvDate: any
  @Input() add: any
  @Input() resvTimeStart: any
  @Input() resvTimeEnd: any
  formGroup: UntypedFormGroup
  isLoadingdata = false
  isLoadingdatapropose = false
  constructor(
    public covService: CovServiceService,

    public modal: NgbActiveModal,

    private modalService: NgbModal,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private siteService: SiteService,
    public userService: UserService,
    public ah: AuthService,
    private fb: UntypedFormBuilder,
    private http: HttpClient,
    @Optional() public cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    public covoiturageService: CovoiturageService,
    public parkingService: AdminParkingService
  ) {
    this.http = http
    this.cdr = cdr
  }
  collab
  collabRes
  mobilite
  //date given by user
  dateNow: any

  disablePopup = false

  disablePopupChange(disablePopup) {
    this.disablePopup = !disablePopup
    localStorage.setItem('FLEXI-COV-PARK-disablePopup', this.disablePopup.toString())
  }

  onChange(event) {
    this.covoiturageService.getMobiliteByIdDate(this.dateNow).subscribe(res => {
    })
    this.resvDate = event
    if (this.resvDate) {
      this.covoiturageService.getCollabProche(this.resvDate).subscribe(res => {
        this.collab = res

      })
    } else {
      this.covoiturageService.getCollabProche(this.dateNow).subscribe(res => {
        this.collab = res
        if (this.collab.length == 0) {
          this.toastr.error('Aucun covoiturage proposé dans ce date')
        }
      })
    }
    this.covoiturageService.getCollabProcheToPropose(this.resvDate).subscribe(res => {
      this.collabRes = res
      if (this.collabRes.length == 0) {
        this.toastr.warning('Aucun covoiturage proposé dans ce date')
      }
    })
  }
  dateM: any
  ngOnInit(): void {
    this.disablePopup = localStorage.getItem('FLEXI-COV-PARK-disablePopup') == 'true'
    // Obtenir la date actuelle
    var currentDate = new Date()

    // Ajouter un jour à la date actuelle
    currentDate.setDate(currentDate.getDate() + 1)
    this.dateM = this.datePipe.transform(currentDate, 'yyyy-MM-dd')
    this.dateNow = this.datePipe.transform(currentDate, 'yyyy-MM-dd')

    this.formGroup = this.fb.group({
      nbrPlace: [null, Validators.compose([Validators.required])],
    })
    if (this.resvDate) {
      this.covoiturageService.getCollabProche(this.resvDate).subscribe(res => {
        this.collab = res
        this.isLoadingdata = true
        this.cdr.detectChanges()
      })
    } else {
      this.covoiturageService.getCollabProche(this.dateNow).subscribe(res => {
        this.collab = res
        this.isLoadingdata = true
        this.cdr.detectChanges()
      })
    }

    if (this.resvDate) {
      this.covoiturageService.getCollabProcheToPropose(this.resvDate).subscribe(res => {
        this.collabRes = res
        this.isLoadingdatapropose = true
        this.cdr.detectChanges()
      })
    } else {
      this.covoiturageService.getCollabProcheToPropose(this.dateNow).subscribe(res => {
        this.collabRes = res
        this.isLoadingdatapropose = true
        this.cdr.detectChanges()
      })
    }

    this.loadForm()
  }
  encoder(s: any) {
    return imgEncoder(s)
  }
  reserver(id, nom_responsable, prenom_responsable, adresse_user) {
    let body = {
      idCoviturage: id,
      status: 0,
      date: this.resvDate,
    }
    this.covoiturageService.reserver(body).subscribe(res => {
      if (res == 404) {
        this.toastr.error('vous avez déjà réservé.')
      } else if (res == 405) {
        this.toastr.error('ce covoiturage atteint le max de reservation')
      } else {
        this.covoiturageService.getMobilite().subscribe(res1 => {
          let body = {
            nom_responsable: nom_responsable,
            prenom_responsable: prenom_responsable,
            reservation_date: this.resvDate,
            //reservation_date: res1[0].date,
            email: adresse_user,
            nom: JSON.parse(localStorage.getItem('name')),
          }
          this.covoiturageService.reserverCovoiturage(body).subscribe(res => {
            this.toastr.success('Reservation enregistrée avec succès')
          })
          // (res[0].date,res[0].nom,res[0].prenom,email,nom)
        })
      }
    })
  }
  loadForm() {
    this.covoiturageService.getMobilite().subscribe(res => {
      this.mobilite = res[0]
      if (this.mobilite) {
        this.formGroup = this.fb.group({
          nbrPlace: [2, Validators.compose([Validators.required])],
        })
      } else {
        this.formGroup = this.fb.group({
          nbrPlace: [2, Validators.compose([Validators.required])],
        })
      }
    })
  }
  propose(email, nom, prenom) {
    /*email: this.email,
        nom: this.nom,
        nom_responsable: this.nom_responsable,
        prenom_responsable: this.prenom_responsable,
        reservation_date: this.reservation_date,*/

    this.covoiturageService.getMobilite().subscribe(res => {
      let body = {
        reservation_date: this.datePipe.transform(this.resvDate, 'yyyy-MM-dd'),
        email: email,
        nom: nom,
        prenom: prenom,
      }
      this.covoiturageService.proposerCovoiturage(body).subscribe(res => {
        this.openModalInfo()
      })
    })
    
  }
  saveMobilite() {
    this.covoiturageService.getMobiliteByIdDate(this.resvDate).subscribe(res => {})
    // this.covoiturageService.getMobilite().subscribe(res => {
    if (this.resvDate) {
      this.covoiturageService.getMobiliteByIdDate(this.resvDate).subscribe(res => {
        this.mobilite = res[0]
        if (this.mobilite) {
          if (this.resvDate) {
            this.covoiturageService.updateMobilite(this.resvDate, this.formGroup.value.nbrPlace).subscribe(res => {
              this.toastr.success('Mobilite enregistrée avec succès')
              this.modal.close()
            })
          } else {
            this.covoiturageService.updateMobilite(this.dateNow, this.formGroup.value.nbrPlace).subscribe(res => {
              this.toastr.success('Mobilite enregistrée avec succès')
              this.modal.close()
            })
          }
        } else {
          if (this.resvDate) {
            this.covoiturageService.saveMobilite(this.resvDate, this.formGroup.value.nbrPlace).subscribe(res => {
              let body = {
                idCoviturage: res[0].covoiturage_uuid,
                status: 1,
                date: this.dateM,
              }
              this.covoiturageService.reserver(body).subscribe(data => {
                this.toastr.success('Mobilite enregistrée avec succès')
                this.modal.close()
              })
            })
          } else {
            this.covoiturageService.saveMobilite(this.dateNow, this.formGroup.value.nbrPlace).subscribe(res => {
              let body = {
                idCoviturage: res[0].covoiturage_uuid,
                status: 1,
                date: this.dateM,
              }
              this.covoiturageService.reserver(body).subscribe(data => {
                this.toastr.success('Mobilite enregistrée avec succès')
                this.modal.close()
              })
            })
          }
        }
      })
    } else {
      this.covoiturageService.getMobiliteByIdDate(this.dateNow).subscribe(res => {
        this.mobilite = res[0]
        if (this.mobilite) {
          if (this.resvDate) {
            this.covoiturageService.updateMobilite(this.resvDate, this.formGroup.value.nbrPlace).subscribe(res => {
              this.toastr.success('Mobilite enregistrée avec succès')
              this.modal.close()
            })
          } else {
            this.covoiturageService.updateMobilite(this.dateNow, this.formGroup.value.nbrPlace).subscribe(res => {
              this.toastr.success('Mobilite enregistrée avec succès')
              this.modal.close()
            })
          }
        } else {
          if (this.resvDate) {
            this.covoiturageService.saveMobilite(this.resvDate, this.formGroup.value.nbrPlace).subscribe(res => {
              let body = {
                idCoviturage: res[0].covoiturage_uuid,
                status: 1,
                date: this.dateM,
              }
              this.covoiturageService.reserver(body).subscribe(data => {
                this.toastr.success('Mobilite enregistrée avec succès')
                this.modal.close()
              })
            })
          } else {
            this.covoiturageService.saveMobilite(this.dateNow, this.formGroup.value.nbrPlace).subscribe(res => {
              let body = {
                idCoviturage: res[0].covoiturage_uuid,
                status: 1,
                date: this.dateM,
              }
              this.covoiturageService.reserver(body).subscribe(data => {
                this.toastr.success('Mobilite enregistrée avec succès')
                this.modal.close()
              })
            })
          }
        }
      })
    }

    this.cdr.detectChanges()
  }
  saveMobiliteParking() {
    this.covoiturageService.getMobilite().subscribe(res => {
      this.mobilite = res[0]
      if (this.mobilite) {
        if (this.resvDate) {
          this.covoiturageService.updateMobilite(this.resvDate, this.formGroup.value.nbrPlace).subscribe(res => {
            this.toastr.success('Mobilite enregistrée avec succès')
            // this.openModalReserve()
            this.openModalReserve()
          })
        } else {
          this.covoiturageService.updateMobilite(this.dateNow, this.formGroup.value.nbrPlace).subscribe(res => {
            this.toastr.success('Mobilite enregistrée avec succès')
            this.openModalReserve()
          })
        }
      } else {
        if (this.resvDate) {
          this.covoiturageService.saveMobilite(this.resvDate, this.formGroup.value.nbrPlace).subscribe(res => {
            let body = {
              idCoviturage: res[0].covoiturage_uuid,
              status: 1,
              date: this.dateM,
            }
            this.covoiturageService.reserver(body).subscribe(data => {
              this.toastr.success('Mobilite enregistrée avec succès')
              this.openModalReserve()
            })
          })
        } else {
          this.covoiturageService.saveMobilite(this.dateNow, this.formGroup.value.nbrPlace).subscribe(res => {
            let body = {
              idCoviturage: res[0].covoiturage_uuid,
              status: 1,
              date: this.dateM,
            }
            this.covoiturageService.reserver(body).subscribe(data => {
              this.toastr.success('Mobilite enregistrée avec succès')
              this.openModalReserve()
            })
          })
        }
      }
    })
  }
  openModalM() {
  }
  openModalReserve() {
    let reservation = {
      choice: 0,
      dateReservation: this.resvDate,
      timeReservation: this.resvDate + ' ' + this.resvTimeStart,

      timeReservationEnd: this.resvDate + ' ' + this.resvTimeEnd,
      auto: 1,
    }
    this.parkingService.reserveParking(reservation).subscribe(res => {
      if (res == 'no' || res == null) {
        this.toastr.warning("Il n'y a pas de places de parking disponibles selon votre choix.")
        // this.modal.dismiss()
      } else {
        this.toastr.success(this.translate.instant('EVENT.toast.toastDesc'))
      }
    })
  }
  openModalInfo() {
    const modalRef = this.modalService.open(ModalInfoComponent)

    modalRef.result.then(() => {
      //this.toastr.success('Mail envoyé avec succès')
    })
    // this.toastr.success('Mail envoyé avec succès')
  }
  close() {
    this.modal.close()
  }
}
