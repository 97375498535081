import { ChangeDetectorRef, Component, OnInit, Optional } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { AdminParkingService } from '../admin-parking.service'

@Component({
  selector: 'app-modal-emplacement',
  templateUrl: './modal-emplacement.component.html',
  styleUrls: ['./modal-emplacement.component.scss'],
})
export class ModalEmplacementComponent implements OnInit {
  formGroup: FormGroup
  type: any
  electricCar: number = 0
  handicapCar: number = 0
  covCar: number = 0
  nonReservable: number = 0
  listSites: any[] = []
  idSite: any

  constructor(
    public parkingService: AdminParkingService,
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    @Optional() public cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.cdr = cdr
  }

  ngOnInit(): void {
    this.parkingService.GetSitesList().subscribe(res => {
      this.listSites = res
      //this.listCibles[0] = 5
      //this.listCibleIds[0] = this.listSites[0].ids
    })
    this.formGroup = this.fb.group({
      code: [null, Validators.compose([Validators.required, Validators.minLength(0), Validators.maxLength(100)])],
      type: [null, Validators.compose([Validators.required])],
      site: [null, Validators.compose([Validators.required])],
    })
  }

  isControlValid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName]
    return control.valid && (control.dirty || control.touched)
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName]
    return control.invalid && (control.dirty || control.touched)
  }

  controlHasError(validation, controlName): boolean {
    const control = this.formGroup.controls[controlName]
    return control.hasError(validation) && (control.dirty || control.touched)
  }

  addParking() {
    let parking = {
      code: this.formGroup.controls['code'].value,
      type: this.type,
      handicap: this.handicapCar,
      electric: this.electricCar,
      cov: this.covCar,
      reservable: this.nonReservable,
      siteId: this.idSite,
    }

    let result = this.parkingService.addParking(parking).subscribe(res => {
      this.toastr.success(this.translate.instant('EVENT.toast.toastDesc')), this.modal.close()
    })
  }

  setElectricCar() {
    if (this.electricCar == 0) {
      this.electricCar = 1
    } else {
      this.electricCar = 0
    }
  }

  setHandicapCar() {
    if (this.handicapCar == 0) {
      this.handicapCar = 1
    } else {
      this.handicapCar = 0
    }
  }

  setCovCar() {
    if (this.covCar == 0) {
      this.covCar = 1
    } else {
      this.covCar = 0
    }
  }

  setNonReservable() {
    if (this.nonReservable == 0) {
      this.nonReservable = 1
    } else {
      this.nonReservable = 0
    }
  }

  closeModal() {
    this.modal.close()
  }
}
