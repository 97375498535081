export default {
  ANNONCE: {
    ADD: 'Ajouter une annonce',
    ATTACHMENTDDL: 'Télécharger la pièce jointe',
    ATTACH: 'Ajouter une image ou un PDF',
    NONE: "Pas d'annonce pour l'instant",
  },
  MEMBERS: {
    NB: 'N° des membres',
    DETAILS: {
      TITLE: 'Liste des membres',
      ADD_MEMBER: 'Ajouter un membre',
      ADD_MEMBERS: 'Ajouter des membres',
      ADD_THE_MEMBERS: 'Ajouter ces membres',
      ADDED_ON: "Date d'adhésion",
    },
    DELETE: {
      TITLE: "Supprimer l'utilisateur de la communauté",
      DESC_1: 'Utilisateur supprimé...',
      DESC_2: "Êtes-vous sûr de supprimer définitivement l'utilisateur de cette Communauté?",
    },
    ADD: {
      SEARCH: 'Recherchez par nom...',
    },
  },
  DETAILS: {
    DETAILS: 'Détails de la communauté',
    NB_MBRS: 'Nombre des membres',
    TITLE: 'Liste des communautés',
    NEW_COMMUNITY: 'Créer une communauté',
    REFERANT: 'Référant',
    EDIT: 'Modifier la communauté',
    DELETE: 'Supprimer la communauté',
  },
  EDIT: {
    Edit: 'Modifier la',
    Create: 'Créer une',
    comm_name: 'Nom de communauté',
  },
  DELETE: {
    TITLE: 'Supprimer la Communauté',
    DESC_1: 'Communauté supprimée...',
    DESC_2: 'Êtes-vous sûr de supprimer définitivement cette Communauté?',
  },
}
