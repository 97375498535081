export default {
  listParking: {
    title: 'Parking management',
    button1: 'Parking',
    button2: 'Reserve',
    filter: 'Filter by type',
    filtersite: 'Filter by site',
    search: 'Search by code',
    column1: 'Parking code',
    column2: 'Type',
    column3: 'Action',
    column4: 'Specificity',
  },

  modalParking: {
    title: 'Ajouter un emplacement',
    input1: 'Code',
    input2: 'Type',
    input3: 'Électrique',
    input4: 'handicapé',
    input5: 'covoiturage',
    input6: 'Non reservable',
    option1: 'Voiture',
    option2: 'Vélo',
    option3: 'Moto',
  },

  modalReserve: {
    title: 'Reserver un emplacement',
    input1: 'Nom et Prenom',
    input2: 'Date de reservation',
    input3: 'Heure de début',
    input4: 'heure de fin',
    input5: 'Type',
    input6: 'Électrique',
    input7: 'handicapé',
    input8: 'covoiturage',
    input9: 'Non reservable',
    button1: 'Réserver',
  },

  modalReservation: {
    title: 'Liste des réservations',
    column1: 'Créneaux',
    column2: 'Photo',
    column3: 'Nom et prénom',
    column4: 'Fonction',
    column5: 'Actions',
    codeParking: 'Parking code',
  },

  ModalAnnulation: {
    title: 'Cancel reservation',
    input1: 'reason for cancel',
    button1: 'confirm',
    button2: 'Reserver',
  },

  modif: {
    title: 'Edit parking',
    button: 'edit',
  },
}
