import { CommonModule } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import { Component, Input, OnInit } from '@angular/core'
import { MatIconModule, MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: 'svg-icon',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  template: `<style>
      .svg-icon {
        width: {{ size }}px;
        height: {{ size }}px;
        svg {
          path {
            fill: {{ color }};
          }
        }
      }
    </style>
    <mat-icon svgIcon="home" color="primary" size="50" width="50" height="50"></mat-icon>

    <div
      style="color: red; fill:red; stroke:red;"
      *ngIf="iconContent"
      [innerHTML]="iconContent"
      [style.fill]="color"
      class="svg-icon"
    ></div> `,
  styles: [],
  providers: [MatIconRegistry],
})
export class SvgIconComponent implements OnInit {
  @Input() path: string
  @Input() color: string
  @Input() size: number = 24
  public iconContent: string

  constructor(private http: HttpClient, private sanitizer: DomSanitizer, private matIconRegistry: MatIconRegistry) {
    this.matIconRegistry.addSvgIcon(
      'home',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/media/svg/icons/Navigation/Angle-double-left.svg')
    )
  }

  ngOnInit() {
    this.loadSvgIcon(this.path)
  }

  loadSvgIcon(url: string) {
    this.http.get(url, { responseType: 'text' }).subscribe(svg => {
      this.iconContent = this.sanitizer.bypassSecurityTrustHtml(svg) as string
    })
  }
}
