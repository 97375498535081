import { getUserUuidFromLocalStorage } from 'src/app/utils/getSessionFromLocalStorage'
//tslint:disable:variable-name
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs'
import { catchError, finalize } from 'rxjs/operators'
import { environment } from '../../../../../environments/environment'
import { GroupingState } from '../models/grouping.model'
import { PaginatorState } from '../models/paginator.model'
import { SortState } from '../models/sort.model'
import { ITableState, TableResponseModel } from '../models/table.model'

const DEFAULT_STATE: ITableState = {
  filter: {},
  paginator: new PaginatorState(),
  sorting: new SortState(),
  searchTerm: '',
  grouping: new GroupingState(),
  entityId: undefined,
}

export abstract class TableSurveys<T> {
  // Private fields
  private _items$ = new BehaviorSubject<T[]>([])
  private _isLoading$ = new BehaviorSubject<boolean>(false)
  private _isFirstLoading$ = new BehaviorSubject<boolean>(true)
  private _tableState$ = new BehaviorSubject<ITableState>(DEFAULT_STATE)
  private _errorMessage = new BehaviorSubject<string>('')
  private _subscriptions: Subscription[] = []

  // Getters
  get items$() {
    return this._items$.asObservable()
  }
  get isLoading$() {
    return this._isLoading$.asObservable()
  }
  get isFirstLoading$() {
    return this._isFirstLoading$.asObservable()
  }
  get errorMessage$() {
    return this._errorMessage.asObservable()
  }
  get subscriptions() {
    return this._subscriptions
  }
  // State getters
  get paginator() {
    return this._tableState$.value.paginator
  }
  get filter() {
    return this._tableState$.value.filter
  }
  get sorting() {
    return this._tableState$.value.sorting
  }
  get searchTerm() {
    return this._tableState$.value.searchTerm
  }
  get grouping() {
    return this._tableState$.value.grouping
  }

  protected authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`
  protected http: HttpClient

  API_URL = `${environment.apiUrlQSP}/survey`

  constructor(http: HttpClient) {
    this.http = http
  }

  formtype

  public setFormtype(formtype) {
    this.formtype = formtype
  }

  create(item: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    this._isLoading$.next(true)
    this._errorMessage.next('')
    return this.http.post<any>(`${this.API_URL}/create`, item, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        return of(err)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  find(tableState: ITableState): Observable<TableResponseModel<T>> {
    const url = this.API_URL + '/list'
    this._errorMessage.next('')
    return this.http.post<TableResponseModel<T>>(url, tableState).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        return of({ items: [], total: 0 })
      })
    )
  }

  getItemById(id: any): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    const url = `${this.API_URL}/${id}`
    return this.http.get<any>(url, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        return of({ id: undefined })
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  update(survey: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid)

    const url = `${this.API_URL}/update`

    this._isLoading$.next(true)
    this._errorMessage.next('')
    return this.http.put(url, survey, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        return of(survey)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  delete(id: any): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()
    const url = `${this.API_URL}/${id}?${sessionid}`

    return this.http.delete(url).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of({})
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  public fetch() {
    return new Promise((resolve, reject) => {
      let sessionid = this.getAuthFromLocalStorage()
      const params = new HttpParams()
        .set('userId', getUserUuidFromLocalStorage())
        .set('sessionid', sessionid)
        .set('formtype', this.formtype)

      const httpHeaders = new HttpHeaders({
        token: localStorage.getItem('QSP_AUTH_TOKEN'),
      })

      this.http
        .get<any>(environment.apiUrlQSP + '/survey/list', { params, headers: httpHeaders })
        .subscribe(surveys => {
          const surveysMaps = new Map()
          surveys.forEach(survey => {
            surveysMaps.set(survey.id, survey)
          })
          this.http.get<any>(environment.apiUrl + '/survey/targets', { params }).subscribe(surveyTargets => {
            surveyTargets.forEach(surveyTarget => {
              surveyTarget.survey = surveysMaps.get(surveyTarget.survey_id)
            })
            this._items$.next(surveyTargets)
            resolve(surveyTargets)
          })
        })
    })
  }

  public setDefaults() {
    this.patchStateWithoutFetch({ filter: {} })
    this.patchStateWithoutFetch({ sorting: new SortState() })
    this.patchStateWithoutFetch({ grouping: new GroupingState() })
    this.patchStateWithoutFetch({ searchTerm: '' })
    this.patchStateWithoutFetch({
      paginator: new PaginatorState(),
    })
    this._isFirstLoading$.next(true)
    this._isLoading$.next(true)
    this._tableState$.next(DEFAULT_STATE)
    this._errorMessage.next('')
  }

  // Base Methods
  public patchState(patch: Partial<ITableState>) {
    this.patchStateWithoutFetch(patch)
    this.fetch()
  }

  public patchStateStrategy(patch: Partial<ITableState>) {
    this.patchStateWithoutFetch(patch)
  }

  public patchStateWithoutFetch(patch: Partial<ITableState>) {
    const newState = Object.assign(this._tableState$.value, patch)
    this._tableState$.next(newState)
  }

  protected setAuthFromLocalStorage(auth: any): boolean {
    if (1 === 1) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth))
      return true
    }
    return false
  }

  protected getAuthFromLocalStorage(): any {
    try {
      const authData = JSON.parse(localStorage.getItem(this.authLocalStorageToken))
      return authData
    } catch (error) {
      window.location.reload()
      return undefined
    }
  }

  protected getUserUuidFromLocalStorage(): any {
    try {
      return localStorage.getItem('uuid').slice(1, -1)
    } catch (error) {
      return undefined
    }
  }
}
