<!--détecte quand l'écran est redimensionné pour changer le texte du bouton en haut de la 
page-->

<div (window:resize)="detectMob($event)">

  <div class="card-header border-0 pt-5">
    <div class="table-responsive angular-bootstrap-table">
      <!-- begin::Table -->
    
      <table class="table align-middle border-0">
        <!-- begin::Table head -->
        <thead>
          <tr>
            <th class="min-w-150px">
              <a class="btn btn-light btn-elevate mr-2"  routerLink="/Quiz"
              routerLinkActive="active" [title]="(changeStyle ? 'gameOne.ListJeux' : '') | translate"
              ><img style="margin-right: 3px;" height="20px" width="15px" src="./../../../../assets/icons/arrow.png" alt="return button"> 
              {{(changeStyle ? "gameOne.ListJeuxM" : "gameOne.ListJeux") | translate }}
            </a> 
            </th>
          
            <th class="min-w-321px">         
              <div class="card-toolbar" style="float:right">
              <!--ajouter une description au bouton si la taille du bouton est petite et qu'il est 
                affiché sur mobile-->
              <button (click)="reset()"
              class="btn btn-primary" style="margin-right:3px;" [title]="(changeStyle ? 'gameOne.rein' : '') | translate">
              {{(changeStyle ? "gameOne.reinM" : "gameOne.rein") | translate }}</button>
              <a class="btn btn-primary" [title]="(changeStyle ? 'gameOne.gereAssoc' : '') | translate" (click)="create()"
                >{{(changeStyle ? "gameOne.gereAssocM" : "gameOne.gereAssoc") | translate }}</a>
            </div>
        </th>
          </tr>
        </thead>
    </table>
    </div>
    </div>
  <br>
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  <!--desciption complete de jeux + titre + image de jeux-->
  <div class="card-header border-0 pt-5">
  <div class="table-responsive angular-bootstrap-table">
    <!-- begin::Table -->
  
    <table class="table align-middle gs-0 gy-3 border-0">
      <!-- begin::Table head -->
      <thead>
        <tr>
          <td class="p-0 w-120px"></td>
          <td class="p-0 min-w-650px"></td>
        </tr>
      </thead>
  <tbody>
  <tr class="border-0">
    <td>
  <img id="gameIcon" src="./assets/media/stock-600x400/img-17.jpg" alt="game icon" width="110px" height="110px">
    </td>
    <td>
    <h5 >{{"gameOne.desc" | translate }}</h5>  
        <p>{{data2?.full_description}}</p>
    </td>
  </tr>
  </tbody> 
  </table>
  </div>
  </div>
  <br>
  
  
  
  
  
  
  
  
  
  <!--statistique-->
    <div class="table-responsive angular-bootstrap-table">
      <!-- begin::Table -->
    
      <table class="table border-0">
        <!-- begin::Table head -->
        <thead>
          <tr><div class="card-header border-0 pt-5">
            <td class="p-0 min-w-175px">
              <img style="margin-bottom:10px;" width="45px" height="42px" src="./../../../../assets/icons/star.png" alt="">   
              <br><span class="stat">{{score}}</span>
              <span class="text-muted font-weight-bold text-muted d-block">{{"gameOne.points" | translate }}</span>
            </td>
          </div>
  
            <td class="min-w-50px"></td>
  
            <div class="card-header border-0 pt-5"><td class="p-0 min-w-175px">
              <img style="margin-bottom:10px;" width="48px" height="48px" src="./../../../../assets/icons/count.png" alt="">    
             <br> <span class="stat">{{count}}</span>
              <span class="text-muted font-weight-bold text-muted d-block">{{"gameOne.numFois" | translate }}</span>
            </td>
          </div>
  
            <td class="min-w-50px"></td>
  
            <div class="card-header border-0 pt-5"><td class="p-0 min-w-175px">
              <img style="margin-bottom:10px;" width="60px" height="48px" src="./../../../../assets/icons/clock.png" alt="">  
             <br> <span class="stat">{{duree}}sec</span>
              <span class="text-muted font-weight-bold text-muted d-block">{{"gameOne.temp" | translate }}</span>
            </td>
          </div>
          </tr>
        </thead>
    </table>
    </div>
    
  <br>
  
  
  
  
  
  
  
  
  
  
  
  
  
  <!--tableau : partie collaborateur -->
  <div class="card-header border-0 pt-5 table-responsive angular-bootstrap-table tabset"> 
  
    <!-- end::Header -->
    <!-- begin::Body -->
    <div class="card-body py-3">
      <!-- begin::Table container -->
      <div class="table-responsive">
        <!-- begin::Table -->
        <table class="table align-middle gs-0 gy-4">
          <!-- begin::Table head -->
          <thead>
            <tr class="fw-bolder text-muted bg-light">
              <th>{{"gameOne.collab" | translate }}</th>
              <th>{{"gameOne.numDePart" | translate }}</th>
              <th>{{"gameOne.date" | translate }}</th>         
            </tr>
          </thead>
          <!-- end::Table head -->
          <!-- begin::Table body -->
          <tbody>
            <tr *ngFor="let row of topCollab">
              <td>
                <div class="d-flex align-items-center">
                  <div class="symbol symbol-50px me-5" style="margin-right:5px;">
                    <span class="symbol-label bg-light">
                      <img
                        src="./assets/media/svg/avatars/001-boy.svg"
                        class="h-75 align-self-end" 
                        alt=""
                      />
                    </span>
                  </div>
                  <div class="d-flex justify-content-start flex-column">
                    <a
                      href="#"
                      class="text-dark fw-bolder mb-1 fs-6"
                    > 
                      {{row.fullname}}
                    </a>
                  </div>
                </div>
              </td>
              <td>
                <span
                  class="text-dark fw-bolder d-block mb-1 fs-6"
                >
                  {{row.count}}
              </span>
              </td>
              <td>
                <span
                  class="text-dark fw-bolder d-block mb-1 fs-6"
                >
                  {{row.lastdate}} 
              </span>
              </td>    
            </tr>
          </tbody>
          <!-- end::Table body -->
       
  
  
  
  
  
  
  
  
          <br>
          <br>
  
  
  
  <!--tableau : partie service -->
          <!-- begin::Table head -->
          <thead>
            <tr class="fw-bolder text-muted bg-light">
              <th>{{"gameOne.serviceActif" | translate }}</th>
              <th>{{"gameOne.taux" | translate }}</th>
              <th>{{"gameOne.derparticipation" | translate }}</th>
            </tr>
          </thead>
          <!-- end::Table head -->
          <!-- begin::Table body -->
          <tbody>
            <tr *ngFor="let row of topService">
              <td>
                <div class="d-flex align-items-center">
                  <div class="symbol symbol-50px me-5" style="margin-right:5px;">
                    <span class="symbol-label bg-light">
                      <img src="./assets/media/svg/avatars/001-boy.svg"
                        class="h-75 align-self-end"/>
                    </span>
                  </div>
                  <div class="d-flex justify-content-start flex-column">
                    <span class="text-dark fw-bolder mb-1 fs-6">
                      {{row.nom_service}}
                    </span>
                  </div>
                </div>
              </td>
              <td>  
                  <span class="text-dark fw-bolder mb-1 fs-6">{{row.usePercent}}%</span>   
              </td>
              <td>
                <span
                  class="text-dark fw-bolder mb-1 fs-6"
                >
                {{row.last_date}}
              </span>
              </td>
            </tr>
          </tbody>
          <!-- end::Table body -->
        </table>
        <!-- end::Table -->
      </div>
      <!-- end::Table container -->
    </div>
    <!-- begin::Body -->
  </div>
  
  
  </div>
  
  <br>
  <br>