import { DatePipe } from '@angular/common'
import { ChangeDetectorRef, Component, OnInit, Optional } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NgbActiveModal, NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { IDropdownSettings } from 'ng-multiselect-dropdown'
import { ToastrService } from 'ngx-toastr'
import { AdminParkingService } from '../admin-parking.service'

@Component({
  selector: 'app-modal-reservation',
  templateUrl: './modal-reservation.component.html',
  styleUrls: ['./modal-reservation.component.scss'],
})
export class ModalReservationComponent implements OnInit {
  formGroup: FormGroup
  choice: any
  options = ['Externe', 'Collaborateur']
  dateMin: any
  type: any
  electricCar: number = 0
  handicapCar: number = 0
  covCar: number = 0
  //dropdownList: any = []
  parkingFor: any
  startTime = { hour: 8, minute: 0 }
  endTime = { hour: 19, minute: 0 }
  listSites: any[] = []
  siteId: any = 0

  constructor(
    public parkingService: AdminParkingService,
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    @Optional() public cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private translate: TranslateService,
    public datePipe: DatePipe,
    private timepickerConfig: NgbTimepickerConfig
  ) {
    this.formGroup = this.fb.group({
      reservedFor: [null, Validators.compose([Validators.required])],
      dateReservation: [null, Validators.compose([Validators.required])],
      type: [null, Validators.compose([Validators.required])],
      parkingFor: [null, Validators.compose([Validators.required])],
      guest: [null, Validators.compose([Validators.required, Validators.minLength(0), Validators.maxLength(100)])],
      time: [null, Validators.compose([Validators.required])],
      endTime: [null, Validators.compose([Validators.required])],
      site: [null, Validators.compose([Validators.required])],
    })

    this.timepickerConfig.minuteStep = 15
  }
  ngOnInit(): void {
    this.dateMin = this.datePipe.transform(new Date(), 'yyyy-MM-dd')
    this.getAllUser()
    this.parkingService.GetSitesList().subscribe(res => {
      this.listSites = res
      //this.listCibles[0] = 5
      //this.listCibleIds[0] = this.listSites[0].ids
    })
  }

  closeModal() {
    this.modal.close()
  }

  isControlValid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName]
    return control.valid && (control.dirty || control.touched)
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName]
    return control.invalid && (control.dirty || control.touched)
  }

  controlHasError(validation, controlName): boolean {
    const control = this.formGroup.controls[controlName]
    return control.hasError(validation) && (control.dirty || control.touched)
  }

  setElectricCar() {
    if (this.electricCar == 0) {
      this.electricCar = 1
    } else {
      this.electricCar = 0
    }
  }

  setHandicapCar() {
    if (this.handicapCar == 0) {
      this.handicapCar = 1
    } else {
      this.handicapCar = 0
    }
  }

  setCovCar() {
    if (this.covCar == 0) {
      this.covCar = 1
    } else {
      this.covCar = 0
    }
  }

  

  reserveParking() {
    const combinedDateTimeStart = this.formGroup.controls['dateReservation'].value

   

    const combinedDateTimeStrStart =
      combinedDateTimeStart +
      ' ' +
      this.startTime.hour.toString().padStart(2, '0') +
      ':' +
      this.startTime.minute.toString().padStart(2, '0')

    const combinedDateTimeStrEnd =
      combinedDateTimeStart +
      ' ' +
      this.endTime.hour.toString().padStart(2, '0') +
      ':' +
      this.endTime.minute.toString().padStart(2, '0')

    let flag = 0
    if (this.choice == 'Externe') {
      flag = 1
      const newItem = {
        fullname: null,
        id: null,
      }
      this.selectedItems.push(newItem)
    }

    let reservation = {
      choice: flag,
      dateReservation: this.formGroup.controls['dateReservation'].value,
      timeReservation: combinedDateTimeStrStart,
      type: this.type,
      handicap: this.handicapCar,
      electric: this.electricCar,
      cov: this.covCar,
      uuid: this.selectedItems[0].id,
      nomPrenom: this.formGroup.controls['guest'].value,
      timeReservationEnd: combinedDateTimeStrEnd,
      siteId: this.siteId,
    }

    this.parkingService.reserveParking(reservation).subscribe(res => {
      if (res == 'no' || res == null) {
        this.toastr.warning("Il n'y a pas de places de parking disponibles selon votre choix.")
      } else {
        this.toastr.success(this.translate.instant('EVENT.toast.toastDesc')),this.modal.close()
      }
    })

  }

  getAllUser() {
    this.parkingService.getAllUsers().subscribe(res => {
      this.dropdownList = res

      this.dropdownSettings = {
        singleSelection: true,
        idField: 'id',
        textField: 'fullname',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',

        enableCheckAll: false,
        allowSearchFilter: true,
        closeDropDownOnSelection: this.closeDropdownSelection,
      }
    })
  }

  listUsers = []
  listUsersDeselect = []
  selectedUsers = []
  selectedItems = []
  listUser1 = []
  listUser1Deselect = []
  listSelected1 = []
  dropdownList: any = []
  dropdownSettings: IDropdownSettings
  closeDropdownSelection = false
  onItemSelect(item: any) {
    this.listUser1.push(item)
  }

  onItemDeSelect(items: any) {
    this.listUser1.forEach((element, index) => {
      if (element == items.uuid) this.listUser1.splice(index, 1)
      this.listUser1Deselect.push(items.uuid)
    })
    //this.presentation.hobiesdeselect = items.id_element
    this.listUser1Deselect.push(items.uuid)
    this.cdr.detectChanges()
  }

  remove(items) {
    const index = this.selectedItems.indexOf(items)

    if (index >= 0) {
      this.selectedItems.splice(index, 1)
      this.listSelected1 = this.selectedItems

      this.cdr.detectChanges()
    }

    //this.cdr.detectChanges()

    //this.listHobies.pus

    this.selectedItems = []

    for (let i = 0; i < this.listSelected1.length; i++) {
      this.selectedItems.push(this.listSelected1[i])
    }

    this.cdr.detectChanges()
    // this.listHobies = [];
    this.listUser1.forEach((element, index) => {
      if (element == items.uuid) this.listUser1.splice(index, 1)
      this.listUser1Deselect.push(items.uuid)
    })
    //this.presentation.hobiesdeselect = items.id_element
    this.listUser1Deselect.push(items.uuid)

    this.cdr.detectChanges()
  }
}
