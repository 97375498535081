import PLANNING_WORK_MODE from './planning-work-mode/en'

export default {
  hi: 'HI',
  USER_PROFIL: 'User Profile',
  SIGNOUT: 'Sign Out',
  MYPROFIL: 'My Profile',
  INFO: 'Account settings and more',
  UPDATE: 'update',
  Subscription: 'Subscription',
  DESC: 'description',
  PLANNING_WORK_MODE,
}
