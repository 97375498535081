import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core'
import { UntypedFormBuilder } from '@angular/forms'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { AdminParkingService } from '../admin-parking.service'
import { ModalCalendarComponent } from '../modal-calendar/modal-calendar.component'
import { ModalEmplacementComponent } from '../modal-emplacement/modal-emplacement.component'
import { UserReservationComponent } from './user-reservation/user-reservation.component'

@Component({
  selector: 'app-user-parking',
  templateUrl: './user-parking.component.html',
  styleUrls: ['./user-parking.component.scss'],
})
export class UserParkingComponent implements OnInit {
  @Input() parkId: any
  @Input() date: any
  listReservations: any = []
  @Input() refresh

  constructor(
    public adminParkingService: AdminParkingService,
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private translate: TranslateService,
    @Optional() public cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getAllReservations()
   
  }

  openModalCreate() {
    const modalRef = this.modalService.open(ModalEmplacementComponent)

    modalRef.result.then(
      () => this.adminParkingService.fetch(),
      () => {}
    )
  }

  openModalReserve() {
    const modalRef = this.modalService.open(ModalCalendarComponent, {
      size: 'xl',
    })

    modalRef.result
      .then
      //() => this.eventService.fetch(),
      //() => {}
      ()
  }

  getAllReservations() {
    this.adminParkingService.getAllMyReservationsForParking().subscribe(res => {
      ;(this.listReservations = res), this.cdr.detectChanges()
    })
  }

  openReservationModal() {
    const modalRef = this.modalService.open(UserReservationComponent)

    modalRef.result.then(
      () => this.getAllReservations(),
      () => {}
    )
  }

  deleteReservation(reservationId) {
    this.adminParkingService.deleteReservation(reservationId, '').subscribe(res => {
      this.getAllReservations(), this.toastr.success(this.translate.instant('EVENT.toast.toastDesc'))
    })
  }
}
