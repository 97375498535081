export default {
  'Gérer les congés': 'Manage',
  'Historique des congés': 'History',
  Groupes: 'Groups',
  Demandes: 'Requests',
  'Mes congés': 'My leave requests',
  Congés: 'Leave requests',
  'Congés de mon équipe': "My teams's leave requests",
  Ajustements: 'Adjustments',
  'Gestion des congés': 'Time Off Management',
  GROUPS: {
    'Nom du groupe': 'Group Name',
    Description: 'Description',
    Site: 'Site',
    Actions: 'Actions',
    'Nouveau groupe de congé': 'New Leave Group',
    'Rechercher un groupe': 'Search Group',
    'Ajouter des types de congé': 'Add Leave Types',
    'Modifier le groupe de congés': 'Edit Leave Group',
    'Type de congé': 'Leave Type:',
    Solde: 'Balance:',
    'jours par': 'days per',
    mois: 'month',
    année: 'year',
    'Date du cycle': 'Cycle Resets on:',
    'Ajouter un type de congé': 'Add Leave Type',
    'Supprimer le groupe de congés': 'Delete Leave Group',
    'Êtes-vous sûr de vouloir supprimer ce groupe de congés?': 'Are you sure you want to delete this leave group?',
    'Groupe de congés supprimé avec succès': 'Leave group deleted successfully',
    'Erreur lors de la suppression du groupe de congés': 'Error deleting leave group',
    LEAVE_GROUPS_TABLE_EMPTY: 'Create your first leave group by clicking the button above. 😊',
    VALIDATION: {
      GROUP_NAME: 'Please enter a group name',
      SITE_NAME: 'Please select a site',
      LEAVE_TYPE_MISSING: 'Please add at least one leave type',
      LEAVE_TYPE_INVALID: 'Please verify the fields for the leave types',
    },
    TOASTR: {
      GROUP_CREATED: 'Leave group created successfully',
      ERROR_CREATING_GROUP: 'Error creating leave group',
      GROUP_UPDATED: 'Leave group updated successfully',
      ERROR_UPDATING_GROUP: 'Error updating leave group',
    },
    'cumulés mensuellement': 'accumulated monthly',
    'acquis en début de cycle': 'acquired at the beginning of the cycle',
    'en fin du mois': 'at the end of the month',
    'en fin de cycle': 'at the end of the cycle',
    'Acquisition:': 'Acquisition:',
    'de chaque année': '1st, every year',
    'Le 1er': '',
  },
  'Aucune demande de congé trouvée ': 'There are no leave requests yet. Click the button above to create one easily.',
  'Aucune demande de congé trouvée (admin)': 'No leave requests? Click the button above to declare one easily.',
  'Aucune demande de congé trouvée (user)': 'This employee has no leave requests at the moment.',
  'Aucune demande de congé trouvée (team)': 'No leave requests for this team at the moment.',
  'Déclarer un congé': 'Declare a leave',
  'Demander un congé': 'Request a leave',
  'Demande effectuée': 'Request made',
  "jours à l'avance": 'days in advance',
  'jours en retard': 'days late',
  'àprès la date de début': 'after the start date',
  'le jour même': 'on the same day',
  'Demande effectuée le:': 'Request made on:',
  'Voir la pièce jointe': 'View Attachment',
  'Détails de la demande de congé': 'Leave Request Details',
  'Approbation de la demande de congé': 'Leave Request Approval',
  days: 'days',
  'Justificatifs:': 'Supporting comment or document:',
  'Ajouter un commentaire': 'Add a comment',
  "Commentaire d'approbation:": 'Approval comment',
  Commentaire: 'Comment',
  'En cours...': 'Loading...',
  Approuver: 'Approve',
  Refuser: 'Decline',
  Collaborateur: 'Employee Name',
  'Type de congé': 'Leave Type',
  'Date de début': 'Start Date',
  'Date de fin': 'End Date',
  'Date de demande': 'Request Date',
  'Total demandé': 'Total Requested',
  Statut: 'Status',
  Tous: 'All',
  'En attente': 'Pending',
  Approuvée: 'Approved',
  Refusée: 'Declined',
  Annulée: 'Cancelled',
  Actions: 'Actions',
  'Appliqué pour': 'Applies to',
  Date: 'Date',
  "Date d'ajustement": 'Adjustment Date',
  Valeur: 'Value',
  Comptabilisé: 'Accounted',
  'Créer un ajustement': 'Create Adjustment',
  "Modifier l'ajustement de ": 'Edit Adjustment for ',
  "Voir l'ajustement de ": 'Adjustment for ',
  "Pas d'ajustements": 'No adjustments yet? Click the button above to create one. 😊',
  "Date d'ajout": 'Date Added',
  'Voir le solde de congés': 'View Leave Balance',
  'Compteurs de solde de congés': 'Leave Balance Counters',
  ADJUSTMENTS: {
    DELETE_CONFIRMATION_TITLE: 'Delete Adjustment',
    DELETE_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this adjustment?',
    ADJUSTMENT_DELETED_SUCCESSFULLY: 'Adjustment deleted successfully',
  },
  'En aquisition': 'Currently acquiring',
}
