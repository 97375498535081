<div style="margin: 20px;">
  <h3>
  {{"wsregister.wsr" | translate}}
  </h3>
  <h6>
  {{"wsregister.wsolution" | translate}}
  </h6>
  </div>
  <div class="modal-footer">
      <div class="card-toolbar">
      <a class="btn btn-light btn-elevate" (click)="close()">{{"wsregister.annuler" | translate }}</a>
      </div>
       <div class="card-toolbar" style="float:right">
      <button class="btn btn-primary" type="submit" (click)="logout()">{{"wsregister.déconnecter" | translate }}</button>
  </div>   
  </div>