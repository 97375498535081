<script src="https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.9.2/html2pdf.bundle.min.js"></script>
<div class="modal-ouvert">
  <div class="modal-body">
    <div class="NgxEditor__Wrapper">
      <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
      <ngx-editor [editor]="editor" [(ngModel)]="htmlContent" [disabled]="false" [placeholder]="'Type here...'"></ngx-editor>
    </div>
  </div>

  <div class="modal-footer">
    <div>
      <button type="button" class="btn btn-light btn-elevate mr-2" (click)="modal.dismiss()">Fermer</button>
      <button type="button" class="btn btn-primary btn-elevate" (click)="save()">Enregistrer</button>

      <button type="button" class="btn btn-primary btn-elevate ml-2" (click)="download()">
        <span class="fa fa-download mr-1"></span>Telecharger
      </button>

      <button [disabled]="isRegen" type="button" class="btn btn-primary btn-elevate ml-2" (click)="regenerate()">
        <span *ngIf="isRegen" class="fa fa-spinner fa-spin mr-1"></span>
        <span *ngIf="!isRegen" class="fa fa-refresh mr-1"></span>

        Regenerer
      </button>
    </div>
  </div>
</div>
