import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { forkJoin, map } from 'rxjs'
import { getSessionFromLocalStorage } from 'src/app/utils/getSessionFromLocalStorage'
import { environment } from 'src/environments/environment'
import { Building } from '../utils/types'

export type SiteT = {
  value: number
  viewValue: string
}
export type ServiceT = SiteT
export type EquipeT = SiteT

export type OSP_GuestT = {
  fname: string
  lname: string
}

@Injectable({
  providedIn: 'root',
})
export class OsModelingService {
  constructor(private http: HttpClient) {}

  getEnterpriseDetails() {
    return this.http.get<{ equipes: EquipeT[]; services: ServiceT[]; sites: SiteT[] }>(
      environment.apiUrl + '/entreprise/sites/services/equipes',
      {
        params: new HttpParams().set('sessionid', getSessionFromLocalStorage()),
      }
    )
  }

  getBuildings() {
    return this.http.get<Building[]>(environment.apiUrl + '/osplanner/buildings', {
      params: new HttpParams().set('sessionid', getSessionFromLocalStorage()),
    })
  }

  saveBuilding(data: Building) {
    return this.http.post<any>(environment.apiUrl + '/osplanner/building', data, {
      params: new HttpParams().set('sessionid', getSessionFromLocalStorage()),
    })
  }

  deleteBuilding(id: string) {
    return this.http.delete<any>(environment.apiUrl + '/osplanner/building/' + id, {
      params: new HttpParams().set('sessionid', getSessionFromLocalStorage()),
    })
  }

  reserveSeat(seatId: string, floorId: string, buildingId: string, date: string, startTime: string, endTime: string) {
    console.info(date, typeof date)
    return this.http.post<any>(
      environment.apiUrl + '/osplanner/seat/reserve',
      { seatId, date, floorId, buildingId, startTime, endTime },
      {
        params: new HttpParams().set('sessionid', getSessionFromLocalStorage()),
      }
    )
  }

  cancelReservation(reservationId: string, guestId?: string) {
    return this.http.delete<any>(environment.apiUrl + '/osplanner/seat/unreserve', {
      params: new HttpParams()
        .set('sessionid', getSessionFromLocalStorage())
        .set('reservationId', reservationId)
        .set('guestId', guestId || ''),
    })
  }

  reserveSeatForGuest(
    seatId: string,
    floorId: string,
    buildingId: string,
    date: string,
    startTime: string,
    endTime: string,
    guest: OSP_GuestT
  ) {
    return this.http.post<any>(
      environment.apiUrl + '/osplanner/seat/reserve/guest',
      { seatId, date, floorId, buildingId, startTime, endTime, guest },
      {
        params: new HttpParams().set('sessionid', getSessionFromLocalStorage()),
      }
    )
  }

  getReservations(date: string, startTime: string, endTime: string) {
    return this.http.get<any>(environment.apiUrl + '/osplanner/seat/reservations', {
      params: new HttpParams()
        .set('sessionid', getSessionFromLocalStorage())
        .set('startTime', startTime)
        .set('endTime', endTime)
        .set('date', date),
    })
  }

  getTeamOccupancies() {
    return this.http.get<any>(environment.apiUrl + '/osplanner/occupancies', {
      params: new HttpParams().set('sessionid', getSessionFromLocalStorage()),
    })
  }

  getCurrentUser() {
    return this.http
      .get<any>(environment.apiUrl + '/todo/user', {
        params: new HttpParams().set('sessionid', getSessionFromLocalStorage()),
      })
      .pipe(map(data => data[0]))
  }

  getUserCarpooling() {
    return this.http.get<any>(environment.apiUrl + '/covoiturage/getForUser', {
      params: new HttpParams().set('sessionid', getSessionFromLocalStorage()),
    })
  }

  getUserParking() {
    return this.http.get<any>(environment.apiUrl + '/osplanner/parking/reservations', {
      params: new HttpParams().set('sessionid', getSessionFromLocalStorage()),
    })
  }

  fetchAllData() {
    return forkJoin([
      this.getEnterpriseDetails(),
      this.getBuildings(),
      this.getCurrentUser(),
      this.getTeamOccupancies(),
      this.getUserCarpooling(),
      this.getUserParking(),
    ])
  }

  autoFlexiNewJob(body) {
    return this.http.post<any>(environment.apiUrl + '/osplanner/autoflexi/job/create', body, {
      params: new HttpParams().set('sessionid', getSessionFromLocalStorage()),
    })
  }

  getAutoFlexiJobs() {
    return this.http.get<any>(environment.apiUrl + '/osplanner/autoflexi/jobs', {
      params: new HttpParams().set('sessionid', getSessionFromLocalStorage()),
    })
  }

  applyAutoFlexiJob(jobId: string) {
    return this.http.post<any>(
      environment.apiUrl + '/osplanner/autoflexi/job/apply',
      { jobId },
      {
        params: new HttpParams().set('sessionid', getSessionFromLocalStorage()),
      }
    )
  }
}
