<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">{{ "ADMINPARKING.modif.title" | translate }}</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="formGroup">
      <div>
        <label for="titre">Code</label>
        <input
          id="code"
          class="form-control"
          type="text"
          formControlName="code"
          [class.is-invalid]="isControlInvalid('code')"
          [class.is-valid]="isControlValid('code')"
        />

        <div class="invalid-feedback" *ngIf="controlHasError('required', 'code')">
          {{ "IDEA.add.input1" | translate }}{{ "CONTROLEDESAISIE.required" | translate }}
        </div>
        <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'code')">
          {{ "IDEA.add.input1" | translate }}{{ "EVENT.control.threeCharMin" | translate }}
        </div>
        <div class="invalid-feedback" *ngIf="controlHasError('maxlength', 'code')">
          {{ "IDEA.add.input1" | translate }}{{ "CONTROLEDESAISIE.max" | translate }}
        </div>
      </div>

      <div>
        <label>Type</label>
        <select class="form-control" style="width: 100%" id="mySelect" [(ngModel)]="type" formControlName="type">
          <option value="1">Voiture</option>
          <option value="2">Velo</option>
          <option value="3">Moto</option>
        </select>
      </div>

      <div class="row" style="margin-top: 10px">
        <div class="col-lg-4" *ngIf="type == 1">
          <input type="checkbox" id="vehicle1" name="vehicle1" value="1" [checked]="electricCar == 1" (click)="setElectricCar()" />
          <label for="vehicle1" style="margin-left: 5px"> electrique</label><br />
        </div>
        <div class="col-lg-4" *ngIf="type == 1">
          <input type="checkbox" id="vehicle2" name="vehicle2" value="1" (click)="setHandicapCar()" [checked]="handicapCar == 1" />
          <label for="vehicle2" style="margin-left: 5px"> handicape</label><br />
        </div>
        <div class="col-lg-4" *ngIf="type == 1">
          <input type="checkbox" id="vehicle3" name="vehicle3" value="1" (click)="setCovCar()" [checked]="covCar == 1" />
          <label for="vehicle3" style="margin-left: 5px"> covoiturage</label><br /><br />
        </div>
        <div class="col-lg-4">
          <input type="checkbox" id="vehicle3" name="vehicle3" value="1" (click)="setNonReservable()" [checked]="nonReservable == 1" />
          <label for="vehicle3" style="margin-left: 5px"> Non reservable</label><br /><br />
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-primary" (click)="closeModal()">{{ "IDEA.add.button1" | translate }}</button>
    <button class="btn btn-primary" (click)="updateParking()" [disabled]="formGroup.invalid">
      <span> {{ "ADMINPARKING.modif.button" | translate }}</span>
    </button>
  </div>
</div>
