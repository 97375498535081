export default {
  ANNONCE: {
    ADD: 'Add an announcement',
    ATTACHMENTDDL: 'Download Attachment',
    ATTACH: 'Attach an image or PDF file',
    NONE: 'There is no announcement at the time.',
  },
  MEMBERS: {
    NB: 'N° of members',
    DETAILS: {
      TITLE: 'List of members',
      ADD_MEMBER: 'Add member',
      ADD_MEMBERS: 'Add members',
      ADD_THE_MEMBERS: 'Add members',
      ADDED_ON: 'Added on',
    },
    DELETE: {
      TITLE: 'Remove user from community',
      DESC_1: 'User Removed...',
      DESC_2: 'Are you sure you want to permanently remove this user from the community?',
    },
    ADD: {
      SEARCH: 'Recherchez par nom...',
    },
  },
  DETAILS: {
    DETAILS: 'Community Details',
    NB_MBRS: 'Number of members',
    TITLE: 'List Communities',
    NEW_COMMUNITY: 'New Community',
    REFERANT: 'Manager',
    EDIT: 'Edit Community',
    DELETE: 'Delete Community',
  },
  EDIT: {
    Edit: 'Update',
    Create: 'New',
    comm_name: 'Community Name',
  },
  DELETE: {
    TITLE: 'Delete Community',
    DESC_1: 'Community deleted...',
    DESC_2: 'Are you sure you want to permanently delete this Community?',
  },
}
