export default {
  header: {
    title: 'Organisez votre prochain événement',
    button: 'Sauvgarder',
  },

  control: {
    tenCharMin: ' doit avoir au moins 10 caractères',
    threeCharMin: ' doit avoir au moins 3 caractères',
    thousandCharMin: ' doit avoir au maximum 1000 caractères',
    monthCheckMax: ' ne doit pas être supérieur à 31',
    monthCheckMin: ' ne doit pas être inférieur à 1',
    monthCheckLengthMin: ' doit avoir au moins 1 caractères',
    invDateCheck: "Date de démarrage ne doit pas être après date d'envoi des invitations",
    maxMinInvCheck: 'Le nombre maximum de participants doit être supérieur au nombre minimum de participants',
    cibleCheck: 'cible invités ne doit pas être vide',
    groupCheck: 'Deux groupes doivent avoir des noms différents',
    suivantCheck: "Merci de renseigner les champs signalés avant de passer à l'écran suivant",
    sum: 'La somme de max inscrits dans les groupes ne doit pas dépasser nombre maximum des participants',
    reach: 'Nombre maximum des inscriptions atteint',
  },

  wizard: {
    stepOne: ' Détails',
    stepTwo: " Date et heure de l'évènement",
    stepThree: 'Participants',
    stepFour: 'Équipes',
    stepFive: 'Annonce',
  },

  wizardOne: {
    title: 'Titre',
    placement: 'Emplacement',
    iconInput: 'Type',
    image: 'Image',
    description: 'Description',
    referrer: 'Choisissez le référent',
    searchInputRef: 'Chercher',
  },

  wizardTwo: {
    duration: "Durée de l'événement (en minutes)",
    frequency: "A quelle fréquence l'événement sera reconduit",
    time: 'Heure de début',
    frequencyFirst: 'Tous les jours',
    frequencySecond: 'Tous les semaines',
    frequencyThird: 'Tous les mois',
    frequencyFourth: 'Une seule fois',
    frequencyFifth: 'premier XX du mois',
    day: 'Jour',
    firstDay: 'Dimanche',
    secondDay: 'Lundi',
    thirdDay: 'Mardi',
    fourthDay: 'mercredi',
    fifthDay: 'Jeudi',
    sixthDay: 'Vendredi',
    seventhDay: 'Samedi',
    month: 'Jour de mois',
    startDate: 'Date de démarrage',
  },

  wizardThree: {
    min: 'Nombre minimum de participants',
    cancelDuration: "Si le minimum n'est pas atteint, annuler XX jours avant",
    max: 'Nombre maximum de participants',
    target: 'Cible',
    team: 'Equipe',
    firstChoice: 'Site',
    secondChoice: 'Service',
    thirdChoice: 'Equipe',
    fourthChoice: 'Equipe transverse',
    checkboxOne: 'Haute potentiel',
    checkboxTwo: 'Suivi particulier',
  },

  wizardFour: {
    inputOne: 'Configuration des équipes',
    inputOneDesc: "Si vous avez besoin de plus d'informations vous pouvez consulter",
    inputOneLink: " l'aide en ligne",
    choiceOne: "Pas d'équipes",
    choiceTwo: 'Plusieurs équipe',
    choiceOneDesc: "Si votre événement ne nécessite pas la constitution d'équipes",
    choiceTwoDesc: 'si vous avez besoin de plus dune équipe en compétition',
    inputTwo: 'Nombre des équipes',
    inputThree: "Nom de l'équipe",
    inputFour: 'Nombre max des inscrits',
  },

  wizardFive: {
    date: "Date de l'annonce et de l'envoi des invitations",
    time: "Heure de l'annonce et de l'envoi des invitations",
    remind: 'Une notification avant XX jours pour les prochains événements',
    cloture: "Cloture des inscriptions XX jours avant l'événement.",
  },

  footer: {
    previous: 'précédent',
    submit: 'Créer',
    next: 'suivant',
  },

  listHeader: {
    title: 'Liste des événements',
    addButton: 'Ajouter un événement',
  },

  tools: {
    search: 'Chercher',
    option: "avec n'importe quel champ",
  },

  table: {
    columnOne: 'Titre',
    columnTwo: 'durée',
    coumnThree: 'Nombre minimum des participants',
    columnFour: 'Nombre des inscrits',
    columnFive: 'état',
    ColumnSix: 'Actions',
    button1: "Modifier l'événement",
    button2: "Annuler l'événement",
    button3: 'Ajouter des événements futurs',
  },

  eventStatus: {
    status: 'annulé',
    status1: 'Créé',
    status2: 'Invitation envoyée',
    status3: 'Inscription fermée',
    status4: 'En cours',
    status5: 'Terminer',
  },

  modify: {
    title: "Modifier l'événement",
  },

  subEvent: {
    titleDetails: "Détails de l'événement récurrent",
    modifyRecurrentEvent: "Modifier l'événement récurrent",
    inputOne: "Emplacement de l'événement récurrent",
    inputTwo: "Une notification avant XX jours pour l'événement récurrent",
    inputThree: "Date de démarrage de l'événement récurrent",
    inputFour: "Heure de début de l'événement récurrent",
  },

  delete: {
    title: "Annuler l'événement",
    desc: 'Êtes-vous sûr de vouloir Annuler définitivement cet événement?',
    button: 'Confirmer',
  },

  listGroup: {
    button: 'Ajouter un groupe',
    column1: 'Nom',
    column2: 'Nombre Maximum des participants',
    column3: 'Nombre des personnes inscrites',
    column4: 'actions',
  },

  myEvent: {
    button1: 'Oui',
    button2: 'Peut-être',
    button3: 'Non',
    status0: "Tu n'as pas répondu",
    status1: 'Tu es inscrit',
    status2: "Tu n'as pas encore décidé",
    status3: 'Tu as décliné',
    statusEvent1: 'Terminer',
    statusEvent2: 'À venir',
    statusEvent3: 'Annulé',
    groupPopUpTitle: 'Choix du groupe',
    groupPopUpDesc: 'Sélectionne le groupe que tu veux rejoindre',
    groupPopUpButton1: "S'inscrire",
    groupPopUpButton2: 'Annuler',
    groupPopUp2Title: "Inscription à l'événement",
    groupPopUp2Desc: "Es-tu sûre de vouloir t'inscrire dans le groupe ",
    groupZoneTitle: 'Liste des groupes',
    toastDesc: 'Ton choix a bien été pris en compte',
    groupZoneButton: "S'inscrire",
    groupZoneColumn1: 'NOM DU GROUPE',
    groupZoneColumn2: 'NOMBRE MAXIMUM DES participants',
    groupZoneColumn3: 'NOMBRE DES PERSONNES inscrites',
    groupZoneColumn4: 'ACTIONS',
    titreGroupModal: 'Liste des inscrits dans le groupe',
    contraint1: "L'inscription à cet événement est fermée",
    contraint2: 'Cet événement est annulé',
    eventEtatFilter: " sur l'état de l'événement",
    userEtatFilter: " sur l'état de votre invitation",
    word: 'Filtrer',
    filterOneOptOne: 'Tous',
    filterOneOptTwo: 'Pas répondu',
    filterOneOptThree: 'Inscrits',
    filterOneOptFour: 'Peut être',
    filterOneOptFive: 'décline',
    filterTwoOptOne: 'Tous',
    filterTwoOptTwo: 'Terminer',
    filterTwoOptThree: 'À venir',
    filterTwoOptFour: 'Annulé',
  },

  listEvents: {
    title: 'Liste des événements',
    column1: 'Titre',
    column2: 'Durée',
    column3: 'Emplacement',
    column4: 'DATE DE DÉMARRAGE',
    column5: 'Etat de votre invitation',
    column6: 'actions',
  },

  tab1: {
    title: 'Liste des invités',
    graph1opt1: 'Oui',
    graph1opt2: 'peut-être',
    graph1opt3: 'Non',
    graph1opt4: 'Pas repondu',
    graph2desc: "Taux d'inscription par rapport au seuil minimum des participants",
    info1: 'Personnes invités',
    info2: 'Personnes Inscrites',
    info3: 'clôture des inscriptions',
    column1: 'Nom',
    column2: 'prenom',
    column3: 'Date inscription',
    column4: "ETAT D'invitation",
  },

  tab2: {
    title: 'Liste des groupes',
    modalTitel: 'Changer la groupe de ',
    inputText: 'Le nouveaux groupe de ',
  },

  tab3: {
    title: 'Messages et rappels',
    title1: 'Envoyez des emails pour',
    button1: 'Relancer ceux qui ont mis peut-être',
    button2: "Relancer ceux qui n'ont pas encore repondu",
    button3: 'Relancer ceux qui sont inscrites',
    button4: "Relancer ceux qui ont décliné l'evenement",
    button5: 'Relancer toutes les invites',
    title2: 'Envoyez des mail personnalisés',
    input1: 'Titre de message',
    input2: 'Message',
    input3: 'Destintaire',
    option1: 'Toutes les invite',
    option2: "Les invite qui sont inscrits a l'evenement",
    option3: "Les invite qui n'a pas encore décidé",
    option4: "Les invite qui ont décliné l'evenement",
    option0: "Les invite qui n'a pas encore repondu",
    button6: 'Envoyez',
  },

  icons: {
    editEvent: 'Modifier',
    deleteEvent: "Annuler l'événement",
    detailsSubEvent: "Détails de l'événement récurrent",
    deleteSubEvent: "Annuler l'événement récurrent",
    detailsGroup: 'Détails du groupe',
    changeGroup: "Changer le groupe d'utilisateurs",
    eventDetails: "Détails de l'événement",
    addSubEvent: 'Ajouter des événement récurrent',
  },
  modalAjouterEventFils: {
    title: 'Ajouter des événements récurrents',
    input1: "Nombre d'événements créés",
    input2: 'Date du premier événement',
  },
  graph: {
    opt1: 'Pas répondu',
    opt2: 'Oui',
    opt3: 'Peut-être',
    opt4: 'Non',
  },

  toast: {
    peutEtreToast: "vous avez changé votre etat d'inscription a peut etre",
    nonToast: 'vous avez annulé votre inscription dans cet événement',
    ouiToast: 'vous êtes inscrit dans cet événement',
    toastDesc: 'Votre changement a ete traitee avec succès',
    toastDesc2: 'Votre action a ete traitee avec succès',
    toastchamps: 'Des champs manquants,veuillez remplir tous les champs correctement',
    ADDEVENT: 'Evénement ajouter avec succées',
    SENDEMAIL: 'Email envoyé avec succées',
  },
}
