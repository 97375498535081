export default {
  MODAL_DICTIONARY: {
    MODAL: 'Test Dictionary Management',
    HEADER: 'Test Dictionary Management',
    TITLE: 'Manage Test Dictionary Settings for Job Offers.',
    ADD: 'Add a Dictionary',
    SELECT: 'Select All',
    DELETE: 'Delete',
    RESPONSE: 'Answer',
    DIFFICULTY: 'Difficulty',
    NBQ: 'Number of Questions',
    SKILL: 'Skill',
    DETAIL: 'Details',
    DETAILEX: 'Hide Details',
    FAILED: 'Please specify the dictionary name, difficulty level, skill, and ensure there is at least one question.',
    PHEADER: 'Add Dictionary Settings',
    PPARAM: 'Parameters',
    PNAME: 'Dictionary Name, e.g.: skill, site, difficulty',
    PNAMEPH: 'Dictionary Name',
    LANGUAGE: 'Language',
    PLANGUAGE: 'Choose a language',
    PFA: 'easy',
    PDIF: 'difficult',
    PMO: 'moderate',
    PNBQ: 'Number of generated questions',
    PNBQPH: 'Specify a number between 1 et 20',
    INSTRUCTIONS: 'Instructions',
    PADD: 'Add a question',
    PGEN: 'Generate questions',
    CANCEL: 'Cancel',
    PSAV: 'Save',
    PSKILL: 'Rechercher une compétence.',
  },
}
