import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core'
import { FullCalendarComponent } from '@fullcalendar/angular'
import { CalendarOptions, DateSelectArg, EventApi, EventClickArg, EventSourceInput } from '@fullcalendar/core'
import frLocale from '@fullcalendar/core/locales/fr'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { AdminParkingService } from '../admin-parking.service'
import { ListReservationsComponent } from '../list-reservations/list-reservations.component'
//import { INITIAL_EVENTS, createEventId } from './event-utils';

@Component({
  selector: 'app-modal-calendar',
  templateUrl: './modal-calendar.component.html',
  styleUrls: ['./modal-calendar.component.scss'],
})
export class ModalCalendarComponent implements OnInit, AfterViewInit {
  @Input() parkId: any
  events: EventSourceInput = [
    /*{ title: 'event 1', date: '2023-05-15', backgroundColor: 'red' },
    { title: 'event 2', date: '2023-05-17' },*/
  ]

  isLoading: boolean = true

  calendarVisible = true
  calendarOptions: CalendarOptions = {
    plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
    },
    initialView: 'dayGridMonth',
    //initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
    events: this.events,
    weekends: true,
    editable: false,
    selectable: false,
    selectMirror: true,
    dayMaxEvents: 3,
    //select: this.handleDateSelect.bind(this),
    //eventClick: this.handleEventClick.bind(this),
    dateClick: this.handleDateClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    locale: frLocale,

    /* you can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */
  }
  currentEvents: EventApi[] = []

  constructor(
    private changeDetector: ChangeDetectorRef,
    private modalService: NgbModal,
    public adminParkingService: AdminParkingService,
    private cdr: ChangeDetectorRef
  ) {}
  ngAfterViewInit(): void {
    this.getAllReservations()
  }
  ngOnInit(): void {}

  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this
    calendarOptions.weekends = !calendarOptions.weekends
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    const title = prompt('Please enter a new title for your event')
    const calendarApi = selectInfo.view.calendar

    calendarApi.unselect() // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      })
    }
  }

  handleEventClick(clickInfo: EventClickArg) {
    if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      clickInfo.event.remove()
    }
  }

  handleDateClick(event: any) {
    const clickedDate = event.dateStr

    const modalRef = this.modalService.open(ListReservationsComponent, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
    })
    modalRef.componentInstance.parkId = this.parkId
    modalRef.componentInstance.date = clickedDate
    modalRef.componentInstance.refresh = this.getAllReservations()

    modalRef.result.then(params => {
      this.getAllReservations()
    })
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events
    this.changeDetector.detectChanges()
  }

  @ViewChild('calendar') calendarComponent: FullCalendarComponent
  getAllReservations() {
    this.isLoading = true
    this.adminParkingService.getAllReservationsForParking(this.parkId).subscribe(res => {
      const calendarApi = this.calendarComponent.getApi()
      calendarApi.destroy()
      calendarApi.removeAllEvents()

      for (const r of res) {
        let start = r.date.slice(10, 16).concat(' -')
        let reservationTime = start.concat(r.date_fin.slice(10, 16))
        calendarApi.addEvent({ title: reservationTime, date: r.date.slice(0, 10) })
      }
      calendarApi.render()
      this.isLoading = false
      this.cdr.detectChanges()
    })
  }
}
function createEventId(): string {
  throw new Error('Function not implemented.')
}
