import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { GroupingState } from 'src/app/_metronic/shared/crud-table/models/grouping.model'
import { PaginatorState } from 'src/app/_metronic/shared/crud-table/models/paginator.model'
import { SortState } from 'src/app/_metronic/shared/crud-table/models/sort.model'
import { ITableState, TableResponseModel } from 'src/app/_metronic/shared/crud-table/models/table.model'
import { TableListeCovoiturageService } from 'src/app/_metronic/shared/crud-table/services/table.liste.covoiturage.service'
import { baseFilter } from 'src/app/common/helpers/http-extenstions'
import { environment } from 'src/environments/environment'

const DEFAULT_STATE: ITableState = {
  filter: {},
  paginator: new PaginatorState(),
  sorting: new SortState(),
  searchTerm: '',
  grouping: new GroupingState(),
  entityId: undefined,
}

@Injectable({
  providedIn: 'root',
})
export class CovServiceService extends TableListeCovoiturageService<any> {
  currentUserSubject: BehaviorSubject<any>
  isLoadingSubject: BehaviorSubject<boolean>

  get currentUserValue(): any {
    return this.currentUserSubject.value
  }

  set currentUserValue(user: any) {
    this.currentUserSubject.next(user)
  }

  API_URLCOVOITURAGE = `${environment.apiUrl}/covoiturage/getResponsableCovoiturage`
  API_URL_UPDATE_STATUS = `${environment.apiUrl}/covoiturage/updateStatus`
  API_URL_GET_PARTICIPANT = `${environment.apiUrl}/covoiturage/getlisteParticipants`
  API_URL_ANNULATION_GNRL = `${environment.apiUrl}/covoiturage/annulationCovoiturageGnrl`

  protected authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`
  protected http: HttpClient
  constructor(http: HttpClient) {
    super(http)
    this.isLoadingSubject = new BehaviorSubject<boolean>(false)
    this.currentUserSubject = new BehaviorSubject<any>(undefined)
  }
  protected getAuthFromLocalStorage(): any {
    try {
      const authData = JSON.parse(localStorage.getItem(this.authLocalStorageToken))
      return authData
    } catch (error) {
      console.error(error)
      window.location.reload()
      return undefined
    }
  }

  find(tableState: ITableState): Observable<TableResponseModel<any>> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(`${this.API_URLCOVOITURAGE}`, { params }).pipe(
      map((response: any[]) => {
        const filteredResult = baseFilter(response, tableState)
        const result: TableResponseModel<any> = {
          items: filteredResult.items,
          total: filteredResult.total,
        }
        return result
      })
    )
  }
  updateCovoiturageStatus(item: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    let idCovoiturage = item.idCovoiturage
    let status = item.status
    let user = item.user
    let email = item.email
    let login_email = item.login_email
    let nom = item.nom
    let nom_responsable = item.nom_responsable
    let prenom_responsable = item.prenom_responsable
    let reservation_date = item.reservation_date

    /*
 idCovoiturage: this.covoiturage_uuid,
        user: this.user,
        status: 1,
        email: this.email,
        nom: this.nom,
        nom_responsable: this.nom_responsable,
        prenom_responsable: this.prenom_responsable,
        reservation_date: this.reservation_date,
        */
    let body = {
      idCovoiturage,
      status,
      user,
      email,
      login_email,
      nom,
      nom_responsable,
      prenom_responsable,
      reservation_date,
    }
    return this.http.put<any>(this.API_URL_UPDATE_STATUS, body, { params })
  }
  annulationCovoiturageGnrl(idCovoiturage): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid).set('covoiturage_uuid', idCovoiturage)

    return this.http.put<any>(this.API_URL_ANNULATION_GNRL, null, { params })
  }
  getlisteParticipants(idCovoiturage): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid).set('covoiturage_uuid', idCovoiturage)

    return this.http.get<any>(this.API_URL_GET_PARTICIPANT, { params })
  }
}
