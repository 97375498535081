<div class="card card-custom gutter-b">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">{{ "ADMINPARKING.listParking.title" | translate }}</h3>
    </div>
    <div class="card-toolbar" *ngIf="parckingAbonnement">
      <button type="button" class="btn btn-primary" (click)="openModalCreate()" style="margin-right: 15px">
        {{ "ADMINPARKING.listParking.button1" | translate }}
      </button>
      <button type="button" class="btn btn-primary" (click)="openModalReserve()">{{ "ADMINPARKING.listParking.button2" | translate }}</button>
    </div>
  </div>
  <div class="card-body" *ngIf="parckingAbonnement">
    <!-- begin: Filtration form -->
    <div class="form form-label-right">
      <div class="form-group row">
        <div class="col-lg-4">
          <div class="row" [formGroup]="filterGroup">
            <div>
              <select style="width: max-content" #t2 class="form-control form-control-lg form-control-solid" (change)="siteId = $event.target.value">
                <option [value]="0">Tous les sites</option>
                <option *ngFor="let s of listSites" [value]="s.ids">
                  {{ s.name }}
                </option>
              </select>
              <small class="form-text text-muted">{{ "ADMINPARKING.listParking.filtersite" | translate }}</small>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="row" [formGroup]="filterGroup">
            <div>
              <select
                style="width: max-content"
                #t2
                class="form-control form-control-lg form-control-solid"
                (change)="typeFilter = $event.target.value"
              >
                <option [value]="0">Tous les types</option>
                <option [value]="1">{{ "ADMINPARKING.modalParking.option1" | translate }}</option>
                <option [value]="2">{{ "ADMINPARKING.modalParking.option2" | translate }}</option>
                <option [value]="3">{{ "ADMINPARKING.modalParking.option3" | translate }}</option>
              </select>
              <small class="form-text text-muted">{{ "ADMINPARKING.listParking.filter" | translate }}</small>
            </div>
          </div>
        </div>

        <div class="col-lg-4" [formGroup]="searchGroup">
          <input
            type="text"
            class="form-control"
            name="searchText"
            placeholder="{{ 'GROUP.LIST.SEARCH' | translate }}"
            value=""
            formControlName="searchTerm"
          />
          <small class="form-text text-muted">{{ "ADMINPARKING.listParking.search" | translate }}</small>
        </div>
      </div>
    </div>
    <!-- end: Filtration form -->

    <!-- begin: Grouping -->
    <ng-container *ngIf="grouping.getSelectedRowsCount()">
      <div class="form">
        <div class="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
          <div class="col-xl-12">
            <div class="form-group form-group-inline"></div>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- end: Grouping -->

    <!-- begin:Table -->
    <div class="table-responsive angular-bootstrap-table">
      <table class="table table-head-custom table-vertical-center overflow-hidden">
        <thead>
          <th>
            Site
            <app-sort-icon
              column="titre_event"
              [activeColumn]="sorting.column"
              [activeDirection]="sorting.direction"
              (sort)="sort($event)"
            ></app-sort-icon>
          </th>
          <th>
            {{ "ADMINPARKING.listParking.column1" | translate }}
            <app-sort-icon
              column="titre_event"
              [activeColumn]="sorting.column"
              [activeDirection]="sorting.direction"
              (sort)="sort($event)"
            ></app-sort-icon>
          </th>
          <th>
            {{ "ADMINPARKING.listParking.column2" | translate }}
            <app-sort-icon
              column="titre_event"
              [activeColumn]="sorting.column"
              [activeDirection]="sorting.direction"
              (sort)="sort($event)"
            ></app-sort-icon>
          </th>
          <th>specificite</th>
          <th>{{ "ADMINPARKING.listParking.column3" | translate }}</th>
        </thead>
        <tbody>
          <tr *ngFor="let p of adminParkingService.items$ | async">
            <ng-container *ngIf="(typeFilter == 0 || typeFilter == p.type) && (siteId == 0 || siteId == p.site_id)">
              <td>{{ p.name }}</td>
              <td>{{ p.code }}</td>
              <td *ngIf="p.type === 1">Voiture</td>
              <td *ngIf="p.type === 2">Velo</td>
              <td *ngIf="p.type === 3">Moto</td>

              <td>
                <i class="fa fa-wheelchair" aria-hidden="true" *ngIf="p.handicap == 1" style="margin-right: 10px"></i>
                <i class="fa fa-bolt" aria-hidden="true" *ngIf="p.electrique == 1" style="margin-right: 10px"></i>
                <i class="fa fa-car" aria-hidden="true" *ngIf="p.covoiturage == 1" style="margin-right: 10px"></i>
                <i class="fa fa-times" aria-hidden="true" *ngIf="p.non_reservable == 1" style="margin-right: 10px"></i>
              </td>

              <td>
                <a
                  [title]="'EVENT.icons.deleteEvent' | translate"
                  class="btn btn-icon btn-light btn-hover-danger btn-sm"
                  (click)="deleteParking(p.park_uuid)"
                  class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                >
                  <span
                    style="pointer-events: none"
                    [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'"
                    [cacheSVG]="true"
                    class="svg-icon svg-icon-md svg-icon-danger"
                  >
                  </span>
                </a>

                <a title="Détails" class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3" (click)="openModalCalendar(p.park_uuid)">
                  <span
                    style="pointer-events: none"
                    [inlineSVG]="'./assets/media/svg/icons/General/Binocular.svg'"
                    [cacheSVG]="true"
                    class="svg-icon svg-icon-md svg-icon-primary"
                  >
                  </span>
                </a>

                <a title="Détails" class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3" (click)="openModalEdit(p)">
                  <span
                    style="pointer-events: none"
                    [inlineSVG]="'./assets/media/svg/icons/Communication/Write.svg'"
                    [cacheSVG]="true"
                    class="svg-icon svg-icon-md svg-icon-primary"
                  >
                  </span>
                </a>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- end: Table -->
    <app-paginator
      class="d-flex justify-content-between align-items-center flex-wrap"
      [paginator]="paginator"
      [isLoading]="isLoading"
      (paginate)="paginate($event)"
    ></app-paginator>
  </div>
</div>
