export default {
  listIdea: {
    title: 'Boîte à idées',
    searchInput: 'Recherche',
    filter: 'Filtrer les idées',
    button1: 'Ajouter une idée',
    opt1: 'Toutes',
    opt2: 'A reçue réponse',
    opt3: "N'a pas reçue réponse",
    opt4: 'Supprimer',
    button: 'Supprimé',
    LISTIDEA: 'Liste des idées',
  },

  listComments: {
    title: 'Commentaires',
    input1: 'Ajouter un commentaire',
    button1: 'Répondre',
    user: 'Utilisateur',
  },

  card: {
    button1: "J'aime",
    button2: 'Commenter',
    text1: 'Etat: ',
  },

  add: {
    title: 'Ajouter une idée',
    input1: "Titre de l'idée",
    input2: "Description de l'idée",
    button1: 'Annuler',
    button2: 'Ajouter',
  },

  reponse: {
    opt1: 'Refusé',
    opt2: 'Accepté à court terme',
    opt3: 'Accepté à moyen terme',
    opt4: 'Accepté à long terme',
  },

  tri: {
    opt1: 'Trier par default',
    opt2: 'Trier par date décroissante',
    opt3: 'Trier par date croissant',
    opt4: 'Trier par nombre like décroissante',
    opt5: 'Trier par nombre like croissant',
    opt6: 'Trier par nombre commentaire décroissante',
    opt7: 'Trier par nombre commentaire croissant',
  },
}
