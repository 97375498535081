import { HttpClient, HttpParams } from '@angular/common/http'
import { ActivatedRoute, Router } from '@angular/router'
import { getSessionFromLocalStorage } from 'src/app/utils/getSessionFromLocalStorage'
import { environment } from 'src/environments/environment'
import { SurveyService } from './../_services/surveys.service'
import { SurveyActivateModalComponent } from './survey-activate-modal/survey-activate-modal.component'
// tslint:disable:no-string-literal
import { Clipboard } from '@angular/cdk/clipboard'
import { AfterViewInit, ApplicationRef, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Subscription } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { imgEncoder } from 'src/app/utils/imgEncoder'

import {
  GroupingState,
  IDeleteAction,
  IFetchSelectedAction,
  IFilterView,
  IGroupingView,
  ISearchView,
  ISortView,
  PaginatorState,
  SortState,
} from '../../../_metronic/shared/crud-table'

import { DatePipe } from '@angular/common'
import { MatOptionSelectionChange } from '@angular/material/core'
import { ToastrService } from 'ngx-toastr'
import { UserModel } from '../../auth/_models/user.model'
import { AuthService } from '../../auth/_services/auth.service'
import { UserService } from '../../collaborateurs/_services'
import { ClosedModalComponent } from '../../communities/gerer/closed-modal/closed-modal.component'
import { PromptModalComponent } from '../../shared/prompt-modal/prompt-modal.component'

@Component({
  selector: 'app-survey-activate',
  templateUrl: './survey-activate.component.html',
  styleUrls: ['./survey-activate.component.scss'],
})
export class SurveyActivateComponent
  implements
    OnInit,
    AfterViewInit,
    OnDestroy,
    IDeleteAction,
    IFetchSelectedAction,
    ISortView,
    IFilterView,
    IGroupingView,
    ISearchView,
    IFilterView
{
  paginator: PaginatorState
  sorting: SortState
  grouping: GroupingState
  isLoading: boolean
  filterGroup: UntypedFormGroup
  searchGroup: UntypedFormGroup
  private subscriptions: Subscription[] = []

  currentUser$: UserModel

  constructor(
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    public surveyService: SurveyService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private http: HttpClient,
    private clipboard: Clipboard,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    public userService: UserService,
    private appref: ApplicationRef
  ) {
    this.authService.currentUserSubject.subscribe(x => (this.currentUser$ = x))
  }
  ngAfterViewInit(): void {
    this.cdr.detectChanges()
  }
  tableOption: any = []

  enqueteAbonnement: boolean = false

  formtype = this.route.data['value'].formtype

  closedModal() {
    this.modalService.open(ClosedModalComponent)
  }

  ngOnInit(): void {
    this.init()
  }

  init() {
    this.filterForm()
    this.searchForm()

    this.surveyService.setFormtype(this.formtype)
    this.userService.getEntrepriseAbonnement().subscribe(async data => {
      for (let i = 0; i < data.length; i++) {
        this.tableOption.push(data[i].code_option)
        this.cdr.detectChanges()
      }
      if (this.tableOption.includes('MENQ')) {
        this.enqueteAbonnement = true
        this.surveyService.fetch().then(() => {
          this.cdr.detectChanges()
        })
      } else {
        this.closedModal()
      }
    })

    this.grouping = this.surveyService.grouping
    this.paginator = this.surveyService.paginator
    this.sorting = this.surveyService.sorting
    const sb = this.surveyService.isLoading$.subscribe(res => (this.isLoading = res))
    this.subscriptions.push(sb)
    this.cdr.detectChanges()
  }

  dateNow = this.datePipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm')
  dateForInput(date) {
    if (!date) {
      return ''
    }
    return this.datePipe.transform(new Date(date).getTime(), 'yyyy-MM-ddTHH:mm')
  }
  castNbr(str) {
    if (isNaN(Number(str))) {
      return str
    } else {
      return Number(str)
    }
  }
  selectSurveyTarget(e: MatOptionSelectionChange, survey: any) {
    survey.targetType = e.source.value
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe())
  }

  // filtration
  filterForm() {
    this.filterGroup = this.fb.group({
      status: [''],
      type: [''],
      searchTerm: [''],
    })
    this.subscriptions.push(this.filterGroup.controls.status.valueChanges.subscribe(() => this.filter()))
    this.subscriptions.push(this.filterGroup.controls.type.valueChanges.subscribe(() => this.filter()))
  }

  filter() {
    const filter = {}
    const status = this.filterGroup.get('status').value
    if (status) {
      filter['status'] = status
    }

    const type = this.filterGroup.get('type').value
    if (type) {
      filter['type'] = type
    }
    this.surveyService.patchState({ filter })
  }

  // search
  searchForm() {
    this.searchGroup = this.fb.group({
      searchTerm: [''],
    })
    const searchEvent = this.searchGroup.controls.searchTerm.valueChanges
      .pipe(debounceTime(150), distinctUntilChanged())
      .subscribe(val => this.search(val))
    this.subscriptions.push(searchEvent)
  }

  search(searchTerm: string) {
    this.surveyService.patchState({ searchTerm })
  }

  // sorting
  sort(column: string) {
    const sorting = this.sorting
    const isActiveColumn = sorting.column === column
    if (!isActiveColumn) {
      sorting.column = column
      sorting.direction = 'asc'
    } else {
      sorting.direction = sorting.direction === 'asc' ? 'desc' : 'asc'
    }
    this.surveyService.patchState({ sorting })
  }

  // pagination
  paginate(paginator: PaginatorState) {
    this.surveyService.patchState({ paginator })
  }

  encoder(img: string, isUserImg?, size = 50) {
    return imgEncoder(img, size, isUserImg)
  }

  activateSurveyTarget() {
    const modalRef = this.modalService.open(SurveyActivateModalComponent, {
      size: 'xl',
      centered: true,
    })
    modalRef.componentInstance.formtype = this.formtype
    modalRef.result.then(() => {
      this.surveyService.fetch()
    })
  }

  delete(id: any) {}

  copyToClip(targetId: string, surveyId: string) {
    this.clipboard.copy(`${window.origin}/survey/fill/${targetId}/${surveyId}`)
    this.toastr.success('Lien copié avec succès')
  }

  closeSurveyTargeting(id: string) {
    const modalRef = this.modalService.open(PromptModalComponent)
    modalRef.componentInstance.data = {
      message: 'Voulez-vous vraiment clôturer ce ciblage ?',
      title: 'Clôturer le ciblage',
    }
    modalRef.closed.subscribe({
      next: () => {
        this.http
          .patch(
            `${environment.apiUrl}/survey/target/close/${id}`,
            {},
            {
              params: new HttpParams().set('sessionid', getSessionFromLocalStorage()),
            }
          )
          .subscribe(res => {
            this.toastr.success('Le ciblage a été clôturé avec succès')
            this.surveyService.fetch()
          })
      },
    })
  }

  deleteSurveyTargeting(id: string) {
    const modalRef = this.modalService.open(PromptModalComponent)
    modalRef.componentInstance.data = {
      message: 'Voulez-vous vraiment supprimer ce ciblage ?',
      title: 'Supprimer le ciblage',
    }
    modalRef.closed.subscribe({
      next: () => {
        this.http
          .delete(`${environment.apiUrl}/survey/target/${id}`, {
            params: new HttpParams().set('sessionid', getSessionFromLocalStorage()),
          })
          .subscribe(res => {
            this.toastr.success('Le ciblage a été supprimé avec succès')
            this.surveyService.fetch()
          })
      },
    })
  }

  targetsDesc = [
    [
      ['Tout le monde'],
      ['Tout ambassadeur'],
      ['Tout Haut Potentiel'],
      ['Tout Suivi Particulier'],
      ['Tout Alumnus'],
      ['Onboarding/Offboarding'],
    ],
    [
      ['Tout le Site '],
      ['Ambassadeurs du site  '],
      ['Haut Potentiel du site  '],
      ['Suivi Particulier du site  '],
      ['Alumni du site  '],
    ],
    [
      ['Tout le Service '],
      ['Ambassadeurs du service '],
      ['Haut Potentiel du service '],
      ['Suivi Particulier du service '],
      ['Alumni du service '],
    ],
    [
      ["Toute l'équipe "],
      ["Ambassadeurs de l'équipe "],
      ["Haut Potentiel de l'équipe "],
      ["Suivi Particulier de l'équipe "],
      ["Alumni de l'équipe "],
    ],
    [
      ["Toute l'équipe transverse "],
      ["Ambassadeurs de l'équipe transverse "],
      ["Haut Potentiel de l'équipe transverse "],
      ["Suivi Particulier de l'équipe transverse "],
      ["Alumni de l'équipe transverse "],
    ],
  ]

  fetchSelected() {
    this.surveyService.fetch()
  }

  get isAdmin() {
    return this.currentUser$ && this.currentUser$[0].IdRole_Role === 0
  }
}
