<!-- begin::Header -->
<div class="card-header border-0 pt-5">
  <h3 class="card-title align-items-start" style="float:left">
    <span class="card-label fw-bolder fs-3 mb-1">{{"ListeDesJeux.ListJeux" | translate}}</span>
  </h3>
  <div class="card-toolbar" style="float:right">
    <ul class="nav">
      <li class="nav-item">
        <a
          class="
            nav-link
            btn btn-sm btn-color-muted btn-active btn-active-light-primary
            fw-bolder
            px-4
            me-1
          "
          data-bs-toggle="tab"
          [ngClass]="activeClass('kt_table_widget_6_tab_2')"
          (click)="setTab('kt_table_widget_6_tab_2')"
        >
        {{"ListeDesJeux.jeuxAssoc" | translate}}
        </a>
      </li>
      
      <li class="nav-item">
        <a
          class="
            nav-link
            btn btn-sm btn-color-muted btn-active btn-active-light-primary
            fw-bolder
            px-4"
          data-bs-toggle="tab"
          [ngClass]="activeClass('kt_table_widget_6_tab_3')"
          (click)="setTab('kt_table_widget_6_tab_3')"
        >
        {{"ListeDesJeux.pasAssoc" | translate}}
        </a>
      </li>
    </ul>
  </div>










  
<!-- end::Header -->
<!-- begin::Body -->
<div class="card-body py-3">
  <div class="tab-content">
    <!-- begin::Tap pane -->
    <div
      class="tab-pane fade"
      [ngClass]="activeClass('kt_table_widget_6_tab_1')"
      id="kt_table_widget_6_tab_1"
    >
    </div>
    <!-- end::Tap pane -->
    <!-- begin::Tap pane -->
    <div
      class="tab-pane fade"
      [ngClass]="activeClass('kt_table_widget_6_tab_2')"
      id="kt_table_widget_6_tab_2"
    >
      <!-- begin::Table container -->
      <div class="table-responsive">
        <!-- begin::Table -->
      
        <table class="table align-middle gs-0 gy-3 border-0">
          <!-- begin::Table head -->
          <thead>
            <tr>
              <th class="p-0 w-100px"></th>
              <th class="p-0 min-w-400px"></th>
              <th class="p-0 min-w-100px"></th>
            </tr>
          </thead>
          <!-- end::Table head -->
          <!-- begin::Table body -->
          <tbody>
            <tr class="border-0" *ngFor="let row of assoc_tab1"> 
              <td> 
                <!-- class="border-0" <img src="./assets/media/stock-600x400/premiumTag.png" alt="" >-->
                <div class="symbol symbol-60px symbol-2by3 me-4" [ngClass]="{'premuimImg':row.premium == 1}">
                  <div
                    class="symbol-label"
                    [style.background-image]="'url(./assets/media/stock-600x400/img-17.jpg)'"
                  ></div>
                </div>
              </td>
              <td>
              
                <a (click)="newMessage(row.game_id)"
                routerLink="/gerer-Quiz"
                routerLinkActive="active"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                  {{row.title}}
                </a>
                
                <span class="text-muted fw-bold d-block">{{row.short_description}} ...</span>
              </td>
              <td style="float:right">
                <span class="text-dark fw-bolder d-block fs-7">{{row.playerscount}}</span>
                <span class="text-muted fw-bold d-block fs-7">{{"ListeDesJeux.joueurs" | translate}}</span>
              </td>
            </tr>
          </tbody>
          <!-- end::Table body -->
        </table>
     
      </div>
      <!-- end::Table -->
    </div>
    <!-- end::Tap pane -->
    <!-- begin::Tap pane -->
    <div
      class="tab-pane fade"
      [ngClass]="activeClass('kt_table_widget_6_tab_3')"
      id="kt_table_widget_6_tab_3"
    >
      <!-- begin::Table container -->
      <div class="table-responsive">
        <!-- begin::Table -->
        <table class="table align-middle gs-0 gy-3">
          <!-- begin::Table head -->
          <thead>
            <tr>
              <th class="p-0 w-100px"></th>
              <th class="p-0 min-w-700px"></th>
              <th class="p-0 min-w-150px"></th>
            </tr>
          </thead>
          <!-- end::Table head -->
          <!-- begin::Table body -->
          <tbody>
            <tr *ngFor="let row of non_assoc_tab2 ">
              <td>
                <div class="symbol symbol-60px symbol-2by3 me-4" [ngClass]="{'premuimImg':row.premium == 1}">
                  <div
                    class="symbol-label"
                    [style.background-image]="'url(./assets/media/stock-600x400/img-17.jpg)'"
                  ></div>
                </div>
              </td>
              <td>
                <a (click)="newMessage(row.game_id)" 
                routerLink="/gerer-Quiz" 
                routerLinkActive="active"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                {{row.title}}    
                </a>
                <span class="text-muted fw-bold d-block">{{row.short_description}} ...</span>
              </td>
              <td>
                <span class="text-dark fw-bolder d-block fs-5">{{row.playerscount}}</span>
                <span class="text-muted fw-bold d-block fs-7">joueurs</span>
              </td>
            </tr>
          </tbody>
          <!-- end::Table body -->
        </table>
      </div>
      <!-- end::Table -->
    </div>
    <!-- end::Tap pane -->
  </div>
</div>
<!-- end::Body -->
</div>

