import { HttpClient, HttpParams } from '@angular/common/http'
import { ChangeDetectorRef, Injectable, OnDestroy, Optional } from '@angular/core'
import * as CryptoJS from 'crypto-js'
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs'
import { catchError, finalize, map, switchMap } from 'rxjs/operators'
import { UserModel } from '../_models/user.model'

import { ActivatedRoute, Router } from '@angular/router'
import { Socket } from 'ngx-socket-io'
import { environment } from 'src/environments/environment'
import { ActivityService } from '../../Activity/_services'
import { User } from '../../collaborateurs/_models/user.models'
import { AuthHTTPService } from './auth-http'
const API_USERS_URL = `${environment.apiUrl}`

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  get currentUserValue(): UserModel {
    return this.currentUserSubject.value
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user)
  }
  returnUrl: string
  constructor(
    private s: ActivityService,
    private authHttpService: AuthHTTPService,
    private router: Router,
    private http: HttpClient,
    @Optional() public cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private socket: Socket
  ) {
    this.token = this.route.snapshot.paramMap.get('id')
    this.cdr = cdr
    this.isLoadingSubject = new BehaviorSubject<boolean>(false)
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined)
    this.currentUser$ = this.currentUserSubject.asObservable()
    this.isLoading$ = this.isLoadingSubject.asObservable()
    const subscr = this.getUserByToken().subscribe()
    this.unsubscribe.push(subscr)
  }
  // private fields
  private unsubscribe: Subscription[] = []
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`
  private authLocalStorageToken1 = 'username'
  token: any
  // public fields
  currentUser$: Observable<UserModel>
  isLoading$: Observable<boolean>
  currentUserSubject: BehaviorSubject<UserModel>
  isLoadingSubject: BehaviorSubject<boolean>
  private key = '1211'
  private authLocalStorageToken01 = '1211z'
  //methode to encrypt et decrypt password
  //debut block
  private encrypt(txt: string): string {
    return CryptoJS.AES.encrypt(txt, this.key).toString()
  }

  private decrypt(txtToDecrypt: string) {
    return CryptoJS.AES.decrypt(txtToDecrypt, this.key).toString(CryptoJS.enc.Utf8)
  }
  public saveData(key: string, value: string) {
    localStorage.setItem(key, this.encrypt(value))
  }
  public getData(key: string) {
    let data = localStorage.getItem(key) || ''
    return this.decrypt(data)
  }
  //fin block encrypt et decrypt password

  // public methods
  login(login_email: string, login_password: string): Observable<any> {
    this.isLoadingSubject.next(true)
    return this.authHttpService.login(login_email, login_password).pipe(
      map(({ sessionid, tokenQSP }) => {
        localStorage.setItem('QSP_AUTH_TOKEN', tokenQSP)
        const result = this.setAuthFromLocalStorage(sessionid)
        return result
      }),
      switchMap(() => this.getUserByToken()),
      catchError(err => {
        console.error('err', err)

        // window.location.reload()
        return of(err.status)
      }),
      finalize(() => this.isLoadingSubject.next(false))
    )
  }

  /* wsregistration(user: any): Promise<any> {
    return this.authHttpService.createWsUser(user)
  }
*/
  logout() {
    const lang = localStorage.getItem('language')
    //voir if deconnect or not for fix deeplink
    localStorage.removeItem('language')

    localStorage.setItem('logout', '1')
    localStorage.removeItem('jwt')
    localStorage.removeItem('pays')
    localStorage.removeItem('ent_id')
    // localStorage.removeItem('foo')
    localStorage.removeItem('ville')
    sessionStorage.clear()
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/auth/login'])
    })
    // this.cdr.detectChanges()
    localStorage.removeItem(this.authLocalStorageToken)
    //localStorage.setItem('returnUrl', JSON.stringify(['/']))
    // sessionStorage.removeItem(this.authLocalStorageToken0);
    //localStorage.removeItem('returnUrl')
    localStorage.removeItem('role')
    localStorage.removeItem('resequipe')
    localStorage.removeItem('ressite')
    localStorage.removeItem('resservice')
    localStorage.removeItem('entname')
    localStorage.removeItem('ent_id')

    localStorage.removeItem(this.authLocalStorageToken01)
    localStorage.removeItem('uuid')
    localStorage.removeItem('email')
    localStorage.removeItem('carte')
    localStorage.removeItem('name')
    localStorage.removeItem('foo')

    this.socket.emit('disconnect')
    // sessionStorage.removeItem("username");
    //this.route.snapshot.queryParams = ['/']

    this.router.navigate(['/auth/login'], {
      queryParams: { returnUrl: '/dashboard' },
    })
    this.returnUrl = '/'
  }
  //////
  getUsesrBySession() {
    const auth = this.getAuthFromLocalStorage()
    return this.authHttpService.getUserbySession(auth).subscribe(data => {})
  }
  ///

  getUserByToken(): Observable<UserModel> {
    const auth = this.getAuthFromLocalStorage()

    if (!auth) {
      return of(undefined)
    }

    this.isLoadingSubject.next(true)
    this.getUserByToken1(auth.sessionid)
    return this.getUserByToken1(auth.sessionid).pipe(
      map((user: any) => {
        localStorage.setItem('name', JSON.stringify(user[0].prenom))
        localStorage.setItem('uuid', JSON.stringify(user[0].uuid))

        sessionStorage.setItem(this.authLocalStorageToken, JSON.stringify(user[0].prenom))

        sessionStorage.setItem(this.authLocalStorageToken1, JSON.stringify(user[0].uuid))

        if (user && user[0].session_status == 1) {
          this.currentUserSubject = new BehaviorSubject<UserModel>(user)
        } else {
          this.logout()
        }
        return user
      }),
      finalize(() => this.isLoadingSubject.next(false))
    )
  }

  // need create new user then login
  registration(user: any): Observable<any> {
    this.isLoadingSubject.next(true)
    return this.authHttpService.createUser(user).pipe(
      map((user: any) => {
        this.isLoadingSubject.next(false)
      }),

      catchError(err => {
        console.error('err', err)
        // window.location.reload()
        return of(err.status)
      }),
      finalize(() => this.isLoadingSubject.next(false))
    )
  }
  registrationLink(user: any, token: any): Observable<any> {
    this.isLoadingSubject.next(true)
    return this.authHttpService.createUserLink(user, token).pipe(
      map((user: any) => {
        this.isLoadingSubject.next(false)
      }),

      catchError(err => {
        console.error('err', err)
        //window.location.reload()
        return of(err.status)
      }),
      finalize(() => this.isLoadingSubject.next(false))
    )
  }

  forgotPassword(login_email: any): Observable<any> {
    this.isLoadingSubject.next(true)
    return this.authHttpService.forgotPassword(login_email).pipe(
      finalize(() => this.isLoadingSubject.next(false)),
      catchError(err => {
        // window.location.reload()
        return of(err.status)
      })
    )
  }

  // private methods
  private setAuthFromLocalStorage(auth: UserModel): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (1 == 1) {
      //auth sessionid ta3 user

      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth))

      return true
    }
    return false
  }

  private getAuthFromLocalStorage(): UserModel {
    try {
      const authData = JSON.parse(localStorage.getItem(this.authLocalStorageToken))
      return authData
    } catch (error) {
      console.error(error)
      return undefined
    }
  }
  getUserByToken1(sessionid) {
    sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<UserModel>(`${API_USERS_URL}/todo/user`, { params })
  }

  getUserByToken2(sessionid) {
    sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<User>(`${API_USERS_URL}/todo/user`, { params })
  }

  getInspirations() {
    let sessionid = this.getAuthFromLocalStorage()

    //const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<User>(`${API_USERS_URL}/dashbord/inspirations_jour?sessionid=${sessionid}`)
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe())
  }
}
