import { ToastrService } from 'ngx-toastr'
import { EquipeT, ServiceT } from './os-modeling-service.service'
export function reserveSeatErrorHandler(
  error,
  toastr: ToastrService,
  services: ServiceT[] = [],
  equipes: EquipeT[] = []
) {
  const {
    error: {
      isPastDate,
      maxReservationsPerWeek,
      maxReservationsPerDay,
      threshold,
      invalidTime,
      unavailable,
      alreadyReserved,
      seat,
      seat: { accesstype = undefined, isMeetingRoom = undefined, reserveByAdmin = undefined } = {},
    },
  } = error
  if (error.status === 400) {
    if (isPastDate) {
      return toastr.error('Vous ne pouvez pas réserver un siège pour une date passée', 'Erreur')
    }
    if (maxReservationsPerWeek) {
      return toastr.error(
        `Vous avez atteint le nombre maximum de réservations pour cette semaine (${threshold})`,
        'Erreur'
      )
    }
    if (maxReservationsPerDay) {
      return toastr.error(`Vous avez atteint la capacité pour cette journée (${threshold} personne)`, 'Erreur')
    }
    if (invalidTime) {
      return toastr.error('Veuillez sélectionner une heure de début et de fin valides', 'Erreur')
    }
    if (unavailable) {
      return toastr.error('Ce siège est déjà réservé pour ce créneau', 'Erreur')
    }
    if (alreadyReserved) {
      return toastr.error('Vous avez déjà une réservation qui chevauche ce créneau', 'Erreur')
    }

    if (accesstype === 1) {
      toastr.error(`Ce siège est dédié au service "${services.find(x => x.value === seat.service).viewValue}"`)
    } else if (accesstype === 2) {
      toastr.error(`Cette salle est dédiée à l'équipe "${equipes.find(x => x.value === seat.equipe).viewValue}"`)
    } else if (reserveByAdmin) {
      toastr.error(`Ce siège est réservable uniquement par un administrateur`)
    } else if (isMeetingRoom) {
      toastr.error(`Cette salle est réservable uniquement pour les réunions`)
    }
  } else {
    toastr.error("Une erreur s'est produite lors de la réservation de ce siège", 'Erreur', {
      timeOut: 3000,
    })
  }
}
