import { Component } from '@angular/core'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.scss'],
})
export class ModalInfoComponent {
  constructor(
    public modal: NgbActiveModal,

    private modalService: NgbModal
  ) {}
  close() {
    this.modal.close()
  }
}
