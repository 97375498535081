export default {
  ADD_BUILDING: 'Add a building',
  ADD_SITE: 'Add a site',
  ADD_FLOOR: 'Add a floor',
  CHOOSE_FLOOR: 'Choose a floor',
  CHOOSE_SITE: 'Choose a site',
  CHOOSE_BUILDING: 'Choose a building',
  BUILDING_NAME: 'Building name',
  RESV_RULES: {
    LIST: 'Reservation rules',
    ADD: 'Create a new rule',
    NAME: 'Rule name',
    VALUE: 'Applied value',
    TARGET: "Rule's target",
    MAX_RESV_PER_DAY: 'Maximum number of people per day',
    MIN_RESV_PER_DAY: 'Minimum number of people per day',
    MAX_RESV_PER_WEEK: 'Maximum number of days per week',
    MIN_RESV_PER_WEEK: 'Minimum number of days per week',
    EXCLUDE_ABSENCE: 'Exclude absence (holidays, sickness, etc.)',
    TARGET_LABELS: ['', 'for user ', 'for team ', 'for service ', 'for site ', 'for everyone'],
    TARGETS: ['', 'User', 'Team', 'Service', 'Site', 'Everyone'],
    APPLIED: 'Applied',
    CHOOSE: 'Choose a rule to configure:',
    CONFIG: 'Apply the rule for: ',
    NBR_PPL: 'Number of employees: ',
    NBR_DAYS: 'Number of days: ',
    CHOICE: 'Rule choice',
    CONFIGURE: 'Configure the rule',
  },
  GRAPHICAL_ELEMENTS: 'Add office elements \nand configure floor settings',
  RESERVE_SLOT: '📅 Reserve a slot',
  FLOOR_COVERING: 'Floor covering',
  OFFICES: 'Offices',
  WALLS: 'Walls',
  FURNITURE: 'Furniture',
  ACCESSIBILITY_AND_DECORATION: 'Accessibility and decoration',
  SETTINGS: 'Settings',
  HORI: 'Horizontal',
  VERT: 'Vertical',
  CHOOSE_THEME: 'Choose a theme',
  ACCESSED_BY: 'Access by',
  MEETING_ROOM: 'Meeting room',
  RESERV_BY_ADMIN: 'Reservable only by an admin',
  OBJECT_CODE: 'Object code',
  ADD_VISITOR: 'Reserve a slot for a visitor',
  FNAME: 'First name',
  LNAME: 'Last name',
  EMAIL: 'Email',
  PHONE: 'Phone',
  PLEASE_ENTER_FIRST_NAME: 'Please enter a first name',
  PLEASE_ENTER_LAST_NAME: 'Please enter a last name',
  MANAGE_RESERVATIONS_ADMIN: {
    ERRORS: {
      DATE_START: 'Please select a start date',
      DATE_END: 'Please select an end date',
      INVALID_DATES: 'Please select an end date greater than the start date',
      DURATION_TOO_LONG: 'The reservation period cannot exceed 45 days',
      APPLY_JOB: 'An error occured while applying the job',
      OVERLAP: 'There is already an execution running that overlaps the chosen period',
    },
    AUTO_FLEXI_JOB_CREATED: 'The automatic reservation generation has been successfully launched',
    AUTO_FLEXI_JOB_APPLIED: 'Automatically generated reservations have been successfully applied',
  },
  MAX_SEATS: 'Maximum number of seats',
}
