import PLANNING_WORK_MODE from './planning-work-mode/fr'

export default {
  hi: 'Bonjour',
  USER_PROFIL: "Profil de l'utilisateur",
  SIGNOUT: 'Se déconnecter',
  MYPROFIL: 'Mon profil',
  INFO: 'Paramètres du compte et plus',
  UPDATE: 'mettre à jour',
  Subscription: 'Abonnement',
  DESC: 'description',
  PLANNING_WORK_MODE,
}
