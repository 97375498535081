import { HttpClient, HttpParams } from '@angular/common/http'
import { Inject, Injectable, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, forkJoin, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { environment } from '../../../../environments/environment'
import { ITableState, TableResponseModel } from '../../../_metronic/shared/crud-table'
import { TableActivityService } from '../../../_metronic/shared/crud-table/services/table.activity.service'
import { baseFilter } from '../../../common/helpers/http-extenstions'
import { Activity } from '../_models/activity.models'

@Injectable({
  providedIn: 'root',
})
export class ActivityService extends TableActivityService<Activity> implements OnDestroy {
  // protected authLocalStorageToken = ${environment.appVersion}-${environment.USERDATA_KEY};
  API_URL = `${environment.apiUrl}/rendezvous/getrendezvous`
  API_URLinfo = `${environment.apiUrl}/rendezvous/dashbord`
  API_URLn = `${environment.apiUrl}/rendezvous/countnotification`
  API_URLgetnotification = `${environment.apiUrl}/notification/get-notification-by-user`

  constructor(@Inject(HttpClient) http, private router: Router) {
    super(http)
    this.findinfo()
    this.findinfo2()
    // this.findinfototal();
  }

  find(TableActivityService: ITableState): Observable<TableResponseModel<Activity>> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(`${this.API_URL}`, { params }).pipe(
      map((response: Activity[]) => {
        const filteredResult = baseFilter(response, TableActivityService)
        const result: TableResponseModel<Activity> = {
          items: filteredResult.items,
          total: filteredResult.total,
        }
        return result
      })
    )
  }

  findinfo(): Observable<any> {
    /* this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/auth/login'])
    })
    */
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    if (sessionid != null) {
      return this.http.get<any>(`${this.API_URLinfo}`, { params })
    } else {
      const lang = localStorage.getItem('language')

      localStorage.removeItem('role')
      localStorage.removeItem('resequipe')
      localStorage.removeItem('ressite')
      localStorage.removeItem('resservice')

      localStorage.removeItem('1211z')
      localStorage.removeItem('T')
      localStorage.removeItem('uuid')
      localStorage.removeItem('email')

      localStorage.removeItem('name')
      localStorage.removeItem('foo')

      sessionStorage.clear()
    }
  }

  findinfo2(): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    if (sessionid != null) {
      return
    } else {
      const lang = localStorage.getItem('language')

      localStorage.removeItem('role')
      localStorage.removeItem('resequipe')
      localStorage.removeItem('ressite')
      localStorage.removeItem('resservice')

      localStorage.removeItem('1211z')
      localStorage.removeItem('T')
      localStorage.removeItem('uuid')
      localStorage.removeItem('email')

      localStorage.removeItem('name')
      localStorage.removeItem('foo')

      sessionStorage.clear()
    }
  }

  nbrenotifications(): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    return this.http.get<any>(`${this.API_URLn}`, { params }).pipe(
      catchError(err => {
        // console.error('FIND ITEMS', err);
        if (err.status == 401) {
          window.location.reload()
        }
        return of({ items: [], total: 0 })
      })
    )
  }
  updatenotifications(): Observable<any> {
    //
    let sessionid = this.getAuthFromLocalStorage()
    let API_URLupdate = `${environment.apiUrl}/rendezvous/updatenotification?sessionid=${sessionid}`

    const params = new HttpParams().set('sessionid', sessionid)

    return this.http.put<any>(`${API_URLupdate}`, { params })
  }

  getUserNotifications(): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    return this.http.get<any>(`${this.API_URLgetnotification}`, { params })
  }

  deleteItems(ids: number[] = []): Observable<any> {
    const tasks$ = []
    ids.forEach(id => {
      tasks$.push(this.delete(ids))
    })
    return forkJoin(tasks$)
  }

  // private methods

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe())
  }
}
