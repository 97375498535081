import { HttpClient, HttpParams } from '@angular/common/http'
import { Inject, Injectable, OnDestroy } from '@angular/core'
import { Observable, forkJoin } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../../../environments/environment'
import { ITableState, TableResponseModel } from '../../../_metronic/shared/crud-table'
import { TableSiteService } from '../../../_metronic/shared/crud-table/services/table.site.service'
import { baseFilter } from '../../../common/helpers/http-extenstions'
import { Site } from '../_models/site.models'

@Injectable({
  providedIn: 'root',
})
export class SiteService extends TableSiteService<Site> implements OnDestroy {
  // protected authLocalStorageToken = ${environment.appVersion}-${environment.USERDATA_KEY};
  API_URL = `${environment.apiUrl}/site/getsites`
  API_URLTX_PARTICIPATIONS = `${environment.apiUrl}/todo/tx_participation_service`

  API_URServicesBySite = `${environment.apiUrl}/site/getservicesByidsite`

  API_URLLE = `${environment.apiUrl}/site/getsites`
  API_URLLE1 = `${environment.apiUrl}/site/getusersforEquipe`
  API_URLLE2 = `${environment.apiUrl}/site/getusersforService`
  API_URLLE3 = `${environment.apiUrl}/site/getusersforSite`
  API_URLLE5 = `${environment.apiUrl}/todo/allusers`

  API_URLLE4 = `${environment.apiUrl}/todo/autocomplete_ville`
  API_URLLE6 = `${environment.apiUrl}/todo/autosuggest`
  API_URLLEsite = `${environment.apiUrl}/site/site_user_cncte`
  API_URLAmbassadeur = `${environment.apiUrl}/site/getAmbassadeurForSite`
  API_URLKEYEXTERNE = `${environment.apiUrl}/site/addApikeyExterne`
  API_URLKEYEXTERNE_DELETE = `${environment.apiUrl}/site/deleteApikeyExterne`
  API_URLGetKeyExterne = `${environment.apiUrl}/site/getApikeyExterne`
  API_URLCONVENTION = `${environment.apiUrl}/site/conventions-collectives`

  constructor(@Inject(HttpClient) http) {
    super(http)
  }
  find(tableSiteService: ITableState): Observable<TableResponseModel<Site>> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(`${this.API_URL}`, { params }).pipe(
      map((response: Site[]) => {
        const filteredResult = baseFilter(response, tableSiteService)
        const result: TableResponseModel<Site> = {
          items: filteredResult.items,
          total: filteredResult.total,
        }
        return result
      })
    )
  }
  // Récupérer la liste des conventions collectives par pays
  getIDCCListByCountry(pays: string): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()
    return this.http.get<[]>(`${this.API_URLCONVENTION}?sessionid=${sessionid}&pays=${pays}`)

  }

  // Récupérer le titre de la convention collective par IDCC
  getIDCCDetail(idcc: string): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()
    return this.http.get<[]>(`${this.API_URLCONVENTION}?sessionid=${sessionid}&idcc=${idcc}`)
    //return this.http.get(`${this.API_URLCONVENTION}`, { params: { idcc } })
  }
  deleteItems(ids: number[] = []): Observable<any> {
    const tasks$ = []
    ids.forEach(id => {
      tasks$.push(this.delete(id))
    })
    return forkJoin(tasks$)
  }
  getservicesBySite(id): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()
    // const url = `${this.API_URLss1}uuid=${item[0].uuid}`
    return this.http.get<[]>(`${this.API_URServicesBySite}?sessionid=${sessionid}&idsite=${id}`)
  }

  //tx_participation_servicebysite
  getparticipationBySite(id): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()
    // const url = `${this.API_URLss1}uuid=${item[0].uuid}`
    return this.http.get<any>(`${this.API_URLTX_PARTICIPATIONS}?sessionid=${sessionid}&idsite=${id}`)
  }
  // private methods
  GetSitesList(): Observable<[]> {
    let sessionid = this.getAuthFromLocalStorage()
    // const url = `${this.API_URLss1}uuid=${item[0].uuid}`
    return this.http.get<[]>(`${this.API_URLLE}?sessionid=${sessionid}`)
  }
  getusersforEquipe(id): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()
    // const url = `${this.API_URLss1}uuid=${item[0].uuid}`
    return this.http.get<[]>(`${this.API_URLLE1}?sessionid=${sessionid}&idequipe=${id}`)
  }
  getusersforService(id): Observable<[]> {
    let sessionid = this.getAuthFromLocalStorage()
    // const url = `${this.API_URLss1}uuid=${item[0].uuid}`
    return this.http.get<[]>(`${this.API_URLLE2}?sessionid=${sessionid}&ids=${id}`)
  }

  getusersforSite(id): Observable<[]> {
    let sessionid = this.getAuthFromLocalStorage()
    if (id == undefined) {
      return this.http.get<[]>(`${this.API_URLLE3}?sessionid=${sessionid}`)
    } else {
      // const url = `${this.API_URLss1}uuid=${item[0].uuid}`
      return this.http.get<[]>(`${this.API_URLLE3}?sessionid=${sessionid}&ids=${id}`)
    }
  }
  getusersforEntreprise(): Observable<[]> {
    let sessionid = this.getAuthFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid)

    return this.http.get<any>(this.API_URLLE5, { params })
  }
  getauto(item: any): Observable<any> {
    // let sessionid= this.getAuthFromLocalStorage();
    // const url = `${this.API_URLss1}uuid=${item[0].uuid}`(this.API_URLe, item,{params}
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    return this.http.post<any>(this.API_URLLE4, item, { params })
  }
  getautoRes(item: any): Observable<any> {
    // let sessionid= this.getAuthFromLocalStorage();
    // const url = `${this.API_URLss1}uuid=${item[0].uuid}`(this.API_URLe, item,{params}
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    return this.http.post<any>(this.API_URLLE6, item, { params })
  }
  siteusercncte(): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(this.API_URLLEsite, { params })
  }
  fetchData(item: string): Observable<any> {
    // let sessionid= this.getAuthFromLocalStorage();
    // const url = `${this.API_URLss1}uuid=${item[0].uuid}`(this.API_URLe, item,{params}
    let sessionid = this.getAuthFromLocalStorage()

    let countrycode = localStorage.getItem('pays')
    const params = new HttpParams().set('sessionid', sessionid).set('countrycode', countrycode)

    return this.http.post<any>(this.API_URLLE6, { q: item }, { params })
  }
  findListAmbassadeurs(site_id: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid).set('site_id', site_id)

    return this.http.get<any>(`${this.API_URLAmbassadeur}`, { params })
  }
  //fonction to add api key externe to entreprise
  genererkeyExterne(): Observable<any> {
    // let sessionid= this.getAuthFromLocalStorage();
    // const url = `${this.API_URLss1}uuid=${item[0].uuid}`(this.API_URLe, item,{params}
    let sessionid = this.getAuthFromLocalStorage()
    //set sessionid in params
    //need session valide to add api key
    const params = new HttpParams().set('sessionid', sessionid)

    return this.http.post<any>(this.API_URLKEYEXTERNE, null, { params })
  }
  supprimerkeyExterne(): Observable<any> {
    // let sessionid= this.getAuthFromLocalStorage();
    // const url = `${this.API_URLss1}uuid=${item[0].uuid}`(this.API_URLe, item,{params}
    let sessionid = this.getAuthFromLocalStorage()
    //set sessionid in params
    //need session valide to add api key
    const params = new HttpParams().set('sessionid', sessionid)

    return this.http.post<any>(this.API_URLKEYEXTERNE_DELETE, null, { params })
  }

  //fonction permet de recuperer key externe de entreprise
  getEntrepriseKeyExterne(): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    return this.http.get<any>(`${this.API_URLGetKeyExterne}`, { params })
  }
  //funtion to modifier email_bienvenue de l'entreprise
  modifierEmailBienvenue(email_bienvenue: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    let body = {
      email_bienvenue,
    }

    return this.http.put<any>(`${environment.apiUrl}/site/modifEntreprise`, body, { params })
  }

  //finction to send to user email bienvenue automatique qui sont inscrit et pas encore recu email de bienvenue
  Paremetre_sendEmailBienvenue(): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid).set('email_bienvenue', '1')

    return this.http.get<any>(`${environment.apiUrl}/todo/Paremetrer_sendEmailBienvenue`, { params })
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe())
  }
}
