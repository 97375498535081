<div class="card card-custom gutter-b">
  <div
    class="card-header"
    style="
      background-color: rgba(255, 255, 255, 0.5);
      background-image: url('/assets/test_nouveaux_logos/park.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
    "
  >
    <div style="flex: 5"></div>
    <div class="card-title" style="flex-grow: 5; text-align: center">
      <h3 class="card-label">Mes réservations</h3>
    </div>
    <div class="card-toolbar">
      <button type="button" class="btn btn-primary" (click)="openReservationModal()" style="margin-right: 10px">
        {{ "ADMINPARKING.listParking.button2" | translate }}
      </button>
    </div>
  </div>
  <div class="card-body">
    <!-- begin: Filtration form -->
    <div class="form form-label-right">
      <div class="form-group row"></div>
    </div>
    <!-- end: Filtration form -->

    <!-- begin: Grouping -->

    <!-- end: Grouping -->

    <!-- begin:Table -->
    <div class="table-responsive angular-bootstrap-table">
      <table class="table table-head-custom table-vertical-center overflow-hidden">
        <thead>
          <th>Site</th>
          <th>Date</th>
          <th>{{ "ADMINPARKING.modalReservation.column1" | translate }}</th>
          <th>{{ "ADMINPARKING.listParking.column1" | translate }}</th>
          <th>{{ "ADMINPARKING.listParking.column2" | translate }}</th>
          <th>{{ "ADMINPARKING.modalReservation.column5" | translate }}</th>
        </thead>
        <tbody>
          <tr *ngFor="let R of listReservations">
            <td>{{ R.name }}</td>
            <td>{{ R.date | localizedDate }}</td>
            <td>{{ R.date | date : "HH:mm" }} - {{ R.date_fin | date : "HH:mm" }}</td>
            <td>{{ R.code }}</td>
            <td *ngIf="R.type === 1">Voiture</td>
            <td *ngIf="R.type === 2">Velo</td>
            <td *ngIf="R.type === 3">Moto</td>
            <td>
              <i class="fa fa-wheelchair" aria-hidden="true" *ngIf="R.handicap == 1" style="margin-right: 10px"></i>
              <i class="fa fa-bolt" aria-hidden="true" *ngIf="R.electrique == 1" style="margin-right: 10px"></i>
              <i class="fa fa-car" aria-hidden="true" *ngIf="R.covoiturage == 1" style="margin-right: 10px"></i>
              <i class="fa fa-times" aria-hidden="true" *ngIf="R.non_reservable == 1" style="margin-right: 10px"></i>
            </td>

            <td>
              <div class="col-lg-6">
                <a
                  [title]="'EVENT.icons.deleteEvent' | translate"
                  class="btn btn-icon btn-light btn-hover-danger btn-sm"
                  (click)="deleteReservation(R.reservation_uuid)"
                >
                  <span
                    style="pointer-events: none"
                    [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'"
                    [cacheSVG]="true"
                    class="svg-icon svg-icon-md svg-icon-danger"
                  >
                  </span>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- end: Table -->
  </div>
</div>
