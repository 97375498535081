import { CommonModule, DatePipe } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatChipsModule } from '@angular/material/chips'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { RouterModule } from '@angular/router'
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap'
import { NgxChartsModule } from '@swimlane/ngx-charts'
import { NgApexchartsModule } from 'ng-apexcharts'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { BarRatingModule } from 'ngx-bar-rating'
import { NgxEditorModule } from 'ngx-editor'
import { CRUDTableModule } from 'src/app/_metronic/shared/crud-table'
import { SurveyActivateModalComponent } from 'src/app/modules/qsp/survey-activate/survey-activate-modal/survey-activate-modal.component'
import { SurveyActivateComponent } from 'src/app/modules/qsp/survey-activate/survey-activate.component'
import { SurveyFillComponent } from 'src/app/modules/qsp/survey-fill/survey-fill.component'
import { SurveyModalComponent } from 'src/app/modules/qsp/survey-modal/survey-modal.component'
import { SurveyResultsComponent } from 'src/app/modules/qsp/survey-results/survey-results.component'
import { SurveyComponent } from 'src/app/modules/qsp/survey/survey.component'
import { CustomPipesModule } from '../shared/custom-pipes/custom-pipes.module'
import { TranslationModule } from './../i18n/translation.module'
import { MySurveysComponent } from './my-surveys/my-surveys.component'
import { SurveyReportingComponent } from './survey-reporting/survey-reporting.component'

@NgModule({
  declarations: [
    SurveyComponent,
    SurveyResultsComponent,
    SurveyActivateComponent,
    SurveyModalComponent,
    SurveyActivateModalComponent,
    SurveyFillComponent,
    MySurveysComponent,
    SurveyReportingComponent,
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    BarRatingModule,
    NgxChartsModule,
    CustomPipesModule,
    MatSelectModule,
    RouterModule,
    TranslationModule,
    ReactiveFormsModule,
    FormsModule,
    MatRadioModule,
    NgbTooltipModule,
    CRUDTableModule,
    NgApexchartsModule,
    InlineSVGModule,
    MatChipsModule,
    NgxEditorModule,
  ],
  providers: [DatePipe],
  exports: [RouterModule, SurveyReportingComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class QuestionnairesModule {}
