export default {
  listIdea: {
    title: 'Ideas box',
    searchInput: 'Search',
    filter: 'Filter by status',
    button1: 'Add new idea',
    button2: 'J\'aime',
    button3: 'Commenter',
    opt1: 'All',
    opt2: 'Answered',
    opt3: 'Not answered',
    opt4: 'Deleted',
    button: 'Deleted',
    LISTIDEA: 'List of ideas',
  },

  listComments: {
    title: 'Comments',
    input1: 'Add comment',
    button1: 'Reply',
    user: 'User'
  },

  card: {
    button1: 'Like',
    button2: 'Comment',
    text1: 'Status',
  },

  reponse: {
    opt1: "Refused",
    opt2: "Accepted at short notice",
    opt3: "Accepted in the medium term",
    opt4: "Accepted long term"
  }
}
