import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(value: string, scope: string = ''): string {
    if (!value) {
      return value;
    }

    if (scope === 'word') {
      return this.capitalizeWords(value);
    } else {
      return this.capitalizeFirstLetter(value);
    }
  }

  private capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  private capitalizeWords(str: string): string {
    return str.replace(/\b\w/g, (match) => {
      return match.toUpperCase();
    });
  }
}