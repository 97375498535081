export default {
  REMOTE: 'Télétravail',
  IN_OFFICE: 'Présentiel',
  COWORKING: 'Coworking',
  ABSENT: 'Absent',
  WORK_MODE: 'Mode de travail',
  ERROR_ADD_ON_DAY_OFF: 'La date sélectionnée coïncide avec un congé',
  CALENDAR_NOTES:
    'Pour modifier un badge de mode de travail, cliquez dessus ou ajoutez-en un nouveau en cliquant sur une date libre.',
}
