import { Component, Input } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { reserveSeatErrorHandler } from '../../os-modeling/errorHandler'
import { OsModelingService } from '../../os-modeling/os-modeling-service.service'

@Component({
  selector: 'app-osp-reserve-guest-popup',
  templateUrl: './osp-reserve-guest-popup.component.html',
  styleUrls: ['./osp-reserve-guest-popup.component.scss'],
})
export class OspReserveGuestPopupComponent {
  constructor(
    public modal: NgbActiveModal,
    private t: TranslateService,
    private svc: OsModelingService,
    private toastr: ToastrService
  ) {}

  isLoading = false
  firstName = ''
  lastName = ''
  email = ''
  phone = ''
  errMsg = ''
  @Input() date
  @Input() timeStart
  @Input() timeEnd
  @Input() seatId
  @Input() floorId
  @Input() buildingId

  submit() {
    this.errMsg = ''
    if (!this.firstName) return (this.errMsg = this.t.instant('OSPLANNER.PLEASE_ENTER_FIRST_NAME'))
    if (!this.lastName) return (this.errMsg = this.t.instant('OSPLANNER.PLEASE_ENTER_LAST_NAME'))
    this.isLoading = true
    this.svc
      .reserveSeatForGuest(this.seatId, this.floorId, this.buildingId, this.date, this.timeStart, this.timeEnd, {
        fname: this.firstName,
        lname: this.lastName,
      })
      .subscribe({
        next: res => {
          this.isLoading = false
          this.modal.close(res)
        },
        error: err => {
          this.isLoading = false
          this.errMsg = "Impossible d'effectuer la réservation"
          console.info(err, err.error)
          reserveSeatErrorHandler(err, this.toastr)
        },
      })
  }
}
