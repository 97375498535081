import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-osplanner',
  templateUrl: './osplanner.component.html',
  styleUrls: ['./osplanner.component.scss'],
})
export class OsplannerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
