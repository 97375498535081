import { CdkDrag, CdkDragEnd, CdkDragMove, CdkDragStart, Point } from '@angular/cdk/drag-drop'
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core'
import { Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ToastrService } from 'ngx-toastr'
import { PanZoom } from 'panzoom'
import { forkJoin } from 'rxjs'
import { getUserUuidFromLocalStorage } from 'src/app/utils/getSessionFromLocalStorage'
import { imgEncoder } from 'src/app/utils/imgEncoder'
import { environment } from 'src/environments/environment'
import { v4 } from 'uuid'
import { AuthService } from '../../auth'
import { UserService } from '../../collaborateurs/_services'
import { ClosedModalComponent } from '../../communities/gerer/closed-modal/closed-modal.component'
import { PromptModalComponent } from '../../shared/prompt-modal/prompt-modal.component'
import { ReservationModalComponent } from '../../user-profile/transport/reservation-modal/reservation-modal.component'
import { OspAutoflexiPopupComponent } from '../manage-reservations-admin/osp-autoflexi-popup/osp-autoflexi-popup.component'
import { OspReserveGuestPopupComponent } from '../manage-reservations-admin/osp-reserve-guest-popup/osp-reserve-guest-popup.component'
import { DELETE_BUILDING_PROMPT_DATA, DELETE_FLOOR_PROMPT_DATA, assetFeatures } from '../utils/consts'
import { canExit, preventObjectAnimationOnLoad, pzInit } from '../utils/events'
import { Building, Desk, Floor, GraphicalObject, GraphicalObjectTypes, MovableObject, Seat } from '../utils/types'
import { doesOverlapWithDesk, keepFloorTilesInBounds, toggleSideBarMenu } from '../utils/utils'
import { ParkingService } from './../_services/parking.service'
import { UserCarpoolListModalComponent } from './../standalone/user-carpool-list-modal/user-carpool-list-modal.component'
import { reserveSeatErrorHandler } from './errorHandler'
import { NewBuildingModalComponent } from './new-building-modal/new-building-modal.component'
import { EquipeT, OsModelingService, ServiceT, SiteT } from './os-modeling-service.service'

@Component({
  selector: 'app-os-modeling',
  templateUrl: './os-modeling.component.html',
  styleUrls: ['./os-modeling.component.scss'],
  encapsulation: ViewEncapsulation.None, // required for the context menu to work properly
})
export class OsModelingComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {
  @Input() mode: 'modeling' | 'reservation' = 'modeling'

  get isAdminMode() {
    return this.router.url === '/osplanner/manage/reservations'
  }

  @ViewChild('floor', { static: false }) floorElRef: ElementRef<HTMLDivElement>
  get floorEl() {
    return this.floorElRef?.nativeElement
  }

  openParkingList() {
    const modalRef = this.modalService.open(UserCarpoolListModalComponent, {
      size: 'lg',
    })
    this.parkingService.startTime = this.resvTimeStart
    this.parkingService.endTime = this.resvTimeEnd
    this.parkingService.resvDate = this.selectedReservationDate
  }

  openCarpoolList(skip?: boolean) {
    const disabledPopup = localStorage.getItem('FLEXI-COV-PARK-disablePopup') == 'true'
    if (!skip && disabledPopup) return
    const modalRef = this.modalService.open(ReservationModalComponent, { size: 'lg' })
    modalRef.componentInstance.resvDate = this.selectedReservationDate
    modalRef.componentInstance.resvTimeStart = this.resvTimeStart
    modalRef.componentInstance.resvTimeEnd = this.resvTimeEnd
    modalRef.result.then(() => {})
  }

  openAutoFlexiPopup() {
    const modalRef = this.modalService.open(OspAutoflexiPopupComponent, { size: 'xl' })
    modalRef.componentInstance.siteId = this.siteId
    modalRef.componentInstance.buildingId = this.building.id
    modalRef.componentInstance.sites = this.sites
    modalRef.componentInstance.buildings = this.buildings
    modalRef.result.then(() => {})
  }

  deleteCarpool() {
    // Add your logic to delete the carpool
  }

  panZoomController: PanZoom
  isPanZoomInitialized = false
  sites: SiteT[] = []
  services: ServiceT[] = []
  equipes: EquipeT[] = []

  spaceHistory = []
  readonly maxHistory = 200

  isUntouched = true
  isLoading = true
  showLeftSideMenu = true

  currentRoute = ''
  get isReservationMode() {
    return this.currentRoute === '/osplanner/reservation' || this.currentRoute === '/osplanner/manage/reservations'
  }
  get isModelingMode() {
    return this.currentRoute === '/osplanner/modeling'
  }
  get isManagerMode() {
    return this.currentRoute === '/osplanner/manage/reservations'
  }

  siteId: number
  building: Building
  buildings: Building[] = []
  _floorData: Floor
  get siteBuildings() {
    return this.buildings.filter(building => building.site_id === this.siteId)
  }

  tableOption: any = []
  flexiAbonnement = false
  parckingAbonnement = false
  covoiturageAbonnement = false
  constructor(
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private svc: OsModelingService,
    private router: Router,
    private parkingService: ParkingService,
    public userService: UserService,
    public ah: AuthService
  ) {
    this.currentRoute = this.router.url
  }

  ngAfterViewChecked(): void {
    if (!this.isPanZoomInitialized && this.floorEl && this.floorEl && this._floorData) {
      this.initPanZoom()
    }
  }
  protected getAuthFromLocalStorage(): any {
    try {
      const authData = JSON.parse(localStorage.getItem(this.authLocalStorageToken))
      return authData
    } catch (error) {
      console.error(error)
      return undefined
    }
  }
  abonnement: any
  protected authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`
  ngOnInit(): void {
    let sessionid = this.getAuthFromLocalStorage()
    toggleSideBarMenu()
    window.addEventListener('beforeunload', this.onBeforeUnloadHandler)
    this.ah.getUserByToken2(sessionid).subscribe(data => {
      this.abonnement = data[0]
      this.cdr.detectChanges()
    })
    this.userService.getEntrepriseAbonnement().subscribe(data => {
      for (let i = 0; i < data.length; i++) {
        this.tableOption.push(data[i].code_option)
        this.cdr.detectChanges()
      }

      if (this.tableOption.includes('MOSP')) {
        this.flexiAbonnement = true
        this.fetchGeneralData()
      }
      if (this.tableOption.includes('MCOV')) {
        this.covoiturageAbonnement = true
      }
      if (this.tableOption.includes('MPAR')) {
        this.parckingAbonnement = true
      } else {
        this.closedModal()
      }

      this.cdr.detectChanges()
    })
  }

  closedModal() {
    this.modalService.open(ClosedModalComponent)
  }

  fetchGeneralData(callback?: () => void) {
    this.svc.fetchAllData().subscribe({
      next: data => {
        this.processFetchedData(data)
        callback && callback()

        this.svc.getReservations(this.selectedReservationDate, this.resvTimeStart, this.resvTimeEnd).subscribe({
          next: data => {
            this.processReservationData(data)
            this.isLoading = false
            this.isLoadingSpace = false
            this.isPanZoomInitialized = false
            this.initPanZoom()
            this.cdr.detectChanges()
          },
          error: err => {
            this.toastr.error(err.message)
            console.error(err)
          },
        })
      },
      error: err => {
        this.toastr.error(err.message)
        console.error(err)
      },
    })
  }

  processFetchedData([{ sites, services, equipes }, buildings, user, occupancies, carpooling, parkings]) {
    // console.info(carpooling)
    occupancies = occupancies.map(occupancy => {
      return {
        ...occupancy,
        hasCarpool: carpooling.some(carpool => carpool.reservation_date === occupancy.date),
        hasParking: parkings.some(parking => parking.date.slice(0, 10) === occupancy.date),
      }
    })

    this.currentUser = user
    this.sites = sites
    this.services = services
    this.equipes = equipes
    this.buildings = buildings
    this._reservableDays = occupancies
    this.selectedReservationDate = this.selectedReservationDate || this._reservableDays[0].date

    this.siteId = this.siteId || this.currentUser.ids
    const buildingId = this.building?.id
    const floorId = this._floorData?.id

    if (this.isReservationMode) {
      this.building = this.buildings.find(x => x?.id === buildingId) || this.siteBuildings?.[0]
      this._floorData = this.building?.floors
        ? this.building.floors.find(x => x?.id === floorId) || this.building?.floors?.[0]
        : null
    } else {
      this.building = this.buildings.find(building => building.site_id === this.siteId)
      this._floorData =
        (this.building?.floors || []).find(floor => floor?.id === this._floorData?.id) || this.building?.floors?.[0]
    }

    if (this.isReservationMode && this.panZoomController && this.panZoomController.getTransform() && this._floorData) {
      this._floorData.panzoom = this.panZoomController.getTransform()
    }

    this.cdr.detectChanges()
  }

  hoveredSeatId: string = ''

  onSeatMouseEnter(seatId: string) {
    this.hoveredSeatId = seatId
    this.cdr.detectChanges()
    console.info('called onSeatMouseEnter with id : ', seatId)
  }
  onSeatMouseLeave() {
    this.hoveredSeatId = null
  }

  fetchReservationData(cb?: () => void) {
    forkJoin([
      this.svc.getReservations(this.selectedReservationDate, this.resvTimeStart, this.resvTimeEnd),
      this.reservableDays.length ? this.svc.getTeamOccupancies() : null,
    ]).subscribe({
      next: ([reservations, occupancies]) => {
        this._reservableDays = occupancies
        this.processReservationData(reservations)
        cb && cb()
        this.isLoading = false
        this.isLoadingSpace = false
        this.isPanZoomInitialized = false
        this.initPanZoom()
        this.cdr.detectChanges()
      },
      error: err => {
        this.toastr.error(err.message)
        console.error(err)
      },
    })
  }

  processReservationData(_reservations) {
    // transform all seats into Seat classes and add reservations to them
    this.buildings.forEach(building => {
      building.floors.forEach(floor => {
        floor.desks.forEach(desk => {
          !desk.seats && (desk.seats = [])
          desk.seats = desk.seats.map(seat => {
            const reservations = _reservations[seat.id] || []
            return new Seat({ ...seat, reservations })
          })
        })
      })
    })
  }

  isLoadingSpace = false

  toDate(date: string): Date {
    return new Date(date)
  }

  saveHistory() {
    if (this.spaceHistory.length > this.maxHistory) this.spaceHistory.shift()
    if (!this._floorData) return
    try {
      this.spaceHistory.push(JSON.parse(JSON.stringify(this._floorData)))
      this.isUntouched = true
    } catch (error) {
      console.log(error)
      this.toastr.error("Une erreur s'est produite lors de la sauvegarde de l'espace")
    }
  }

  undo() {
    if (this.spaceHistory.length > 0) {
      this._floorData = this.spaceHistory.pop()
      this.cdr.detectChanges()
    }
  }
  saveBuilding() {
    this.building.floors.forEach(floor => {
      floor.desks.forEach(desk => {
        desk.seats.forEach(seat => {
          console.log('SEAAT', seat)
        })
      })
    })
    this.svc.saveBuilding(this.building).subscribe(() => {
      this.toastr.success('Enregistré avec succès')
      this.initPanZoom()
    })
  }

  save() {
    this._floorData.panzoom = this.panZoomController.getTransform()
    this.saveHistory()
    this.saveCurrentStateToLocalStorage()
    this.saveBuilding()
  }

  saveCurrentStateToLocalStorage() {
    this._floorData.panzoom = this.panZoomController.getTransform()
    // save the current state of site, building and floor to local storage
    localStorage.setItem(
      'osplanner_' + (this.isModelingMode ? 'modeling' : 'reservation'),
      JSON.stringify({ siteId: this.siteId, building: this.building, floor: this._floorData })
    )
  }

  loadCurrentStateFromLocalStorage() {
    // load the current state of site, building and floor from local storage
    try {
      const state = JSON.parse(localStorage.getItem('osplanner_' + (this.isModelingMode ? 'modeling' : 'reservation')))
      this.siteId = state.siteId
      this.building = state.building
      this._floorData = this.building?.floors?.[0]
      this.cdr.detectChanges()
    } catch (error) {
      console.error('Error loading state from local storage', error)
    }
  }

  encodeImgUrl(imgUrl: string) {
    return imgEncoder(imgUrl)
  }

  addBuilding() {
    const modalRef = this.modalService.open(NewBuildingModalComponent)
    modalRef.componentInstance.buildings = this.buildings
    modalRef.componentInstance.siteId = this.siteId
    modalRef.result.then((building: Building) => {
      this.building = building
      this.buildings.push(building)
      this._floorData = null
      this.cdr.detectChanges()
      this.toastr.success('Bâtiment ajouté avec succès')
    })
  }

  removeBuilding() {
    const modalRef = this.modalService.open(PromptModalComponent)
    modalRef.componentInstance.data = DELETE_BUILDING_PROMPT_DATA
    modalRef.result.then(() => {
      this.svc.deleteBuilding(this.building.id).subscribe({
        next: () => {
          this.toastr.success('Bâtiment supprimé avec succès')
          this.buildings = this.buildings.filter(building => building.id !== this.building.id)
          this.building = this.siteBuildings[0]
          this._floorData = this.building?.floors?.[0]
        },
        error: err => {
          this.toastr.error('Erreur lors de la suppression du bâtiment')
        },
      })
    })
  }

  addFloor() {
    const floor = new Floor(`Étage ${this.building.floors.length + 1}`)
    this.building.floors.push(floor)
    const buildingId = this.building.id
    const floorId = floor.id
    this.isLoadingSpace = true
    this.svc.saveBuilding(this.building).subscribe({
      next: () => {
        this.fetchGeneralData(() => {
          this.toastr.success('Étage ajouté avec succès')
          this.building = this.buildings.find(building => building.id === buildingId)
          this._floorData = this.building.floors.find(floor => floor.id === floorId)
          this.selectedObject = null
          this.cdr.detectChanges()
        })
      },
      error: err => {
        this.toastr.error("Erreur lors de la création de l'étage")
        this.building.floors = this.building.floors.filter(floor => floor.id !== floor.id)
      },
    })
  }

  removeFloor() {
    const modalRef = this.modalService.open(PromptModalComponent)
    modalRef.componentInstance.data = DELETE_FLOOR_PROMPT_DATA
    modalRef.result.then(() => {
      this.building.floors = this.building.floors.filter(floor => floor.id !== this._floorData.id)
      this.svc.saveBuilding(this.building).subscribe({
        next: () => {
          this.toastr.success('Étage supprimé avec succès')
          this.building.floors = this.building.floors.filter(floor => floor.id !== this._floorData.id)
          this._floorData = this.building?.floors?.[0]
          this.selectedObject = null
          this.cdr.detectChanges()
        },
        error: err => {
          this.toastr.error("Erreur lors de la suppression de l'étage")
        },
      })
    })
  }

  canExit(): Promise<boolean> {
    return canExit(this.isUntouched)
  }

  ngAfterViewInit() {
    this.floorEl && this.initPanZoom()
    document.addEventListener('click', this.storedDocumentClickHandler, { capture: false })
  }

  storedDocumentClickHandler = () => {
    this.selectedSeatId = null
    this.cdr.detectChanges()
  }

  currentUser

  selectedReservationDate: string
  resvTimeStart: string = '09:00'
  resvTimeEnd: string = '18:00'

  _reservableDays = []
  get reservableDays() {
    return this._reservableDays
  }

  resetResvTime() {
    this.resvTimeStart = '00:00'
    this.resvTimeEnd = '23:59'
  }

  refetchTodayReservations() {
    this.isLoadingSpace = true
    this.resetResvTime()
    this.fetchReservationData()
  }

  selectReservationDate(date: string) {
    this.selectedReservationDate = date
    this.isLoadingSpace = true
    this.fetchReservationData()
  }

  @ViewChild('startTimeRef') startTimeRef: ElementRef
  @ViewChild('endTimeRef') endTimeRef: ElementRef

  changeResvTimeStart($event) {
    const startTime = new Date(`01/01/2000 ${$event}`)
    const endTime = new Date(`01/01/2000 ${this.resvTimeEnd}`)

    this.resvTimeStart = $event
    this.isLoadingSpace = true
    this.fetchReservationData()
  }

  changeResvTimeEnd($event) {
    const startTime = new Date(`01/01/2000 ${this.resvTimeStart}`)
    const endTime = new Date(`01/01/2000 ${$event}`)
    if (startTime >= endTime) {
      this.toastr.error("L'heure de début ne peut pas dépasser l'heure de fin")
      this.endTimeRef.nativeElement.value = this.resvTimeEnd
      return
    }
    this.resvTimeEnd = $event
    this.isLoadingSpace = true
    this.fetchReservationData()
  }

  initPanZoom() {
    if (!this.floorEl || !this._floorData) return
    const { x, y, scale } = this._floorData.panzoom
    this.panZoomController = pzInit(this.floorEl, scale, this.isReservationMode)
    this.panZoomController.moveTo(x, y)

    // disable adding seats when panning
    this.panZoomController.on('pan', e => (this.isAddingSeats = false))

    this.panZoomController.on('transform', (e: any) => {
      this._floorData.panzoom = e.getTransform()
      const { x, y, scale } = e.getTransform()
      const { width, height } = this._floorData
      // prettier-ignore
      keepFloorTilesInBounds(x, y, scale, width * this.realScaleFactor, height * this.realScaleFactor, this.panZoomController)
    })

    this.selectedObject = null // reset selected object for the context menu
    this.isPanZoomInitialized = true

    setTimeout(() => {
      preventObjectAnimationOnLoad('.desk-box,.seat-circle')
    }, 500)

    this.cdr.detectChanges()
  }

  ngOnDestroy(): void {
    window.removeEventListener('beforeunload', this.onBeforeUnloadHandler)
    window.onbeforeunload = null
    document.removeEventListener('click', this.storedDocumentClickHandler, { capture: true })
    toggleSideBarMenu({ open: true })
  }

  onBeforeUnloadHandler = e => {
    if (this.isUntouched) return
    e.preventDefault()
    e.returnValue = 'PROMPT' // any value triggers the dialog
  }

  /**
   * !VERY IMPORTANT: this function must be an anonymous fn to work properly with "this"
   */
  // prettier-ignore
  constrainPosition= (userPointerPosition: Point, dragRef: CdkDrag, dimensions: ClientRect, 
    pickupPositionInElement: Point) => {
    let zoomMoveXDifference = 0
    let zoomMoveYDifference = 0

    if (this.scale != 1) {
      zoomMoveXDifference = (1 - this.scale) * dragRef.getFreeDragPosition().x
      zoomMoveYDifference = (1 - this.scale) * dragRef.getFreeDragPosition().y
    }
    const newPosition = {
      x: (userPointerPosition.x - pickupPositionInElement.x + zoomMoveXDifference) as number,
      y: (userPointerPosition.y - pickupPositionInElement.y + zoomMoveYDifference) as number,
    }

    return newPosition
  }

  startDeskDragging(event: CdkDragStart, desk: Desk) {
    this.saveHistory()
    this.isUntouched = false
    this.isAddingSeats = false
    const { x, y } = desk.position
    desk.prevposition = {
      ...desk.position,
    }
    const position = {
      x: x * this.scale,
      y: y * this.scale,
    }
    event.source._dragRef.setFreeDragPosition(position)
    ;(event.source._dragRef as any)._activeTransform = desk.position
    ;(event.source._dragRef as any)._applyRootElementTransform(x, y)
  }

  /**
   *  When dragging a desk, we need to move all the seats that are attached to it
   */
  onDeskDragging(event: CdkDragMove, desk: Desk) {
    const diffx = desk.prevposition.x - desk.position.x
    const diffy = desk.prevposition.y - desk.position.y
    desk.seats.forEach((seat: Seat) => {
      seat.position = {
        x: seat.position.x - diffx,
        y: seat.position.y - diffy,
      }
    })
    desk.prevposition = { ...desk.position }
  }

  onSeatDragging(event: CdkDragMove, seat: Seat) {
    const { x, y } = seat.position
    console.log('seat dragging', x, y)
  }

  onSeatDragEnd(event: CdkDragEnd, seat: Seat, desk: Desk) {
    // validate that seat overlaps with its desk
    const { overlap } = doesOverlapWithDesk(seat.position, 105, 105, [desk])
    if (!overlap) {
      seat.position = seat.prevposition
      this.toastr.error('Merci de placer le siège sur son bureau respectif')
    }
  }
  endDeskDragging(event: CdkDragEnd, obj: MovableObject, img: HTMLImageElement) {
    // const overlap = doesOverlapWithDesk(obj, this._floorData.desks)
    // console.info('overlap', overlap, obj.type)
    // if (obj.type === 'desk' && overlap) {
    //   obj.position = (obj as Desk).prevposition
    // }
    // if seat doesn't overlap with its desk, reset its position
    // if (obj.type === 'seat') {
    //   const seat = obj as Seat
    //   const { overlap } = doesOverlapWithDesk(seat.position, 105, 105, [
    //     this._floorData.desks.find(x => x.id === seat.deskId),
    //   ])
    //   if (!overlap) {
    //     seat.position = seat.prevposition
    //   }
    // }
  }

  toastrSelectFloor() {
    this.toastr.info(!this.buildings.length ? 'Veuillez choisir un bâtiment' : 'Veuillez choisir un étage')
  }

  /**
   * This is a workaround for the cursor not changing immediately when pressing ctrl/alt
   */
  @HostListener('window:keyup', ['$event'])
  keyUp(event: KeyboardEvent) {
    if (!this.floorEl) return
    event.preventDefault()
    this.floorEl.style.cursor = 'default'
  }

  /**
   * This is a workaround for the cursor not changing immediately when pressing ctrl/alt
   */
  @HostListener('window:keydown', ['$event'])
  keyDown(event: KeyboardEvent) {
    if (!this.floorEl) return
    // change cursor to grab when ctrl/alt is pressed
    if (event.ctrlKey || event.altKey) {
      setTimeout(() => {
        this.floorEl.style.cursor = 'grab'
        this.cdr.detectChanges()
      }, 10)
    }

    // undo with ctrl+z
    if (event.ctrlKey && event.key === 'z') {
      this.undo()
    }
  }

  realScaleFactor: number = 2
  get scale() {
    return this.panZoomController.getTransform().scale
  }
  isAddingSeats = false

  toggleAddingSeats() {
    this.isAddingSeats = !this.isAddingSeats
  }
  toggleLeftSideMenu() {
    this.showLeftSideMenu = !this.showLeftSideMenu
  }

  onFloorMouseUp(event: any) {
    if (this.isAddingSeats) {
      this.saveHistory()
      this.isUntouched = false
      this.addSeat(event)
    }
  }

  addSeat({ layerX: screenX, layerY: screenY }) {
    let position = this.currentPzScaledPosition
    const { x, y } = this.currentPzScaledPosition
    if (this.scale > 0.5) {
      position = {
        x: x + (screenX - 50 + (1 - this.scale) * 10) / this.scale,
        y: y + (screenY - 50 + (1 - this.scale) * 10) / this.scale,
      }
    } else {
      position = {
        x: x + (screenX - 20 + this.scale * 10) / this.scale,
        y: y + (screenY - 20 + this.scale * 10) / this.scale,
      }
    }

    // check if seat overlaps with a desk
    const { overlap, desk } = doesOverlapWithDesk(position, 105, 105, this._floorData.desks)
    if (!overlap) {
      this.toastr.error('Merci de placer le siège sur un bureau')
      return
    }
    if (desk.maxSeats && desk.seats?.length >= desk.maxSeats) {
      this.toastr.error("Bureau plein. Merci d'augmenter la limite des sièges pour en ajouter plus.")
      return
    }
    const seat = new Seat({ position, deskId: desk.id })
    desk.seats ? desk.seats.push(seat) : (desk.seats = [seat])
    this.cdr.detectChanges()
    return
  }

  /**
   * Utiliy function to create an array with N elements containing numbers from 1 to N (used in the template)
   */
  createArray(n: number): number[] {
    return Array.from({ length: n }, (_, k) => k + 1)
  }

  get randInt() {
    return Math.floor(Math.random() * 1000)
  }

  removeObject(object: GraphicalObject) {
    this.saveHistory()
    this.isUntouched = false
    switch (object.type) {
      case 'wall':
        this._floorData.walls = this._floorData.walls.filter(w => w.id !== object.id)
        break
      case 'desk':
        this._floorData.desks = this._floorData.desks.filter(d => d.id !== object.id)
        break
      case 'seat':
        const desk = this._floorData.desks.find(d => d.id === (object as Seat).deskId)
        desk.seats = desk.seats.filter(s => s.id !== object.id)
        break
      default:
        this._floorData.movables = this._floorData.movables.filter(m => m.id !== object.id)
        break
    }
    return
  }

  addWall(orientation: 'vertical' | 'horizontal') {
    this.saveHistory()
    this.isUntouched = false
    this._floorData.walls.push({
      type: 'wall',
      id: v4(),
      position: this.currentPzScaledPosition,
      width: orientation === 'horizontal' ? 1000 : 50,
      height: orientation === 'horizontal' ? 50 : 1000,
      orientation,
      name: '',
      z: 1,
      class: '',
      image: '',
      rotation: 0,
    })
  }

  get currentPzScaledPosition(): Point {
    const { x, y } = this.panZoomController.getTransform()
    return {
      x: -x / this.scale,
      y: -y / this.scale,
    }
  }

  addObject(type: GraphicalObjectTypes, assetNumber: number) {
    this.saveHistory()
    this.isUntouched = false

    const position = this.currentPzScaledPosition

    let width,
      height = 0
    if (assetFeatures[type]?.length) {
      width = Math.round(assetFeatures[type].find(x => x.assetNumber === assetNumber).width / assetFeatures.sizeFactor)
      height = Math.round(
        assetFeatures[type].find(x => x.assetNumber === assetNumber).height / assetFeatures.sizeFactor
      )
    } else {
      width = type === 'decoration' ? 50 : 100
      height = type === 'decoration' ? 50 : 100
    }

    if (type === 'desk') {
      const desk = new Desk()
      desk.position = position
      desk.assetNumber = assetNumber
      desk.width = width
      desk.height = height
      this._floorData.desks.push(desk)
      return
    }

    const movable = new MovableObject({ type, position, width, height, assetNumber })
    this._floorData.movables.push(movable)
  }

  selectedObject: GraphicalObject | any = null // used for the context menu
  isRightClickedObject = false
  floorRightClickEvent: PointerEvent | null = null

  @ViewChild('ctxMenuTemplate', { static: true }) ctxMenuTemplateRef!: TemplateRef<any>

  contextMenuEvents = {
    deleteObject: () => {
      this.removeObject(this.selectedObject!)
      this.isRightClickedObject = false
      this.cdr.detectChanges()
    },
  }

  getContextMenuTemplate() {
    return this.ctxMenuTemplateRef
  }

  onRightClickObject(event: any, object: GraphicalObject) {
    event.preventDefault()
    this.selectedObject = object
    setTimeout(() => {
      this.isRightClickedObject = true
      this.cdr.detectChanges()
    }, 10)
    this.isAddingSeats = false
    this.selectedSeatId = null
  }

  /**
   * This is used to hide the context menu when the user clicks the floor where there is no object
   */
  onFloorRightClick(event: PointerEvent) {
    this.floorRightClickEvent = event
    this.isRightClickedObject = false
    this.isAddingSeats = false
    this.selectedSeatId = null
  }

  getSelectedObject() {
    return this.selectedObject as GraphicalObject | MovableObject
  }

  //  --------------- TOP ACTIONS BAR ---------------
  changeSite(siteId: number, setFirst = false) {
    this.siteId = siteId
    this.building = setFirst ? this.siteBuildings?.[0] : this.siteBuildings.find(x => x.id === this.building.id)
    this._floorData = setFirst
      ? this.building?.floors?.[0]
      : this.building.floors.find(x => x.id === this._floorData.id)
    this.cdr.detectChanges()
    this.initPanZoom()
  }

  changeBuilding(buildingId: string) {
    this.building = this.siteBuildings.find(x => x?.id === buildingId)
    this._floorData = this.building.floors.find(x => x?.id === this._floorData?.id) || this.building?.floors?.[0]
    this.initPanZoom()
  }

  changeFloor(floorId: string) {
    this._floorData = this.building.floors.find(x => x?.id === floorId) || this.building.floors[0]
    this.initPanZoom()
  }

  changeFloorTile(assetNbr: number) {
    this._floorData.tile = assetNbr
    this.saveHistory()
    this.isUntouched = false
  }
  // --------------- END TOP ACTIONS BAR ---------------

  // --------------- RESERVATION ---------------

  hasMadePlanning(occupancies) {
    return (
      occupancies?.length &&
      occupancies.find(x => x.has_planified && !x.has_reserved && x.user_id === this.currentUser.uuid)
    )
  }

  hasMadeReservation(occupancies) {
    return occupancies?.length && occupancies.find(x => x.has_reserved && x.user_id === this.currentUser.uuid)
  }

  selectedSeatId: string | null = null

  onFloorClick(event: PointerEvent) {
    this.selectedSeatId = null
  }

  onSeatClick(event, seat: Seat) {
    event.stopPropagation()
    if (!this.isReservationMode) return
    if (!this.selectedReservationDate) {
      return this.toastr.error('Veuillez sélectionner une date', 'Erreur', {
        timeOut: 3000,
      })
    }
    if (this.isAdminMode) {
      this.selectedSeatId = seat.id
      return
    }
    if (!seat.isReservedOnDate(this.selectedReservationDate)) {
      this.reserveSeat(seat)
    } else if (seat.isReservedByUser(this.selectedReservationDate, getUserUuidFromLocalStorage())) {
      this.selectedSeatId = seat.id
    } else {
      this.toastr.error('Ce siège est déjà réservé', 'Erreur', {
        timeOut: 3000,
      })
    }
  }

  reserveSeat(seat: Seat) {
    this.isLoadingSpace = true
    // prettier-ignore
    this.svc.reserveSeat(seat.id, this._floorData.id, this.building.id, this.selectedReservationDate, this.resvTimeStart, this.resvTimeEnd).subscribe({
      next: () => {
        this.fetchReservationData(() => {
          this.cdr.detectChanges()
          this.toastr.success('Ce siège a été réservé avec succès', 'Succès', {
            timeOut: 3000,
          })
          this.openCarpoolList()
        })
      },
      error: error => {
        this.fetchReservationData(() => {
          reserveSeatErrorHandler(error, this.toastr, this.services, this.equipes)
          this.cdr.detectChanges()
        })
      },
    })
  }

  cancelReservation(reservationId: string, guestId?: string) {
    this.isLoadingSpace = true
    this.svc.cancelReservation(reservationId, guestId).subscribe({
      next: () => {
        this.fetchReservationData(() => {
          this.cdr.detectChanges()
          this.toastr.success('Cette réservation a été annulée avec succès', 'Succès', {
            timeOut: 3000,
          })
          this.selectedSeatId = null
        })
      },
      error: err => {
        this.fetchReservationData(() => {
          if (err.error.isPastDate) {
            return this.toastr.error('Les modification de réservations dans le passé sont interdites', 'Erreur')
          }
          this.cdr.detectChanges()
          this.toastr.error("Une erreur s'est produite lors de l'annulation de cette réservation", 'Erreur', {
            timeOut: 3000,
          })
          this.selectedSeatId = null
        })
      },
    })
  }

  reserveVisitor(seat: Seat) {
    const modalRef = this.modalService.open(OspReserveGuestPopupComponent, { size: 'lg' })
    modalRef.componentInstance.seatId = seat.id
    modalRef.componentInstance.floorId = this._floorData.id
    modalRef.componentInstance.buildingId = this.building.id
    modalRef.componentInstance.date = this.selectedReservationDate
    modalRef.componentInstance.timeStart = this.resvTimeStart
    modalRef.componentInstance.timeEnd = this.resvTimeEnd

    modalRef.result.then(() => {
      this.fetchReservationData(() => {
        this.cdr.detectChanges()
        this.toastr.success('Ce siège a été réservé avec succès', 'Succès', {
          timeOut: 3000,
        })
      })
    })
  }

  ctxChangeDeskAccessType({ value }: { value: number }) {
    const desk = this.selectedObject as Desk
    this.isUntouched = false
    if (value === 1) {
      desk.service = desk.service || this.services[0].value
      desk.seats.forEach(seat => {
        seat.equipe = desk.equipe
        seat.service = desk.service
        seat.accessType = desk.accesstype
      })
    } else if (value === 2) {
      desk.equipe = desk.equipe || this.equipes[0].value
    }
  }

  // --------------- END RESERVATION ---------------
}

type ContextMenuItem = {
  label: string
  icon: string
  command: (event: any, item: ContextMenuItem) => void
  items?: ContextMenuItem[]
}
