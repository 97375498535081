import { Component, OnInit, ChangeDetectorRef, Optional } from '@angular/core'
import { quizService } from './_services/quiz.service'
import { AuthService, UserModel } from '../../modules/auth'
import { Subscription } from 'rxjs'

type Tabs = 'kt_table_widget_6_tab_1' | 'kt_table_widget_6_tab_2' | 'kt_table_widget_6_tab_3'
@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
})
export class QuizComponent implements OnInit {
  currentUser$: UserModel
  data: any
  assoc_tab1 = []
  non_assoc_tab2 = []

  message: string
  subscription: Subscription

  constructor(
    private squiz: quizService,
    private authService: AuthService,
    @Optional() public cdr: ChangeDetectorRef
  ) {
    this.cdr = cdr
    this.authService.currentUserSubject.subscribe(x => (this.currentUser$ = x))
  }

  activeTab: Tabs = 'kt_table_widget_6_tab_2'

  setTab(tab: Tabs) {
    this.activeTab = tab
  }

  activeClass(tab: Tabs) {
    return tab === this.activeTab ? 'show active' : ''
  }

  ngOnInit() {
    this.subscription = this.squiz.currentMessage.subscribe(message => (this.message = message))

    this.squiz.getGamesDataA().subscribe(data => {
      this.assoc_tab1 = data
    })

    this.squiz.getGamesDataN().subscribe(data => {
      this.non_assoc_tab2 = data
    })
    this.cdr.detectChanges()
  }

  AfterViewInit() {
    this.cdr.detectChanges()
  }

  // obtenir le nom du jeu cliqué
  newMessage(x) {
    this.squiz.changeMessage(x)
  }
}
