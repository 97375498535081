import { CommonModule } from '@angular/common'
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core'

@Component({
  selector: 'app-context-menu',
  standalone: true,
  template: `
    <style>
      #context-menu {
        display: block;
        position: fixed;
        z-index: 100;
        background-color: #fff;
        border: 1px solid #ccc;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        border-radius: 10px;
        padding: 5px 0;
        opacity: 1;
      }

      #context-menu.hidden {
        opacity: 0;
        display: none;
      }
    </style>

    <div #contextMenu id="context-menu" [style.left.px]="x" [style.top.px]="y" [class.hidden]="!visible">
      <ng-container *ngTemplateOutlet="template"></ng-container>
    </div>
  `,
  imports: [CommonModule],
})
export class ContextMenuComponent implements OnInit {
  @ViewChild('contextMenu') contextMenuRef!: ElementRef
  @Input() target: HTMLElement
  @Input() template: TemplateRef<HTMLElement>
  @Output() itemClick = new EventEmitter<any>()
  x: number = 0
  y: number = 0
  visible: boolean = false

  _isActive = false
  @Input() set isActive(value: boolean) {
    this._isActive = value
    this.visible = value
    this.cdr.detectChanges()
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (!this.target) {
      throw new Error('target is required')
    }
    this.target.addEventListener('contextmenu', event => {
      this.visible = this._isActive
      this.x = this.y = -1000
      setTimeout(() => {
        event.preventDefault()
        const { height, width } = document.getElementById('context-menu').getBoundingClientRect()
        this.y = event.clientY - (event.clientY > window.innerHeight - height ? height : 0) // if context menu is out of screen, move it up
        this.x = event.clientX - (event.clientX > window.innerWidth - width ? width : 0) // if context menu is out of screen, move it left
        this.cdr.detectChanges()
      }, 10)
    })

    this.target.addEventListener('click', () => {
      this.visible = false
      this.cdr.detectChanges()
    })
  }

  onItemClick(item: any) {
    this.itemClick.emit(item)
    this.visible = false
    this.cdr.detectChanges()
  }
}
