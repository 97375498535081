<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">{{ "ADMINPARKING.modalReserve.title" | translate }}</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="formGroup">
      <div class="row" style="margin-top: 10px">
        <div class="col-lg-4" style="margin-top: 10px">
          <label>Site</label>
        </div>
        <div class="col-lg-8">
          <select
            class="form-control"
            formControlName="site"
            style="width: 100%"
            [class.is-invalid]="isControlInvalid('site')"
            [class.is-valid]="isControlValid('site')"
            (change)="siteId = $event.target.value"
          >
            <option *ngFor="let s of listSites" [value]="s.ids">{{ s.name }}</option>
          </select>

          <div class="invalid-feedback" *ngIf="controlHasError('required', 'site')">Site{{ "CONTROLEDESAISIE.required" | translate }}</div>
        </div>
      </div>

      <div class="row" style="margin-top: 10px">
        <div class="col-lg-4" style="margin-top: 10px">
          <label>{{ "ADMINPARKING.modalReserve.input2" | translate }}</label>
        </div>
        <div class="col-lg-8">
          <input
            type="date"
            class="form-control"
            [min]="dateMin"
            formControlName="dateReservation"
            style="width: 100%"
            [class.is-invalid]="isControlInvalid('dateReservation')"
            [class.is-valid]="isControlValid('dateReservation')"
          />
          <div class="invalid-feedback" *ngIf="controlHasError('required', 'dateReservation')">
            {{ "EVENT.wizardTwo.frequency" | translate }}{{ "CONTROLEDESAISIE.required" | translate }}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4" style="margin-top: 40px">
          <label>{{ "EVENT.wizardTwo.time" | translate }}</label>
        </div>
        <div class="col-lg-8">
          <ngb-timepicker [(ngModel)]="startTime" formControlName="time" style="width: 100%"></ngb-timepicker>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4" style="margin-top: 40px">
          <label>{{ "ADMINPARKING.modalReserve.input4" | translate }}</label>
        </div>
        <div class="col-lg-8">
          <ngb-timepicker [(ngModel)]="endTime" formControlName="endTime" style="width: 100%"></ngb-timepicker>
        </div>
      </div>

      <div class="row" style="margin-top: 10px">
        <div class="col-lg-4" style="margin-top: 10px">
          <label>{{ "ADMINPARKING.modalReserve.input5" | translate }}</label>
        </div>
        <div class="col-lg-8">
          <select
            class="form-control"
            [(ngModel)]="type"
            formControlName="type"
            style="width: 100%"
            [class.is-invalid]="isControlInvalid('type')"
            [class.is-valid]="isControlValid('type')"
          >
            <option value="1">{{ "ADMINPARKING.modalParking.option1" | translate }}</option>
            <option value="2">{{ "ADMINPARKING.modalParking.option2" | translate }}</option>
            <option value="3">{{ "ADMINPARKING.modalParking.option3" | translate }}</option>
          </select>

          <div class="invalid-feedback" *ngIf="controlHasError('required', 'type')">
            {{ "ADMINPARKING.modalReserve.input5" | translate }}{{ "CONTROLEDESAISIE.required" | translate }}
          </div>
        </div>
      </div>

      <div class="row" style="margin-top: 10px">
        <div class="col-lg-4" *ngIf="type == 1">
          <input type="checkbox" id="vehicle1" name="vehicle1" value="1" (click)="setElectricCar()" />
          <label for="vehicle1" style="margin-left: 5px"> {{ "ADMINPARKING.modalReserve.input6" | translate }}</label
          ><br />
        </div>
        <div class="col-lg-4" *ngIf="type == 1">
          <input type="checkbox" id="vehicle2" name="vehicle2" value="1" (click)="setHandicapCar()" />
          <label for="vehicle2" style="margin-left: 5px"> {{ "ADMINPARKING.modalReserve.input7" | translate }}</label
          ><br />
        </div>
        <div class="col-lg-4" *ngIf="type == 1">
          <input type="checkbox" id="vehicle3" name="vehicle3" value="1" (click)="setCovCar()" />
          <label for="vehicle3" style="margin-left: 5px"> {{ "ADMINPARKING.modalReserve.input8" | translate }}</label
          ><br /><br />
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-primary" (click)="closeModal()"><i class="fas fa-times"></i>{{ "IDEA.add.button1" | translate }}</button>
    <button
      class="btn btn-primary"
      [disabled]="formGroup.controls['dateReservation'].invalid || formGroup.controls['type'].invalid"
      (click)="reserveParking()"
    >
      <span> {{ "ADMINPARKING.modalReserve.button1" | translate }}</span>
    </button>
  </div>
</div>
