import { QuizData } from './../_models/quiz-data.model'
import { Component, OnInit, ChangeDetectorRef, Optional } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { GererStrategieComponent } from '../gerer-strategie/gerer-strategie.component'
import { quizService } from '../_services/quiz.service'
import { AuthService, UserModel } from '../../../modules/auth'
import { Subscription } from 'rxjs'
import { ToastrService } from 'ngx-toastr'
import { Router } from '@angular/router'
import { ResetStatsComponent } from '../reset-stats/reset-stats.component'
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-gerer-quiz',
  templateUrl: './gerer-quiz.component.html',
  styleUrls: ['./gerer-quiz.component.scss'],
})
export class GererQuizComponent implements OnInit {
  currentUser$: UserModel
  data: any
  game_id: any
  // message is the QuizData passed from listdesjeux component using a service
  message: any
  subscription: Subscription
  QuizData = new QuizData()
  data2: any
  StatData: any
  // game stats
  score = 0
  duree = 0
  count = 0
  // table collaborateur
  topCollab: any
  topService: any
  changeStyle = false

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    private squiz: quizService,
    private datePipe: DatePipe,
    private authService: AuthService,
    @Optional()
    public cdr: ChangeDetectorRef
  ) {
    this.cdr = cdr
    this.authService.currentUserSubject.subscribe(x => (this.currentUser$ = x))
  }

  ngOnInit() {
    // vérifer la largeur de page pour savoir si on doit changer le texte des button en haut de page de tel sorte qu'ils sont aligné
    if (window.innerWidth <= 600) {
      this.changeStyle = true
    }
    this.subscription = this.squiz.currentMessage.subscribe(message => {
      this.message = message
      // redirect user to games list if QuizData is not provided
      if (this.message == 'default message') {
        this.router.navigate(['Quiz'])
      }
      this.cdr.detectChanges()
    })

    this.squiz.getGamesDetails(this.message).subscribe(data => {
      this.data2 = data
      //  this.QuizData.entreprise_id = this.data2.entreprise_id ;
      this.QuizData.game_id = this.formatData(JSON.stringify(this.data2.game_id))
      this.cdr.detectChanges()
    })

    this.squiz.getStat(this.message).subscribe(data => {
      // will get average value of all playing duration with same entreprise and game Id
      // then apply ceilling to result
      this.score = data[0].score
      this.duree = data[0].duree
      this.count = data[0].count
      if (this.score == null) {
        this.score = 0
      }
      if (this.duree == null) {
        this.duree = 0
      }
      if (this.count == null) {
        this.count = 0
      }
      this.cdr.detectChanges()
    })
    // get data to display it on top active collaborators table
    this.squiz.getTopCollab(this.message).subscribe(data => {
      this.topCollab = data
      this.cdr.detectChanges()
    })

    this.squiz.getTopService().subscribe(data => {
      this.topService = data
      this.cdr.detectChanges()
    })
  }

  //create pop-up if associate strategy button clicked
  create() {
    this.edit()
  }

  //reinitialise all stats of a game to zero (score, playing duration ...)
  reset() {
    const modalRef = this.modalService.open(ResetStatsComponent, {
      size: 'l',
      animation: true,
      centered: true,
    })
    this.game_id = this.data2.game_id
    modalRef.componentInstance.game_id = this.game_id
    modalRef.result.then(
      data => {
        if (data == 1) {
          //refresh parent page "jeux.component" after closing modal
          //location.reload();
          this.toastr.success('les statistiques ont été réinitialisées avec succès !')
          this.router.navigate(['Quiz'])
        }
      },
      error => {
        console.log(error)
      }
    )
    this.cdr.detectChanges()
  }

  /*l'objet QuizData avec la propriété game_id reçoit l'identifiant du jeu sélectionné pour 
être utilisé ultérieurement 
dans la fenêtre contextuelle de stratégie */
  edit() {
    //this.QuizData.game_id = this.message ;
    const modalRef = this.modalService.open(GererStrategieComponent, {
      size: 'l',
      backdrop: 'static',
    })
    modalRef.componentInstance.QuizData = this.QuizData
  }

  formatData(data) {
    data = data.replace(/"\"/g, '').replace(/\""/g, '')

    return data
  }

  // detect when window is resized to change style
  detectMob(event) {
    if (window.innerWidth <= 550) {
      this.changeStyle = true
    } else {
      this.changeStyle = false
    }
  }
}
