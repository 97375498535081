import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { MatSelectChange } from '@angular/material/select'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { AnimationOptions } from 'ngx-lottie'
import { ToastrService } from 'ngx-toastr'
import { getEntIdFromLocalStorage } from 'src/app/utils/getSessionFromLocalStorage'
import { KTUtil } from 'src/assets/js/components/util'
import KTWizard from 'src/assets/js/components/wizard'
import { EquipeT, OsModelingService, ServiceT, SiteT } from '../../os-modeling/os-modeling-service.service'
import { OspReservationRulesService, ResvRuleT, RuleCodeT } from '../os-modeling-service.service'

@Component({
  selector: 'app-create-resv-rule-modal',
  templateUrl: './create-resv-rule-modal.component.html',
  styleUrls: ['./create-resv-rule-modal.component.scss'],
})
export class CreateResvRuleModalComponent implements OnInit, AfterViewInit {
  @ViewChild('wizard', { static: true }) el: ElementRef
  wizard: any

  constructor(
    private oSvc: OsModelingService,
    private mainSvc: OspReservationRulesService,
    private toastr: ToastrService,
    private modal: NgbActiveModal
  ) {}

  ngAfterViewInit() {
    // Initialize form wizard
    this.wizard = new KTWizard(this.el.nativeElement, {
      startStep: 1,
    })

    // Validation before going to next page
    this.wizard.on('beforeNext', wizardObj => {
      // https://angular.io/guide/forms
      // https://angular.io/guide/form-validation
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    })

    // Change event
    this.wizard.on('change', () => {
      setTimeout(() => {
        KTUtil.scrollTop()
      }, 500)
    })
  }
  ruleCodes: RuleCodeT[] = ['MAX_RESV_PER_DAY', 'MIN_RESV_PER_DAY', 'MAX_RESV_PER_WEEK', 'MIN_RESV_PER_WEEK']

  styles: Partial<CSSStyleDeclaration> = {
    maxWidth: '500px',

    margin: '0 auto',
  }
  options: AnimationOptions = {
    path: '/assets/icons/73862-confetti.json',
  }
  options1: AnimationOptions = {
    path: '/assets/icons/strategie_icon/tune.json',
  }
  options2: AnimationOptions = {
    path: '/assets/icons/strategie_icon/idea.json',
  }
  options3: AnimationOptions = {
    path: '/assets/icons/strategie_icon/check-circled.json',
  }
  options4: AnimationOptions = {
    path: '/assets/icons/strategie_icon/circle-square-1.json',
  }
  options5: AnimationOptions = {
    path: '/assets/icons/strategie_icon/circles-menu-4.json',
  }

  lottieOptions = [this.options1, this.options2, this.options3, this.options4, this.options5]

  sites: SiteT[] = []
  services: ServiceT[] = []
  equipes: EquipeT[] = []

  targets = [[], [], []] as [SiteT[], ServiceT[], EquipeT[]]
  targetLabels = ['Équipe', 'Service', 'Site']

  rule: ResvRuleT = new ResvRuleT()

  ngOnInit() {
    this.oSvc.getEnterpriseDetails().subscribe(({ sites, services, equipes }) => {
      this.sites = sites
      this.services = services
      this.equipes = equipes
      this.targets = [equipes, services, sites]
    })
  }

  createRule() {
    const rule = this.rule
    const equipeId = rule.targetType === 2 ? rule.targetId : null
    const serviceId = rule.targetType === 3 ? rule.targetId : null
    const siteId = rule.targetType === 4 ? rule.targetId : null
    const entrepriseId = getEntIdFromLocalStorage()
    const userId = null
    const { code, value, excludeAbsence } = rule
    this.mainSvc
      .createRule({
        equipeId,
        serviceId,
        siteId,
        entrepriseId,
        userId,
        excludeAbsence,
        code,
        value,
      })
      .subscribe({
        next: res => {
          this.toastr.success('La nouvelle règle a été ajoutée avec succès', 'Succès')
          this.modal.close()
        },
        error: err => {
          this.toastr.error("Une erreur est survenue lors de l'ajout de la règle", 'Erreur')
        },
      })
  }

  get formIsValid() {
    const rule = this.rule
    return rule.code && rule.value && rule.targetType && (rule.targetType === 5 || rule.targetId !== null)
  }

  changeTargetType(e: MatSelectChange) {
    this.rule.targetType = e.source.value
    this.rule.targetId = null
  }
}
