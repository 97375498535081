import axios from 'axios'
// general.interceptor.ts

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

@Injectable()
export class GeneralInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Log the outgoing request

    // Pass the request to the next interceptor or to the server
    return next.handle(request).pipe(
      tap(
        event => {
          // Log the incoming response
          if (event.type === 4) {
          }
        },
        error => {
          // Log any errors
          // console.error('Error:', error)
        }
      )
    )
  }
}

// Extend the interceptor to capture global XMLHttpRequest
const originalOpen = XMLHttpRequest.prototype.open

XMLHttpRequest.prototype.open = function (
  method: string,
  url: string,
  async?: boolean,
  user?: string | null,
  password?: string | null
): void {
  // Log the outgoing request

  if (
    environment.enableLogginApiCalls &&
    url.indexOf('/temp/apicalls') === -1 &&
    url.indexOf('/rendezvous/countnotification') === -1 &&
    url.indexOf(environment.apiUrl) === -1 &&
    url.indexOf(environment.socketUrl) === -1 &&
    url.indexOf(environment.apiUrlQSP) === -1 &&
    url.indexOf(environment.supersetServer) === -1 &&
    url.indexOf(environment.emyeChatbotApiUrl) === -1 &&
    url.indexOf(environment.apiUrlFront) === -1
  ) {
    axios
      .post(environment.apiUrl + '/temp/apicalls', { method, url, async, user, password })
      .then(res => {})
      .catch(err => {})
  }
  // Call the original open method
  originalOpen.apply(this, arguments as any)
}
