export default {
  gnrl: {
    Sauvegarder: 'Sauvegarder',
    SauvegarderModel: 'Sauvegarder comme modèle',
    ProgrammerPub: 'Programmer la publication',
    QUITER: 'Quitter',
  },
  SAVEASMODEL: {
    TITLE: 'Sauvegarder comme modèle',
    TITLE1: "Attention c'est irreversible",
    p1: 'Êtes-vous sûr de sauvgarder ce newsletter comme modèle ?',
    btn: 'Sauvegarder',
  },
  PLANIFICATION: {
    TITLE: 'Planifie la date de publication',
    p1: "Date d'activation",
    btn: 'Planifier',
  },
  LIST: {
    TITLE: 'Liste des newsletters',
    BTN: 'Nouvelle newsletter',
    TITRE: 'Titre',
  },
  DELETE: {
    TITLE: ' Supprimer newsletter',
    DESC_1: 'suppression newsletter ...',
    DESC_2: 'Êtes-vous sûr de supprimer définitivement ce newsletter ?',
    BTN_DELETE: 'Supprimer',
    BTN_CANCEL: 'Annuler',
  },
  CHART: {
    TITLE: 'Statistique sur newsletter',
    FILTER: 'Filtrer',
    TMP_MIN: 'Temps de lecture minimum',
    TMP_MAX: 'Temps de lecture maximum',
    SECOND: 'Secondes',
    TEAUX: "Taux d'ouverture",
    DETAILS_PAR_BLOCK: 'Détails par bloc de la newsletter',
    NUM: 'Numero block',
    NBR_30: 'nombre de personnes qui ont lu plus 30 sec',
    MOY: 'moyenne de lecture en s',
  },
}
