<div *ngIf="questions" class="bg-white py-10">
  <a style="float: left; margin-left: 25px" type="button" class="btn btn-light" (click)="goBackToPrevPage()">
    <i class="f1 fa fa-arrow-left mr-2"></i>
    <span class="f2" translate="MYACTVITY.DETAILS.BACK"></span>
  </a>

  <h1 class="h1 text-center mr-5">Résultats</h1>
  <br />
  <!--bouton generer par l'IA-->
  <div class="d-flex justify-content-end align-items-center mr-15">
    <button class="btn btn-primary" style="margin-bottom: 20px; position: relative" (click)="getReport()" [disabled]="isLoading">
      <span *ngIf="!isLoading">Compte rendu global</span>
      <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      <span *ngIf="isLoading" style="margin-left: 10px">Loading...</span>
    </button>
  </div>
  <!-- Dashboardcomponent-->
  <app-dashboard [isModalOpen]="isModalOpen" (closeModalEvent)="closeModal()"></app-dashboard>

  <div class="mt-15 d-flex align-items-center justify-content-between">
    <div class="mt-15 d-flex align-items-center">
      <p class="h4 m-0 pl-8" style="width: 250px">Choisissez la question:</p>
      <!-- list using mat-select and bind on selectedQuestion and onQuestionChange-->
      <mat-form-field appearance="outline" style="width: 700px">
        <mat-select [(value)]="selectedQuestion" (selectionChange)="onQuestionChange($event.value)">
          <mat-option *ngFor="let question of questions" [value]="question"> {{ question.order + 1 }}) {{ question.text }} </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="mt-15 mr-15 d-flex align-items-center">
      <p class="h4 m-0 pl-10 pr-5">Télécharger les résultats:</p>
      <button class="btn btn-success" (click)="exportResults('csv')">CSV</button>
      <!-- <button class="btn btn-success" (click)="exportResults('xlsx')">Excel</button> -->
    </div>
  </div>
  <hr />
  <br />
  <div style="padding: 25px; background: rgba(255, 255, 255, 0.621)">
    <div style="height: 100px" *ngIf="!results?.length || noResultsAllQuestions">
      <h3 class="text-center">Ce questionnaire n'a pas encore reçu de réponse</h3>
    </div>

    <!-- question is loading so show bootstrap spinner centered vertically and horizontally: -->
    <ng-container *ngIf="results?.length && !noResultsAllQuestions && isLoadingQuestion">
      <div style="height: 250px" class="d-flex justify-content-center align-items-center">
        <div class="spinner-border" role="status"></div>
      </div>
    </ng-container>

    <!-- is not loading show the question: -->
    <ng-container *ngIf="!isLoadingQuestion && results?.length && !noResultsAllQuestions" #resultsTemplate>
      <!--attendre le compte rendu de la question -->
      <div
        *ngFor="let question of selectedQuestion ? [selectedQuestion] : []"
        style="padding: 50px; background: white; margin-bottom: 35px; border-radius: 5px"
        class="mat-elevation-z5"
      >
        <div class="mb-5" style="display: flex; justify-content: space-between; align-items: center">
          <h3 class="m-0" style="color: #522f04; height: fit-content">{{ question.order + 1 }}) {{ question.text }}</h3>

          <!-- Priority and Psychorisk bubble -->
          <div style="display: flex">
            <div
              *ngIf="question.resultsMetrics?.priority"
              style="display: flex; width: 100%; justify-content: center; align-items: center"
              class="mr-10"
            >
              <span style="color: rgb(33, 33, 33)" class="m-0 mr-2">Priorité:</span>

              <div
                style="
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                  background-color: #e39971;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                "
              >
                <p style="color: rgb(255, 255, 255)" class="m-0 h3">
                  {{ question.resultsMetrics?.priority }}
                </p>
              </div>
            </div>
            <div *ngIf="question.resultsMetrics?.psychoRisk" style="display: flex; width: 100%; justify-content: center; align-items: center">
              <p style="color: rgb(52, 52, 52)" class="m-0 mr-2">Risque:</p>

              <div
                style="
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                  background-color: #eb7b7b;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                "
              >
                <p style="color: rgb(255, 255, 255)" class="m-0 h3">
                  {{ question.resultsMetrics?.psychoRisk }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Interpretation of the question generated by Ai -->
        <div *ngIf="question.resultsMetrics?.interpretation" style="margin-top: 20px; margin-bottom: 10px; display: flex">
          <p style="margin-right: 5px; font-weight: bold">Objectif de la question:</p>
          <p>{{ question.resultsMetrics?.interpretation }}</p>
        </div>

        <!-- Simple Question ('text' | 'number' | 'date' | 'datetime-local') -->
        <div class="mt-10" *ngIf="question.type === 'simple'" style="display: flex; align-items: center">
          <p class="h3 mr-5">Réponses:</p>

          <p *ngIf="['date', 'datetime-local'].includes(question.inputType); else textAndNumberValues" class="text-muted h3">
            <ng-container *ngFor="let date of getTextValues(question.order); let i = index">
              {{ date | localizedDate }}
              <ng-container *ngIf="question.inputType === 'datetime-local'"> ⌚ {{ date | date : "HH:mm" }} </ng-container>
              {{ i === getTextValues(question.order).length - 1 ? "" : ", " }}
              &nbsp;
            </ng-container>
          </p>
          <ng-template #textAndNumberValues>
            <p class="text-muted h3">
              {{ getTextValues(question.order).join(", ") }}
            </p>
          </ng-template>
        </div>

        <!-- Scale & Rating Question -->
        <div class="mt-10" *ngIf="['rating', 'scale'].includes(question.type)">
          <h3 class="text-center ml-15 pl-15" *ngIf="noQuestionResultsYet(question)">Cette question n'a pas encore reçu de réponse.</h3>
          <div style="display: flex; align-items: center" *ngIf="!noQuestionResultsYet(question)">
            <p class="text-muted h3 mr-5">Réponse Moyenne:</p>
            <p class="h2 mr-10">{{ question.avg }}/{{ question[question.type] }}</p>
            <bar-rating
              [rate]="question.avg"
              [max]="question[question.type]"
              [readOnly]="false"
              [theme]="question.type === 'scale' ? 'square' : 'default'"
              style="margin-top: -10px; pointer-events: none; --br-inactive-color: #e3e3e3; --br-active-color: #e39971"
            ></bar-rating>
          </div>
          <ng-container *ngIf="!noQuestionResultsYet(question)">
            <div style="display: flex" *ngIf="!allResultsAreAnonymous">
              <div style="display: flex; flex-flow: column; width: 50%">
                <apx-chart
                  *ngIf="question.series.sites && question.xaxis.sites"
                  style="width: 100%"
                  [series]="question.series.sites"
                  [xaxis]="question.xaxis.sites"
                  [chart]="chartSites"
                  [dataLabels]="chartOptions.dataLabels"
                  [plotOptions]="chartOptions.plotOptions"
                  [stroke]="chartOptions.stroke"
                  [fill]="chartOptions.fill"
                  [yaxis]="chartOptions.yaxis"
                  [tooltip]="chartOptions.tooltip"
                  [legend]="chartOptions.legend"
                  [colors]="chartOptions.colors"
                  [title]="question.title.sites"
                  autoUpateSeries="false"
                  [markers]="{ size: 0 }"
                ></apx-chart>

                <apx-chart
                  *ngIf="question.series.services && question.xaxis.services"
                  style="width: 100%"
                  [series]="question.series.services"
                  [xaxis]="question.xaxis.services"
                  [title]="question.title.services"
                  [chart]="chartServices"
                  [dataLabels]="chartOptions.dataLabels"
                  [plotOptions]="chartOptions.plotOptions"
                  [stroke]="chartOptions.stroke"
                  [fill]="chartOptions.fill"
                  [yaxis]="chartOptions.yaxis"
                  [tooltip]="chartOptions.tooltip"
                  [legend]="chartOptions.legend"
                  [colors]="chartOptions.colors"
                  [markers]="{ size: 0 }"
                ></apx-chart>
              </div>
              <div style="width: 50%">
                <apx-chart
                  *ngIf="question.series.equipes && question.xaxis.equipes"
                  style="width: 100%"
                  [series]="question.series.equipes"
                  [xaxis]="question.xaxis.equipes"
                  [title]="question.title.equipes"
                  [chart]="{
                    type: 'bar',
                    height: 400,
                    stacked: true,
                 
                  }"
                  [markers]="{ size: 0 }"
                  [dataLabels]="chartOptions.dataLabels"
                  [plotOptions]="{ bar: { horizontal: false } }"
                  [stroke]="chartOptions.stroke"
                  [fill]="chartOptions.fill"
                  [yaxis]="chartOptions.yaxis"
                  [tooltip]="chartOptions.tooltip"
                  [legend]="chartOptions.legend"
                  [colors]="chartOptions.colors"
                ></apx-chart>
              </div>
            </div>
            <div style="display: flex" *ngIf="allResultsAreAnonymous">
              <apx-chart
                style="width: 100%"
                [series]="[
                  {
                    name: '',
                    data: question.choicesSeries
                  }
                ]"
                [chart]="{
                  height: 350,
                  type: 'bar'
                }"
                [xaxis]="{
                  categories: question.choicesLabels
                }"
              ></apx-chart>
            </div>
          </ng-container>
        </div>

        <br />
        <!-- <input type="checkbox" class="btn btn-primary btn-xs" (click)="changeChartType()" /> -->

        <!-- Choice Question -->
        <div *ngIf="question.type === 'choice'" style="display: flex; width: 100%">
          <h3 class="text-center ml-15 pl-15" *ngIf="noQuestionResultsYet(question)">Cette question n'a pas encore reçu de réponse.</h3>
          <ng-container *ngIf="!noQuestionResultsYet(question) && !allResultsAreAnonymous">
            <div style="display: flex; flex-flow: column; width: 50%">
              <apx-chart
                *ngIf="question.series.sites && question.xaxis.sites"
                style="width: 100%"
                [series]="question.series.sites"
                [xaxis]="question.xaxis.sites"
                [chart]="chartSites"
                [dataLabels]="chartOptions.dataLabels"
                [plotOptions]="chartOptions.plotOptions"
                [stroke]="chartOptions.stroke"
                [fill]="chartOptions.fill"
                [yaxis]="chartOptions.yaxis"
                [tooltip]="chartOptions.tooltip"
                [legend]="chartOptions.legend"
                [colors]="chartOptions.colors"
                [title]="question.title.sites"
                [markers]="{ size: 0 }"
              ></apx-chart>

              <apx-chart
                *ngIf="question.series.services && question.xaxis.services"
                style="width: 100%"
                [series]="question.series.services"
                [xaxis]="question.xaxis.services"
                [title]="question.title.services"
                [chart]="chartServices"
                [dataLabels]="chartOptions.dataLabels"
                [plotOptions]="chartOptions.plotOptions"
                [stroke]="chartOptions.stroke"
                [fill]="chartOptions.fill"
                [yaxis]="chartOptions.yaxis"
                [tooltip]="chartOptions.tooltip"
                [legend]="chartOptions.legend"
                [colors]="chartOptions.colors"
                [markers]="{ size: 0 }"
              ></apx-chart>
            </div>
            <div style="width: 50%">
              <apx-chart
                *ngIf="question.series.equipes && question.xaxis.equipes"
                style="width: 100%"
                [series]="question.series.equipes"
                [xaxis]="question.xaxis.equipes"
                [title]="question.title.equipes"
                [chart]="{
                  type: 'bar',
                  height: 400,
                  stacked: true
                }"
                [dataLabels]="chartOptions.dataLabels"
                [plotOptions]="{ bar: { horizontal: false } }"
                [stroke]="chartOptions.stroke"
                [fill]="chartOptions.fill"
                [yaxis]="chartOptions.yaxis"
                [tooltip]="chartOptions.tooltip"
                [legend]="chartOptions.legend"
                [colors]="chartOptions.colors"
                [markers]="{ size: 0 }"
              ></apx-chart>
            </div>
          </ng-container>

          <div style="display: flex; width: 100%" *ngIf="allResultsAreAnonymous">
            <apx-chart
              style="width: 100%"
              [series]="[
                {
                  name: '',
                  data: question.choicesSeries
                }
              ]"
              [chart]="{
                height: 350,
                type: 'bar'
              }"
              [xaxis]="{
                categories: question.choicesLabels
              }"
              [yaxis]="{
                labels: {
                  minWidth: 'auto',
                  maxWidth: 'auto'
                }
              }"
              [plotOptions]="{
                bar: {
                  horizontal: true
                }
              }"
              [stroke]="chartOptions.stroke"
              [fill]="chartOptions.fill"
              [tooltip]="chartOptions.tooltip"
              [legend]="chartOptions.legend"
              [colors]="chartOptions.colors"
            ></apx-chart>
          </div>
        </div>

        <div *ngIf="question.resultsMetrics?.risks?.length">
          <span class="svg-icon svg-icon-lg svg-icon-danger svg-icon-2x mr-2" [inlineSVG]="'assets/media/svg/icons/Code/Warning-2.svg'"></span>
          <span>Trois risques principaux: </span>
          <span>{{ formatStringList(question.resultsMetrics?.risks) }}</span>
        </div>

        <div *ngIf="question.resultsMetrics?.shortTermRisk?.length">
          <span class="svg-icon svg-icon-lg svg-icon-danger svg-icon-2x mr-2" [inlineSVG]="'assets/media/svg/icons/Code/Warning-2.svg'"></span>
          <span>Risque majeur: </span>
          <span>{{ question.resultsMetrics?.shortTermRisk }}</span>
        </div>

        <div *ngIf="question.resultsMetrics?.recommendations?.length" class="mt-3">
          <span class="svg-icon svg-icon-lg svg-icon-success svg-icon-2x mr-2" [inlineSVG]="'assets/media/svg/icons/Code/Done-circle.svg'"></span>
          <span>Trois solutions précaunisées: </span>
          <span>{{ formatStringList(question.resultsMetrics?.recommendations) }}</span>
        </div>

        <br />
        <!-- NB: -->
        <p *ngIf="question.resultsMetrics?.risks?.length || question.resultsMetrics?.recommendations?.length" class="mt-3">
          *Les resultats et les recommandations ci-dessus sont générées par une IA, elles sont fournies à titre indicatifs. En cas de besoin merci de
          faire appel à nos experts RH et QVT pour vous aider et vous assister.
        </p>

        <br />
      </div>
    </ng-container>
    <!--show the card only if comptereduques ==1-->
    <ng-container *ngIf="compteRenduQues == 1">
      <div style="padding: 50px; background: white; margin-bottom: 35px; border-radius: 5px" class="mat-elevation-z5">
        <ng-container *ngIf="isLoadingQuesReport; else content">
          <div class="spinner-border text-primary" style="width: 5rem; height: 5rem" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </ng-container>
        <ng-template #content>
          <div class="NgxEditor__Wrapper">
            <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
            <ngx-editor [editor]="editor" [(ngModel)]="html" [disabled]="false"> </ngx-editor>
          </div>
          <!-- Bouton enregister-->
          <button class="btn btn-primary" style="margin-top: 20px; margin-right: 5px" (click)="saveQuesReport()">Enregistrer</button>
        </ng-template>
      </div>
    </ng-container>
  </div>
</div>
<div
  *ngIf="!questions"
  class="spinner-border text-primary"
  style="position: fixed; top: 50%; left: 50%; z-index: 9999; width: 5rem; height: 5rem"
  role="status"
>
  <span class="sr-only">Loading...</span>
</div>
