import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from '../auth/_services/auth.guard'
import { OsModelingComponent } from './os-modeling/os-modeling.component'
import { OsReservationComponent } from './os-reservation/os-reservation.component'
import { OspReservationRulesComponent } from './osp-reservation-rules/osp-reservation-rules.component'
import { OsplannerComponent } from './osplanner.component'
import { PreventUnsavedChangesGuard } from './prevent-unsaved-changes-guard.guard'

const routes: Routes = [
  {
    path: '',
    component: OsplannerComponent,
    children: [
      {
        path: 'modeling',
        component: OsModelingComponent,
        canDeactivate: [PreventUnsavedChangesGuard],
        canActivate: [AuthGuard],
        data: { CD_Permission: ['RFLEX'] },
      },
      {
        path: 'reservation',
        component: OsReservationComponent,
      },
      {
        path: 'manage/rules',
        canActivate: [AuthGuard],
        data: { CD_Permission: ['RFLEX'] },
        component: OspReservationRulesComponent,
      },
      {
        path: 'manage/reservations',
        canActivate: [AuthGuard],
        data: { CD_Permission: ['RFLEX'] },
        component: OsReservationComponent,
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OsplannerRoutingModule {}
