export const DELETE_FLOOR_PROMPT_DATA = {
  message: 'Voulez-vous vraiment supprimer cet étage?',
  title: "Supprimer l'étage",
  confirmText: 'Supprimer',
  cancelText: 'Annuler',
  confirmColor: 'danger',
  cancelColor: 'light',
}

export const DELETE_BUILDING_PROMPT_DATA = {
  message: 'Voulez-vous vraiment supprimer ce bâtiment?',
  title: 'Supprimer le bâtiment',
  confirmText: 'Supprimer',
  cancelText: 'Annuler',
  confirmColor: 'danger',
  cancelColor: 'light',
}

export const assetFeatures = {
  sizeFactor: 1.72,
  desk: [
    { width: 412, height: 444, assetNumber: 1 },
    { width: 311, height: 402, assetNumber: 2 },
    { width: 250, height: 263, assetNumber: 3 },
    { width: 252, height: 225, assetNumber: 4 },
    { width: 449, height: 227, assetNumber: 5 },
    { width: 363, height: 358, assetNumber: 6 },
    { width: 459, height: 458, assetNumber: 7 },
    { width: 409, height: 409, assetNumber: 8 },
    { width: 400, height: 444, assetNumber: 9 },
    { width: 333, height: 363, assetNumber: 10 },
    { width: 611, height: 367, assetNumber: 11 },
    { width: 460, height: 349, assetNumber: 12 },
    { width: 390, height: 389, assetNumber: 13 },
    { width: 277, height: 245, assetNumber: 14 },
    { width: 211, height: 162, assetNumber: 15 },
    { width: 212, height: 157, assetNumber: 16 },
    { width: 212, height: 144, assetNumber: 17 },
    { width: 211, height: 160, assetNumber: 18 },
    { width: 211, height: 171, assetNumber: 19 },
    { width: 211, height: 159, assetNumber: 20 },
    { width: 212, height: 158, assetNumber: 21 },
    { width: 212, height: 155, assetNumber: 22 },
    { width: 211, height: 146, assetNumber: 23 },
    { width: 334, height: 363, assetNumber: 24 },
    { width: 211, height: 95, assetNumber: 25 },
    { width: 211, height: 89, assetNumber: 26 },
    { width: 211, height: 89, assetNumber: 27 },
    { width: 211, height: 89, assetNumber: 28 },
    { width: 212, height: 89, assetNumber: 29 },
    { width: 211, height: 89, assetNumber: 30 },
    { width: 211, height: 89, assetNumber: 31 },
    { width: 211, height: 89, assetNumber: 32 },
    { width: 74, height: 76, assetNumber: 33 },
    { width: 82, height: 87, assetNumber: 34 },
    { width: 85, height: 71, assetNumber: 35 },
    { width: 83, height: 90, assetNumber: 36 },
    { width: 84, height: 86, assetNumber: 37 },
    { width: 108, height: 93, assetNumber: 38 },
  ],
  furniture: [
    { width: 239, height: 102, assetNumber: 1 },
    { width: 231, height: 96, assetNumber: 2 },
    { width: 306, height: 152, assetNumber: 3 },
    { width: 490, height: 198, assetNumber: 4 },
    { width: 148, height: 93, assetNumber: 5 },
    { width: 188, height: 65, assetNumber: 6 },
    { width: 168, height: 57, assetNumber: 7 },
    { width: 299, height: 59, assetNumber: 8 },
    { width: 386, height: 94, assetNumber: 9 },
    { width: 325, height: 68, assetNumber: 10 },
    { width: 157, height: 72, assetNumber: 11 },
    { width: 77, height: 166, assetNumber: 12 },
    { width: 251, height: 68, assetNumber: 13 },
    { width: 255, height: 68, assetNumber: 14 },
    { width: 274, height: 71, assetNumber: 15 },
    { width: 267, height: 64, assetNumber: 16 },
    { width: 88, height: 57, assetNumber: 17 },
    { width: 164, height: 49, assetNumber: 18 },
    { width: 266, height: 99, assetNumber: 19 },
    { width: 124, height: 99, assetNumber: 20 },
    { width: 129, height: 105, assetNumber: 21 },
    { width: 394, height: 223, assetNumber: 22 },
    { width: 126, height: 54, assetNumber: 23 },
    { width: 330, height: 228, assetNumber: 24 },
  ],
  decoration: [
    { width: 104, height: 99, assetNumber: 1 },
    { width: 83, height: 89, assetNumber: 2 },
    { width: 106, height: 104, assetNumber: 3 },
    { width: 91, height: 93, assetNumber: 4 },
    { width: 89, height: 90, assetNumber: 5 },
    { width: 104, height: 91, assetNumber: 6 },
    { width: 115, height: 97, assetNumber: 7 },
    { width: 103, height: 96, assetNumber: 8 },
    { width: 93, height: 94, assetNumber: 9 },
    { width: 100, height: 100, assetNumber: 10 },
    { width: 100, height: 100, assetNumber: 11 },
  ],
}
