<div class="modal-header">
  <div class="modal-title h4">Covoiturage</div>
  <span aria-hidden="true">
    <i class="ki ki-close" (click)="close()"></i>
  </span>
</div>
<ng-container *ngIf="add == 1">
  <div style="padding: 30px; margin-bottom: -48px">
    <span>{{ "TRANSPORT.RESERVATION.SELECT" | translate }}</span>
    <div class="input-container">
      <!--[(ngModel)]="dateDebut" required-->
      <input
        type="date"
        class="form-control form-control-lg form-control-solid col-lg-3"
        autocomplete="off"
        #t
        (change)="onChange(t.value)"
        [(ngModel)]="dateNow"
        ngModel
        [min]="dateM"
      />
    </div>
  </div>
</ng-container>

<div style="padding: 30px">
  <ng-container *ngIf="!isLoadingdata">
    <div class="d-flex justify-content-center align-items-center" style="height: 100vh">
      <div class="spinner-grow text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </ng-container>
  <div
    class="card"
    *ngIf="isLoadingdata"
    style="padding: 10px; margin-bottom: 10px; border-radius: 10px; box-shadow: 1px 11px 5px -5px rgba(0, 0, 0, 0.2)"
  >
    <h4 style="margin-bottom: 20px">{{ "TRANSPORT.RESERVATION.txt1" | translate }}</h4>
    <div *ngIf="collabRes?.length > 0">
      <div class="scroller">
        <table class="table table-striped" style="border-radius: 5%">
          <thead>
            <tr>
              <th>{{ "TRANSPORT.RESERVATION.Photo" | translate }}</th>
              <th>{{ "TRANSPORT.RESERVATION.Nom_Prénom" | translate }}</th>
              <th>{{ "TRANSPORT.RESERVATION.Places_restantes" | translate }}</th>
              <th>{{ "TRANSPORT.RESERVATION.Distance" | translate }}</th>
              <th>{{ "TRANSPORT.RESERVATION.Action" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let customer1 of collabRes">
              <td><img [src]="encoder(customer1.photo_principale)" class="img1" /></td>
              <td style="padding-top: 3%">{{ customer1.nom }} {{ customer1.prenom }}</td>
              <td style="padding-top: 3%">{{ customer1.nbrplace }}</td>
              <td style="padding-top: 3%">{{ customer1.distance }}</td>
              <td>
                <button
                  class="btn btn-primary"
                  (click)="reserver(customer1.covoiturage_uuid, customer1.nom, customer1.prenom, customer1.email_responsable)"
                >
                  {{ "TRANSPORT.RESERVATION.RESERVER" | translate }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div *ngIf="collabRes?.length == 0 || collabRes == null" style="margin-bottom: 2px">
      {{ "TRANSPORT.RESERVATION.NON_COVOITURAGE" | translate }}
    </div>
  </div>

  <ng-container *ngIf="!isLoadingdatapropose">
    <div class="d-flex justify-content-center align-items-center" style="height: 100vh">
      <div class="spinner-grow text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </ng-container>
  <div
    class="card"
    *ngIf="isLoadingdatapropose"
    style="padding: 10px; margin-bottom: 10px; border-radius: 10px; box-shadow: 1px 11px 5px -5px rgba(0, 0, 0, 0.2)"
  >
    <h4 style="margin-bottom: 20px">{{ "TRANSPORT.RESERVATION.txt2" | translate }}</h4>
    <div *ngIf="collab?.length > 0" style="margin-bottom: 15px">
      <div class="scroller">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>{{ "TRANSPORT.RESERVATION.Photo" | translate }}</th>
              <th>{{ "TRANSPORT.RESERVATION.Nom_Prénom" | translate }}</th>
              <th class="col-lg-3"></th>
              <th>{{ "TRANSPORT.RESERVATION.Distance" | translate }}</th>
              <th>{{ "TRANSPORT.RESERVATION.Action" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let customer of collab">
              <td><img [src]="encoder(customer.photo_principale)" class="img1" /></td>
              <td style="padding-top: 3%">{{ customer.nom }} {{ customer.prenom }}</td>
              <td></td>
              <td style="padding-top: 3%">{{ customer.distance }}</td>
              <td>
                <button class="btn btn-primary" (click)="propose(customer.email, customer.nom, customer.prenom)">
                  {{ "TRANSPORT.RESERVATION.Proposer" | translate }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div *ngIf="collab?.length == 0 || collab == null" style="margin-bottom: 2px">{{ "TRANSPORT.RESERVATION.NON_PROPOSEE" | translate }}</div>
  </div>
  <div class="row mb-3" [formGroup]="formGroup" style="display: flex; align-items: center">
    <div style="display: inline">
      <div style="margin-top: 10px; margin-left: 12px; margin-top: 5px; margin-right: 50px">{{ "TRANSPORT.RESERVATION.txt3" | translate }}</div>
      <div style="margin-top: 10px; margin-left: 12px; margin-top: 5px" *ngIf="add != 1">{{ "TRANSPORT.RESERVATION.txt31" | translate }}</div>
    </div>
    <div style="margin-right: 5px; margin-left: 5px; margin-top: 5px; display: flex; align-items: center">
      <label style="width: max-content; margin-right: 3px">N° places</label
      ><input style="width: 50px" type="number" class="form-control form-control-solid" formControlName="nbrPlace" />
    </div>
    <div *ngIf="add != 1" style="margin-top: 5px">
      <button class="btn btn-primary" (click)="saveMobiliteParking()">{{ "TRANSPORT.RESERVATION.proposer_reserver" | translate }}</button>
    </div>
    <div *ngIf="add == 1" style="margin-top: 5px; display: flex; align-items: center">
      <button class="btn btn-primary" (click)="saveMobilite()" style="display: flex; align-items: center">
        <mat-icon style="margin-right: 5px">directions_car</mat-icon>{{ "TRANSPORT.RESERVATION.proposer_voiture" | translate }}
      </button>
    </div>
  </div>
  <div class="row" *ngIf="add != 1">
    <div class="col-12" style="display: flex; align-items: center">
      {{ "TRANSPORT.RESERVATION.txt4" | translate }}
      <button class="btn btn-primary ml-3" (click)="openModalReserve()" style="display: flex; align-items: center">
        <mat-icon style="margin-right: 5px">local_parking</mat-icon>
        {{ "TRANSPORT.RESERVATION.Reserver" | translate }}
      </button>

      <!-- <button class="btn btn-primary ml-3" (click)="openModalReserve()">
        <button class="btn"><i class="fa fa-car"></i>{{ "TRANSPORT.RESERVATION.Reserver" | translate }}</button>
      </button> -->
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="d-flex align-items-center justify-content-between w-100">
    <div class="d-flex align-items-center">
      <!-- MAT CHECKBOX: -->
      <mat-checkbox color="primary" [ngModel]="disablePopup" (ngModelChange)="disablePopupChange(disablePopup)"></mat-checkbox>
      <span (click)="disablePopupChange(disablePopup)" style="cursor: pointer">
        {{ "TRANSPORT.RESERVATION.disablePopup" | translate }}
      </span>
    </div>
    <button type="button" class="btn btn-light btn-elevate mr-1" (click)="modal.dismiss()">{{ "TRANSPORT.RESERVATION.CLOSE" | translate }}</button>
  </div>
</div>
