
import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs'
import { catchError, finalize } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class WsService {
  // Private fields
  private _isLoading$ = new BehaviorSubject<boolean>(false)
  private _isFirstLoading$ = new BehaviorSubject<boolean>(true)
  private _errorMessage = new BehaviorSubject<string>('')

  private _subscriptions: Subscription[] = []
  protected authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`
  protected http: HttpClient

  WSAPI_URL1 = `${environment.apiUrl}/ws/getWsGames`
  WSAPI_URL2 = `${environment.apiUrl}/ws/getwsStats`
  WSAPI_URL3 = `${environment.apiUrl}/ws/getUserwsId`
  WSAPI_URL4 = `${environment.apiUrl}/ws/verifyWsUser`
  WSAPI_URL5 = `${environment.newPassWsUrl}`

  // Getters
  get isLoading$() {
    return this._isLoading$.asObservable()
  }
  get isFirstLoading$() {
    return this._isFirstLoading$.asObservable()
  }
  get errorMessage$() {
    return this._errorMessage.asObservable()
  }
  get subscriptions() {
    return this._subscriptions
  }
  // State getters

  constructor(http: HttpClient) {
    this.http = http
  }

  protected getAuthFromLocalStorage(): any {
    try {
      const authData = JSON.parse(localStorage.getItem(this.authLocalStorageToken))
      return authData
    } catch (error) {
      console.error(error)
      if (error.status == 401) {
        window.location.reload()
      }
      return undefined
    }
  }

  getWsGames(): Promise<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()
    let promise = new Promise<any>((resolve, reject) => {
      const url = `${this.WSAPI_URL1}`
      this.http
        .get(url)
        .toPromise()
        .then(
          res => {
            // success
            resolve(res)
          },
          msg => {
            // Error
            reject(msg)
          }
        )
    })
    return promise
  }

  getwsStats(user_id): Promise<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('user_id', user_id)

    let promise = new Promise<any>((resolve, reject) => {
      const url = `${this.WSAPI_URL2}`
      this.http
        .get(url, { params })
        .toPromise()
        .then(
          res => {
            // success
            resolve(res)
          },
          msg => {
            // Error
            reject(msg)
          }
        )
    })
    return promise
  }

  getUserwsId(user_email): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')

    const params = new HttpParams().set('user_email', user_email)

    const url = `${this.WSAPI_URL3}`
    return this.http.get<any>(url, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('ERROR ! couldnt get requested data', err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  verifyWsUser(user_email): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')

    const params = new HttpParams().set('user_email', user_email)

    const url = `${this.WSAPI_URL4}`
    return this.http.get<any>(url, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('ERROR ! couldnt get requested data', err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  changePassword(user_id, password): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')

    const params = new HttpParams().set('password', password)

    let url = this.WSAPI_URL5 + user_id.toString()

    return this.http.put<any>(url, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('ERROR ! couldnt get requested data', err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }
}
