import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, map } from 'rxjs'
import { ITableState, TableResponseModel } from 'src/app/_metronic/shared/crud-table'
import { TableAdminParkingService } from 'src/app/_metronic/shared/crud-table/services/table.admin-parking.service'
import { baseFilter } from 'src/app/common/helpers/http-extenstions'
import { getSessionFromLocalStorage } from 'src/app/utils/getSessionFromLocalStorage'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class AdminParkingService extends TableAdminParkingService<any> {
  private _errorMsg = new BehaviorSubject<string>('')
  private _isLoad$ = new BehaviorSubject<boolean>(false)

  API_URL_GETALLPARKING = `${environment.apiUrl}/adminParking/getAllParking`
  find(TableEquipeService: ITableState): Observable<TableResponseModel<any>> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(`${this.API_URL_GETALLPARKING}`, { params }).pipe(
      map((response: any[]) => {
        const filteredResult = baseFilter(response, TableEquipeService)
        const result: TableResponseModel<any> = {
          items: filteredResult.items,
          total: filteredResult.total,
        }
        return result
      })
    )
  }

  API_URL_ADDPARKING = `${environment.apiUrl}/adminParking/addParking`
  addParking(parking: any): Observable<any> {
    const sessionid = getSessionFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid)

    return this.http.post<any>(this.API_URL_ADDPARKING, parking, { params })
  }

  URL_GET_ALL_USERS = `${environment.apiUrl}/todo/allusers`
  getAllUsers(): Observable<any> {
    const sessionid = getSessionFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get(`${this.URL_GET_ALL_USERS}`, {
      params,
    })
  }

  API_URL_RESERVEPARKING = `${environment.apiUrl}/adminParking/reserveParking`
  reserveParking(reservation: any): Observable<any> {
    const sessionid = getSessionFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid)

    return this.http.post<any>(this.API_URL_RESERVEPARKING, reservation, { params })
  }

  URL_GET_ALL_RESERVATIONS = `${environment.apiUrl}/adminParking/getReservationForDate`
  getAllReservations(parkId, date): Observable<any> {
    const sessionid = getSessionFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid).set('idParking', parkId).set('date', date)
    return this.http.get(`${this.URL_GET_ALL_RESERVATIONS}`, {
      params,
    })
  }

  URL_GET_ALL_RESERVATIONS_FOR_PARKING = `${environment.apiUrl}/adminParking/getReservationForParking`
  getAllReservationsForParking(parkId): Observable<any> {
    const sessionid = getSessionFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid).set('idParking', parkId)
    return this.http.get(`${this.URL_GET_ALL_RESERVATIONS_FOR_PARKING}`, {
      params,
    })
  }

  API_URL_RESERVEPARKINGSECOND = `${environment.apiUrl}/adminParking/reserveParkingSecond`
  reserveParkingSecond(reservation: any): Observable<any> {
    const sessionid = getSessionFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid)

    return this.http.post<any>(this.API_URL_RESERVEPARKINGSECOND, reservation, { params })
  }

  API_URL_DELETEPARKING = `${environment.apiUrl}/adminParking/deleteParking`
  deleteParking(parkId: any): Observable<any> {
    const sessionid = getSessionFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid).set('parkId', parkId)

    return this.http.put<any>(this.API_URL_DELETEPARKING, null, { params })
  }

  API_URL_DELETERESERVATION = `${environment.apiUrl}/adminParking/deleteReservation`
  deleteReservation(reservationId: any, reason: any): Observable<any> {
    const sessionid = getSessionFromLocalStorage()
    const params = new HttpParams()
      .set('sessionid', sessionid)
      .set('reservationId', reservationId)
      .set('reason', reason)

    return this.http.put<any>(this.API_URL_DELETERESERVATION, null, { params })
  }

  API_URL_UPDATEPARKING = `${environment.apiUrl}/adminParking/updateParking`
  updateParking(parking: any): Observable<any> {
    const sessionid = getSessionFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid)

    return this.http.put<any>(this.API_URL_UPDATEPARKING, parking, { params })
  }

  API_URLLE = `${environment.apiUrl}/site/getsites`
  GetSitesList(): Observable<[]> {
    let sessionid = this.getAuthFromLocalStorage()
    // const url = `${this.API_URLss1}uuid=${item[0].uuid}`
    return this.http.get<[]>(`${this.API_URLLE}?sessionid=${sessionid}`)
  }

  URL_GET_ALL__MY_RESERVATIONS_FOR_PARKING = `${environment.apiUrl}/adminParking/getReservationForParkingUser`
  getAllMyReservationsForParking(): Observable<any> {
    const sessionid = getSessionFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid).set('startDate', '').set('endDate', '')
    return this.http.get(`${this.URL_GET_ALL__MY_RESERVATIONS_FOR_PARKING}`, {
      params,
    })
  }
}
