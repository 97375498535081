import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-sondage',
  templateUrl: './sondage.component.html',
  styleUrls: ['./sondage.component.scss'],
})
export class SondageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
