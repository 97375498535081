import { filter } from "rxjs";

export default {
  listParking: {
    title: 'Gestion des parking',
    button1: '+ Emplacement',
    button2: 'Réserver',
    filter: 'Filtrer par type',
    filtersite: 'Filtrer par site',
    search: 'Rechercher par code',
    column1: "Code de l'emplacement",
    column2: 'Type',
    column3: 'Action',
    column4: 'Spécificité',
  },

  modalParking: {
    title: 'Ajouter un emplacement',
    input1: 'Code',
    input2: 'Type',
    input3: 'Électrique',
    input4: 'handicapé',
    input5: 'covoiturage',
    input6: 'Non reservable',
    option1: 'Voiture',
    option2: 'Vélo',
    option3: 'Moto',
  },

  modalReserve: {
    title: 'Reserver un emplacement',
    input1: 'Nom et prénom',
    input2: 'Date de reservation',
    input3: 'Heure de début',
    input4: 'heure de fin',
    input5: 'Type',
    input6: 'Électrique',
    input7: 'handicapé',
    input8: 'covoiturage',
    input9: 'Non reservable',
    button1: 'Réserver',
  },

  modalReservation: {
    title: 'Liste des reservations',
    column1: 'Créneaux',
    column2: 'Photo',
    column3: 'Nom et prénom',
    column4: 'Fonction',
    column5: 'Actions',
    codeParking: 'Code du parking',
  },

  ModalAnnulation: {
    title: 'Annuler réservation',
    input1: "Raison d'annulation",
    button1: 'confirmer',
    button2: 'Reserve',
  },

  modif: {
    title: 'Modifier emplacement',
    button: 'Modifier',
  },
}
