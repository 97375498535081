import { HttpClient, HttpParams } from '@angular/common/http'
import { ChangeDetectorRef, Inject, Injectable, OnDestroy, Optional } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs'
import { catchError, finalize, map } from 'rxjs/operators'
import { environment } from '../../../../environments/environment'
import { ITableState, TableResponseModel } from '../../../_metronic/shared/crud-table'
import { TableMyActivityService } from '../../../_metronic/shared/crud-table/services/table.myactivity.service'
import { baseFilter } from '../../../common/helpers/http-extenstions'
import { Activity } from '../_models/activity.models'
@Injectable({
  providedIn: 'root',
})
export class MyactivityService extends TableMyActivityService<Activity> implements OnDestroy {
  private stat = new BehaviorSubject(0)
  stat1 = this.stat.asObservable()

  //private socket = io(`${environment.apiUrl}`);
  // protected authLocalStorageToken = ${environment.appVersion}-${environment.USERDATA_KEY};
  API_URL = `${environment.apiUrl}/rendezvous/getActivitysForUser`
  API_URLdashbord = `${environment.apiUrl}/rendezvous/getrdvdashbord`
  API_URLdashbordDetail = `${environment.apiUrl}/todo/details_dashbord`
  API_URLmsg = `${environment.apiUrl}/rendezvous/historiquemsg`
  API_URLgetPresentation = `${environment.apiUrl}/todo/getdetailsuser_rendezvous`
  API_URLgetHobies = `${environment.apiUrl}/todo/gethobbiesforuserrendezvous`
  API_URLgetResto = `${environment.apiUrl}/rendezvous/getdetais_rendezvous`
  API_URLgetWeather = `${environment.apiUrl}/rendezvous/getdetais_weather`
  apiURL2 = `${environment.apiUrl}/rendezvous/getActivityDetails`
  apiURLtopservice = `${environment.apiUrl}/todo/services_de_mois`
  apiURLtopsemainemiameurs = `${environment.apiUrl}/todo/miameurs_de_semaine`
  apiURLtopmoismiameurs = `${environment.apiUrl}/todo/miameurs_de_mois`
  API_URLGETCOMM = `${environment.apiUrl}/communication/get_liste_communications`
  API_URLIDEADASHBOARD = `${environment.apiUrl}/idea/getIdeaAcceptedDashboard`
  API_URLEVENTDASHBOARD = `${environment.apiUrl}/event/getMyEventsInscrit`
  apiURLrangesemainemiameurs = `${environment.apiUrl}/todo/myrangeSemaine`
  API_URLgetjoursferies = `${environment.apiUrl}/dashbord/jours_feries`
  constructor(@Inject(HttpClient) http, private route: ActivatedRoute, @Optional() public cdr: ChangeDetectorRef) {
    super(http)
    this.cdr = cdr
    const room = this.route.snapshot.paramMap.get('id')

    // this.socket;
  }
  find(TableMyactivityService: ITableState): Observable<TableResponseModel<Activity>> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(`${this.API_URL}`, { params }).pipe(
      map((response: Activity[]) => {
        const filteredResult = baseFilter(response, TableMyactivityService)
        const result: TableResponseModel<Activity> = {
          items: filteredResult.items,
          total: filteredResult.total,
        }

        return result
      })
    )
  }
  historiquemsg() {
    const room = this.route.snapshot.paramMap.get('id')

    // let sessionid= this.getAuthFromLocalStorage();

    const params = new HttpParams().set('room', room)
    return this.http.get<any>(`${this.API_URLmsg}`, { params })
  }

  findrdvdashbord() {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(`${this.API_URLdashbord}`, { params })
  }

  //ws pour idee collaborateur de ecran dashboard
  findIdeaActifdashbord() {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(`${this.API_URLIDEADASHBOARD}`, { params })
  }
  //fonction to get jours_feries de l'entreprise
  // /dashord/jours_feries
  getjoursferies() {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(`${this.API_URLgetjoursferies}`, { params })
  }

  //ws get events ou user est inscrit
  //developpe le 19 avril 2023 par khaled
  findEventActifdashbord() {
    try {
      let sessionid = this.getAuthFromLocalStorage()

      const params = new HttpParams().set('sessionid', sessionid)
      return this.http.get<any>(`${this.API_URLEVENTDASHBOARD}`, { params })
    } catch (e) {
    }
  }

  //get details dashbord
  //idee,question reponse en attente,alerte
  //users actif,entree sortie,male femelle

  finddashbordDetail() {
    let sessionid = this.getAuthFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(`${this.API_URLdashbordDetail}`, { params })
  }

  topservices() {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(`${this.apiURLtopservice}`, { params })
  }
  topmiameurs_par_semaine() {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(`${this.apiURLtopsemainemiameurs}`, { params })
  }

  //my range dans liste des miameurs par semaine
  //developpe le 19 avril 2021 par khaled
  range_par_semaine() {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(`${this.apiURLrangesemainemiameurs}`, { params })
  }
  topmiameurs_par_mois() {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(`${this.apiURLtopmoismiameurs}`, { params })
  }
  acceptItems(id: number[] = [], user_uuid: any): Observable<any> {
    const tasks$ = []
    id.forEach(id => {
      tasks$.push(this.accept(id, user_uuid))
    })
    return forkJoin(tasks$)
  }

  //chat methods
  // joinRoom(data) {
  //
  //   this.socket.emit("join", data);
  //   this.cdr.detectChanges();
  // }

  // newUserJoined() {
  //   let observable = new Observable<{ user: String; message: String }>(
  //     (observer) => {
  //       this.socket.on("new user joined", (data) => {
  //         observer.next(data);
  //         this.cdr.detectChanges();
  //       });
  //       return () => {
  //         this.socket.disconnect();
  //       };
  //     }
  //   );

  //   return observable;
  // }

  // leaveRoom(data) {
  //   this.socket.emit("leave", data);

  //   this.cdr.detectChanges();
  // }

  // userLeftRoom() {
  //   let observable = new Observable<{ user: String; message: String }>(
  //     (observer) => {
  //       this.socket.on("left room", (data) => {
  //         observer.next(data);
  //         this.cdr.detectChanges();
  //       });
  //       return () => {
  //         this.socket.disconnect();
  //       };
  //     }
  //   );

  //   return observable;
  // }

  // sendMessage(data) {
  //

  //   this.socket.emit("message", data);
  //   //this.cdr.detectChanges()
  // }

  // newMessageReceived() {
  //   let observable = new Observable<{ user: String; message: String }>(
  //     (observer) => {
  //       this.socket.on("new message", (data) => {
  //         observer.next(data);
  //         this.cdr.detectChanges();
  //       });
  //       return () => {
  //         this.socket.disconnect();
  //       };
  //     }
  //   );

  //   return observable;
  // }

  getRdvById(id: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    const url = `${this.apiURL2}?act_uuid=${id}`

    return this.http.get<any>(url, { params }).pipe(
      catchError(err => {
        console.error('ACTIVER ITEM', id, err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => {})
    )
  }
  // private methods
  //get presentation
  getPresentation(id: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid).set('id', id)

    return this.http.get<any>(`${this.API_URLgetPresentation}`, { params }).pipe(
      catchError(err => {
        console.error('ACTIVER ITEM', id, err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => {})
    )
  }
  getHobies(id: any): Observable<any> {
    let lang = localStorage.getItem('language')
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid).set('id', id).set('lan', lang)

    return this.http.get<any>(`${this.API_URLgetHobies}`, { params }).pipe(
      catchError(err => {
        console.error('ACTIVER ITEM', id, err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => {})
    )
  }
  getRestoDetails(id: any): Observable<any> {
    let restaurant_id = id
    let sessionid = this.getAuthFromLocalStorage()
    let countrycode = localStorage.getItem('pays')

    const params = new HttpParams()
      .set('sessionid', sessionid)
      .set('countrycode', countrycode)
      .set('restaurant_id', restaurant_id)

    return this.http.post<any>(`${this.API_URLgetResto}`, {}, { params }).pipe(
      catchError(err => {
        console.error('ACTIVER ITEM', id, err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => {})
    )
  }
  getdetais_weather(act_start_dt: any): Observable<any> {
    let countrycode = localStorage.getItem('pays')
    let city = localStorage.getItem('ville')

    const params = new HttpParams().set('countrycode', countrycode).set('city', city).set('act_start_dt', act_start_dt)

    return this.http.post<any>(`${this.API_URLgetWeather}`, {}, { params }).pipe(
      catchError(err => {
        console.error('ACTIVER ITEM', err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => {})
    )
  }
  getCommunications(): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    let language = localStorage.getItem('language')
    if (language == null) {
      language = navigator.language.split('-')[0]
    } else {
      language = localStorage.getItem('language')
    }
    const params = new HttpParams().set('sessionid', sessionid).set('language', language)

    return this.http.get<any>(`${this.API_URLGETCOMM}`, { params }).pipe(
      catchError(err => {
        console.error('ACTIVER ITEM', err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => {})
    )
  }
  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe())
  }
}
