import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'

export interface IDeactivateComponent {
  canExit: () => Observable<boolean> | Promise<boolean> | boolean
}

@Injectable({
  providedIn: 'root',
})
export class PreventUnsavedChangesGuard  {
  public canDeactivate(component: IDeactivateComponent): Observable<boolean> | Promise<boolean> | boolean {
    return component.canExit ? component.canExit() : true
  }
}
