import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormBuilder } from '@angular/forms'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { imgEncoder } from 'src/app/utils/imgEncoder'
import { AdminParkingService } from '../admin-parking.service'
import { ModalCalendarComponent } from '../modal-calendar/modal-calendar.component'
import { ModalDeleteReservationComponent } from '../modal-delete-reservation/modal-delete-reservation.component'
import { ModalEmplacementComponent } from '../modal-emplacement/modal-emplacement.component'
import { ModalReservationSecondComponent } from '../modal-reservation-second/modal-reservation-second.component'

@Component({
  selector: 'app-list-reservations',
  templateUrl: './list-reservations.component.html',
  styleUrls: ['./list-reservations.component.scss'],
})
export class ListReservationsComponent implements OnInit {
  @Input() parkId: any
  @Input() date: any
  listReservations: any = []
  @Input() refresh

  constructor(
    public adminParkingService: AdminParkingService,
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    public modal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.getAllReservations()
  }

  openModalCreate() {
    const modalRef = this.modalService.open(ModalEmplacementComponent)

    modalRef.result.then(
      () => this.adminParkingService.fetch(),
      () => {}
    )
  }

  openModalReserve() {
    const modalRef = this.modalService.open(ModalCalendarComponent, {
      size: 'xl',
    })

    modalRef.result
      .then
      //() => this.eventService.fetch(),
      //() => {}
      ()
  }

  closeModal() {
    this.modal.close()
  }

  getAllReservations() {
    this.adminParkingService.getAllReservations(this.parkId, this.date).subscribe(res => (this.listReservations = res))
  }

  encoder(s: any) {
    return imgEncoder(s)
  }

  openReservationModal() {
    const modalRef = this.modalService.open(ModalReservationSecondComponent)

    modalRef.componentInstance.date = this.date
    modalRef.componentInstance.parkId = this.parkId

    modalRef.result.then(
      () => this.getAllReservations(),
      () => {}
    )
  }

  openDeleteReservationModal(reservationId) {
    const modalRef = this.modalService.open(ModalDeleteReservationComponent)

    modalRef.componentInstance.reservationId = reservationId

    modalRef.result.then(
      () => {
        this.getAllReservations()
        this.refresh
      },
      () => {}
    )
  }
}
