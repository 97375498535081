import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { SplashScreenService } from './_metronic/partials/layout/splash-screen/splash-screen.service'
import { TableExtendedService } from './_metronic/shared/crud-table'
import { TranslationService } from './modules/i18n/translation.service'
import { locale as enLang } from './modules/i18n/vocabs/en'
import { locale as frLang } from './modules/i18n/vocabs/fr'

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = []
  hide: boolean = true

  constructor(
    private translationService: TranslationService,
    private splashScreenService: SplashScreenService,
    private router: Router,
    private tableService: TableExtendedService
  ) {
    // register translations
    this.translationService.loadTranslations(enLang, frLang)
  }

  ngOnInit() {
    const routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // clear filtration paginations and others
        this.tableService.setDefaults()
        // hide splash screen
        this.splashScreenService.hide()

        // scroll to top on every route change
        window.scrollTo(0, 0)

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded')
        }, 500)

        //  ;(window as any).HubSpotConversations.widget.refresh()
      }
    })
    this.unsubscribe.push(routerSubscription)
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe())
  }
  hideCookies() {
    this.hide = false
  }
}
