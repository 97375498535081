import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { getSessionFromLocalStorage } from 'src/app/utils/getSessionFromLocalStorage'
import { v4 } from 'uuid'
import { environment } from './../../../../environments/environment'

export class ResvRuleT {
  id: string = v4()
  code: RuleCodeT
  targetType: 1 | 2 | 3 | 4 | 5
  targetId: number
  excludeAbsence: 0 | 1
  value: number

  constructor(init?: Partial<ResvRuleT>) {
    this.excludeAbsence = 0
    this.code = 'MAX_RESV_PER_DAY'
    Object.assign(this, init)
  }
}

export type RuleCodeT =
  | 'MAX_RESV_PER_DAY'
  | 'MIN_RESV_PER_DAY'
  | 'MAX_RESV_PER_WEEK'
  | 'MIN_RESV_PER_WEEK'
  | 'EXCLUDE_ABSENCE'
@Injectable({
  providedIn: 'root',
})
export class OspReservationRulesService {
  constructor(private http: HttpClient) {}

  get httpParams() {
    return new HttpParams().set('sessionid', getSessionFromLocalStorage())
  }
  createRule(ruleObj: any) {
    return this.http.post(environment.apiUrl + '/osplanner/reservation-rules/create', ruleObj, {
      params: this.httpParams,
    })
  }
}
