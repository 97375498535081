import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, catchError, of } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class CovoiturageService {
  http: HttpClient
  constructor(http: HttpClient) {
    this.http = http
  }
  protected authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`

  protected getAuthFromLocalStorage(): any {
    try {
      const authData = JSON.parse(localStorage.getItem(this.authLocalStorageToken))
      return authData
    } catch (error) {
      console.error(error)
      if (error.status == 401) {
        window.location.reload()
      }
      return undefined
    }
  }

  API_URL_GET_POLYLINE = `${environment.apiUrl}/covoiturage/testPolyline`
  API_URL_COVOITURAGE_LOCALISATION = `${environment.apiUrl}/covoiturage/saveLocalisation`
  API_URL_COVOITURAGE_GET_LOCALISATION = `${environment.apiUrl}/covoiturage/getLocalisation`
  API_URL_ADD_COVOITURAGE = `${environment.apiUrl}/covoiturage/saveCovoiturage`
  API_URL_COVOITURAGE_GET_COVOITURAGE = `${environment.apiUrl}/covoiturage/getCovoiturage`
  API_URL_ADD_MOBILITE = `${environment.apiUrl}/covoiturage/saveMobilite`
  API_URL_UPDATE_MOBILITE = `${environment.apiUrl}/covoiturage/updateMobilite`
  API_URL_COVOITURAGE_GET_MOBILITE = `${environment.apiUrl}/covoiturage/getMobiliteById`
  API_URL_COVOITURAGE_GET_MOBILITE_BY_DATE = `${environment.apiUrl}/covoiturage/getMobiliteByIdDate`
  API_URL_MY_POSITION = `${environment.apiUrl}/covoiturage/updateGeoPos`
  API_URL_COVOITURAGE_GET_GEOPOSITION = `${environment.apiUrl}/covoiturage/getGeoPos`
  API_URL_COVOITURAGE_GET_COLLAB_PROCHE = `${environment.apiUrl}/covoiturage/getCollabProche`
  API_URL_COVOITURAGE_GET_COLLAB_PROCHE_TO_PROPOSE = `${environment.apiUrl}/covoiturage/getCollabProcheToPropose`
  API_URL_COVOITURAGE_GET_USER_SITE_POSITION = `${environment.apiUrl}/covoiturage/getUserPosSitePos`
  API_URLLE4 = `${environment.apiUrl}/todo/autocomplete_ville`
  API_URL_RESERVATION = `${environment.apiUrl}/covoiturage/reserverCovoiturage`
  API_URL_SEND_EMAIL = `${environment.apiUrl}/covoiturage/sendEmailCovoiturage`
  API_URL_PROPOSER = `${environment.apiUrl}/covoiturage/proposerCovoiturage`
  API_URL_RESERVER = `${environment.apiUrl}/covoiturage/demanderCovoiturage`
  API_URL_DELETE_POINT = `${environment.apiUrl}/covoiturage/deletePointPassage`

  getauto(item: any): Observable<any> {
   
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    return this.http.post<any>(this.API_URLLE4, item, { params })
  }
  getPolylineByLonLat(
    longOrigin: any,
    latOrigin: any,
    longDestination: any,
    latDestination: any,
    tabVia: any
  ): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams()
      .set('sessionid', sessionid)
      .set('longOrigin', longOrigin)
      .set('latOrigin', latOrigin)
      .set('longDestination', longDestination)
      .set('latDestination', latDestination)
      .set('tabVia', tabVia)
    const url = `${this.API_URL_GET_POLYLINE}`

    return this.http.get<any>(url, { params }).pipe(
      catchError(err => {
        console.error('ACTIVER ITEM', err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      })
    )
  }
  addPoint(item: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    let longitude = item.longitude
    let latitude = item.latitude

    let body = {
      longitude,
      latitude,
    }
    return this.http.post<any>(this.API_URL_COVOITURAGE_LOCALISATION, body, { params })
  }
  addCovoiturage(item: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    let type = item.type
    let secificity = item.secificity
    let matricule = item.matricule
    let marque = item.marque
    let modele = item.modele
    let parking = item.parking
    if (item.parking == true) {
      parking = 1
    } else {
      parking = 0
    }

    //type, secificity, matricule, marque, modele
    let body = {
      type,
      secificity,
      matricule,
      marque,
      modele,
      parking,
    }
    return this.http.post<any>(this.API_URL_ADD_COVOITURAGE, body, { params })
  }
  getPoint(): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(this.API_URL_COVOITURAGE_GET_LOCALISATION, { params })
  }
  getCovoiturage(): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(this.API_URL_COVOITURAGE_GET_COVOITURAGE, { params })
  }
  saveMobilite(date, nbrPlace): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid).set('date', date).set('nbrPlace', nbrPlace)

    //type, secificity, matricule, marque, modele

    return this.http.post<any>(this.API_URL_ADD_MOBILITE, null, { params })
  }
  updateMobilite(date, nbrPlace): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid).set('date', date).set('nbrPlace', nbrPlace)

    //type, secificity, matricule, marque, modele

    return this.http.put<any>(this.API_URL_UPDATE_MOBILITE, null, { params })
  }
  getMobilite(): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(this.API_URL_COVOITURAGE_GET_MOBILITE, { params })
  }
  getMobiliteByIdDate(date): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid).set('date', date)
    return this.http.get<any>(this.API_URL_COVOITURAGE_GET_MOBILITE_BY_DATE, { params })
  }
  addPosition(item: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    let long = item.longitude
    let lat = item.latitude

    let body = {
      long,
      lat,
    }
    return this.http.put<any>(this.API_URL_MY_POSITION, body, { params })
  }
  proposerCovoiturage(item: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    return this.http.post<any>(this.API_URL_PROPOSER, item, { params })
  }
  reserverCovoiturage(item: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    return this.http.post<any>(this.API_URL_RESERVER, item, { params })
  }
  getGeoPos(): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(this.API_URL_COVOITURAGE_GET_GEOPOSITION, { params })
  }
  getCollabProche(date): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid).set('date', date)
    return this.http.get<any>(this.API_URL_COVOITURAGE_GET_COLLAB_PROCHE, { params })
  }
  getCollabProcheToPropose(date): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid).set('date', date)
    return this.http.get<any>(this.API_URL_COVOITURAGE_GET_COLLAB_PROCHE_TO_PROPOSE, { params })
  }
  getUserPosSitePos(): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(this.API_URL_COVOITURAGE_GET_USER_SITE_POSITION, { params })
  }
  reserver(idCoviturage): Observable<any> {
    let body = idCoviturage
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    //type, secificity, matricule, marque, modele

    return this.http.post<any>(this.API_URL_RESERVATION, body, { params }).pipe(
      catchError(err => {
        // window.location.reload()
        return of(err.status)
      })
    )
  }
  sendEmailCovoiturage(email): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('email', email).set('sessionid', sessionid)

    //type, secificity, matricule, marque, modele

    return this.http.get<any>(this.API_URL_SEND_EMAIL, { params })
  }
  deletePointCovoiturage(point_passage: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('point_passage', point_passage).set('sessionid', sessionid)

    //type, secificity, matricule, marque, modele

    return this.http.delete<any>(this.API_URL_DELETE_POINT, { params })
  }
}
