import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-closed-modal',
  templateUrl: './closed-modal.component.html',
  styleUrls: ['./closed-modal.component.scss'],
})
export class ClosedModalComponent implements OnInit, OnDestroy {
  constructor(
    public modal: NgbActiveModal,
    private elementRef: ElementRef,
    private router: Router,
    private toastr: ToastrService
  ) {}
  public text: String

  ngOnInit(): void {
    const HTML = document.getElementsByTagName('html')[0]
    HTML.style.pointerEvents = 'none'
  }
  ngOnDestroy(): void {
    const HTML = document.getElementsByTagName('html')[0]
    HTML.style.pointerEvents = 'auto'
  }
  ok() {
    this.toastr.success('Votre demande a été transmise. Vous serez recontacté très prochainement.')
    this.modal.close()

    //redirect me to page dashboard
    this.router.navigate(['/'])
  }
  sortir() {
    //this.toastr.success('Votre demande a été transmise. Vous serez recontacté très prochainement.')
    this.modal.close()

    //redirect me to page dashboard
    this.router.navigate(['/'])
  }
  // @HostListener('document:click', ['$event'])
  // clickout(event) {
  //   if (this.elementRef.nativeElement.contains(event.target)) {
  //     // const HTML = document.getElementById('blockOutsideClick')
  //     // HTML.style.pointerEvents = 'none'
  //     this.text = 'clicked inside'
  //   } else {
  //     this.text = 'clicked outside'
  //     const HTML = document.getElementsByTagName('html')[0]

  //     HTML.style.pointerEvents = 'none'
  //   }
  // }
}
