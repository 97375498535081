<div>
  <div class="modal-header modal-title h3">Gestionnaires des réservations</div>
  <div class="modal-body">
    <div *ngIf="!siteId" style="display: flex; align-items: center; justify-content: center; height: 25vh">
      <h4>Veuillez avoir au moins un site et un bâtiment pour utiliser cette fonctionnalité</h4>
    </div>
    <div *ngIf="siteId" class="mx-5 my-2">
      <mat-tab-group>
        <mat-tab label="Générer">
          <div class="mt-7" style="height: 60vh">
            <p>
              Bienvenue à notre algorithme IA de planfication automatique et intelligente des sièges pour vos collaborateurs.
              <br />
              Merci de remplir les informations ci-dessous pour générer un planning adapté à vos besoin 😊.
            </p>
            <br />
            <br />
            <div style="display: flex">
              <mat-form-field appearance="fill" class="mr-5">
                <mat-label>
                  {{ "OSPLANNER.CHOOSE_SITE" | translate }}
                </mat-label>
                <mat-select [ngModel]="siteId" (ngModelChange)="siteId = $event">
                  <mat-option *ngFor="let site of sites" [value]="site.value">
                    {{ site.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <!-- BUILDING ID - TO DISCUSS -->
              <!-- <mat-form-field appearance="fill" class="ml-5" *ngIf="siteId && siteBuildings?.length">
                <mat-label>
                  {{ "OSPLANNER.CHOOSE_BUILDING" | translate }}
                </mat-label>
                <mat-select [ngModel]="buildingId" (ngModelChange)="buildingId = $event">
                  <mat-option *ngFor="let building of siteBuildings" [value]="building?.id">
                    {{ building.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field> -->
            </div>

            <div class="d-flex w-100 align-items-center mt-10">
              <h6 class="m-0 mr-5" style="font-weight: normal; margin-right: 67px !important">Période du planning:</h6>
              <span class="d-flex align-items-center">
                <p style="margin: 0; padding: 0" class="mr-5">
                  {{ "GENERAL.FROM" | translate }}
                </p>
                <input
                  #startTimeRef
                  [ngModel]="dateStart"
                  (ngModelChange)="dateStart = $event"
                  type="date"
                  class="form-control"
                  style="height: auto; cursor: pointer"
                  [min]="dateNow"
                />
              </span>
              <span class="d-flex align-items-center">
                <p style="margin: 0; padding: 0" class="mx-5">{{ "GENERAL.TO" | translate }}:</p>
                <input
                  #endTimeRef
                  [ngModel]="dateEnd"
                  (ngModelChange)="dateEnd = $event"
                  type="date"
                  class="form-control"
                  style="height: auto; cursor: pointer"
                  [min]="dateNow"
                />
              </span>

              <span class="mb-1 ml-5 badge badge-pill badge-light" style="font-size: 0.95rem"> {{ durationInDays }} jours</span>
            </div>

            <div class="d-flex w-100 align-items-center mt-10">
              <h6 class="m-0 mr-5" style="font-weight: normal; margin-right: 80px !important">Horaires de travail:</h6>
              <span class="d-flex align-items-center">
                <p style="margin: 0; padding: 0" class="mr-5">
                  {{ "GENERAL.FROM" | translate }}
                </p>
                <input
                  #startTimeRef
                  [ngModel]="timeStart"
                  (ngModelChange)="timeStart = $event"
                  type="time"
                  class="form-control"
                  style="height: auto; cursor: pointer"
                />
              </span>
              <span class="d-flex align-items-center">
                <p style="margin: 0; padding: 0" class="mx-5">{{ "GENERAL.TO" | translate }}:</p>
                <input
                  #endTimeRef
                  [ngModel]="timeEnd"
                  (ngModelChange)="timeEnd = $event"
                  type="time"
                  class="form-control"
                  style="height: auto; cursor: pointer"
                />
              </span>

              <span class="mb-1 ml-5 badge badge-pill badge-light" style="font-size: 0.95rem"> {{ workingHours }} heures</span>
            </div>

            <div class="d-flex w-100 align-items-center mt-10">
              <h6 class="m-0 mr-5" style="font-weight: normal; margin-right: 53px !important">Mode de génération:</h6>

              <!-- radio group with mode full and delta -->

              <mat-radio-group aria-label="Select an option" [(ngModel)]="mode" (change)="mode = $event.value">
                <mat-radio-button class="mr-5" value="full">Génération complète</mat-radio-button>
                <mat-radio-button class="mr-5" value="delta">Génération incrémentale</mat-radio-button>
              </mat-radio-group>
            </div>
            <br />
            <div *ngIf="errMsg" class="alert-danger text-center py-3">{{ errMsg }}</div>
            <div class="modal-footer">
              <div style="display: flex">
                <button type="button" class="btn btn-danger btn-elevate mr-1" (click)="modal.dismiss()">
                  {{ "GENERAL.BTN_CANCEL" | translate }}
                </button>
                <button [disabled]="isLoading" type="button" class="btn btn-primary btn-elevate" (click)="submit()">
                  {{ "Commencer" | translate }}
                  <div *ngIf="isLoading" class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Historique">
          <div class="mt-7" style="height: 60vh">
            <div class="mat-elevation-z1 m-1">
              <table *ngIf="dataSource" mat-table [dataSource]="dataSource">
                <!-- interface Job {
  site_id: number
  site_name: string
  start_date: string
  end_date: string
  start_time: string
  end_time: string
  job_status: number
} -->
                <!-- Site Column -->
                <ng-container matColumnDef="site_name">
                  <th mat-header-cell *matHeaderCellDef>Site</th>
                  <td mat-cell *matCellDef="let element">{{ element.site_name }}</td>
                </ng-container>

                <!-- Start Date Column -->
                <ng-container matColumnDef="dt_start">
                  <th mat-header-cell *matHeaderCellDef>Date de début</th>
                  <td mat-cell *matCellDef="let element">{{ element.dt_start | localizedDate : "shortDate" }}</td>
                </ng-container>

                <!-- End Date Column -->
                <ng-container matColumnDef="dt_end">
                  <th mat-header-cell *matHeaderCellDef>Date de fin</th>
                  <td mat-cell *matCellDef="let element">{{ element.dt_end | localizedDate : "shortDate" }}</td>
                </ng-container>

                <!-- Start Time Column -->
                <ng-container matColumnDef="time_start">
                  <th mat-header-cell *matHeaderCellDef>Heure de début</th>
                  <td mat-cell *matCellDef="let element">{{ element.time_start.slice(0, 5) }}</td>
                </ng-container>

                <!-- End Time Column -->
                <ng-container matColumnDef="time_end">
                  <th mat-header-cell *matHeaderCellDef>Heure de fin</th>
                  <td mat-cell *matCellDef="let element">{{ element.time_end.slice(0, 5) }}</td>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="job_status">
                  <th mat-header-cell *matHeaderCellDef>Statut</th>
                  <td mat-cell *matCellDef="let element">{{ getJobStatus(element.job_status) }}</td>
                </ng-container>

                <ng-container matColumnDef="job_dt_start">
                  <th mat-header-cell *matHeaderCellDef>Job Commencé à</th>
                  <td mat-cell *matCellDef="let element">{{ element.job_dt_start | localizedDate : "shortDayTime" }}</td>
                </ng-container>

                <ng-container matColumnDef="exec_mode">
                  <th mat-header-cell *matHeaderCellDef>Mode</th>
                  <td mat-cell *matCellDef="let element">{{ [null, "Complet", "Incrémental"][element.exec_mode] }}</td>
                </ng-container>

                <!-- Actions Column -->
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef>Status</th>
                  <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="[0, 1, 2].includes(element.job_status)">
                      <div class="d-flex align-items-center justify-content-center">
                        <div class="mr-5">en cours</div>
                      </div>
                    </ng-container>
                    <div *ngIf="element.job_status === -1">échoué</div>
                    <div *ngIf="element.job_status === 3">terminé</div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>

              <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements"> </mat-paginator>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<!-- Workaround to prevent autofocus on modal open. Don't remove it -->
<input type="text" #preventFocus style="opacity: 0; display: block; width: 0; height: 0" />
