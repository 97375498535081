export default {
  GERER: {
    TITRE: 'Mes documents',
    BTN: 'Interroger vos documents',
    CREATEDOC:'Créer un document',
    FILTER1: {
      TOUS: 'Tous les salariés',
      PRIVATE: 'Privé',
      TEAM: 'Equipe',
      Site: 'Site',
    },
    FILTER2: {
      ATTENTE: 'En attente',
      INDEXATION: 'Indexation...',
      INDEXE: 'Indexé',
      ERR: 'Erreur',
    },
    TABLE: {
      COL1: 'Nom du document',
      COL2: 'Visibilité',
    },
  },
  INTERROGER: {
    REP: 'Réponse courte',
    Source: 'Source',
    Extrait: 'Extrait',
    LOADING: 'Chargement du document...',
    TXT: 'Vous pouvez interroger vos documents',
    BTN_NEW: 'Nouvelle question',
    BTN_CLOSE: 'Fermer',
  },
  UPLOAD: {
    title: 'Ajouter des documents',
    txt: 'Veuillez importer vos documents',
    error:
      "*Les comptes gratuits sont limités aux fichiers pdf de 30 pages max et 5Mo max. Si vous souhaitez lever ces limites, vous pouvez passer à l'offre premium.",
    visibilite: 'Changer la visibilité du document',
    prive: 'Privé',
    equipe: 'Equipe',
    site: 'Site',
    ts: 'Tous',
    doc_selectionne: 'Document sélectionnés',
    Fermer: 'Fermer',
    En_cours: 'En cours...',
    Soumettre: 'Soumettre',
  },
  EDIT: {
    title: 'Détails du document',
    EN_ATTENTE: 'En attente',
    INDEXATION: 'Indexation...',
    INDEXE: 'Indexé',
    ERR: 'Erreur',
    titre_doc: 'Titre du document',
    auteur: 'Auteur',
    IMPORTEEPAR: 'Importé par',
    DATE: "Date d'import",
    nbrPages: 'Nombres des pages',
    Version: 'Version',
    Sujet: 'Sujet',
    Resume: 'Résumé',
    listUser: 'Liste des utilisateurs concernés',
    Email: 'Email',
    Telephone: 'Téléphone',
    Entreprise: 'Entreprise',
    Fonction: 'Fonction',
    listeVide: "Pas d'utilisateurs concernés",
    add: 'Ajouter',
    addUser: {
      title: 'Ajouter des utilisateurs',
      txt: "Sélectionner l'utilisateur à ajouter à la liste des utilisateurs",
    },
  },
}
