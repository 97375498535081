import { DatePipe } from '@angular/common'
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Subscription } from 'rxjs'
import { GroupingState, ISortView, PaginatorState, SortState } from '../../../_metronic/shared/crud-table'
import { SurveyModalComponent } from '../survey-modal/survey-modal.component'
import { UserModel } from './../../../modules/auth/_models/user.model'
import { MySurveysService } from './../_services/my-surveys.service'

@Component({
  selector: 'app-my-surveys',
  templateUrl: './my-surveys.component.html',
  styleUrls: ['./my-surveys.component.scss'],
})
export class MySurveysComponent implements OnInit, OnDestroy, ISortView {
  paginator: PaginatorState
  sorting: SortState
  grouping: GroupingState
  isLoading: boolean
  filterGroup: UntypedFormGroup
  searchGroup: UntypedFormGroup
  private subscriptions: Subscription[] = []

  currentUser$: UserModel
  lan: any
  myDate: any
  dateNow: any
  constructor(
    public svc: MySurveysService<any>,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef
  ) {}

  currentRate = 1
  public isCollapsed = false

  ngOnInit(): void {
    this.svc.fetch({ all: true }, 'poll')
    this.paginator = this.svc.paginator
    this.sorting = this.svc.sorting
    const sb = this.svc.isLoading$.subscribe(res => {
      this.isLoading = res
      this.cdr.detectChanges()
    })
    this.subscriptions.push(sb)
  }

  openSurvey(target: any) {
    const modalRef = this.modalService.open(SurveyModalComponent, { size: 'lg' })
    modalRef.componentInstance.surveyId = target.survey_id
    modalRef.componentInstance.targetId = target.target_id
    modalRef.result.then(() => {
      this.svc.fetch({ all: true }, 'poll')
    })
  }

  dateForInput(date) {
    if (!date) {
      return ''
    }
    return this.datePipe.transform(new Date(date).getTime(), 'yyyy-MM-ddTHH:mm')
  }

  // sorting
  sort(column: string) {
    const sorting = this.sorting
    const isActiveColumn = sorting.column === column
    if (!isActiveColumn) {
      sorting.column = column
      sorting.direction = 'asc'
    } else {
      sorting.direction = sorting.direction === 'asc' ? 'desc' : 'asc'
    }
    this.svc.patchState({ sorting }, 'poll')
  }

  // pagination
  paginate(paginator: PaginatorState) {
    this.svc.patchState({ paginator }, 'poll')
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe())
  }
}
