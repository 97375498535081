export default {
  EVENT: 'évènement',
  DELETE_EVENT_PROMPT: "Êtes-vous sûr de vouloir supprimer l'événement",
  DELETE_EVENT: "Annuler l'évènement",
  EVENT_WAS_ADDED: "L'évènement a été ajouté!",
  ERROR_WHILE_EVENT_ADDING: "Erreur lors de l'ajout de l'évènement!",
  EVENT_WAS_DELETED: "L'évènement a été supprimé!",
  ERROR_WHILE_EVENT_DELETE: "Erreur lors de la suppression de l'évènement!",
  EVENT_ALREADY_EXISTS: "L'évènement existe déjà!",
  DROP_CARD_TO_ADD_EVENT: "Déposer l'une des cartes à gauche pour ajouter un évènement.",
  CLICK_EVENT_TO_DELETE: 'Clicker sur un évènement dans le calendrier pour le supprimer.',
}
