<div style="margin: 20px;">
  <h3>
  {{"reset.resettitle" | translate}}
  </h3>
  <h6>
  {{"reset.resetdesc" | translate}}
  </h6>
  </div>
  <div class="modal-footer">
      <div class="card-toolbar">
      <a class="btn btn-light btn-elevate" (click)="close()">{{"assocStrat.annuler" | translate }}</a>
      </div>
       <div class="card-toolbar" style="float:right">
      <button class="btn btn-primary" type="submit" (click)="resetStat()">{{"reset.confirm" | translate }}</button>
  </div>   
  </div>
  