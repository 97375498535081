export default {
  GERER: {
    TITRE: 'My documents',
    BTN: 'Query your documents',
    CREATEDOC: 'Create document',
    FILTER1: {
      TOUS: 'All employees',
      PRIVATE: 'Private',
      TEAM: 'Team',
      Site: 'Site',
    },
    FILTER2: {
      ATTENTE: 'Pending',
      INDEXATION: 'Indexing...',
      INDEXE: 'Indexed',
      ERR: 'Error',
    },
    TABLE: {
      COL1: 'Document name',
      COL2: 'Visibility',
    },
  },
  INTERROGER: {
    REP: 'Short answer',
    Source: 'Source',
    Extrait: 'Extract',
    LOADING: 'Loading document...',
    TXT: 'You can query your documents',
    BTN_NEW: 'New question',
    BTN_CLOSE: 'Close',
  },
  UPLOAD: {
    title: 'Add documents',
    txt: 'Please import your documents',
    error:
      '*Free accounts are limited to pdf files of 30 pages max and 5MB max. If you wish to lift these limits, you can upgrade to the premium offer.',
    visibilite: 'Change document visibility',
    prive: 'Private',
    equipe: 'Team',
    site: 'Site',
    ts: 'All',
    doc_selectionne: 'Selected documents',
    Fermer: 'Close',
    En_cours: 'In progress...',
    Soumettre: 'Submit',
  },
  EDIT: {
    title: 'Document details',
    EN_ATTENTE: 'Waiting',
    INDEXATION: 'Indexation...',
    INDEXE: 'Indexed',
    ERR: 'Error',
    titre_doc: 'Document title',
    auteur: 'Author',
    IMPORTEEPAR: 'Imported by',
    DATE: 'Import date',
    nbrPages: 'Number of pages',
    Version: 'Version',
    Sujet: 'Subject',
    Resume: 'Summary',
    listUser: 'List of users concerned',
    Email: 'Email',
    Telephone: 'Phone',
    Entreprise: 'Company',
    Fonction: 'Function',
    listeVide: 'No users concerned',
    add: 'Add',
    addUser: {
      title: 'Add users',
      txt: 'Select the user to add to the user list',
    },
  },
}
