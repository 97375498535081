import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-nouveautes',
  templateUrl: './nouveautes.component.html',
  styleUrls: ['./nouveautes.component.scss'],
})
export class NouveautesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
