<div class="d-flex w-100 h-100">
  <div class="bg-white w-100 h-100 d-flex flex-column justify-content-center px-15">
    <div #wizard class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
      <!--begin: Form Wizard Nav -->
      <div class="wizard-nav border-bottom">
        <div class="wizard-steps p-8 p-lg-10">
          <a class="wizard-step" href="javascript:;" data-wizard-type="step" data-wizard-state="current">
            <div class="wizard-label">
              <i class="wizard-icon flaticon-layers"></i>
              <h3 class="wizard-title">1) {{ "OSPLANNER.RESV_RULES.CHOICE" | translate }}</h3>
            </div>
          </a>
          <a class="wizard-step" href="javascript:;" data-wizard-type="step">
            <div class="wizard-label">
              <i class="wizard-icon flaticon-map"></i>
              <h3 class="wizard-title">2) {{ "OSPLANNER.RESV_RULES.CONFIGURE" | translate }}</h3>
            </div>
          </a>
        </div>
      </div>
      <!--end: Form Wizard Nav -->
      <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
        <div class="col-xl-12 col-xxl-7">
          <!--begin: Form Wizard Form-->
          <div>
            <!--begin: Form Wizard Step 1-->
            <div class="pb-5" data-wizard-type="step-content">
              <div class="pb-10 pb-lg-15">
                <h3 class="fw-bolder d-flex align-items-center text-dark">
                  {{ "OSPLANNER.RESV_RULES.CHOOSE" | translate }}
                </h3>
              </div>

              <div class="fv-row">
                <div class="row">
                  <div *ngFor="let ruleCode of ruleCodes; let index = index">
                    <div class="col">
                      <input
                        name="accountType"
                        type="radio"
                        class="btn-check premuim"
                        id="{{ ruleCode }}"
                        value="{{ ruleCode }}"
                        [(ngModel)]="rule.code"
                        style="display: none"
                        (click)="rule.code = ruleCode"
                      />
                      <label class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10" for="{{ ruleCode }}">
                        <span class="d-block fw-bold text-start">
                          <span class="text-dark fw-bolder d-block fs-4 mb-2" [style.fontSize]="'14px'">
                            {{ "OSPLANNER.RESV_RULES." + ruleCode | translate }}
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end: Form Wizard Step 1-->

            <div class="pb-5" data-wizard-type="step-content" style="min-width: 700px">
              <div class="row align-items-center mb-10" style="min-width: 700px">
                <span class="h4 mr-10">
                  {{ "OSPLANNER.RESV_RULES.CONFIG" | translate }}
                </span>

                <mat-form-field appearance="fill" class="mr-5">
                  <mat-select [value]="rule.targetType" (selectionChange)="changeTargetType($event)">
                    <mat-option [value]="5">{{ "OSPLANNER.RESV_RULES.TARGETS.5" | translate }} </mat-option>
                    <mat-option [value]="4">
                      {{ "OSPLANNER.RESV_RULES.TARGETS.4" | translate }}
                    </mat-option>
                    <mat-option [value]="3">
                      {{ "OSPLANNER.RESV_RULES.TARGETS.3" | translate }}
                    </mat-option>
                    <mat-option [value]="2">
                      {{ "OSPLANNER.RESV_RULES.TARGETS.2" | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field style="max-width: 80%" appearance="fill" *ngIf="rule.targetType < 5">
                  <mat-label> {{ targetLabels[rule.targetType - 2] }} </mat-label>
                  <mat-select [(value)]="rule.targetId">
                    <mat-option *ngFor="let target of targets[rule.targetType - 2]" [value]="target.value"> {{ target.viewValue }} </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="row align-items-center mb-10" *ngIf="rule.code" style="display: flex; flex-flow: column; flex-direction: row">
                <span class="h4 mr-10">
                  {{ "OSPLANNER.RESV_RULES." + (rule.code.indexOf("PER_DAY") > 0 ? "NBR_PPL" : "NBR_DAYS") | translate }}
                </span>
                <mat-form-field>
                  <input matInput placeholder="" [(ngModel)]="rule.value" />
                </mat-form-field>
              </div>
              <div class="row align-items-center mb-10" *ngIf="rule.code.indexOf('PER_WEEK') > 0">
                <span class="h4 mr-5">
                  {{ "OSPLANNER.RESV_RULES.EXCLUDE_ABSENCE" | translate }}
                </span>
                <mat-radio-group style="flex-wrap: wrap; flex-direction: row" [(ngModel)]="rule.excludeAbsence">
                  <mat-radio-button class="example-radio-button" [value]="1">
                    {{ "GENERAL.YES" | translate }}
                  </mat-radio-button>
                  <mat-radio-button class="example-radio-button" [value]="0"> {{ "GENERAL.NO" | translate }} </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>

            <div class="pb-5 cent" data-wizard-type="step-content">
              <div>
                <h4 class="mb-10 font-weight-bold text-dark">
                  {{ "WIZARDS.REVIEW" | translate }}
                </h4>
                <ng-lottie width="200px" height="220px" [styles]="styles" [options]="options"></ng-lottie>
                <!-- félicitation: -->
                <span> Félicitation, vous avez terminé la configuration de votre règle de réservation. </span>
              </div>
            </div>

            <!--begin: Form Actions -->
            <div class="d-flex justify-content-between border-top pt-10">
              <div class="mr-2">
                <div class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-prev">
                  {{ "WIZARDS.Previous" | translate }}
                </div>
              </div>
              <div>
                <button
                  (click)="createRule()"
                  [disabled]="!formIsValid"
                  class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                  data-wizard-type="action-submit"
                >
                  {{ "WIZARDS.Submit" | translate }}
                </button>
                <div>
                  <div>
                    <button [disabled]="!rule.code" class="btn btn-primary font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-next">
                      {{ "WIZARDS.Next" | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!--end: Form Actions -->
          </div>
          <!--end: Form Wizard Form-->
        </div>
      </div>
    </div>
  </div>
</div>
