import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Component, OnInit, Input, ChangeDetectorRef, Optional } from '@angular/core'
import { quizService } from '../_services/quiz.service'
import { ToastrService } from 'ngx-toastr'
// pour tracer quand l'utilisateur quitte la page de jeux
import { Router } from '@angular/router'
import { AuthHTTPService } from '../../auth/_services/auth-http'
import { WsService } from '../_services/wsquiz.service'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-jouer',
  templateUrl: './jouer.component.html',
  styleUrls: ['./jouer.component.scss'],
})
export class JouerComponent implements OnInit {
  key: any
  jeuxDispo: any
  gameid: any
  source: any
  baseUrl = environment.baseUrl
  redirectUrl = environment.redirectUrl

  quizid: any
  game_id: any
  strategy_id: any
  user_email: any
  user_id: any

  // on va utiliser la date de début/fin de session de jeux dans WM et WP et puis les comparer
  // pour déterminer si l'utilisateur a vraiment jouer au quiz et pas juste ouvrir le pop-up
  // et le fermer puisque le quiz est dans wordpress et indépendant de la page de quiz
  start_date_WM: Date
  start_date_WP: Date
  end_date_WM: Date
  end_date_WP: Date

  @Input() public playId
  constructor(
    public modal: NgbActiveModal,
    private squiz: quizService,
    private authservice: AuthHTTPService,
    private ws: WsService,
    private toastr: ToastrService,
    private router: Router,
    @Optional() public cdr: ChangeDetectorRef
  ) {
    this.cdr = cdr
  }

  ngOnInit() {
    // obtenir la valeur des id de jeux en utilisant le object destructuring
    const {
      gameid: gameid,
      strategyid: strategy_id,
      quizid: quizid,
      user_email: user_email,
      user_id: user_id,
    } = this.playId
    this.quizid = quizid
    this.game_id = gameid
    this.strategy_id = strategy_id
    this.user_email = user_email
    this.user_id = user_id

    this.squiz.startPlay(gameid, strategy_id)
    this.toastr.success('loading ...', '', {
      progressBar: true,
      progressAnimation: 'increasing',
      timeOut: 3000,
    })

    this.gameid = gameid
  }

  // get token and embedded it to wordpress url to get access to quiz
  linksrc(): any {
    // redirect user in iframe to this url that contains the quiz
    //that player has chosen to play
    let redirectUrl = this.redirectUrl + this.quizid + '/'
    this.key = localStorage.getItem('JWT')
    if (this.key == null) {
      // if this fires, there's a high chance the problem is in jwtlogin service
      this.toastr.error("aucun jeu n'est disponible pour l'instant !")
      this.source = 'https://chromedino.com/'
      this.source = this.source.replace(/"/g, '')
      //this.router.navigate(['quiz'])
    } else {
      let r =
        this.baseUrl +
        '&' +
        'redirectUrl=' +
        redirectUrl +
        '&' +
        'JWT=' +
        this.key +
        '&' +
        'user_email=' +
        this.user_email

      r = r.replace(/"/g, '') //remove this later
      this.source = r
    }
    return this.source
  }

  close(x) {
    let st: string
    let dt: string
    // si l'utilisateur quitte le jeux.
    // faire update sur le ligne qui contient
    // le game et strategy id spécififié pour sauvegarder la date exacte de fin de jeux
    this.squiz.endPlay(this.gameid)

    if (this.user_email != '') {
      this.squiz.getPlayDate(this.game_id, this.strategy_id).then(dates => {
        this.start_date_WM = new Date(dates[0].start_dt)
        this.end_date_WM = new Date(dates[0].end_dt)
        st = dates[0].start_dt
        dt = dates[0].end_dt
      })

      this.ws.getwsStats(this.user_id).then(stats => {
        this.start_date_WP = new Date(stats[0].start_date)
        this.end_date_WP = new Date(stats[0].end_date)

        /*  
PROBLÈME : comment savoir quand le joueur a joué au quiz et pas juste ouvert et fermer le pop-up ?

CAUSE DU PROBLÈME : get_score recherchera le dernier enregistrement dans 
                    wordpress reports table en utilisant l'email de user comme parametre dés 
                    que l'utilisateur ferme le pop-up donc si l'utilisateur n'as pas jouer au 
                    quiz.
                   

l'heure d'ouverture et de fermeture de le pop-up est enregistrée dans la base de données WM, 
ainsi que l'heure de jouer au quiz et sa fin est enregistrée dans la base de données wordpress.

SOLUTION : logiquement, l'heure à laquelle nous ouvrons la pop-up est "inférieure" pour 
           commencer à jouer l'heure du quiz "et" le temps de fermeture de la pop-up est 
           supérieur à la fin du quiz. de cette façon, nous pouvons comparer ces des dates 
           pour s'assurer que le joueur a vraiment joué au quiz
*/

        let isPlayed =
          this.start_date_WM < this.start_date_WP && this.end_date_WP > this.end_date_WM

        if (isPlayed) {
          let name = localStorage.getItem('name')
          this.toastr.success('hope to see you soon ' + name + ' ... bye !')
          this.squiz.insertScore(this.game_id, this.strategy_id, stats)
        } else {
          this.toastr.warning("tu n'as pas fini le quiz !")
          // delete record in WM db if player didn't finish playing quiz
          this.squiz.cleanRecord(st, dt, this.gameid, this.strategy_id)
        }
      })
    } else {
      this.toastr.warning('no email provided !! try to reconnect to solve the problem ...')
    }

    this.cdr.detectChanges()

    this.router.navigate(['trier-quiz'])
    this.modal.close()
  }
}
