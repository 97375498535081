import { Location } from '@angular/common'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap'
import * as FileSaver from 'file-saver'
import { Editor } from 'ngx-editor'
import { Subscription, interval } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { DashboardComponent } from './dashboard/dashboard/dashboard.component'

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexPlotOptions,
  ApexStroke,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
} from 'ng-apexcharts'
import { getSessionFromLocalStorage, getUserUuidFromLocalStorage } from 'src/app/utils/getSessionFromLocalStorage'
import { SurveyService } from '../_services/surveys.service'
import { environment } from './../../../../environments/environment'
const CSV_EXTENSION = '.csv'
interface SurveyReport {
  question_id: string
  target_id: string
  analysis: string
  status: number
  text: string
  created_at: string
}

@Component({
  selector: 'app-survey-results',
  templateUrl: './survey-results.component.html',

  styleUrls: ['./survey-results.component.scss'],
})
export class SurveyResultsComponent implements OnInit, OnDestroy {
  surveyId: string
  targetId: string
  results: any[] // without anonymous results
  allResults: any[] // with anonymous results
  questions: any[]
  compteRenduQues = 0
  reportQues: any
  isLoading: boolean
  isLoadingQuesReport = false
  isModalOpen = false
  editor: Editor
  html: string
  htmlContent: any
  report: any
  isRegen = false
  allResultsAreAnonymous = false
  isLoadingQuestion = false
  selectedQuestion = null
  statusSubscription: Subscription

  handleEditorChange(event: any) {
    this.html = event // Update your property when the editor content changes
  }
  onQuestionChange(question) {
    this.reportQues = null
    this.compteRenduQues = 0
    this.selectedQuestion = question
    setTimeout(() => {
      this.isLoadingQuestion = false
      this.cdr.detectChanges()
    }, 750)
    try {
      this.getQuesReport()
      this.compteRenduQues = 1
    } catch (error) {
      console.log('error fetching question report', error)
    }
  }

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private location: Location,
    private surveyService: SurveyService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.html = ''
    this.editor = new Editor()
    this.surveyId = this.route.snapshot.paramMap.get('surveyId')
    this.targetId = this.route.snapshot.paramMap.get('targetId')
    try {
      this.startPollingReportStatus()
    } catch (error) {}

    this.http
      .get<any>(environment.apiUrl + '/entreprise/sites/services/equipes', {
        params: new HttpParams().set('sessionid', getSessionFromLocalStorage()),
      })
      .subscribe(result => {
        this.targets = result
        this.getResults()
        console.log('targets', this.targets)
        console.log('results', this.results)
      })

    console.log('questions', this.questions)
  }

  getResults() {
    this.http
      .get<any>(environment.apiUrl + '/survey/results', {
        params: new HttpParams()
          .set('sessionid', getSessionFromLocalStorage())
          .set('surveyId', this.surveyId || '')
          .set('targetId', this.targetId || ''),
      })
      .subscribe(
        res => {
          this.results = res

          this.allResults = res
          this.allResultsAreAnonymous = this.allResults.every(x => !x.user_id)
          this.results.forEach(x => {
            x.result.sort((a, b) => a.order - b.order)
          })
          this.getQuestions()
          this.cdr.detectChanges()
          console.log('questions***', this.questions)
        },
        err => {
          console.error(err)
        }
      )
  }

  getTextValues(order: number) {
    const result = this.results.map(x => x.result.find(x => x.order === order).value)
    return result as string[]
  }

  /**
   * Les cibles (target): sites, services, équipes.
   */
  targets!: any

  formatStringList(list: string[]) {
    // remove dots from the end of the string
    list = list.map(x => x.replace(/\.$/, ''))
    return list.join(', ')
  }

  getQuestions() {
    const params = new HttpParams().set('userId', getUserUuidFromLocalStorage())
    const httpHeaders = new HttpHeaders({
      token: localStorage.getItem('QSP_AUTH_TOKEN'),
    })
    this.http
      .get<any>(environment.apiUrlQSP + '/survey/' + this.surveyId + '/questions', {
        params,
        headers: httpHeaders,
      })
      .subscribe(
        result => {
          this.questions = result.questions
          console.log('questions in get 1', this.questions)

          this.questions.sort((a, b) => a.order - b.order)
          console.log('questions in get 2', this.questions)
          this.selectedQuestion = this.questions[0]
          this.getQuesReport()
          this.cdr.detectChanges()
          this.results?.forEach((x, i) => {
            x.result.forEach(r => {
              r.value = r.value || r.choices.find(x => x.checked)?.text
            })
          })

          let time = 0
          const wait = setInterval(() => {
            if (this.targets) {
              this.processResultsForApexCharts()
              clearInterval(wait)
              console.log('results', this.results)
            }
            if (time > 10000) {
              clearInterval(wait)
              location.reload()
            }
            this.cdr.detectChanges()
            time += 500
          }, 500) // TODO: find a better way to do this. (use rxjs's forkJoin maybe?)
        },
        err => {
          console.error(err)
        }
      )
  }
  sites = []
  services = []
  equipes = []
  filter = null
  /**
   * Process the survey results and returns sets the data to be displayed in apexcharts.
   */
  processResultsForApexCharts(filter?: { site?: string; service?: string; equipe?: string }) {
    console.time('Processing survey results')

    this.selectedQuestion = this.questions[0]

    this.filter = filter
    const { sites, services, equipes } = this.targets
    this.sites = sites.map(x => x.viewValue)
    this.services = services.map(x => x.viewValue)
    this.equipes = equipes.map(x => x.viewValue)

    this.questions.forEach(q => {
      let qss = this.results
        .map(x => {
          const r = x.result.find(x => x.order === q.order)
          return { ...r, site: x.site, service: x.service, equipe: x.equipe }
        })
        .filter(x => !!x)

      if (['scale', 'rating'].includes(q.type)) {
        // declaration of a const as the value of: qss.filter(x => x.value).length)   (the questions that has a value for the response)
        const qssWithValues = qss.filter(x => x.value)
        const sum = qssWithValues.reduce((acc, current) => acc + (current.value || 0), 0)
        q.avg = Math.round((sum * 100) / qssWithValues.filter(x => x.value).length) / 100
        q.choices = new Array(q[q.type]).fill(0).map((x, i) => ({ id: i + 1, text: i + 1, avg: 0 } as any))
      }

      qss.forEach(q => {
        if (['scale', 'rating'].includes(q.type)) {
          q.avg = qss.reduce((acc, current) => acc + (current.value || 0), 0)
          q.choices = new Array(q[q.type])
            .fill(0)
            .map((x, i) => ({ id: i + 1, text: i + 1, avg: 0, checked: i + 1 === q.value } as any))
        }
      })

      if (q) {
        q.choices.forEach(choice => {
          if (!qss.length) choice.avg = 0
          else
            choice.avg = qss
              .map(x => x.choices.find(y => y.id === choice.id).checked)
              .reduce((acc, current) => (current ? acc + 1 : acc), 0)
        })
        q.choicesSeries = q.choices.map(x => x.avg)
        q.choicesLabels = q.choices.map(x => x.text)

        q.xaxis = {}
        q.series = {}
        q.title = {}
        q.series.sites = []
        this.sites.forEach(site => {
          const qssCiblé = qss.filter(x => x.site === site)

          q.choices.forEach(choice => {
            choice['avg_' + site] = qssCiblé
              .map(x => x.choices.find(y => y.id === choice.id).checked)
              .reduce((acc, current) => (current ? acc + 1 : acc), 0)
          })
        })
        q.choices.forEach(choice => {
          q.series.sites.push({
            name: choice.text,
            data: this.sites.map(x => choice['avg_' + x]),
          })
        })
        q.xaxis.sites = {
          categories: this.sites,
          labels: {
            formatter,
          },
        }
        q.title.sites = { text: 'Répartition par site' }

        q.series.services = []
        this.services.forEach(service => {
          const data = []
          const qssCiblé = qss.filter(
            x => x.service === service && (this.filter?.site ? filter?.site === x.site : true)
          )

          q.choices.forEach(choice => {
            choice['avg_' + service] = qssCiblé
              .map(x => x.choices.find(y => y.id === choice.id).checked)
              .reduce((acc, current) => (current ? acc + 1 : acc), 0)
          })
        })
        q.choices.forEach(choice => {
          q.series.services.push({
            name: choice.text,
            data: this.services.map(x => choice['avg_' + x]),
          })
        })
        q.xaxis.services = {
          categories: this.services,
          labels: {
            formatter,
          },
        }
        q.title.services = { text: 'Répartition par service' }

        q.series.equipes = []
        this.equipes.forEach(equipe => {
          const data = []
          const qssCiblé = qss.filter(
            x =>
              x.equipe === equipe &&
              (this.filter?.site ? filter?.site === x.site : true) &&
              (this.filter?.service ? filter?.service === x.service : true)
          )

          q.choices.forEach(choice => {
            choice['avg_' + equipe] = qssCiblé
              .map(x => x.choices.find(y => y.id === choice.id).checked)
              .reduce((acc, current) => (current ? acc + 1 : acc), 0)
          })
        })
        q.choices.forEach(choice => {
          q.series.equipes.push({
            name: choice.text,
            data: this.equipes.map(x => choice['avg_' + x]),
          })
        })
        q.xaxis.equipes = {
          categories: this.equipes,
          labels: {
            formatter,
          },
        }
        q.title.equipes = { text: 'Répartition par équipe' }
      }
    })
    console.timeEnd('Processing survey results')
    console.log('results', this.results)
    this.cdr.detectChanges()
  }

  noQuestionResultsYet(question) {
    return question.type === 'choice' ? !question.choices.filter(x => x.avg > 0).length : !question.avg
  }
  startPollingReportStatus(): void {
    this.statusSubscription = interval(5000) // Poll every 5 seconds
      .pipe(switchMap(() => this.surveyService.getReportStatus(this.targetId)))
      .subscribe(
        (data: SurveyReport) => {
          console.log('data********', data)
          this.isLoading = data[0]?.status == 1
          console.log('isLoading', this.isLoading)

          if (!this.isLoading) {
            // Stop polling if the status is not 1
            this.statusSubscription.unsubscribe()
          }
          console.log('report status', data[0]?.status)
        },
        error => {
          console.error(error)
          // Optionally handle the error, and stop polling if needed
          this.statusSubscription.unsubscribe()
        }
      )
  }

  get noResultsAllQuestions() {
    return false
    // question types: scale, rating, choice
    let noResults = false
    this.results.some(r => {
      noResults = r.result.some(q => this.noQuestionResultsYet(q))
      return !noResults
    })
    return noResults
  }

  exportResults(type: string) {
    if (type === 'xlsx') {
      return
    }
    const rows = []
    this.allResults.forEach(r => {
      const row = {}
      row['timestamp'] = r.created_at
      row['Nom'] = r.lname
      row['Prénom'] = r.fname
      r.result.forEach((rep, i) => {
        const q = this.questions[i]
        row[q.order + 1 + '- ' + q.text] = rep.value
      })
      rows.push(row)
    })

    this.exportToCsv(rows, "Résultats de l'enquête")
  }

  /**
   * Saves the file on the client's machine via FileSaver library.
   *
   * @param buffer The data that need to be saved.
   * @param fileName File name to save as.
   * @param fileType File type to save as.
   */
  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType })
    FileSaver.saveAs(data, fileName)
  }

  /**
   * Creates an array of data to CSV. It will automatically generate a title row based on object keys.
   *
   * @param rows array of data to be converted to CSV.
   * @param fileName filename to save as.
   * @param columns array of object properties to convert to CSV. If skipped, then all object properties will be used for CSV.
   */
  public exportToCsv(rows: object[], fileName: string, columns?: string[]): string {
    if (!rows || !rows.length) {
      return
    }
    const separator = ','
    const keys = Object.keys(rows[0]).filter(k => {
      if (columns?.length) {
        return columns.includes(k)
      } else {
        return true
      }
    })
    const csvContent =
      keys.join(separator) +
      '\n' +
      rows
        .map(row => {
          return keys
            .map(k => {
              let cell = row[k] === null || row[k] === undefined ? '' : row[k]
              cell = cell instanceof Date ? cell.toLocaleString() : cell.toString().replace(/"/g, '""')
              if (cell.search(/("|,|\n)/g) >= 0) {
                cell = `"${cell}"`
              }
              return cell
            })
            .join(separator)
        })
        .join('\n')
    this.saveAsFile(csvContent, `${fileName}${CSV_EXTENSION}`, 'text/csv')
  }

  Math = Math

  view: any[] = [600, 150]

  // options
  showXAxis: boolean = true
  showYAxis: boolean = true
  gradient: boolean = false
  showLegend: boolean = false
  showXAxisLabel: boolean = true
  yAxisLabel: string = 'Choix'
  showYAxisLabel: boolean = true
  xAxisLabel: string = 'Nombre de Réponses'

  colorScheme = {
    domain: ['#A0E8B7', '#EB0A44', '#F2643D', '#F2A73D', '#A0E8B7'],
  }

  getChoicesData(choices: any) {
    return choices.map(x => ({ value: x.avg, name: x.text }))
  }

  changeChartType() {
    if (this.chartOptions.chart.stackType) {
      this.chartOptions.chart = {
        type: 'bar',
        height: 'auto',
        stacked: true,
      }
    } else {
      this.chartOptions.chart = {
        type: 'bar',
        height: 'auto',
        stacked: true,
        stackType: '100%',
      }
    }
  }

  chartServices = {
    type: 'bar',
    height: 'auto',
    stacked: true,
    events: {
      click: (chart, w, e) => {
        if (e.seriesIndex > -1 && e.dataPointIndex > -1) {
          this.processResultsForApexCharts({ service: this.services[e.dataPointIndex] })
        }
      },
    },
    animations: {
      enabled: true,
    },
  }

  chartSites = {
    type: 'bar',
    height: 'auto',
    stacked: true,
    events: {
      click: (chart, w, e) => {
        if (e.seriesIndex > -1 && e.dataPointIndex > -1) {
          this.processResultsForApexCharts({ site: this.sites[e.dataPointIndex] })
        }
      },
    },
    animations: {
      enabled: true,
    },
  }

  chartOptions: ChartOptions = {
    series: [
      {
        name: 'oui',
        data: [44, 55, 41, 37, 22, 43, 21],
      },
      {
        name: 'non',
        data: [53, 32, 33, 52, 13, 43, 32],
      },
    ],
    chart: id => ({
      id,
      type: 'bar',
      height: 'auto',
      stacked: true,
      events: {
        click: (chart, w, e) => {
          if (e.seriesIndex > -1 && e.dataPointIndex > -1) {
            if (e.config?.chart?.id === 'sites') {
              this.processResultsForApexCharts({ site: this.sites[e.dataPointIndex] })
            }
            if (e.config?.chart?.id === 'services') {
              this.processResultsForApexCharts({ service: this.services[e.dataPointIndex] })
            }
          }
        },
      },
      animations: {
        enabled: true,
      },
    }),
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    title: {
      text: '',
    },
    yaxis: {
      title: {
        text: undefined,
      },
      labels: {
        formatter,
      },
      type: 'numeric',
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40,
    },

    colors: [
      '#DBD26B', // Yellowish
      '#3EE6DF', // Cyan
      '#F2643D', // Orange
      '#F2A73D', // Yellow-orange
      '#A0E8B7', // Light green
      '#9B3EE6', // Purple
      '#7DF23E', // Lime green
      '#3E57F2', // Blue
      '#F23E86', // Pink
      '#E63E3E', // Red
    ],
  }

  goBackToPrevPage(): void {
    this.location.back()
  }
  //get question report if it was already generated
  getQuesReport() {
    this.isLoadingQuesReport = true
    this.compteRenduQues = 1
    this.reportQues = null
    if (!this.selectedQuestion) {
      this.selectedQuestion = this.questions[0]
    }

    this.surveyService.getQuestionReport(this.selectedQuestion.id).subscribe(
      (data: SurveyReport) => {
        console.log('data', data)

        // Directly stringify the analysis object without cleaning brackets (unless absolutely necessary)
        this.reportQues = data[0]?.analysis
        this.html = this.reportQues
        this.isLoadingQuesReport = false // Set loading to false when the data is successfully returned
        this.cdr.detectChanges()
        if (!this.reportQues) {
          this.compteRenduQues = 0
        }

        // Consider removing this check if reportQues initialization is sufficient
      },
      error => {
        console.error('Error fetching results', error)
        this.isLoadingQuesReport = false
        this.compteRenduQues = 0 // Set loading to false in case of an error
      }
    )
  }
  getReport() {
    this.isLoading = true

    this.surveyService.getSurveyReport(this.results[0]?.target_id).subscribe(
      (data: SurveyReport) => {
        console.log('data', data)

        // Set loading to false when the data is successfully returned
        this.htmlContent = data[0]?.analysis
        this.cdr.detectChanges()
        if (this.htmlContent?.length) {
          this.isLoading = false
          this.openModal()
          this.cdr.detectChanges()
        }
        if (!this.htmlContent) {
          this.generateReport()
        }
      },
      error => {
        console.error('Error fetching results', error)
        this.isLoading = false // Set loading to false in case of an error
      }
    )
  }
  generateReport(regenerate = false) {
    this.isLoading = true
    const questionDetails = {
      regen: regenerate,
      questions: this.questions,
      results: this.results,
      targets: this.targets,
      target_ID: this.results[0]?.target_id,
      survey_ID: this.surveyId,
    }

    console.log('surveyDetails', questionDetails)
    this.surveyService.generateSurveyReport(questionDetails).subscribe(
      (data: SurveyReport) => {
        console.log('data', data)
        this.isLoading = false // Set loading to false when the data is successfully returned
        this.htmlContent = data.text
        this.getQuesReport()
        this.cdr.detectChanges()
        this.openModal()
      },
      error => {
        console.error('Error fetching results', error)
        this.isLoading = false // Set loading to false in case of an error
      }
    )
  }
  openModal() {
    this.isModalOpen = true
    const modalOptions: NgbModalOptions = {
      centered: false,
      size: 'xl',
      backdrop: true,
      keyboard: true,
    }
    const modalRef = this.modalService.open(DashboardComponent, modalOptions)
    modalRef.componentInstance.htmlContent = this.htmlContent
    modalRef.componentInstance.targetId = this.targetId
    modalRef.componentInstance.targets = this.targets
    modalRef.componentInstance.questions = this.questions
    modalRef.componentInstance.results = this.results
  }

  closeModal() {
    this.isModalOpen = false
  }
  ngOnDestroy(): void {
    this.editor.destroy()
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe()
    }
  }
  saveQuesReport() {
    //call the service to save the report
    this.isLoading = true
    const questionDetails = {
      question_Id: this.selectedQuestion.id,
      analysis: this.html,
    }
    this.surveyService.saveQuestionReport(questionDetails).subscribe(
      data => {
        console.log('data', data)
        this.isLoading = false // Set loading to false when the data is successfully returned
        this.cdr.detectChanges()
      },
      error => {
        console.error('Error fetching results', error)
        this.isLoading = false // Set loading to false in case of an error
      }
    )
  }
  saveReport(html: any) {
    //call the service to save the report
    this.isLoading = true
    const surveyDetails = {
      target_ID: this.results[0]?.target_id,
      analysis: html,
    }
    console.log('surveyDetails', surveyDetails)
    this.surveyService.saveSurveyReport(surveyDetails).subscribe(
      data => {
        console.log('data', data)
        this.isLoading = false // Set loading to false when the data is successfully returned
        this.cdr.detectChanges()
      },
      error => {
        console.error('Error fetching results', error)
        this.isLoading = false // Set loading to false in case of an error
      }
    )
  }
}

type ChartOptions = {
  series: ApexAxisChartSeries
  chart: ApexChart
  dataLabels: ApexDataLabels
  plotOptions: ApexPlotOptions
  xaxis: ApexXAxis
  yaxis: ApexYAxis
  stroke: ApexStroke
  title: ApexTitleSubtitle
  tooltip: ApexTooltip
  fill: ApexFill
  legend: ApexLegend
} & any

function formatter(val) {
  if (isNaN(Number(val))) {
    return val
  }
  return Math.round(val) + ''
}
