import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs'
import { catchError, finalize, map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { DatePipe } from '@angular/common'

@Injectable({
  providedIn: 'root',
})
export class quizService {
  // Private fields
  private _isLoading$ = new BehaviorSubject<boolean>(false)
  private _isFirstLoading$ = new BehaviorSubject<boolean>(true)
  private _errorMessage = new BehaviorSubject<string>('')
  subscription: Subscription

  // get game name when a game is clicked in the catalogue
  private messageSource = new BehaviorSubject('default message')
  currentMessage = this.messageSource.asObservable()

  private _subscriptions: Subscription[] = []
  protected authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`
  protected http: HttpClient

  API_URLsa = `${environment.apiUrl}/listedesjeux/getAGameslist`
  API_URLsn = `${environment.apiUrl}/listedesjeux/getNonAGameslist`
  API_URL1 = `${environment.apiUrl}/listedesjeux/getPlayersNumber`
  API_URL2 = `${environment.apiUrl}/listedesjeux/getGamesDetails`
  API_URL3 = `${environment.apiUrl}/listedesjeux/getStrategies`
  API_URL4 = `${environment.apiUrl}/listedesjeux/activateStrategy`
  API_URL5 = `${environment.apiUrl}/listedesjeux/deactivateStrategy`
  API_URL6 = `${environment.apiUrl}/listedesjeux/dissasociateStrategy`
  API_URL7 = `${environment.apiUrl}/listedesjeux/gardSecret`
  API_URL8 = `${environment.apiUrl}/listedesjeux/getdisStrategy`
  API_URL9h = `${environment.apiUrl}/listedesjeux/getAssocHistory`
  API_URL9i = `${environment.apiUrl}/listedesjeux/insertAssocStrategy`
  API_URL9u = `${environment.apiUrl}/listedesjeux/updateAssocStrategy`
  API_URL10 = `${environment.apiUrl}/listedesjeux/miseAzero`
  API_URL14 = `${environment.apiUrl}/listedesjeux/insertScore`

  // services for playing game
  API_URLG1 = `${environment.apiUrl}/listedesjeux/startPlay`
  API_URLG2 = `${environment.apiUrl}/listedesjeux/endPlay`
  API_URLG3 = `${environment.apiUrl}/listedesjeux/getGamesToPlay`
  // API_URLG4=  `${environment.apiUrl}/listedesjeux/getScore`;
  //API_URLG5=  `${environment.apiUrl}/listedesjeux/getDuree`;
  API_URLG6 = `${environment.apiUrl}/listedesjeux/getStat`
  API_URLG7 = `${environment.apiUrl}/listedesjeux/getTopCollab`
  API_URLG8 = `${environment.apiUrl}/listedesjeux/getTopService`
  API_URLG9 = `${environment.apiUrl}/listedesjeux/getAvailableGames`
  API_URLG10 = `${environment.apiUrl}/listedesjeux/getPlayDate`
  API_URLG11 = `${environment.apiUrl}/listedesjeux/cleanRecord`

  // Getters
  get isLoading$() {
    return this._isLoading$.asObservable()
  }
  get isFirstLoading$() {
    return this._isFirstLoading$.asObservable()
  }
  get errorMessage$() {
    return this._errorMessage.asObservable()
  }
  get subscriptions() {
    return this._subscriptions
  }
  // State getters

  constructor(http: HttpClient, private datePipe: DatePipe) {
    this.http = http
  }

  protected getAuthFromLocalStorage(): any {
    try {
      const authData = JSON.parse(localStorage.getItem(this.authLocalStorageToken))
      return authData
    } catch (error) {
      console.error(error)
      if (error.status == 401) {
        window.location.reload()
      }
      return undefined
    }
  }

  //obtenir tous les jeux associé d'un utilisateur et les afficher dans le catalogue de jeux
  //paramètre : sessionid
  getGamesDataA(): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    const url = `${this.API_URLsa}`

    return this.http.get<any>(url, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('ERROR ! couldnt get requested data', err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  //obtenir tous les jeux non associé d'un utilisateur et les afficher dans le catalogue de jeux
  //paramètre : sessionid
  getGamesDataN(): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    const url = `${this.API_URLsn}`

    return this.http.get<any>(url, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('ERROR ! couldnt get requested data', err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  //obtenir les détails des jeux (icône de titre de description complète)
  // paramètre : sessionid, game_id
  getGamesDetails(game_id): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid).set('game_id', game_id)

    const url = `${this.API_URL2}`
    return this.http.get<any>(url, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('ERROR ! couldnt get requested data', err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  //obtenir des stratégies liées au jeu dans la strategy popup
  //paramètre : game_id sessionid
  getStrategies(game_id: any): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('game_id', game_id).set('sessionid', sessionid)

    const url = `${this.API_URL3}`

    return this.http.get<any>(url, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('ERROR ! couldnt get requested data', err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  // change gameid value (from quiz to gerer-quiz component)
  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  // activer une strategie
  activateStrategy(Strategy_id,game_id): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()
    let actif = 1

    let body = { actif }
    const url = `${this.API_URL4}?Strategy_id=${Strategy_id}&&sessionid=${sessionid}&&game_id=${game_id}`
    return this.http.put<any>(url, body).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('ERROR ! couldnt get requested data', err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  deactivateStrategy(Strategy_id,game_id): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()
    let deact = 0

    let body = { deact }

    const url = `${this.API_URL5}?Strategy_id=${Strategy_id}&&sessionid=${sessionid}&&game_id=${game_id}`
    return this.http.put<any>(url, body).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('ERROR ! couldnt get requested data', err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  dissasociateStrategy(Strategy_id, game_id) {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()
    let dissoc = 0
    let body = { dissoc }

    const url = `${this.API_URL6}?Strategy_id=${Strategy_id}&&sessionid=${sessionid}&&game_id=${game_id}`
    return this.http.put<any>(url, body).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('ERROR ! couldnt get requested data', err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  // changer le status d'option "garder les résultats secrets jusqu'a la fin de jeux"
  gardSecret(Strategy_id, isChecked) {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()
    let body = { isChecked }

    const url = `${this.API_URL7}?Strategy_id=${Strategy_id}&&sessionid=${sessionid}`
    return this.http.put<any>(url, body).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('ERROR ! couldnt get requested data', err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  //obtenir des stratégies liées au jeu dans la strategy popup
  //paramètre : game_id sessionid
  getdisStrategies(game_id: any): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams()
    .set('game_id', game_id)
    .set('sessionid', sessionid)

    const url = `${this.API_URL8}`

    return this.http.get<any>(url, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('ERROR ! couldnt get requested data', err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  //
  getAssocHistory(strategy_id, game_id) {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()
    let promise = new Promise<any>((resolve, reject) => {
      const url = `${this.API_URL9h}?sessionid=${sessionid}&&strategy_id=${strategy_id}&&game_id=${game_id}`
      this.http
        .get(url)
        .toPromise()
        .then(
          res => {
            // success
            resolve(res)
          },
          msg => {
            // Error
            reject(msg)
          }
        )
    })
    return promise
  }

  //associer une stratégie au jeu via son identifiant de stratégie et game_id
  insertAssocStrategy(strategy_id, game_id, entreprise_id, Date_debut, Date_fin): Promise<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()

    let body = { strategy_id, game_id, Date_debut, Date_fin, entreprise_id }

    let promise = new Promise<any>((resolve, reject) => {
      const url = `${this.API_URL9i}?sessionid=${sessionid}`
      this.http
        .post(url, body)
        .toPromise()
        .then(
          res => {
            // success
            resolve(res)
          },
          msg => {
            // Error
            reject(msg)
          }
        )
    })
    return promise
  }

  // update strategy associated column if strategy-game already exists
  updateAssocStrategy(strategy_id, game_id, date_debut, date_fin): Promise<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()

    let body = { strategy_id, game_id, date_debut, date_fin }

    let promise = new Promise<any>((resolve, reject) => {
      const url = `${this.API_URL9u}?sessionid=${sessionid}`
      this.http
        .put(url, body)
        .toPromise()
        .then(
          res => {
            // success
            resolve(res)
          },
          msg => {
            // Error
            reject(msg)
          }
        )
    })
    return promise
  }

  //reinitialise all stats of a game to zero (score, playing duration ...)
  miseAzero(game_id): Promise<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()
    let promise = new Promise<any>((resolve, reject) => {
      let params = new HttpParams().set('game_id', game_id).set('sessionid', sessionid)

      const url = `${this.API_URL10}`
      this.http
        .delete<any>(url, { params })
        .toPromise()
        .then(
          res => {
            resolve(res)
          },
          msg => {
            reject(msg)
          }
        )
    })
    return promise
  }

  // get games associated to user

  //obtenir les détails des jeux (icône de titre de description complète)
  // paramètre : sessionid, game_id
  getStat(game_id): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid).set('game_id', game_id)

    const url = `${this.API_URLG6}`
    return this.http.get<any>(url, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('ERROR ! couldnt get requested data', err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  //obtenir les collaborateurs avec le plus nombre de participation (nom,prenom, nombre de participation, date de derniere participation)
  // paramètre : sessionid, game_id
  getTopCollab(game_id): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid).set('game_id', game_id)

    const url = `${this.API_URLG7}`
    return this.http.get<any>(url, { params }).pipe(
      map(data =>
        data.map(row => {
          row.lastdate = formatDate(row.lastdate, this.datePipe)
          row.fullname = row.prenom + ' ' + row.nom
          return row
        })
      ),
      catchError(err => {
        this._errorMessage.next(err)
        console.error('ERROR ! couldnt get requested data', err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  getTopService(): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    const url = `${this.API_URLG8}`
    return this.http.get<any>(url, { params }).pipe(
      map(data =>
        data.map(row => {
          row.last_date = formatDate(row.last_date, this.datePipe)
          row.use_count = Number(row.use_count)
          row.users_count = Number(row.users_count)
          // toFixed will only leave two decimals after point
          row.usePercent = ((row.use_count / row.users_count) * 100).toFixed(2)
          return row
        })
      ),
      catchError(err => {
        this._errorMessage.next(err)
        console.error('ERROR ! couldnt get requested data', err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  getAvailableGames() {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()
    let currentDate = new Date();

    const params = new HttpParams().set('sessionid', sessionid)
    const url = `${this.API_URLG9}`

    return this.http.get<any>(url, { params }).pipe(
      map(data =>
        data.map(row => {
          row.counter = Number(row.counter)
          row.date = formatDateTwo(row.date,this.datePipe)
          let final_date = new Date(row.date)
          let diffTime  = final_date.valueOf() - currentDate.valueOf() 
          row.date = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
          //let diffTime = Math.abs(row.date.getTime() - currentDate.getTime()) ;
          //const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
          
          return row
        })
      ),
      catchError(err => {
        this._errorMessage.next(err)
        console.error('ERROR ! couldnt get requested data', err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  startPlay(game_id, strategy_id) {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()
    let body = { game_id, strategy_id }
    let promise = new Promise<any>((resolve, reject) => {
      const url = `${this.API_URLG1}?sessionid=${sessionid}`
      this.http
        .post(url, body)
        .toPromise()
        .then(
          res => {
            // success
            resolve(res)
          },
          msg => {
            // Error
            reject(msg)
          }
        )
    })
    return promise
  }

  endPlay(gameid) {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()
    let body = { gameid }
    let promise = new Promise<any>((resolve, reject) => {
      const url = `${this.API_URLG2}?sessionid=${sessionid}`
      this.http
        .put(url, body)
        .toPromise()
        .then(
          res => {
            // success
            resolve(res)
          },
          msg => {
            // Error
            reject(msg)
          }
        )
    })
    return promise
  }

  getPlayDate(game_id, strategy_id) {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('game_id', game_id).set('strategy_id', strategy_id)

    let promise = new Promise<any>((resolve, reject) => {
      const url = `${this.API_URLG10}?sessionid=${sessionid}`
      this.http
        .get(url, { params })
        .toPromise()
        .then(
          res => {
            // success
            resolve(res)
          },
          msg => {
            // Error
            reject(msg)
          }
        )
    })
    return promise
  }

  insertScore(game_id, strategy_id, stats) {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()

    const {
      corrects_count: correct_count,
      duration: duration,
      max_points: max_points,
      options: options,
      points: points,
      questions_count: question_count,
      score: score,
      status: state,
    } = stats[0]

    let status

    switch (state) {
      case 'finished':
        status = 1
        break
      case 'unfinished':
        status = 0
        break
      default:
        status = -1
        break
    }

    let body = {
      correct_count,
      duration,
      max_points,
      options,
      points,
      question_count,
      score,
      game_id,
      strategy_id,
      status,
    }
    let promise = new Promise<any>((resolve, reject) => {
      const url = `${this.API_URL14}?sessionid=${sessionid}`
      this.http
        .put(url, body)
        .toPromise()
        .then(
          res => {
            // success
            resolve(res)
          },
          msg => {
            // Error
            reject(msg)
          }
        )
    })
    return promise
  }

  cleanRecord(st, dt, game_id, strategy_id): Promise<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()
    let promise = new Promise<any>((resolve, reject) => {
      let params = new HttpParams()
        .set('sessionid', sessionid)
        .set('st', st)
        .set('dt', dt)
        .set('game_id', game_id)
        .set('strategy_id', strategy_id)

      const url = `${this.API_URLG11}`
      this.http
        .delete<any>(url, { params })
        .toPromise()
        .then(
          res => {
            resolve(res)
          },
          msg => {
            reject(msg)
          }
        )
    })
    return promise
  }
}

// get rid of fractions of second and time zone tag
function formatDate(x: any, datePipe) {
  let date = new Date(x)
  let final_d = datePipe.transform(date, 'yyyy-MM-ddTHH:mm')
  return final_d
}

function formatDateTwo(x: any, datePipe) {
  let date = new Date(x)
  let final_d = datePipe.transform(date, 'yyyy-MM-dd')
  return final_d
}