import { DragDropModule } from '@angular/cdk/drag-drop'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSliderModule } from '@angular/material/slider'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap'
import { TranslateModule } from '@ngx-translate/core'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { LottieModule } from 'ngx-lottie'
import { CRUDTableModule } from 'src/app/_metronic/shared/crud-table'
import { SvgIconComponent } from 'src/app/common/components/svg-icon/svg-icon.component'
import { CustomPipesModule } from '../shared/custom-pipes/custom-pipes.module'
import { ContextMenuComponent } from './context-menu/context-menu.component'
import { ManageReservationsAdminComponent } from './manage-reservations-admin/manage-reservations-admin.component'
import { OspAutoflexiPopupComponent } from './manage-reservations-admin/osp-autoflexi-popup/osp-autoflexi-popup.component'
import { OspReserveGuestPopupComponent } from './manage-reservations-admin/osp-reserve-guest-popup/osp-reserve-guest-popup.component'
import { OsModelingPageComponent } from './os-modeling-page/os-modeling-page.component'
import { NewBuildingModalComponent } from './os-modeling/new-building-modal/new-building-modal.component'
import { OsModelingComponent } from './os-modeling/os-modeling.component'
import { OsReservationComponent } from './os-reservation/os-reservation.component'
import { CreateResvRuleModalComponent } from './osp-reservation-rules/create-resv-rule-modal/create-resv-rule-modal.component'
import { OspReservationRulesComponent } from './osp-reservation-rules/osp-reservation-rules.component'
import { OsplannerRoutingModule } from './osplanner-routing.module'
import { OsplannerComponent } from './osplanner.component'

@NgModule({
  declarations: [
    OsplannerComponent,
    OsModelingComponent,
    NewBuildingModalComponent,
    OsReservationComponent,
    OsModelingPageComponent,
    OspReservationRulesComponent,
    CreateResvRuleModalComponent,
    ManageReservationsAdminComponent,
    OspReserveGuestPopupComponent,
    OspAutoflexiPopupComponent,
  ],
  imports: [
    CommonModule,
    OsplannerRoutingModule,
    FormsModule,
    DragDropModule,
    TranslateModule,
    MatExpansionModule,
    MatIconModule,
    InlineSVGModule,
    MatSidenavModule,
    MatButtonModule,
    MatSliderModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
    CRUDTableModule,
    ReactiveFormsModule,
    SvgIconComponent,
    CustomPipesModule,
    NgbPopoverModule,
    InlineSVGModule,
    NgbModule,
    LottieModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    ContextMenuComponent,
  ],
})
export class OsplannerModule {}
