<div style="min-height: 85vh; min-width: 50vw; display: flex; background-color: transparent; border-radius: 50%">
  <div style="display: none">
    <wm-manage-surveys
      *ngIf="authToken && userId"
      (showResults)="showResults($event.detail)"
      [lang]="'fr'"
      style="--color_coffee: #967e73; --color_choco: #8c746a; --color_honey: #d8aa72"
      [owner]="330"
      [formtype]="'survey'"
      [authToken]="authToken"
      [userId]="userId"
    >
    </wm-manage-surveys>
  </div>
  <wm-fill-survey
    *ngIf="authToken && userId && surveyId"
    style="width: 100%"
    [surveyId]="surveyId"
    preview-mode="false"
    state="closed"
    (submitResults)="submitResults($event.detail)"
    [authToken]="authToken"
    [userId]="userId"
    [formtype]="'survey'"
  >
  </wm-fill-survey>
</div>
