import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { SurveyModalComponent } from 'src/app/modules/qsp/survey-modal/survey-modal.component'
import { getEntIdFromLocalStorage, getUserUuidFromLocalStorage } from 'src/app/utils/getSessionFromLocalStorage'
import { UserService } from '../../collaborateurs/_services'
import { ClosedModalComponent } from '../../communities/gerer/closed-modal/closed-modal.component'
import { SurveyService } from '../_services/surveys.service'

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
})
export class SurveyComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    public cdr: ChangeDetectorRef,
    public userService: UserService,
    public svc: SurveyService
  ) {}

  owner = getEntIdFromLocalStorage()
  formtype = this.route.data['value'].formtype

  lang = localStorage.getItem('language')
  hideDocScrollbar = true
  tableOption: any = []
  enqueteAbonnement = false
  quizAbonnement = false
  pollAbonnement = false

  ngOnInit() {
    this.userService.getEntrepriseAbonnement().subscribe(data => {
      for (let i = 0; i < data.length; i++) {
        this.tableOption.push(data[i].code_option)
        this.cdr.detectChanges()
      }

      if (this.tableOption.includes('MENQ') && this.formtype === 'survey') {
        this.enqueteAbonnement = true
        this.cdr.detectChanges()
      } else if (this.tableOption.includes('MQUZ') && this.formtype === 'quiz') {
        this.quizAbonnement = true
        this.cdr.detectChanges()
      } else if (this.tableOption.includes('MPOL') && this.formtype === 'poll') {
        this.pollAbonnement = true
        this.cdr.detectChanges()
      } else {
        this.closedModal()
      }

      this.cdr.detectChanges()
    })
    document.getElementsByTagName('html')[0].style.overflow = 'hidden'
  }

  closedModal() {
    this.modalService.open(ClosedModalComponent)
  }
  get authToken() {
    return localStorage.getItem('QSP_AUTH_TOKEN')
  }

  get userId() {
    return getUserUuidFromLocalStorage()
  }

  isReady = false
  ngAfterViewInit() {
    this.isReady = true
  }

  public surveyId?: string

  previewSurvey(e: CustomEvent) {
    this.surveyId = e.detail
    const modalRef = this.modalService.open(SurveyModalComponent, { size: 'lg' })
    modalRef.componentInstance.surveyId = this.surveyId
    modalRef.componentInstance.previewMode = true
  }

  showResults(id: string) {
    this.router.navigate(['/survey/results/survey/' + id])
  }

  deletedSurvey(id: string) {
    this.svc.deleteSurveyDataById(id).subscribe(() => {
    })
  }

  screenChanged(screen: string) {
    this.screen = screen as any
  }

  ngOnDestroy(): void {
    document.getElementsByTagName('html')[0].style.overflow = 'auto'
  }

  screen: 'edit-survey' | 'manage-survey' | 'preview-survey' | 'list-templates' = 'manage-survey'
}
