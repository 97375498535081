import { HttpClient, HttpParams } from '@angular/common/http'
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs'
import { catchError, finalize, tap } from 'rxjs/operators'
import { environment } from '../../../../../environments/environment'
import { BaseModel } from '../models/base.model'
import { GroupingState } from '../models/grouping.model'
import { PaginatorState } from '../models/paginator.model'
import { SortState } from '../models/sort.model'
import { ITableState, TableResponseModel } from '../models/table.model'

const DEFAULT_STATE: ITableState = {
  filter: {},
  paginator: new PaginatorState(),
  sorting: new SortState(),
  searchTerm: '',
  grouping: new GroupingState(),
  entityId: undefined,
}

export abstract class TableDispoService<T> {
  // Private fields
  private _items$ = new BehaviorSubject<T[]>([])
  private _isLoading$ = new BehaviorSubject<boolean>(false)
  private _isFirstLoading$ = new BehaviorSubject<boolean>(true)
  private _tableState$ = new BehaviorSubject<ITableState>(DEFAULT_STATE)
  private _errorMessage = new BehaviorSubject<string>('')
  private _subscriptions: Subscription[] = []

  // Getters
  get items$() {
    return this._items$.asObservable()
  }
  get isLoading$() {
    return this._isLoading$.asObservable()
  }
  get isFirstLoading$() {
    return this._isFirstLoading$.asObservable()
  }
  get errorMessage$() {
    return this._errorMessage.asObservable()
  }
  get subscriptions() {
    return this._subscriptions
  }
  // State getters
  get paginator() {
    return this._tableState$.value.paginator
  }
  get filter() {
    return this._tableState$.value.filter
  }
  get sorting() {
    return this._tableState$.value.sorting
  }
  get searchTerm() {
    return this._tableState$.value.searchTerm
  }
  get grouping() {
    return this._tableState$.value.grouping
  }

  protected authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`
  protected http: HttpClient
  // API URL has to be overrided
  API_URL = `${environment.apiUrl}/endpoint`
  API_URLs = `${environment.apiUrl}/rendezvous/deletedispo`
  API_URLss = `${environment.apiUrl}/equipe/modifierequipe`
  API_URLsss = `${environment.apiUrl}/equipe/getequipebyide`

  API_URLe = `${environment.apiUrl}/rendezvous/adddisponibilite`

  constructor(http: HttpClient) {
    this.http = http
  }

  // CREATE
  // server should return the object with ID
  create(item: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    this._isLoading$.next(true)
    this._errorMessage.next('')
    return this.http.post<any>(this.API_URLe, item, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('CREATE ITEM', err)
        window.location.reload()
        return of({ id: undefined })
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  // READ (Returning filtered list of entities)
  find(tableEquipeState: ITableState): Observable<TableResponseModel<T>> {
    const url = this.API_URL + '/find'
    this._errorMessage.next('')
    return this.http.post<TableResponseModel<T>>(url, tableEquipeState).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        window.location.reload()
        console.error('FIND ITEMS', err)
        return of({ items: [], total: 0 })
      })
    )
  }

  getItemById(ide: any): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    const url = `${this.API_URLsss}?ide=${ide}`

    return this.http.get<any>(url, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        window.location.reload()
        // console.error('GET ITEM BY IT', ide, err);
        return of({ ids: undefined })
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  // UPDATE
  update(item: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const url = `${this.API_URLss}?ide=${item[0].id}&&sessionid=${sessionid}`

    let nom_equipe = item.nom_equipe
    let id_service = item.id_service

    let body = { nom_equipe, id_service }
    //   const params = new HttpParams()
    // .set('nom_service', nom_service)

    // .set('sessionid', sessionid);

    this._isLoading$.next(true)
    this._errorMessage.next('')
    return this.http.put(url, body).pipe(
      catchError(err => {
        window.location.reload()
        this._errorMessage.next(err)
        //  console.error('UPDATE ITEM', item, err);
        return of(item)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  // UPDATE Status
  updateStatusForItems(ide: number[], status: number): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    const body = { ide, status }
    const url = this.API_URL + '/updateStatus'
    return this.http.put(url, body).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        //  console.error('UPDATE STATUS FOR SELECTED ITEMS', ide, status, err);
        return of([])
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  // DELETE
  delete(creneau_uuid: any): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    const url = `${this.API_URLs}?creneau_uuid=${creneau_uuid}`

    return this.http.delete(url, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        // console.error('DELETE ITEM', creneau_uuid, err);
        if (err.status == 401) {
          window.location.reload()
        }
        return of({})
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  // delete list of items
  deleteItems(ide: number[] = []): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    const url = this.API_URL + '/deleteItems'
    const body = { ide }
    return this.http.put(url, body).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        // console.error('DELETE SELECTED ITEMS', ide, err);
        return of([])
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  public fetch() {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    const request = this.find(this._tableState$.value)
      .pipe(
        tap((res: TableResponseModel<T>) => {
          this._items$.next(res.items)
          this.patchStateWithoutFetch({
            paginator: this._tableState$.value.paginator.recalculatePaginator(res.total),
          })
        }),
        catchError(err => {
          window.location.reload()
          this._errorMessage.next(err)
          return of({
            items: [],
            total: 0,
          })
        }),
        finalize(() => {
          this._isLoading$.next(false)
          const itemIds = this._items$.value.map((el: T) => {
            const item = el as unknown as BaseModel
            return item.id
          })
          this.patchStateWithoutFetch({
            grouping: this._tableState$.value.grouping.clearRows(itemIds),
          })
        })
      )
      .subscribe(data => {})
    this._subscriptions.push(request)
  }

  public setDefaults() {
    this.patchStateWithoutFetch({ filter: {} })
    this.patchStateWithoutFetch({ sorting: new SortState() })
    this.patchStateWithoutFetch({ grouping: new GroupingState() })
    this.patchStateWithoutFetch({ searchTerm: '' })
    this.patchStateWithoutFetch({
      paginator: new PaginatorState(),
    })
    this._isFirstLoading$.next(true)
    this._isLoading$.next(true)
    this._tableState$.next(DEFAULT_STATE)
    this._errorMessage.next('')
  }

  // Base Methods
  public patchState(patch: Partial<ITableState>) {
    this.patchStateWithoutFetch(patch)
    this.fetch()
  }

  public patchStateWithoutFetch(patch: Partial<ITableState>) {
    const newState = Object.assign(this._tableState$.value, patch)
    this._tableState$.next(newState)
  }
  protected setAuthFromLocalStorage(auth: any): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (1 === 1) {
      //auth sessionid ta3 user

      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth))

      return true
    }
    return false
  }

  protected getAuthFromLocalStorage(): any {
    try {
      const authData = JSON.parse(localStorage.getItem(this.authLocalStorageToken))
      return authData
    } catch (error) {
      // console.error(error);
      return undefined
    }
  }
}
