import { Injectable, Inject, OnDestroy } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { TableDispoService } from '../../../_metronic/shared/crud-table/services/table.dispo.service'
import { Calender } from '../_models/dispo.models'
import { environment } from '../../../../environments/environment'
import {
  TableResponseModel,
  ITableState,
} from '../../../_metronic/shared/crud-table'
import { Observable, forkJoin } from 'rxjs'
import { map } from 'rxjs/operators'
import { baseFilter } from '../../../common/helpers/http-extenstions'

@Injectable({
  providedIn: 'root',
})
export class DispoService extends TableDispoService<Calender> implements OnDestroy {
  // protected authLocalStorageToken = ${environment.appVersion}-${environment.USERDATA_KEY};
  API_URL = `${environment.apiUrl}/rendezvous/getdisponibilite`
  constructor(@Inject(HttpClient) http) {
    super(http)
  }
  find(TableEquipeService: ITableState): Observable<TableResponseModel<Calender>> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any>(`${this.API_URL}`, { params }).pipe(
      map((response: Calender[]) => {
        const filteredResult = baseFilter(response, TableEquipeService)
        const result: TableResponseModel<Calender> = {
          items: filteredResult.items,
          total: filteredResult.total,
        }
        return result
      })
    )
  }
  deleteItems(creneau_uuid: number[] = []): Observable<any> {
    const tasks$ = []
    creneau_uuid.forEach(id => {
      tasks$.push(this.delete(id))
    })
    return forkJoin(tasks$)
  }

  // private methods

  getDispo(): Observable<any[]> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get<any[]>(this.API_URL, { params })
  }
  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe())
  }
}
