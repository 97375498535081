import * as moment from 'moment'
import { Pipe, PipeTransform, OnDestroy, Inject, LOCALE_ID } from '@angular/core'
import { DatePipe } from '@angular/common'
import { Subject } from 'rxjs'

@Pipe({
  name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
  static DEFAULT_LOCALE = 'fr-FR'
  static DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss.sssZ'

  defaultLocale: string = TimeAgoPipe.DEFAULT_LOCALE
  defaultDateTimeFormat: string = TimeAgoPipe.DEFAULT_DATE_TIME_FORMAT

  private onDestroy$ = new Subject<boolean>()

  constructor(@Inject(LOCALE_ID) public locale: string) {
    this.defaultLocale = locale
  }

  transform(value: Date, locale?: string) {
    if (value !== null && value !== undefined) {
      const lang = localStorage.getItem('language')
      const actualLocale = lang == 'en' ? 'en-US' : 'fr-FR'
      const then = moment(value)
      const diff = moment().locale(actualLocale).diff(then, 'days')
      return then.locale(actualLocale).fromNow()

      if (diff > 7) {
        const datePipe: DatePipe = new DatePipe(actualLocale)
        return datePipe.transform(value, this.defaultDateTimeFormat)
      } else {
        return then.locale(actualLocale).fromNow()
      }
    }
    return ''
  }

  ngOnDestroy() {
    this.onDestroy$.next(true)
    this.onDestroy$.complete()
  }
}
