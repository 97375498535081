import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { AssocierStrategieComponent } from '../associer-strategie/associer-strategie.component'
import { quizService } from '../_services/quiz.service'
import { ToastrService } from 'ngx-toastr'
import { catchError } from 'rxjs/operators'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { of } from 'rxjs'

@Component({
  selector: 'app-gerer-strategie',
  templateUrl: './gerer-strategie.component.html',
  styleUrls: ['./gerer-strategie.component.scss'],
})
export class GererStrategieComponent implements OnInit {
  strategies: any // data received from database containing strategy id, name
  Strategy_id = [] // array to store all strategy ids
  isChecked = 0
  prevId: any
  game_id: any
  isDisabled = true

  @Input() public QuizData
  constructor(
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    private squiz: quizService,
    private toastr: ToastrService,
    public cdr: ChangeDetectorRef
  ) {
    this.cdr = cdr
  }

  //calling getstrategies Service when popup is opened to get all strategies linked to game
  //(sélectionné par le parametre QuizData (objet) qui a un attribut game_id contient le uuid
  //de jeux
  ngOnInit() {
    this.game_id = this.QuizData.game_id
    this.squiz.getStrategies(this.game_id).subscribe(data => {
      this.strategies = data
      this.cdr.detectChanges()
    })
    //
  }

  create() {
    this.edit()
  }

  edit() {
    const modalRef = this.modalService.open(AssocierStrategieComponent, {
      size: 'l',
      backdrop: 'static',
    })
    modalRef.componentInstance.QuizData = this.QuizData
  }

  /* lorsque le bouton est cliqué. une demande de publication sera envoyée au backend pour 
modifier le statut des stratégies sélectionnées (dissocier, activer, désactiver) à l'aide de 
param strategy.id */

  activateStrategy() {
    for (var value of this.Strategy_id) {
      this.squiz.activateStrategy(value,this.game_id).pipe(
          catchError(err => {
            this.modal.dismiss(err)
            return of(undefined)
          })
        )
        .subscribe(data => {})
      this.squiz
        .gardSecret(value, this.isChecked)
        .pipe(
          catchError(err => {
            this.modal.dismiss(err)
            return of(undefined)
          })
        )
        .subscribe(data => {})
    }
    this.toastr.success('la stratégie a été activée avec succès')
    this.modal.close()
  }

  deactivateStrategy() {
    for (var value of this.Strategy_id) {
      this.squiz.deactivateStrategy(value,this.game_id).pipe(
          catchError(err => {
            this.modal.dismiss(err)
            return of(undefined)
          })
        )
        .subscribe(data => {})
      this.squiz
        .gardSecret(value, this.isChecked)
        .pipe(
          catchError(err => {
            this.modal.dismiss(err)
            return of(undefined)
          })
        )
        .subscribe(data => {})
    }
    this.toastr.error('la stratégie a été désactivé avec succès')
    this.modal.close()
  }

  dissasociateStrategy() {
    for (var value of this.Strategy_id) {
      this.squiz
        .dissasociateStrategy(value, this.game_id)
        .pipe(
          catchError(err => {
            this.modal.dismiss(err)
            return of(undefined)
          })
        )
        .subscribe(data => {})
      this.squiz
        .gardSecret(this.Strategy_id, this.isChecked)
        .pipe(
          catchError(err => {
            this.modal.dismiss(err)
            return of(undefined)
          })
        )
        .subscribe(data => {})
    }
    this.toastr.info('la stratégie a été dissociée avec succès')
    this.modal.close()
  }

  /*an event handler qui détectera chaque fois que la case à cocher de l'option 
  de masquage du score est cliquée et poussera l'ID de stratégie choisi vers le tableau*/
  onChange(id, isChecked) {
    if (isChecked) {
      this.Strategy_id.push(id)
    } else {
      const index = this.Strategy_id.indexOf(id)
      if (index > -1) {
        this.Strategy_id.splice(index, 1)
      }
    }
    this.prevId = id
    // enable/disable buttons based on checkbox
    if (this.Strategy_id.length > 0) {
      this.isDisabled = false
    } else {
      this.isDisabled = true
    }
  }

  // choisir l'option de garder les résultats secrets
  gardScore(event: Event) {
    if (this.isChecked == 0) {
      this.isChecked = 1
    } else {
      this.isChecked = 0
    }
  }

  close() {
    this.modal.close()
  }

  validate() {
    return this.isDisabled
  }
}
