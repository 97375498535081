<div class="table-responsive angular-bootstrap-table card-header">
  <table class="table table-head-custom table-vertical-center overflow-hidden">
    <thead>
      <tr>
      <th class="p-0 w-90px"></th>
      <th class="p-0 min-w-50px"></th>
      <th class="p-0 min-w-50px"></th>
      <th class="p-0 min-w-110px"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of jeuxDispo"> 
        <td> 
          <div class="symbol symbol-80px symbol-2by3 me-4">
            <div class="symbol-label" [style.background-image]="'url(./assets/media/stock-600x400/img-17.jpg)'">
            </div>
          </div>
        </td>
        <td>
          
          <h5 class="d-flex align-items-center" [ngClass]="{'actif':row.actif == 0}">{{row.title}}</h5>
        </td>
        <td>
          {{"jeux.timer" | translate}} {{row.date}} {{"jeux.timerDays" |translate}}
        </td>
        <td>
          <span class="text-dark">
            <button *ngIf="row.actif == 1" [disabled]="row.counter > 0" style="float:right"  class="btn btn-primary" width="101px" (click)="create(row.game_id,row.Strategy_id,row.wp_quiz_id)">
            <img *ngIf="row.counter < 1" style="margin-right: 3px;" height="16px" width="15px" src="./../../../../assets/icons/playarrow.png" alt="play button"> 
            {{(row.counter < 1 ? "jeux.play" : "jeux.played") | translate}}
            </button>
            <!--show this button on games that are associated to deactivated strartegies-->
            <button *ngIf="row.actif == 0" [disabled]="true" style="float:right" class="btn btn-primary" >
              <img src="../../../../assets/media/svg/icons/Media/Pause.svg" style="margin-right: 3px;" height="18px" width="17px" alt="Game strategy deactivated">
              {{ "jeux.actif" | translate}}
              </button>
        </span>
        </td>
      </tr>
    </tbody>
    <!-- end::Table body -->
  </table>
</div>

