<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">{{ "ADMINPARKING.modalReserve.title" | translate }}</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="formGroup">
      <!--<div>
          <label for="titre">Code</label>
          <input
            id="code"
            class="form-control"
            type="text"
            formControlName="code"
            [class.is-invalid]="isControlInvalid('code')"
            [class.is-valid]="isControlValid('code')"
          />

          <div class="invalid-feedback" *ngIf="controlHasError('required', 'code')">
            {{ "IDEA.add.input1" | translate }}{{ "CONTROLEDESAISIE.required" | translate }}
          </div>
          <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'code')">
            {{ "IDEA.add.input1" | translate }}{{ "EVENT.control.threeCharMin" | translate }}
          </div>
          <div class="invalid-feedback" *ngIf="controlHasError('maxlength', 'code')">
            {{ "IDEA.add.input1" | translate }}{{ "CONTROLEDESAISIE.max" | translate }}
          </div>
        </div>-->

      <!--<div>
          <label>Type</label>
          <select
            class="form-control"
            [(ngModel)]="type"
            formControlName="type"
            style="width: 100%"
            [class.is-invalid]="isControlInvalid('type')"
            [class.is-valid]="isControlValid('type')"
          >
            <option value="1">
              Voiture
            </option>
            <option value="2">
              Velo
            </option>
            <option value="3">
              Moto
            </option>
           
          </select>

          <div class="invalid-feedback" *ngIf="controlHasError('required', 'type')">
            {{ "EVENT.wizardTwo.frequency" | translate
            }}{{ "CONTROLEDESAISIE.required" | translate }}
          </div>
        </div>-->

      <div class="row" style="margin-top: 10px; display: flex; justify-content: center">
        <mat-radio-group [(ngModel)]="choice" formControlName="reservedFor">
          <mat-radio-button *ngFor="let O of options" [value]="O">
            {{ O }}
          </mat-radio-button>
        </mat-radio-group>
        <div class="invalid-feedback" *ngIf="controlHasError('required', 'reservedFor')">
          {{ "EVENT.wizardTwo.frequency" | translate }}{{ "CONTROLEDESAISIE.required" | translate }}
        </div>
      </div>

      <div class="row" style="margin-top: 10px" *ngIf="choice == 'Collaborateur'">
        <div class="col-lg-4" style="margin-top: 10px">
          <label>{{ "ADMINPARKING.modalReserve.input1" | translate }}</label>
        </div>
        <div class="col-lg-8">
          <ng-multiselect-dropdown
            class="form-control form-control-lg form-control-solid"
            [placeholder]="''"
            [settings]="dropdownSettings"
            [data]="dropdownList"
            (onDeSelect)="onItemDeSelect($event)"
            (onSelect)="onItemSelect($event)"
            [(ngModel)]="selectedItems"
            formControlName="parkingFor"
            #dropdown
          >
          </ng-multiselect-dropdown>
        </div>
      </div>
      <!-- <div class="row" style="margin-top: 10px" *ngIf="choice == 'Collaborateur'">
        <div class="col-lg-4" style="margin-top: 10px">
          <label>{{ "ADMINPARKING.modalReserve.input1" | translate }}</label>
        </div>
        <div class="col-lg-8">
          <select
            class="form-control"
            [(ngModel)]="parkingFor"
            formControlName="parkingFor"
            style="width: 100%"
            [class.is-invalid]="isControlInvalid('parkingFor')"
            [class.is-valid]="isControlValid('parkingFor')"
            data-live-search="true"
          >
            <option *ngFor="let u of dropdownList" [value]="u.uuid" data-tokens="u.nom">{{ u.nom }} {{ u.prenom }} {{ u.login_email }}</option>
          </select>

          <div class="invalid-feedback" *ngIf="controlHasError('required', 'parkingFor')">
            {{ "EVENT.wizardTwo.frequency" | translate }}{{ "CONTROLEDESAISIE.required" | translate }}
          </div>
        </div>
      </div>-->

      <div class="row" style="margin-top: 10px" *ngIf="choice == 'Externe'">
        <div class="col-lg-4" style="margin-top: 10px">
          <label for="titre">{{ "ADMINPARKING.modalReserve.input1" | translate }}</label>
        </div>
        <div class="col-lg-8">
          <input
            id="code"
            class="form-control"
            type="text"
            formControlName="guest"
            [class.is-invalid]="isControlInvalid('guest')"
            [class.is-valid]="isControlValid('guest')"
          />

          <div class="invalid-feedback" *ngIf="controlHasError('required', 'guest')">
            {{ "ADMINPARKING.modalReserve.input1" | translate }}{{ "CONTROLEDESAISIE.required" | translate }}
          </div>
          <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'guest')">
            {{ "ADMINPARKING.modalReserve.input1" | translate }}{{ "EVENT.control.threeCharMin" | translate }}
          </div>
          <div class="invalid-feedback" *ngIf="controlHasError('maxlength', 'guest')">
            {{ "ADMINPARKING.modalReserve.input1" | translate }}{{ "CONTROLEDESAISIE.max" | translate }}
          </div>
        </div>
      </div>

      <div class="row" style="margin-top: 10px">
        <div class="col-lg-4" style="margin-top: 10px">
          <label>Site</label>
        </div>
        <div class="col-lg-8">
          <select
            class="form-control"
            formControlName="site"
            style="width: 100%"
            [class.is-invalid]="isControlInvalid('site')"
            [class.is-valid]="isControlValid('site')"
            (change)="siteId = $event.target.value"
          >
            <option *ngFor="let s of listSites" [value]="s.ids">{{ s.name }}</option>
          </select>

          <div class="invalid-feedback" *ngIf="controlHasError('required', 'site')">Site{{ "CONTROLEDESAISIE.required" | translate }}</div>
        </div>
      </div>

      <div class="row" style="margin-top: 10px">
        <div class="col-lg-4" style="margin-top: 10px">
          <label>{{ "ADMINPARKING.modalReserve.input2" | translate }}</label>
        </div>
        <div class="col-lg-8">
          <input
            type="date"
            class="form-control"
            [min]="dateMin"
            formControlName="dateReservation"
            style="width: 100%"
            [class.is-invalid]="isControlInvalid('dateReservation')"
            [class.is-valid]="isControlValid('dateReservation')"
          />
          <div class="invalid-feedback" *ngIf="controlHasError('required', 'reservedFor')">
            {{ "EVENT.wizardTwo.frequency" | translate }}{{ "CONTROLEDESAISIE.required" | translate }}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4" style="margin-top: 40px">
          <label>{{ "EVENT.wizardTwo.time" | translate }}</label>
        </div>
        <div class="col-lg-8">
          <ngb-timepicker [(ngModel)]="startTime" formControlName="time" style="width: 100%"></ngb-timepicker>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4" style="margin-top: 40px">
          <label>{{ "ADMINPARKING.modalReserve.input4" | translate }}</label>
        </div>
        <div class="col-lg-8">
          <ngb-timepicker [(ngModel)]="endTime" formControlName="endTime" style="width: 100%"></ngb-timepicker>
        </div>
      </div>

      <div class="row" style="margin-top: 10px">
        <div class="col-lg-4" style="margin-top: 10px">
          <label>{{ "ADMINPARKING.modalReserve.input5" | translate }}</label>
        </div>
        <div class="col-lg-8">
          <select
            class="form-control"
            [(ngModel)]="type"
            formControlName="type"
            style="width: 100%"
            [class.is-invalid]="isControlInvalid('type')"
            [class.is-valid]="isControlValid('type')"
          >
            <option value="1">{{ "ADMINPARKING.modalParking.option1" | translate }}</option>
            <option value="2">{{ "ADMINPARKING.modalParking.option2" | translate }}</option>
            <option value="3">{{ "ADMINPARKING.modalParking.option3" | translate }}</option>
          </select>

          <div class="invalid-feedback" *ngIf="controlHasError('required', 'type')">
            {{ "ADMINPARKING.modalReserve.input5" | translate }}{{ "CONTROLEDESAISIE.required" | translate }}
          </div>
        </div>
      </div>

      <div class="row" style="margin-top: 10px">
        <div class="col-lg-4" *ngIf="type == 1">
          <input type="checkbox" id="vehicle1" name="vehicle1" value="1" (click)="setElectricCar()" />
          <label for="vehicle1" style="margin-left: 5px"> {{ "ADMINPARKING.modalReserve.input6" | translate }}</label
          ><br />
        </div>
        <div class="col-lg-4" *ngIf="type == 1">
          <input type="checkbox" id="vehicle2" name="vehicle2" value="1" (click)="setHandicapCar()" />
          <label for="vehicle2" style="margin-left: 5px"> {{ "ADMINPARKING.modalReserve.input7" | translate }}</label
          ><br />
        </div>
        <div class="col-lg-4" *ngIf="type == 1">
          <input type="checkbox" id="vehicle3" name="vehicle3" value="1" (click)="setCovCar()" />
          <label for="vehicle3" style="margin-left: 5px"> {{ "ADMINPARKING.modalReserve.input8" | translate }}</label
          ><br /><br />
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-primary" (click)="closeModal()"><i class="fas fa-times"></i>{{ "IDEA.add.button1" | translate }}</button>
    <button
      class="btn btn-primary"
      [disabled]="
        (formGroup.controls['reservedFor'].invalid || formGroup.controls['dateReservation'].invalid || formGroup.controls['type'].invalid) &&
        (formGroup.controls['parkingFor'].invalid || formGroup.controls['guest'].invalid)
      "
      (click)="reserveParking()"
    >
      <span> {{ "ADMINPARKING.modalReserve.button1" | translate }}</span>
    </button>
  </div>
</div>
