export default {
  TRANSPORT: {
    Transport: 'Transportation',
    Type: 'Type',
    Spécificité: 'Specificity',
    Matricule: 'Matriculation',
    Marque: 'Brand',
    Modèle: 'Model',
    txt: "I'm not interested in carpooling",
  },
  RESERVATION: {
    disablePopup: 'Disable popup',
    txt1: 'Your college who offer their car for carpooling',
    Photo: 'Picture',
    Nom_Prénom: 'Name',
    Places_restantes: 'Places remaining',
    Distance: 'Distance (km)',
    Action: 'Action',
    RESERVER: 'To book',
    txt2: 'Your nearby colleagues who could carpool',
    Proposer: 'To propose',
    txt3: 'I agree to make my car available for carpooling ',
    txt31: 'and I reserve a place for packing',
    proposer_reserver: 'propose and book',
    txt4: 'I just want to reserve a parking space',
    Reserver: 'To book',
    SELECT: 'Select carpool date',
    NON_COVOITURAGE: 'There is no carpooling for this date',
    NON_PROPOSEE: 'There is no one nearby to offer a carpool',
    proposer_voiture: 'Propose my car',
    CLOSE: 'Close',
  },
  COVOITURAGE: {
    Covoiturage: 'Carpooling',
    Date: 'Date',
    Status: 'Status',
    attent: 'waiting for answer',
    acceptée: 'accepted',
    refusée: 'denied',
    annulée: 'cancelled',
    terminée: 'completed',
  },
}
