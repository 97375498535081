export default {
  MODAL_DICTIONARY: {
    MODAL: 'Gestion des Dictionnaires de Test',
    HEADER: 'Gestion des Dictionnaires de Test',
    TITLE: 'Gérer les paramètres des dictionnaires de Test pour les offres de travail.',
    ADD: 'Ajouter un Dictionnaire',
    SELECT: 'Sélectionner tout',
    DELETE: 'Supprimer',
    RESPONSE: 'Résponse',
    DIFFICULTY: 'Difficulté',
    NBQ: 'Nombre de questions',
    SKILL: 'Compétence',
    DETAIL: 'Détails',
    DETAILEX: 'Cacher les Détails',
    FAILED:
      "Veuillez spécifier le nom du dictionnaire, le niveau de difficulté, la compétence, et assurez-vous qu'il y ait au moins une question.",
    PHEADER: 'Ajouter les paramètres du dictionnaire',
    PPARAM: 'pramètres',
    PNAME: 'Nom du dictionnaire, e.g : skill, site, difficulté',
    PNAMEPH: 'Nom du dictionnaire',
    LANGUAGE: 'Langue',
    PLANGUAGE: 'Choisir une langue',
    PFA: 'facile',
    PDIF: 'difficile',
    PMO: 'Moyen',
    PNBQ: 'Nombre de questions à générer',
    PNBQPH: 'Entrez un nombre entre 1 et 20',
    INSTRUCTIONS: 'Instructions',
    PADD: 'Ajouter une question',
    PGEN: 'Générer des questions',
    CANCEL: 'Annuler',
    PSAV: 'Sauvegarder',
    PSKILL: 'Chercher une compétence',
  },
}
