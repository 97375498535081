export default {
  'Gérer les congés': 'Demandes',
  'Historique des congés': 'Historique',
  Groupes: 'Groupes',
  Demandes: 'Demandes',
  'Mes congés': 'Mes congés',
  Congés: 'Congés',
  'Congés de mon équipe': 'Congés de mon équipe',
  Ajustements: 'Ajustements',
  'Gestion des congés': 'Gestion des congés',
  GROUPS: {
    'Nom du groupe': 'Nom du groupe',
    Description: 'Description',
    Site: 'Site',
    Actions: 'Actions',
    'Nouveau groupe de congé': 'Nouveau groupe de congé',
    'Rechercher un groupe': 'Rechercher un groupe',
    'Ajouter des types de congé': 'Ajouter des types de congé',
    'Modifier le groupe de congés': 'Modifier le groupe de congés',
    'Type de congé': 'Type de congé:',
    Solde: 'Solde:',
    'jours par': 'jours par',
    mois: 'mois',
    année: 'année',
    'Date du cycle': 'Début du cycle:',
    'Ajouter un type de congé': 'Ajouter un type de congé',
    'Supprimer le groupe de congés': 'Supprimer le groupe de congés',
    'Êtes-vous sûr de vouloir supprimer ce groupe de congés?':
      'Êtes-vous sûr de vouloir supprimer ce groupe de congés?',
    'Groupe de congés supprimé avec succès': 'Groupe de congés supprimé avec succès',
    'Erreur lors de la suppression du groupe de congés': 'Erreur lors de la suppression du groupe de congés',
    LEAVE_GROUPS_TABLE_EMPTY: 'Créez votre premier groupe de congé en cliquant sur le bouton ci-dessus. 😊',
    VALIDATION: {
      GROUP_NAME: 'Merci de saisir un nom pour le groupe',
      SITE_NAME: 'Merci de sélectionner un site auquel associer le groupe',
      LEAVE_TYPE_MISSING: "Merci d'ajouter au moins un type de congé",
      LEAVE_TYPE_INVALID: 'Merci de vérifier les champs pour les types de congé',
    },
    TOASTR: {
      GROUP_CREATED: 'Groupe de congés créé avec succès',
      ERROR_CREATING_GROUP: 'Erreur lors de la création du groupe de congés',
      GROUP_UPDATED: 'Groupe de congés mis à jour avec succès',
      ERROR_UPDATING_GROUP: 'Erreur lors de la mise à jour du groupe de congés',
    },
    'cumulés mensuellement': 'cumulés mensuellement',
    'acquis en début de cycle': 'acquis en début de cycle',
    'en fin du mois': 'en fin du mois',
    'en fin de cycle': 'en fin de cycle',
    'Acquisition:': 'Acquisition:',
    'de chaque année': 'de chaque année',
    'Le 1er': 'Le 1er',
  },
  'Aucune demande de congé trouvée ':
    'Pas encore de demande de congé ? Cliquez sur le bouton ci-dessus pour en créer une facilement. 😊',
  'Aucune demande de congé trouvée (admin)':
    'Pas de demande de congé ? Cliquez sur le bouton ci-dessus pour déclarer une facilement. 😊',
  'Aucune demande de congé trouvée (user)': 'Ce collaborateur n’a aucune demande de congé pour le moment.',
  'Aucune demande de congé trouvée (team)': 'Aucune demande de congé pour cette équipe pour le moment.',
  'Déclarer un congé': 'Déclarer un congé',
  'Demander un congé': 'Demander un congé',
  'Demande effectuée': 'Demande effectuée',
  "jours à l'avance": "jours à l'avance",
  'jours en retard': 'jours en retard',
  'le jour même': 'le jour même',
  'Demande effectuée le:': 'Demande effectuée le:',
  'Voir la pièce jointe': 'Voir la pièce jointe',
  'Détails de la demande de congé': 'Détails de la demande de congé',
  'Approbation de la demande de congé': 'Approbation de la demande de congé',
  days: 'jours',
  'Justificatifs:': 'Justificatifs:',
  "Commentaire d'approbation:": "Commentaire d'approbation:",
  'Ajouter un commentaire': 'Ajouter un commentaire',
  Commentaire: 'Commentaire:',
  'En cours...': 'En cours...',
  Approuver: 'Accepter',
  Refuser: 'Refuser',
  Collaborateur: 'Collaborateur',
  'Type de congé': 'Type de congé',
  'Date de début': 'Date de début',
  'Date de fin': 'Date de fin',
  'Date de demande': 'Date de demande',
  'Total demandé': 'Total demandé',
  Statut: 'Statut',
  Tous: 'Tous',
  'En attente': 'En attente',
  Approuvée: 'Approuvée',
  Refusée: 'Refusée',
  Annulée: 'Annulée',
  Actions: 'Actions',
  'Appliqué pour': 'Appliqué pour',
  Date: 'Date',
  "Date d'ajustement": "Date d'ajustement",
  Valeur: 'Valeur',
  Comptabilisé: 'Comptabilisé',
  'Créer un ajustement': 'Créer un ajustement',
  "Modifier l'ajustement de ": "Modifier l'ajustement de ",
  "Voir l'ajustement de ": 'Ajustement de ',
  "Pas d'ajustements": "Pas encore d'ajustements? Cliquez sur le bouton ci-dessus pour en créer un. 😊",
  "Date d'ajout": "Date d'ajout",
  'Voir le solde de congés': 'Voir le solde de congés',
  'Compteurs de solde de congés': 'Compteurs de solde de congés',
  ADJUSTMENTS: {
    DELETE_CONFIRMATION_TITLE: 'Supprimer l’ajustement',
    DELETE_CONFIRMATION_MESSAGE: 'Êtes-vous sûr de vouloir supprimer cet ajustement?',
    ADJUSTMENT_DELETED_SUCCESSFULLY: 'Ajustement supprimé avec succès',
  },
  Acquis: 'Acquis',
  Pris: 'Pris',
  'Solde Restant': 'Solde Restant',
  'En attente de validation': 'En attente de validation',
  'En aquisition': 'En aquisition',
}
