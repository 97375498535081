<ng-container *ngIf="isLoading">
  <div class="d-flex justify-content-center align-items-center" style="height: 100vh">
    <div class="spinner-grow text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!isLoading && flexiAbonnement == true">
  <div class="top-actions-menu d-flex align-items-center justify-content-between">
    <div style="width: fit-content; flex-wrap: wrap" class="d-flex align-items-center justify-content-between">
      <mat-form-field appearance="fill" class="ml-5">
        <mat-label>
          {{ "OSPLANNER.CHOOSE_SITE" | translate }}
        </mat-label>
        <mat-select [ngModel]="siteId" (ngModelChange)="changeSite($event, true)">
          <mat-option *ngFor="let site of sites" [value]="site.value">
            {{ site.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container *ngIf="siteId">
        <mat-form-field appearance="fill" class="ml-5" *ngIf="siteBuildings?.length">
          <mat-label>
            {{ "OSPLANNER.CHOOSE_BUILDING" | translate }}
          </mat-label>
          <mat-select [ngModel]="building?.id" (ngModelChange)="changeBuilding($event)">
            <mat-option *ngFor="let building of siteBuildings" [value]="building?.id">
              {{ building.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <ng-container *ngIf="isModelingMode">
          <button style="color: white" *ngIf="!siteBuildings?.length" class="mx-5" (click)="addBuilding()" mat-raised-button color="accent">
            {{ "OSPLANNER.ADD_BUILDING" | translate }}
          </button>
          <button *ngIf="siteBuildings?.length" class="mx-5" (click)="addBuilding()" mat-mini-fab color="accent">
            <mat-icon>add</mat-icon>
          </button>
          <button
            *ngIf="siteBuildings?.length"
            class="mx-5"
            (click)="removeBuilding()"
            class="white-icon"
            mat-mini-fab
            color="warn"
            aria-label="Example icon button with a menu icon"
          >
            <mat-icon style="color: white !important">delete</mat-icon>
          </button>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="building">
        <mat-form-field appearance="fill" class="ml-5" *ngIf="building.floors.length">
          <mat-label> {{ "OSPLANNER.CHOOSE_FLOOR" | translate }} </mat-label>
          <mat-select [ngModel]="_floorData?.id" (ngModelChange)="changeFloor($event)">
            <mat-option *ngFor="let floor of building.floors" [value]="floor.id">
              {{ floor.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <ng-container *ngIf="isModelingMode">
          <button style="color: white" *ngIf="!building.floors.length" class="mx-5" (click)="addFloor()" mat-raised-button color="accent">
            {{ "OSPLANNER.ADD_FLOOR" | translate }}
          </button>
          <button
            *ngIf="building.floors.length"
            class="mx-5"
            (click)="addFloor()"
            mat-mini-fab
            color="accent"
            aria-label="Example icon button with a menu icon"
          >
            <mat-icon>add</mat-icon>
          </button>
          <button
            *ngIf="building.floors.length"
            class="mx-5"
            (click)="removeFloor()"
            class="white-icon"
            mat-mini-fab
            color="warn"
            aria-label="Example icon button with a menu icon"
          >
            <mat-icon style="color: white !important">delete</mat-icon>
          </button>
        </ng-container>
      </ng-container>
    </div>

    <div class="d-flex align-items-center">
      <div class="d-flex align-items-center mr-5" *ngIf="isManagerMode">
        <button mat-raised-button color="secondary" (click)="openAutoFlexiPopup()">
          <mat-icon><mat-icon> highlight</mat-icon> </mat-icon> Auto FlexiSpace
        </button>
      </div>
      <ng-container *ngIf="isReservationMode && !isAdminMode">
        <div class="d-flex align-items-center">
          <!-- Parking Button -->
          <div class="d-flex align-items-center mr-5" *ngIf="parckingAbonnement == true && abonnement?.parking_actif == 1">
            <button mat-raised-button color="secondary" (click)="openParkingList()"><mat-icon>local_parking</mat-icon> Parking</button>
          </div>

          <!-- Carpooling Button -->
          <div class="d-flex align-items-center mr-10" *ngIf="covoiturageAbonnement == true && abonnement?.covoiturage_actif == 1">
            <button mat-raised-button color="secondary" (click)="openCarpoolList(true)">
              <mat-icon>directions_car</mat-icon>
              Covoiturage
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isModelingMode">
        <button
          mat-raised-button
          color="primary"
          style="width: 50px"
          class="ml-3 text-white"
          style="min-width: unset; width: fit-content"
          (click)="toggleLeftSideMenu()"
        >
          <mat-icon style="font-size: 26px !important" class="mr-0">first_page</mat-icon>
        </button>
        <button
          [disabled]="!_floorData"
          mat-raised-button
          [color]="isAddingSeats ? 'accent' : 'primary'"
          class="ml-3 {{ _floorData ? 'text-white' : '' }}"
          (click)="toggleAddingSeats()"
        >
          <mat-icon style="font-size: 26px !important" class="mr-0">person_pin</mat-icon>
        </button>
        <button
          [disabled]="!_floorData || isUntouched"
          mat-raised-button
          color="primary"
          class="ml-3 {{ _floorData ? 'text-white' : '' }}"
          (click)="save()"
        >
          <!-- {{ "GENERAL.BTN_SAVE" | translate }} -->
          <mat-icon style="font-size: 26px !important" class="mr-0">save</mat-icon>
        </button>
        <button
          mat-raised-button
          color="primary"
          style="width: 50px"
          class="ml-3 text-white"
          style="min-width: unset; width: fit-content"
          (click)="undo()"
          [disabled]="!_floorData || !spaceHistory.length"
        >
          <mat-icon style="font-size: 26px !important" class="mr-0">undo</mat-icon>
        </button>
      </ng-container>
    </div>
  </div>

  <hr />
  <div class="d-flex">
    <!-- BEGIN LEFT SIDE MENU -->
    <div class="card elevation leftsidemenu customScrollBar" [ngClass]="{ 'leftsidemenu-open': showLeftSideMenu }">
      <div class="py-5" style="width: 100%">
        <!-- prettier-ignore -->
        <pre class="h3 title-1 mt-5 text-center" style="font-family: unset; overflow: hidden">{{ (isReservationMode ? "OSPLANNER.RESERVE_SLOT" : "OSPLANNER.GRAPHICAL_ELEMENTS") | translate }}</pre>
        <div class="d-flex w-100 align-items-center justify-content-center mt-10" *ngIf="isReservationMode">
          <span class="d-flex align-items-center">
            <p style="margin: 0; padding: 0" class="mr-5">
              {{ "GENERAL.FROM" | translate }}
            </p>
            <input
              #startTimeRef
              [ngModel]="resvTimeStart"
              (ngModelChange)="changeResvTimeStart($event)"
              type="time"
              class="form-control"
              style="width: 111px; height: auto; cursor: pointer"
            />
          </span>
          <span class="d-flex align-items-center">
            <p style="margin: 0; padding: 0" class="mx-5">{{ "GENERAL.TO" | translate }}:</p>
            <input
              #endTimeRef
              [ngModel]="resvTimeEnd"
              (ngModelChange)="changeResvTimeEnd($event)"
              type="time"
              class="form-control"
              style="width: 111px; height: auto; cursor: pointer"
            />
          </span>
          <button class="xs-icon-button ml-3" (click)="refetchTodayReservations()">
            <mat-icon class="custom-icon">autorenew</mat-icon>
          </button>
        </div>
      </div>
      <hr />
      <div *ngIf="isModelingMode" (click)="_floorData ? null : toastrSelectFloor()">
        <mat-accordion
          class="hideScrollBar"
          style="width: 100%; max-width: 100%; max-height: 85%; overflow-y: auto; overflow-x: hidden; border-bottom: 1px solid rgb(222, 222, 222)"
          [style.pointerEvents]="_floorData ? 'all' : 'none'"
        >
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div style="display: flex; align-items: center; justify-content: center">
                  <mat-icon class="mr-3">texture</mat-icon>
                  <p class="m-0 p-0" style="min-width: max-content">
                    {{ "OSPLANNER.FLOOR_COVERING" | translate }}
                  </p>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="hideScrollBar desks-expansion-content" *ngIf="_floorData">
              <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 25px; background-color: rgba(234, 234, 234, 0.333); padding: 20px">
                <div
                  *ngFor="let tileNbr of createArray(5)"
                  (click)="changeFloorTile(tileNbr)"
                  style="
                    display: block;
                    cursor: pointer;
                    border: 1px dashed rgba(134, 134, 134, 0.464);
                    border-radius: 5px;
                    padding: 5px;
                    max-height: 100px;
                    width: 100%;
                  "
                >
                  <img
                    draggable="false"
                    [src]="'assets/images/floors/tile_' + tileNbr + '.jpg'"
                    width="100"
                    height="auto"
                    style="margin-left: auto; margin-right: auto; display: block; padding: 5px 0; width: 100%; height: 100%; object-fit: cover"
                  />
                </div>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div style="display: flex; align-items: center; justify-content: center">
                  <mat-icon class="mr-3 svg-icon menu-icon">desktop_mac</mat-icon>
                  <p class="m-0 p-0" style="min-width: max-content">
                    {{ "OSPLANNER.OFFICES" | translate }}
                  </p>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <hr />
            <div class="hideScrollBar desks-expansion-content">
              <div style="display: grid; grid-template-columns: auto auto; gap: 25px; background-color: rgba(234, 234, 234, 0.333); padding: 20px">
                <div
                  *ngFor="let assetNbr of createArray(38)"
                  (click)="addObject('desk', assetNbr)"
                  style="display: block; cursor: pointer; border: 1px dashed rgba(134, 134, 134, 0.464)"
                >
                  <img
                    draggable="false"
                    [src]="'assets/images/desks/' + assetNbr + '.png'"
                    width="100"
                    height="auto"
                    style="margin-left: auto; margin-right: auto; display: block; padding: 5px 0"
                  />
                </div>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div style="display: flex; align-items: center; justify-content: center">
                  <mat-icon class="mr-3">calendar_view_day</mat-icon>
                  <p class="m-0 p-0">
                    {{ "OSPLANNER.WALLS" | translate }}
                  </p>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="py-3" style="border: 1px solid rgb(222, 222, 222)">
              <div class="d-flex align-items-center justify-content-around">
                <button mat-button color="accent" class="px-10" style="background-color: rgb(247, 247, 247)" (click)="addWall('horizontal')">
                  {{ "OSPLANNER.HORI" | translate }}
                </button>
                <button mat-button color="accent" class="px-10" style="background-color: rgb(247, 247, 247)" (click)="addWall('vertical')">
                  {{ "OSPLANNER.VERT" | translate }}
                </button>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div style="display: flex; align-items: center; justify-content: center">
                  <mat-icon class="mr-3">chair</mat-icon>
                  <p class="m-0 p-0">
                    {{ "OSPLANNER.FURNITURE" | translate }}
                  </p>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="hideScrollBar desks-expansion-content">
              <div style="display: grid; grid-template-columns: auto auto; gap: 25px; background-color: rgba(234, 234, 234, 0.333); padding: 20px">
                <div class="hideScrollBar desks-expansion-content">
                  <div
                    style="display: grid; grid-template-columns: auto auto; gap: 25px; background-color: rgba(234, 234, 234, 0.333); padding: 20px"
                  >
                    <div
                      *ngFor="let assetNbr of createArray(24)"
                      (click)="addObject('furniture', assetNbr)"
                      style="display: block; cursor: pointer; border: 1px dashed rgba(134, 134, 134, 0.464)"
                    >
                      <img
                        draggable="false"
                        [src]="'assets/images/furnitures/' + assetNbr + '.png'"
                        width="100"
                        height="auto"
                        style="margin-left: auto; margin-right: auto; display: block; padding: 5px 0"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div style="display: flex; align-items: center; justify-content: center">
                  <mat-icon class="mr-3">streetview</mat-icon>
                  <p class="m-0 p-0">
                    {{ "OSPLANNER.ACCESSIBILITY_AND_DECORATION" | translate }}
                  </p>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="hideScrollBar desks-expansion-content">
              <div style="display: grid; grid-template-columns: auto auto; gap: 25px; background-color: rgba(234, 234, 234, 0.333); padding: 20px">
                <div class="hideScrollBar desks-expansion-content">
                  <div
                    style="display: grid; grid-template-columns: auto auto; gap: 25px; background-color: rgba(234, 234, 234, 0.333); padding: 20px"
                  >
                    <div
                      *ngFor="let deco of createArray(11).slice(1).reverse(); let i = index"
                      (click)="addObject('decoration', deco)"
                      style="display: block; cursor: pointer; border: 1px dashed rgba(134, 134, 134, 0.464)"
                    >
                      <img
                        draggable="false"
                        [src]="'assets/images/decorations/' + deco + '.png'"
                        [alt]="'deco'"
                        width="100"
                        height="auto"
                        style="margin-left: auto; margin-right: auto; display: block; padding: 5px 0"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div style="display: flex; align-items: center; justify-content: center">
                  <mat-icon class="mr-3">settings</mat-icon>
                  <p class="m-0 p-0">
                    {{ "OSPLANNER.SETTINGS" | translate }}
                  </p>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="hideScrollBar desks-expansion-content">
              <div
                style="
                  display: flex;
                  grid-template-columns: auto auto;
                  gap: 10px 25px;
                  direction: ltr;
                  width: 100%;
                  justify-content: center;
                  align-content: center;
                  margin: 15px 0;
                  justify-content: space-between;
                "
                class="px-7"
                *ngIf="_floorData"
              >
                <span class="d-flex align-items-center">
                  <label style="align-self: center" class="m-0 mr-3"> {{ "GENERAL.WIDTH" | translate }} (cm) :</label>
                  <input class="m-0 w-50" type="number" [(ngModel)]="_floorData.width" (input)="isUntouched = false" style="border: none" />
                </span>
                <span class="d-flex align-items-center">
                  <label style="align-self: center" class="m-0 mr-3"> {{ "GENERAL.HEIGHT" | translate }} (cm) :</label>
                  <input class="m-0 w-50" type="number" [(ngModel)]="_floorData.height" (input)="isUntouched = false" style="border: none" />
                </span>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div *ngIf="isReservationMode && reservableDays.length" style="max-height: 100%; overflow: auto" class="customScrollBar d-flex flex-column">
        <span *ngFor="let day of reservableDays; let i = index" class="d-flex w-100 flex-column">
          <span
            class="dayBtn d-flex pr-0"
            [ngClass]="{
              selected: day.date === selectedReservationDate,
              'has-planning': hasMadePlanning(day.occupancies),
              'has-reserved': hasMadeReservation(day.occupancies)
            }"
            (click)="selectReservationDate(day.date)"
            style="position: relative"
          >
            <!-- shoow car icon if day.hasCarpool as a square neutral icon floating in the center of this div in absolute position -->
            <span
              *ngIf="day.hasCarpool"
              style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)"
              class="d-flex"
              [class]="day.hasParking ? 'ml-10' : ''"
            >
              <mat-icon>directions_car</mat-icon>
            </span>
            <!-- show parking icon if day.hasParking as a square neutral icon floating in the center of this div in absolute position -->
            <span *ngIf="day.hasParking" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)" class="d-flex">
              <mat-icon>local_parking</mat-icon>
            </span>

            <span> {{ toDate(day.date) | localizedDate : "daymonth" }}</span>

            <div *ngIf="day.occupancies?.length" class="symbol-group symbol-hover ml-10">
              <ng-container *ngFor="let user of day.occupancies.slice(0, 3)">
                <div
                  [style.border]="user.has_reserved ? '2px solid #5cdb60' : 'none'"
                  class="symbol symbol-circle elevation"
                  triggers="mouseenter:mouseleave"
                  ngbPopover="{{ user?.fname + ' ' + user?.lname }}"
                >
                  <span
                    *ngIf="user.avatar"
                    style="display: flex; width: 35px; height: 35px"
                    [ngStyle]="{
                      backgroundImage: 'url(' + encodeImgUrl(user.avatar) + ')',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      borderRadius: '50%'
                    }"
                  >
                  </span>
                  <span *ngIf="!user.avatar" style="width: 35px; height: 35px" class="symbol-label bg-light-primary text-primary 40px">
                    {{ user.fname.charAt(0).toUpperCase() }}
                  </span>
                </div>
              </ng-container>

              <a *ngIf="day.occupancies?.length > 3" class="symbol symbol-circle cursor-pointer elevation">
                <span
                  style="width: 35px; height: 35px"
                  class="symbol-label fs-8 fw-bolder"
                  data-bs-toggle="tooltip"
                  data-bs-trigger="hover"
                  title="View more users"
                >
                  +{{ day.occupancies?.length - 3 }}
                </span>
              </a>

              <span class="pr-5"> </span>
            </div>
          </span>
          <div *ngIf="toDate(day.date).getDay() === 0" style="display: flex; height: 10px; background-color: rgb(222, 222, 222); width: 100%"></div>
        </span>
      </div>
    </div>
    <!-- END LEFT SIDE MENU -->

    <!-- BEGIN FLOOR SPACE -->
    <div
      [style]="'display:flex; overflow: hidden; max-height: 75vh; height: 75vh; display: flex; transition: width 0.5s;'"
      [ngStyle]="{ width: showLeftSideMenu ? '70%' : '97%' }"
      class="card elevation ml-5"
    >
      <div
        *ngIf="isLoadingSpace"
        class="d-flex justify-content-center align-items-center"
        style="height: 100%; width: 100%; opacity: 0.5; background: white; position: absolute; top: 0; left: 0; z-index: 999999"
      >
        <div [style.opacity]="1" class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div [style.pointerEvents]="isLoadingSpace ? 'none' : 'all'" style="display: block; position: relative">
        <div
          *ngIf="_floorData"
          #floor
          style="display: block; background-color: transparent"
          [ngStyle]="{
            'background-image': isPanZoomInitialized ? 'url(\'assets/images/floors/tile_' + _floorData.tile + '.jpg\')' : 'none',
            width: _floorData.width * realScaleFactor + 'px',
            height: _floorData.height * realScaleFactor + 'px'
          }"
          class="elevation-strong"
          (mouseup)="onFloorMouseUp($event)"
          (contextmenu)="onFloorRightClick($event)"
        >
          <div *ngIf="isPanZoomInitialized" [ngClass]="{ modeling: isModelingMode }">
            <ng-container *ngFor="let desk of _floorData.desks; let i = index">
              <div
                (contextmenu)="onRightClickObject($event, desk)"
                class="desk-box"
                cdkDrag
                [cdkDragDisabled]="isReservationMode"
                [cdkDragFreeDragPosition]="desk.position"
                [cdkDragConstrainPosition]="constrainPosition"
                (cdkDragStarted)="startDeskDragging($event, desk)"
                (cdkDragEnded)="endDeskDragging($event, desk, img)"
                (cdkDragMoved)="onDeskDragging($event, desk)"
              >
                <img
                  #img
                  draggable="false"
                  style="display: block; position: relative"
                  [style.transform]="'rotate(' + desk.rotation + 'deg)'"
                  [src]="'assets/images/desks/' + desk.assetNumber + '.png'"
                  [alt]="desk.name"
                  [width]="desk.width * realScaleFactor"
                  height="auto"
                />
              </div>
              <div
                *ngFor="let seat of desk.seats; let i = index"
                class="seat-circle-container"
                cdkDrag
                [cdkDragDisabled]="isReservationMode"
                [cdkDragFreeDragPosition]="seat.position"
                [cdkDragConstrainPosition]="constrainPosition"
                (cdkDragStarted)="startDeskDragging($event, seat)"
                (cdkDragMoved)="onSeatDragging($event, seat)"
                (cdkDragEnded)="onSeatDragEnd($event, seat, desk)"
                [style.zIndex]="999999 + (hoveredSeatId === seat.id ? 1 : 0)"
                (mouseenter)="onSeatMouseEnter(seat.id)"
                (mouseleave)="onSeatMouseLeave()"
              >
                <ng-container *ngIf="isAdminMode || (isReservationMode && seat.reservations?.length)">
                  <div
                    (click)="$event.stopPropagation()"
                    *ngIf="hoveredSeatId === seat.id"
                    class="username-popover elevation"
                    style="opacity: 1; padding: 25px"
                  >
                    <span class="triangle"></span>
                    <span class="rect"></span>
                    <div class="d-flex align-items-start flex-column">
                      <ng-container *ngFor="let reservation of seat.reservations">
                        <span class="d-flex mb-3 align-items-center justify-content-start">
                          <div
                            triggers="mouseenter:mouseleave"
                            ngbPopover="{{ reservation?.fname + ' ' + reservation?.lname }}"
                            [ngStyle]="{
                        backgroundImage:
                        selectedReservationDate
                            ? 'url(' + encodeImgUrl(reservation.avatar) + ')'
                            : 'unset',
                        width: 45  + 'px',
                        height: 45  + 'px',
                   
                      }"
                            style="display: flex; background-size: cover; background-position: center; border-radius: 50%"
                            class="elevation mr-3"
                          ></div>
                          <span class="badge mr-3" style="background: #d8aa72; color: white"
                            >{{ reservation.startTime.slice(0, 5) }} - {{ reservation.endTime.slice(0, 5) }}</span
                          >
                          <button
                            *ngIf="isAdminMode || (isReservationMode && reservation.userId === currentUser.uuid)"
                            (click)="cancelReservation(reservation.id, isAdminMode ? reservation.userId : undefined)"
                            class="btn btn-sm btn-icon btn-danger ms-2"
                          >
                            <i class="fas fa-times"></i>
                          </button>
                        </span>
                      </ng-container>

                      <!-- <span *ngIf="isAdminMode && seat.reservations?.length" class="my-4"></span> -->

                      <div *ngIf="isAdminMode && !seat.reservations?.length" class="d-flex align-items-center" style="width: 100%">
                        <button
                          (click)="reserveVisitor(seat)"
                          class="btn btn-sm btn-icon btn-success"
                          style="background: #5bcc5f; color: white; width: 100%; font-size: 18px; padding: 5px 15px"
                        >
                          <i class="fas fa-user mr-3"></i> <span>Réserver un visiteur</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- <div
                    *ngIf="seat.isReservedByOnePerson(selectedReservationDate) && seat.getUserId(selectedReservationDate) !== currentUser.uuid"
                    class="username-popover elevation"
                    style="z-index: 99 !important"
                  >
                    <span class="triangle"></span>
                    <span class="rect"></span>

                    {{ seat.getUserFullName(selectedReservationDate) }}
                  </div> -->
                </ng-container>

                <div
                  [ngStyle]="{
                backgroundImage:
                  isReservationMode && selectedReservationDate && seat.isReservedByOnePerson(selectedReservationDate)
                    ? 'url(' + encodeImgUrl(seat.getUserAvatar(selectedReservationDate)) + ')'
                    : 'unset',
                width: seat.width * realScaleFactor + 'px',
                height: seat.width * realScaleFactor + 'px',
           
              }"
                  (click)="isReservationMode && onSeatClick($event, seat)"
                  (contextmenu)="onRightClickObject($event, seat)"
                  class="elevation-strong seat-circle"
                >
                  <!-- number of seat centered in no reservation -->
                  <div *ngIf="isModelingMode">
                    <span class="empty-seat">
                      {{ i + 1 }}
                    </span>
                  </div>
                  <ng-container *ngIf="isReservationMode">
                    <ng-container *ngIf="selectedReservationDate && seat.isReservedByManyPeople(selectedReservationDate)">
                      <mat-icon style="font-size: 82px !important; color: #1bc5bd; top: 5px; position: absolute; left: 3px"> group </mat-icon>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </ng-container>

            <div
              *ngFor="let movable of _floorData.movables; let i = index"
              (contextmenu)="onRightClickObject($event, movable)"
              cdkDrag
              [cdkDragDisabled]="isReservationMode"
              [cdkDragFreeDragPosition]="movable.position"
              [cdkDragConstrainPosition]="constrainPosition"
              (cdkDragStarted)="startDeskDragging($event, movable)"
              style="display: block; position: absolute; top: 0; left: 0; z-index: 999"
              class="movable-object"
            >
              <img
                draggable="false"
                style="display: block; position: relative"
                [style.transform]="'rotate(' + movable.rotation + 'deg)'"
                [src]="'./assets/images/' + movable.type + 's' + '/' + movable.assetNumber + '.png'"
                [alt]="movable.name"
                [width]="movable.width * 2"
                height="auto"
              />
            </div>

            <div
              *ngFor="let wall of _floorData.walls; let i = index"
              (contextmenu)="onRightClickObject($event, wall)"
              cdkDrag
              [cdkDragDisabled]="isReservationMode"
              [cdkDragFreeDragPosition]="wall.position"
              [cdkDragConstrainPosition]="constrainPosition"
              (cdkDragStarted)="startDeskDragging($event, wall)"
            >
              <div
                style="display: block; position: absolute; top: 0; left: 0; z-index: 10; cursor: pointer; overflow: auto"
                [ngStyle]="{
                  width: wall.width * realScaleFactor + 'px',
                  height: wall.height * realScaleFactor + 'px',
                  resize: wall.orientation,
                  cursor: 'move',
                  backgroundColor: wall.color || '#00003f'
                }"
                class="elevation-strong wall"
                [style.transform]="'rotate(' + wall.rotation + 'deg)'"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END FLOOR SPACE -->
  </div>

  <br />

  <app-context-menu
    *ngIf="isModelingMode && ctxMenuTemplateRef && _floorData && floorElRef"
    [target]="floorEl"
    [template]="getContextMenuTemplate()"
    [isActive]="isRightClickedObject"
  ></app-context-menu>
</ng-container>

<ng-template #ctxMenuTemplate>
  <div *ngIf="_floorData && selectedObject" style="display: flex; flex-flow: column; padding: 10px 10px; max-width: 400px">
    <span class="d-flex align-items-center mb-2">
      <p class="m-0 p-0 mr-5">{{ "OSPLANNER.OBJECT_CODE" | translate }} :</p>
      <input
        type="text"
        class="form-control"
        style="width: fit-content; max-width: 60%; max-height: 25px"
        [(ngModel)]="selectedObject.name"
        (input)="isUntouched = false"
      />
    </span>

    <span class="d-flex align-items-center my-3" *ngIf="selectedObject.type === 'wall'">
      <p class="m-0 p-0 mr-3">{{ "GENERAL.HEIGHT" | translate }} (cm):</p>
      <div style="display: flex; position: relative">
        <input style="max-height: 25px" class="form-control w-50" type="number" [(ngModel)]="selectedObject.height" />
      </div>
    </span>
    <span class="d-flex align-items-center mb-3">
      <p class="m-0 p-0 mr-3">{{ "GENERAL.WIDTH" | translate }} (cm):</p>
      <div style="display: flex; position: relative">
        <input style="max-height: 25px" class="form-control w-50" type="number" [(ngModel)]="selectedObject.width" />
      </div>
    </span>

    <span class="d-flex align-items-center mb-3">
      <p class="m-0 p-0 mr-3">{{ "OSPLANNER.MAX_SEATS" | translate }}:</p>
      <div style="display: flex; position: relative">
        <input style="max-height: 25px" class="form-control w-50" type="number" [(ngModel)]="selectedObject.maxSeats" />
      </div>
    </span>

    <span *ngIf="selectedObject.type === 'wall'" class="d-flex align-items-center mb-3">
      <p class="m-0 p-0 mr-3">{{ "GENERAL.COLOR" | translate }}:</p>
      <input type="color" style="width: 100px" class="form-control" [(ngModel)]="selectedObject.color" (input)="isUntouched = false" />
      <span>
        <span></span>
        <mat-micro-toggle [checked]="selectedObject.applyToAll" (change)="selectedObject.applyToAll = !selectedObject.applyToAll"></mat-micro-toggle>
      </span>
    </span>

    <span class="d-flex align-items-center my-5">
      <p class="m-0 p-0 mr-3">Rotation °:</p>
      <input
        style="max-height: 25px; width: 42px"
        class="form-control mr-1"
        type="number"
        [(ngModel)]="selectedObject.rotation"
        (input)="isUntouched = false"
      />
      <input class="form-range" type="range" min="0" max="180" [(ngModel)]="selectedObject.rotation" (input)="isUntouched = false" />
    </span>

    <ng-container *ngIf="selectedObject.type === 'desk'">
      <div class="d-flex justify-content-center align-items-center">
        <mat-form-field appearance="fill" class="ctx">
          <mat-label> {{ "OSPLANNER.ACCESSED_BY" | translate }} </mat-label>
          <mat-select [(ngModel)]="selectedObject.accesstype" (selectionChange)="ctxChangeDeskAccessType($event)">
            <mat-option [value]="0">
              {{ "OSPLANNER.RESV_RULES.TARGETS.5" | translate }}
            </mat-option>
            <mat-option [value]="1"> {{ "OSPLANNER.RESV_RULES.TARGETS.3" | translate }} </mat-option>
            <mat-option [value]="2"> {{ "OSPLANNER.RESV_RULES.TARGETS.2" | translate }} </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field style="max-width: 80%" appearance="fill" class="ctx" *ngIf="selectedObject.accesstype === 1">
          <mat-label>
            {{ "OSPLANNER.RESV_RULES.TARGETS.3" | translate }}
          </mat-label>
          <mat-select [(value)]="selectedObject.service" (selectionChange)="isUntouched = false">
            <mat-option *ngFor="let service of services" [value]="service.value"> {{ service.viewValue }} </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="max-width: 80%" appearance="fill" class="ctx" *ngIf="selectedObject.accesstype === 2">
          <mat-label>
            {{ "OSPLANNER.RESV_RULES.TARGETS.2" | translate }}
          </mat-label>
          <mat-select [(value)]="selectedObject.equipe" (selectionChange)="isUntouched = false">
            <mat-option *ngFor="let equipe of equipes" [value]="equipe.value">
              {{ equipe.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="d-flex align-items-center mt-5">
        <mat-checkbox class="mr-5" [(ngModel)]="selectedObject.isMeetingRoom">
          {{ "OSPLANNER.MEETING_ROOM" | translate }}
        </mat-checkbox>
        <mat-checkbox [(ngModel)]="selectedObject.reserveByAdmin">
          {{ "OSPLANNER.RESERV_BY_ADMIN" | translate }}
        </mat-checkbox>
      </div>
    </ng-container>

    <span style="display: flex; float: right; justify-content: right; margin-top: 15px; cursor: pointer">
      <mat-icon style="color: #f64e60 !important; font-size: 24px !important" (click)="contextMenuEvents.deleteObject()">delete</mat-icon>
    </span>
  </div>
</ng-template>
