import { Component, OnInit } from '@angular/core'
import { AuthService } from '../../auth'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-auth-quiz',
  templateUrl: './auth-quiz.component.html',
  styleUrls: ['./auth-quiz.component.scss'],
})
export class AuthQuizComponent implements OnInit {
  constructor(private auth: AuthService, public modal: NgbActiveModal) {}

  ngOnInit(): void {}

  logout() {
    this.auth.logout()
    document.location.reload()
  }

  close() {
    this.modal.close()
  }
}
