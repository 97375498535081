/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err))

// if (environment.production) {
//   enableProdMode()
//   if (window) {
//     window.console.log = function () {}
//   }
// }
