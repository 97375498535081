//tslint:disable:variable-name
import { HttpClient, HttpParams } from '@angular/common/http'
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs'
import { catchError, finalize, tap } from 'rxjs/operators'
import { environment } from '../../../../../environments/environment'
import { BaseModel } from '../models/base.model'
import { GroupingState } from '../models/grouping.model'
import { PaginatorState } from '../models/paginator.model'
import { SortState } from '../models/sort.model'
import { ITableState, TableResponseModel } from '../models/table.model'

const DEFAULT_STATE: ITableState = {
  filter: {},
  paginator: new PaginatorState(),
  sorting: new SortState(),
  searchTerm: '',
  grouping: new GroupingState(),
  entityId: undefined,
}

export abstract class TableService<T> {
  // Private fields
  private _items$ = new BehaviorSubject<T[]>([])
  private _isLoading$ = new BehaviorSubject<boolean>(false)
  private _isFirstLoading$ = new BehaviorSubject<boolean>(true)
  private _tableState$ = new BehaviorSubject<ITableState>(DEFAULT_STATE)
  private _errorMessage = new BehaviorSubject<string>('')
  private _subscriptions: Subscription[] = []

  // Getters
  get items$() {
    return this._items$.asObservable()
  }
  get isLoading$() {
    return this._isLoading$.asObservable()
  }
  get isFirstLoading$() {
    return this._isFirstLoading$.asObservable()
  }
  get errorMessage$() {
    return this._errorMessage.asObservable()
  }
  get subscriptions() {
    return this._subscriptions
  }
  // State getters
  get paginator() {
    return this._tableState$.value.paginator
  }
  get filter() {
    return this._tableState$.value.filter
  }
  get sorting() {
    return this._tableState$.value.sorting
  }
  get searchTerm() {
    return this._tableState$.value.searchTerm
  }
  get grouping() {
    return this._tableState$.value.grouping
  }

  protected authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`
  protected http: HttpClient
  // API URL has to be overrided
  API_URL = `${environment.apiUrl}/endpoint`
  API_URLSTRATEGY = `${environment.apiUrl}/strategie/getstrategie`
  API_URLs = `${environment.apiUrl}/todo/supprimeruserdetails`
  API_URLssmodifiuser = `${environment.apiUrl}/todo/modifieruserdetails`
  API_URLss1 = `${environment.apiUrl}/todo/modifierprofil`
  API_URLAnnuaire = `${environment.apiUrl}/annuaire/updateAnnuaireOption`
  API_URLsss = `${environment.apiUrl}/todo/getbyid`
  API_URLHistory = `${environment.apiUrl}/todo/gethistoriquebyid`
  API_URLe = `${environment.apiUrl}/todo/adduserdetails`
  API_URLpass = `${environment.apiUrl}/todo/changerpasswordok`
  API_URLALLSTRATEGY = `${environment.apiUrl}/strategie/getstrategieforajout`
  API_URLTAG = `${environment.apiUrl}/todo/add_Haut_potentiel`
  API_URLTAGSP = `${environment.apiUrl}/todo/add_Suivi_particulier`
  API_URLDETAG = `${environment.apiUrl}/todo/detaguer_Haut_potentiel`
  API_URLDETAGSP = `${environment.apiUrl}/todo/detaguer_Suivi_particulier`
  API_DELETE_CONGE = `${environment.apiUrl}/todo/deleteconge`

  API_URLUPDATECONGE = `${environment.apiUrl}/todo/addconge`
  API_URLONBOARDING = `${environment.apiUrl}/strategie/addOnboarding_Rules`
  API_URLONBOARDINGUPDATE = `${environment.apiUrl}/strategie/updateOnboarding_Rules`
  API_URLLinkUpdate = `${environment.apiUrl}/lienEntite/modifierLienEntite`
  constructor(http: HttpClient) {
    this.http = http
  }

  // CREATE
  // server should return the object with ID
  create(item: any, candidatid: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid).set('candidate_id', candidatid)
    this._isLoading$.next(true)
    this._errorMessage.next('')
    return this.http.post<any>(this.API_URLe, item, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('CREATE ITEM', err)

        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }
  createOnboarding(item: any, user_uuid: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid).set('user_uuid', user_uuid)
    this._isLoading$.next(true)
    this._errorMessage.next('')
    return this.http.post<any>(this.API_URLONBOARDING, item, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('CREATE ITEM', err)

        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }
  modifierOnboarding(item: any, rule_uuid: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid).set('rule_uuid', rule_uuid)
    this._isLoading$.next(true)
    this._errorMessage.next('')
    return this.http.put<any>(this.API_URLONBOARDINGUPDATE, item, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('CREATE ITEM', err)

        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }
  // UPDATE
  updateOnboarding(item: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const url = `${this.API_URLONBOARDINGUPDATE}?rule_uuid=${item[0].Rule_uuid}&&sessionid=${sessionid}`

    let site_id = item.site_id
    let service_id = item.service_id
    let equipe_id = item.equipe_id
    let time = item.time
    let type_cible = item.type_cible
    let priorite = item.priorite
    let event_id = item.event_id

    let body = {
      site_id,
      service_id,
      equipe_id,
      time,
      type_cible,
      priorite,
      event_id,
    }

    this._isLoading$.next(true)
    this._errorMessage.next('')
    return this.http.put(url, body).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('UPDATE ITEM', item, err)
        // window.location.reload()
        return of(item)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }
  updateLinkService(item: any, type: any, uuid_lien: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const url = `${this.API_URLLinkUpdate}?uuid_lien=${uuid_lien}&&sessionid=${sessionid}&&type=${type}`
    let choix_equipe_1 = item.choix_equipe_1
    let choix_equipe_2 = item.choix_equipe_2
    let choix_service_1 = item.choix_service_1
    let choix_service_2 = item.choix_service_2

    let body = {
      choix_service_1,
      choix_service_2,
      choix_equipe_1,
      choix_equipe_2,
    }

    this._isLoading$.next(true)
    this._errorMessage.next('')
    return this.http.put(url, body).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('UPDATE ITEM', item, err)
        // window.location.reload()
        return of(item)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  // READ (Returning filtered list of entities)
  find(tableState: ITableState, TYPE_COMPTE: any, site: any, list: any): Observable<TableResponseModel<T>> {
    const url = this.API_URL + '/find'
    this._errorMessage.next('')
    return this.http.post<TableResponseModel<T>>(url, tableState).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('FIND ITEMS', err)
        window.location.reload()
        return of({ items: [], total: 0 })
      })
    )
  }
  findStrategie(tableState: ITableState): Observable<TableResponseModel<T>> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    const url = this.API_URLSTRATEGY
    this._errorMessage.next('')
    return this.http.post<TableResponseModel<T>>(url, tableState, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('FIND ITEMS', err)
        window.location.reload()
        return of({ items: [], total: 0 })
      })
    )
  }
  findAllStrategie(tableState: ITableState): Observable<TableResponseModel<T>> {
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    const url = this.API_URLALLSTRATEGY
    this._errorMessage.next('')
    return this.http.post<TableResponseModel<T>>(url, tableState, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('FIND ITEMS', err)
        window.location.reload()
        return of({ items: [], total: 0 })
      })
    )
  }

  getItemById(id: any): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    const url = `${this.API_URLsss}?id=${id}`

    return this.http.get<any>(url, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('GET ITEM BY IT', id, err)
        window.location.reload()
        return of({ id: undefined })
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }
  getHistoryById(id: any, date: any): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid).set('id', id).set('date', date)
    const url = `${this.API_URLHistory}`

    return this.http.get<any>(url, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('GET ITEM BY IT', id, err)
        //window.location.reload()
        return of({ id: undefined })
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  //   // num_securite_social:

  // UPDATE
  update(item: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    let url
    if (item[0].id != null) {
      url = `${this.API_URLssmodifiuser}?id=${item[0].id}&&sessionid=${sessionid}`
    } else {
      url = `${this.API_URLssmodifiuser}?id=${item[0].uuid}&&sessionid=${sessionid}`
    }
    let nom = item.nom
    let prenom = item.prenom
    let photo_principale = item.photo_principale
    let fonction = item.fonction
    let login_email = item.login_email
    let IdRole_Role = item.IdRole_Role
    let idequipe = item.idequipe
    let processus_onboarding = item.processus_onboarding
    let email_personelle = item.email_personelle
    let date_entre = item.date_entre
    let date_fin = item.date_fin
    let genre = item.genre
    let type_contrat = item.type_contrat
    let date_debut_contrat = item.date_debut_contrat
    let date_fin_contrat = item.date_fin_contrat
    let pays_user = item.pays_user
    let ville_user = item.ville_user
    let adresse_user = item.adresse_user
    let code_postal = item.code_postal
    let telephone = item.telephone
    let uuid_manager = item.uuid_manager
    // num_securite_social:
    // nationalite:
    // telephone_professionel:
    // situation_familiale:
    // lieu_naissance:
    // identite_verso:
    // identite_recto:
    // num_enfants:
    // user_contact_urgence:
    // phone_user_urgence:
    // complement_adress:
    // date_naissance:
    let num_securite_social = item.num_securite_social
    let nationalite = item.nationalite
    let telephone_professionel = item.telephone_professionel
    let situation_familiale = item.situation_familiale
    let lieu_naissance = item.lieu_naissance
    let identite_verso = item.identite_verso
    let identite_recto = item.identite_recto
    let num_enfants = item.num_enfants
    let user_contact_urgence = item.user_contact_urgence
    let phone_user_urgence = item.phone_user_urgence
    let complement_adress = item.complement_adress
    let date_naissance = item.date_naissance
    let body = {
      nom,
      prenom,
      fonction,
      login_email,
      IdRole_Role,
      photo_principale,
      idequipe,
      processus_onboarding,
      email_personelle,
      date_entre,
      date_fin,
      genre,
      type_contrat,
      date_debut_contrat,
      date_fin_contrat,
      pays_user,
      ville_user,
      adresse_user,
      code_postal,
      telephone,
      uuid_manager,
      num_securite_social,
      nationalite,
      telephone_professionel,
      situation_familiale,
      lieu_naissance,
      identite_verso,
      identite_recto,
      num_enfants,
      user_contact_urgence,
      phone_user_urgence,
      complement_adress,
      date_naissance,
    }

    this._isLoading$.next(true)
    this._errorMessage.next('')
    return this.http.put(url, body).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('UPDATE ITEM', item, err)
        window.location.reload()
        return of(item)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }
  // UPDATE11
  update1(item: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()
    let uuid = JSON.parse(localStorage.getItem('uuid'))
    if (item[0].uuid != null) {
      const url = `${this.API_URLss1}?uuid=${item[0].uuid}&&sessionid=${sessionid}`
      let nom = item.nom
      let prenom = item.prenom

      let photo_principale = item.photo_principale
      let adresse_user = item.adresse
      let ville_user = item.ville
      let pays_user = item.pays
      let file = new FormData()
      //let user_langue = item[0].user_langue
      let body = { nom, prenom, photo_principale, file, adresse_user, ville_user, pays_user }
      return this.http.put<any>(url, body)
    } else {
      const url = `${this.API_URLss1}?uuid=${uuid}&&sessionid=${sessionid}`
      let nom = item.nom
      let prenom = item.prenom

      let photo_principale = item.photo_principale
      let adresse_user = item.adresse
      let ville_user = item.ville
      let pays_user = item.pays
      let file = new FormData()
      let user_langue = item[0].user_langue
      let body = { nom, prenom, photo_principale, file, user_langue, adresse_user, ville_user, pays_user }
      return this.http.put<any>(url, body)
    }
  }
  updateAnnuaire(item: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()
    const url = `${this.API_URLAnnuaire}?sessionid=${sessionid}`
    let annuaire_actif = item.annuaire_actif
    let propositions_rendezvous = item.propositions_rendezvous || item.updateAnnuaireOption

    let body = { annuaire_actif, propositions_rendezvous }
    return this.http.put<any>(url, body)
  }
  update2(item: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()
    const url = `${this.API_URLssmodifiuser}?id=${item[0].uuid}&&sessionid=${sessionid}`
    let nom = item.nom
    let prenom = item.prenom
    let adresse_user = item.adresse
    let ville_user = item.ville
    let pays_user = item.pays
    let date_entre = item.date_entre
    let body = { nom, prenom, adresse_user, ville_user, pays_user, date_entre }
    return this.http.put<any>(url, body)
  }
  //updateprofil
  updateprofil(item: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const url = `${this.API_URLss1}?uuid=${item[0].uuid}&&sessionid=${sessionid}`

    let nom = item.nom
    let prenom = item.prenom

    let body = { nom, prenom }

    this._isLoading$.next(true)
    this._errorMessage.next('')
    return this.http.put(url, body).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('UPDATE ITEM', item, err)
        window.location.reload()
        return of(item)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }
  // CHANGE PASSWORD
  updatepass(item: any, oldpass: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()

    const url = `${this.API_URLpass}?id=${item[0].uuid}&&sessionid=${sessionid}`

    let login_password = item.login_password
    let oldlogin_password = oldpass

    let body = { login_password, oldlogin_password }

    this._isLoading$.next(true)
    this._errorMessage.next('')
    return this.http.put(url, body).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('UPDATE ITEM', item, err)
        // window.location.reload()
        return of(err.status)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }
  // UPDATE Status
  updateStatusForItems(id: number[], status: number): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    const body = { id, status }
    const url = this.API_URL + '/updateStatus'
    return this.http.put(url, body).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('UPDATE STATUS FOR SELECTED ITEMS', id, status, err)
        window.location.reload()
        return of([])
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  // DELETE
  delete(id: any, dateSortie: any): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    const url = `${this.API_URLs}?id=${id}`

    const body = { dateSortie }
    return this.http.put(url, body, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('DELETE ITEM', id, err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of({})
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  detag(id: any): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    const url = `${this.API_URLDETAG}?id=${id}`

    return this.http.put(url, null, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('DELETE ITEM', id, err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of({})
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }
  detagSP(id: any): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    const url = `${this.API_URLDETAGSP}?id=${id}`

    return this.http.put(url, null, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('DELETE ITEM', id, err)
        if (err.status == 401) {
          window.location.reload()
        }
        return of({})
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  // delete list of items
  deleteItems(id: number[] = []): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    const url = this.API_URL + '/deleteItems'
    const body = { id }
    return this.http.put(url, body).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('DELETE SELECTED ITEMS', id, err)
        window.location.reload()
        return of([])
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  public fetch(TYPE_COMPTE: any, idsite: any, list: any) {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    const request = this.find(this._tableState$.value, TYPE_COMPTE, idsite, list)
      .pipe(
        tap((res: TableResponseModel<T>) => {
          this._items$.next(res.items)
          this.patchStateWithoutFetch({
            paginator: this._tableState$.value.paginator.recalculatePaginator(res.total),
          })
        }),
        catchError(err => {
          this._errorMessage.next(err)
          window.location.reload()
          return of({
            items: [],
            total: 0,
          })
        }),
        finalize(() => {
          this._isLoading$.next(false)
          const itemIds = this._items$.value.map((el: T) => {
            const item = el as unknown as BaseModel
            return item.id
          })
          this.patchStateWithoutFetch({
            grouping: this._tableState$.value.grouping.clearRows(itemIds),
          })
        })
      )
      .subscribe(res => {})
    this._subscriptions.push(request)
  }
  public fetchStrategy() {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    const request = this.findStrategie(this._tableState$.value)
      .pipe(
        tap((res: TableResponseModel<T>) => {
          this._items$.next(res.items)
          this.patchStateWithoutFetch({
            paginator: this._tableState$.value.paginator.recalculatePaginator(res.total),
          })
        }),
        catchError(err => {
          this._errorMessage.next(err)
          window.location.reload()
          return of({
            items: [],
            total: 0,
          })
        }),
        finalize(() => {
          this._isLoading$.next(false)
          const itemIds = this._items$.value.map((el: T) => {
            const item = el as unknown as BaseModel
            return item.id
          })
          this.patchStateWithoutFetch({
            grouping: this._tableState$.value.grouping.clearRows(itemIds),
          })
        })
      )
      .subscribe(data => {})
    this._subscriptions.push(request)
  }
  public fetchAllStrategy() {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    const request = this.findAllStrategie(this._tableState$.value)
      .pipe(
        tap((res: TableResponseModel<T>) => {
          this._items$.next(res.items)
        }),
        catchError(err => {
          this._errorMessage.next(err)
          window.location.reload()
          return of({
            items: [],
            total: 0,
          })
        })
      )
      .subscribe(data => {})
    this._subscriptions.push(request)
  }
  public setDefaults() {
    this.patchStateWithoutFetch({ filter: {} })
    this.patchStateWithoutFetch({ sorting: new SortState() })
    this.patchStateWithoutFetch({ grouping: new GroupingState() })
    this.patchStateWithoutFetch({ searchTerm: '' })
    this.patchStateWithoutFetch({
      paginator: new PaginatorState(),
    })
    this._isFirstLoading$.next(true)
    this._isLoading$.next(true)
    this._tableState$.next(DEFAULT_STATE)
    this._errorMessage.next('')
  }

  // Base Methods
  public patchState(patch: Partial<ITableState>, status: any, idsite: any, list: any) {
    this.patchStateWithoutFetch(patch)
    this.fetch(status, idsite, list)
  }
  public patchStateStrategy(patch: Partial<ITableState>) {
    this.patchStateWithoutFetch(patch)
    this.fetchStrategy()
  }

  public patchStateWithoutFetch(patch: Partial<ITableState>) {
    const newState = Object.assign(this._tableState$.value, patch)
    this._tableState$.next(newState)
  }
  protected setAuthFromLocalStorage(auth: any): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (1 === 1) {
      //auth sessionid ta3 user

      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth))

      return true
    }
    return false
  }

  protected getAuthFromLocalStorage(): any {
    try {
      const authData = JSON.parse(localStorage.getItem(this.authLocalStorageToken))
      return authData
    } catch (error) {
      console.error(error)
      window.location.reload()
      return undefined
    }
  }
  tag(item: any): Observable<any> {
    let id = item.id
    let commentaire = item.commentaire

    let body = { id, commentaire }

    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    this._isLoading$.next(true)
    this._errorMessage.next('')
    return this.http.post<any>(this.API_URLTAG, body, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('CREATE ITEM', err)

        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  tagSP(item: any): Observable<any> {
    let id = item.id
    let commentaire = item.commentaire

    let body = { id, commentaire }

    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    this._isLoading$.next(true)
    this._errorMessage.next('')
    return this.http.post<any>(this.API_URLTAGSP, body, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('CREATE ITEM', err)

        if (err.status == 401) {
          window.location.reload()
        }
        return of(err.status)
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }
}
