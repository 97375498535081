//tslint:disable:variable-name
import { HttpClient, HttpParams } from '@angular/common/http'
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs'
import { catchError, finalize, tap } from 'rxjs/operators'
import { environment } from '../../../../../environments/environment'
import { BaseModel } from '../models/base.model'
import { GroupingState } from '../models/grouping.model'
import { PaginatorState } from '../models/paginator.model'
import { SortState } from '../models/sort.model'
import { ITableState, TableResponseModel } from '../models/table.model'

const DEFAULT_STATE: ITableState = {
  filter: {},
  paginator: new PaginatorState(),
  sorting: new SortState(),
  searchTerm: '',
  grouping: new GroupingState(),
  entityId: undefined,
}

export abstract class TableActivityService<T> {
  // Private fields
  private _items$ = new BehaviorSubject<T[]>([])
  private _isLoading$ = new BehaviorSubject<boolean>(false)
  private _isFirstLoading$ = new BehaviorSubject<boolean>(true)
  private _tableState$ = new BehaviorSubject<ITableState>(DEFAULT_STATE)
  private _errorMessage = new BehaviorSubject<string>('')
  private _subscriptions: Subscription[] = []

  // Getters
  get items$() {
    return this._items$.asObservable()
  }
  get isLoading$() {
    return this._isLoading$.asObservable()
  }
  get isFirstLoading$() {
    return this._isFirstLoading$.asObservable()
  }
  get errorMessage$() {
    return this._errorMessage.asObservable()
  }
  get subscriptions() {
    return this._subscriptions
  }
  // State getters
  get paginator() {
    return this._tableState$.value.paginator
  }
  get filter() {
    return this._tableState$.value.filter
  }
  get sorting() {
    return this._tableState$.value.sorting
  }
  get searchTerm() {
    return this._tableState$.value.searchTerm
  }
  get grouping() {
    return this._tableState$.value.grouping
  }

  protected authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`
  protected http: HttpClient
  // API URL has to be overrided
  API_URL = `${environment.apiUrl}/endpoint`
  API_URLs = `${environment.apiUrl}/rendezvous/deleterdv`
  API_URLss = `${environment.apiUrl}/rendezvous/modifrendezvous`
  API_URLsss = `${environment.apiUrl}/rendezvous/getactByID`

  API_URLe = `${environment.apiUrl}/rendezvous/addrendezvous`

  constructor(http: HttpClient) {
    this.http = http
  }

  // CREATE
  // server should return the object with ID
  create(item: any): Observable<any> {
    if (item.osm == undefined) {
      item.restaurant_name = 'A definir le restaurant'
    }
    let sessionid = this.getAuthFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid)
    this._isLoading$.next(true)
    this._errorMessage.next('')
    return this.http.post<any>(this.API_URLe, item, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('CREATE ITEM', err)
        // window.location.reload();
        return err
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  // READ (Returning filtered list of entities)
  find(tableRendezVousState: ITableState): Observable<TableResponseModel<T>> {
    const url = this.API_URL + '/find'
    this._errorMessage.next('')
    return this.http.post<TableResponseModel<T>>(url, tableRendezVousState).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('FIND ITEMS', err)
        window.location.reload()
        return of({ items: [], total: 0 })
      })
    )
  }

  getItemById(act_uuid: any): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    const url = `${this.API_URLsss}?act_uuid=${act_uuid}`

    return this.http.get<any>(url, { params }).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        console.error('GET ITEM BY IT', act_uuid, err)
        window.location.reload()
        return of({ ids: undefined })
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  // UPDATE
  update(item: any): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()
    if (item.id) {
      const url = `${this.API_URLss}?act_uuid=${item.id}&&sessionid=${sessionid}`

      let restaurant_name = item.restaurant_name || item.name_restaurant
      let act_start_dt = item.act_start_dt
      let description = item.description
      let type_id = item.type_id
      let osm_id = item.osm_id || null
      let body = { restaurant_name, osm_id, act_start_dt, description, type_id }
      //   const params = new HttpParams()
      // .set('nom_service', nom_service)

      // .set('sessionid', sessionid);

      this._isLoading$.next(true)
      this._errorMessage.next('')
      return this.http.put(url, body).pipe(
        catchError(err => {
          // window.location.reload();
          this._errorMessage.next(err)
          console.error('UPDATE ITEM', item, err)
          throw err
        }),
        finalize(() => this._isLoading$.next(false))
      )
    } else {
      const url = `${this.API_URLss}?act_uuid=${item[0].act_uuid}&&sessionid=${sessionid}`

      let restaurant_name = item.restaurant_name
      let act_start_dt = item.act_start_dt
      let description = item.description
      let type_id = item.type_id

      let body = { restaurant_name, act_start_dt, description, type_id }
      //   const params = new HttpParams()
      // .set('nom_service', nom_service)

      // .set('sessionid', sessionid);

      this._isLoading$.next(true)
      this._errorMessage.next('')
      return this.http.put(url, body).pipe(
        catchError(err => {
          // window.location.reload();
          this._errorMessage.next(err)
          console.error('UPDATE ITEM', item, err)
          throw err
        }),
        finalize(() => this._isLoading$.next(false))
      )
    }
  }
  updateOsm(item: any, resto: any, room): Observable<any> {
    let sessionid = this.getAuthFromLocalStorage()
    let id = room

    const url = `${this.API_URLss}?act_uuid=${id}&&sessionid=${sessionid}`
    let restaurant_name = resto || null
    let osm_id = item || null
    let body = { osm_id, restaurant_name }
    //   const params = new HttpParams()
    // .set('nom_service', nom_service)

    // .set('sessionid', sessionid);

    this._isLoading$.next(true)
    this._errorMessage.next('')
    return this.http.put(url, body).pipe(
      catchError(err => {
        // window.location.reload();
        this._errorMessage.next(err)
        console.error('UPDATE ITEM', item, err)
        throw err
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }
  // UPDATE Status
  updateStatusForItems(ids: number[], status: number): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    const body = { ids, status }
    const url = this.API_URL + '/updateStatus'
    return this.http.put(url, body).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        //  console.error('UPDATE STATUS FOR SELECTED ITEMS', ids, status, err);
        window.location.reload()
        return of([])
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  // DELETE
  delete(act_uuid: any): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)

    const url = `${this.API_URLs}?act_uuid=${act_uuid}&&sessionid=${sessionid}`

    return this.http.delete(url).pipe(
      catchError(err => {
        //  window.location.reload()
        if (err.status == 200) {
          this._errorMessage.next(err)
          // console.error('DELETE ITEM', act_uuid, err);
          return of({})
        } else {
          window.location.reload()
        }
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  // delete list of items
  deleteItems(ids: number[] = []): Observable<any> {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    const url = this.API_URL + '/deleteItems'
    const body = { ids }
    return this.http.put(url, body).pipe(
      catchError(err => {
        this._errorMessage.next(err)
        // console.error('DELETE SELECTED ITEMS', ids, err);
        return of([])
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  public fetch() {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    const request = this.find(this._tableState$.value)
      .pipe(
        tap((res: TableResponseModel<T>) => {
          this._items$.next(res.items)
          this.patchStateWithoutFetch({
            paginator: this._tableState$.value.paginator.recalculatePaginator(res.total),
          })
        }),
        catchError(err => {
          window.location.reload()
          this._errorMessage.next(err)
          return of({
            items: [],
            total: 0,
          })
        }),
        finalize(() => {
          this._isLoading$.next(false)
          const itemIds = this._items$.value.map((el: T) => {
            const item = el as unknown as BaseModel
            return item.id
          })
          this.patchStateWithoutFetch({
            grouping: this._tableState$.value.grouping.clearRows(itemIds),
          })
        })
      )
      .subscribe()
    this._subscriptions.push(request)
  }

  public setDefaults() {
    this.patchStateWithoutFetch({ filter: {} })
    this.patchStateWithoutFetch({ sorting: new SortState() })
    this.patchStateWithoutFetch({ grouping: new GroupingState() })
    this.patchStateWithoutFetch({ searchTerm: '' })
    this.patchStateWithoutFetch({
      paginator: new PaginatorState(),
    })
    this._isFirstLoading$.next(true)
    this._isLoading$.next(true)
    this._tableState$.next(DEFAULT_STATE)
    this._errorMessage.next('')
  }

  // Base Methods
  public patchState(patch: Partial<ITableState>) {
    this.patchStateWithoutFetch(patch)
    this.fetch()
  }

  public patchStateWithoutFetch(patch: Partial<ITableState>) {
    const newState = Object.assign(this._tableState$.value, patch)
    this._tableState$.next(newState)
  }
  protected setAuthFromLocalStorage(auth: any): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (1 === 1) {
      //auth sessionid ta3 user

      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth))

      return true
    }
    return false
  }

  protected getAuthFromLocalStorage(): any {
    try {
      const authData = JSON.parse(localStorage.getItem(this.authLocalStorageToken))
      return authData
    } catch (error) {
      //   console.error(error);
      return undefined
    }
  }
}
