import { Point } from '@angular/cdk/drag-drop'
import { Desk } from './types'

/**
 * Function used to check if an object overlaps with a desk
 */

export function doesOverlapWithDesk(position: Point, width: number, height: number, desks: Desk[]) {
  for (const desk of desks) {
    const left = Math.max(position.x, desk.position.x)
    const top = Math.max(position.y, desk.position.y)
    const right = Math.min(position.x + width, desk.position.x + desk.width * 2)
    const bottom = Math.min(position.y + height, desk.position.y + desk.height * 2)

    if (left < right && top < bottom) {
      return { overlap: true, desk }
    }
  }
  return { overlap: false }
}

/**
 * Function used to check if an object
 */

/**
 * Function used to prevent the user from leaving the page without saving the changes
 */
export function promptBeforeUnload(isUntouched = false) {
  return e => {
    if (isUntouched) return e
    e = e || window.event
    // For IE and Firefox prior to version 4
    if (e) {
      e.returnValue = 'Vous avez des modifications non enregistrées. Voulez-vous vraiment quitter?'
    }
    // For Safari
    return 'Vous avez des modifications non enregistrées. Voulez-vous vraiment quitter?'
  }
}

/**
 * Function used to toggle minimize the metronic side bar menu
 */
export function toggleSideBarMenu({ open } = { open: false }) {
  const body = document.getElementsByTagName('body')[0]
  if (open) {
    body.classList.remove('aside-minimize')
  } else {
    body.classList.add('aside-minimize')
  }
}

/**
 * This function is used to prevent the user from leaving the bounds of the floor plan
 * when panning or zooming. Includes a safe zone of 100 units
 */
export function keepFloorTilesInBounds(x, y, scale, width, height, panZoomController) {
  const safeZone = 200
  if (-width * scale + safeZone > x) {
    return panZoomController.moveTo(-width * scale + safeZone, y)
  } // stops from panning left
  if (x > safeZone) {
    return panZoomController.moveTo(safeZone, y)
  } // stops from panning right

  if (-height * scale + safeZone > y) {
    return panZoomController.moveTo(x, -height * scale + safeZone)
  } // stops from panning top
  if (y > safeZone) {
    return panZoomController.moveTo(x, safeZone)
  } // stops from panning bottom
}
