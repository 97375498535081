import panzoom from 'panzoom'

export function pzBeforeMouseDown(isReservationMode: boolean) {
  return e => {
    // allow panning only if the middle mouse button is pressed or altkey/ctrlkey is pressed while dragging
    const validKeys = e.button === 1 || e.altKey || e.ctrlKey
    return isReservationMode ? undefined : !validKeys
  }
}

export function pzInit(floorPlan, initialZoom: number, isReservationMode: boolean) {
  return panzoom(floorPlan, {
    minZoom: 0.1,
    maxZoom: 1,
    initialZoom,
    zoomSpeed: 0.07,
    beforeMouseDown: pzBeforeMouseDown(isReservationMode),
    smoothScroll: true,
  })
}

export function preventObjectAnimationOnLoad(selectors: string) {
  return // temporary disabled transition of desks and seats
  const elements = document.querySelectorAll(selectors)
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i] as HTMLDivElement
    element.style.transition = 'transform 300ms cubic-bezier(0, 0, 0.2, 1)'
  }
}

export function canExit(isUntouched = false): Promise<boolean> {
  return new Promise((resolve, reject) => {
    if (isUntouched) return resolve(true)
    const confirmation = window.confirm('Vous avez des modifications non enregistrées. Voulez-vous vraiment quitter?')
    confirmation && resolve(true)
    resolve(false)
  })
}
