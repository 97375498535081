<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">{{ "ADMINPARKING.ModalAnnulation.title" | translate }}</h4>
  </div>
  <div class="modal-body">
    <div class="row" style="margin-top: 10px">
      <div class="col-lg-4" style="margin-top: 10px">
        <label for="titre">{{ "ADMINPARKING.ModalAnnulation.input1" | translate }}</label>
      </div>
      <div class="col-lg-8">
        <textarea class="form-control" type="text" id="reason"></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-primary" (click)="closeModal()"><i class="fas fa-times"></i>{{ "IDEA.add.button1" | translate }}</button>
    <button class="btn btn-primary" (click)="deleteReservation()">
      <span>{{ "ADMINPARKING.ModalAnnulation.button1" | translate }}</span>
    </button>
  </div>
</div>
