import { Point } from '@angular/cdk/drag-drop'
import { getUserUuidFromLocalStorage } from 'src/app/utils/getSessionFromLocalStorage'
import { v4 } from 'uuid'

export type GraphicalObjectTypes = 'desk' | 'wall' | 'seat' | 'decoration' | 'furniture'

export class GraphicalObject {
  id: string = v4()
  name: string
  width: number
  height: number
  z: number
  class: string
  image: string
  type?: GraphicalObjectTypes
  position: Point
  rotation: number = 0
  assetNumber?: number // A number that specifies image file in the assets folder (e.g. 1.png)
  prevposition?: Point
}

export class MovableObject extends GraphicalObject {
  constructor({ type, position, width, height, assetNumber }) {
    super()
    this.type = type
    this.position = position
    this.width = width
    this.height = height
    this.assetNumber = assetNumber
  }
}

export class Desk extends GraphicalObject {
  width = 250
  height = 250
  type: GraphicalObjectTypes = 'desk'
  service?: number | string = '*'
  equipe?: number | string = '*'
  accesstype?: number = 0
  seats: Seat[] = []
  maxSeats?: number = 10
}

export class Wall extends GraphicalObject {
  type: 'wall'
  orientation: 'horizontal' | 'vertical'
}

class SeatReservation {
  id: string
  userId: string
  date: string
  floorId: string
  buildingId: string
  avatar?: string
  fname?: string
  lname?: string
  startTime?: string
  endTime?: string
}

export class Seat extends GraphicalObject {
  deskId: string
  service?: number | string
  equipe?: number | string
  accessType?: number | string
  private reservations: SeatReservation[] = []
  // prettier-ignore
  constructor({ width, position, id, reservations, deskId }: { position: Point; id?: string; reservations?: SeatReservation[]; deskId: string; width?: number }) {
    super()
    this.id = id || this.id
    this.reservations = reservations || this.reservations
    this.type = 'seat'
    this.position = position
    this.deskId = deskId
    this.width = width || 50
    this.height = width || 50
  }

  getUserAvatar(date: string) {
    const reservation = this.reservations.find(r => r.date === date)
    if (reservation) {
      return reservation.avatar
    }
    return ''
  }

  getUserFullName(date: string) {
    const reservation = this.reservations.find(r => r.date === date)
    if (reservation) {
      return reservation.fname + ' ' + reservation.lname
    }
    return ''
  }

  getUserId(date: string) {
    const reservation = this.reservations.find(r => r.date === date)
    if (reservation) {
      return reservation.userId
    }
    return ''
  }

  isReservedByUser(date: string, userId: string) {
    return this.reservations.filter(r => r.userId === userId && r.date === date).length > 0
  }

  isReservedOnDate(date: string, startTime?: string, endTime?: string) {
    const start = startTime || '00:00'
    const end = endTime || '23:59'
    return this.reservations.filter(r => r.date === date).length > 0
  }

  // prettier-ignore
  reserve(id: string, userId: string, date: string, floorId: string, buildingId: string, avatar: string, startTime?: string, endTime?: string) {
    if (this.isReservedOnDate(date, startTime, endTime)) return false
    this.reservations.push({
      id,
      userId,
      date,
      floorId,
      buildingId,
      avatar,
      startTime,
      endTime,
    })
    return true
  }

  unreserve(userId: string) {
    this.reservations = this.reservations.filter(r => r.userId !== userId)
  }

  isReservedByOnePerson(date: string) {
    return this.reservations.filter(r => r.date === date).length === 1
  }

  isReservedByOtherPerson(date: string) {
    return this.reservations.filter(r => r.date === date && r.userId !== getUserUuidFromLocalStorage()).length > 0
  }

  isReservedByManyPeople(date: string) {
    return this.reservations.filter(r => r.date === date).length > 1
  }
}

export class Building {
  id: string = v4()
  name: string
  address: string = ''
  floors: Floor[] = []
  site_id: number
  constructor(name?: string, address?: string, site_id?: number) {
    if (name) {
      this.name = name
    }
    if (address) {
      this.address = address
    }
    if (site_id) {
      this.site_id = site_id
    }
  }
}

export class Floor {
  id: string = v4()
  name: string
  width: number = 2500
  height: number = 2500
  panzoom: {
    x: number
    y: number
    scale: number
  } = {
    x: 0,
    y: 0,
    scale: 1,
  }
  desks: Desk[] = []
  seats: Seat[] = []
  walls: Wall[] = []
  movables: MovableObject[] = []
  tile: number = 1
  site: number | null = null

  constructor(name?: string, width?: number, height?: number) {
    if (name) this.name = name
    if (width) this.width = width
    if (height) this.height = height
  }
}
