<div>
  <div class="modal-header modal-title h4">{{ data?.title | translate }}</div>

  <div class="modal-body">
    <ng-container>
      <span>{{ data?.message | translate }}</span>
    </ng-container>
  </div>

  <div class="modal-footer">
    <div style="display: flex">
      <button type="button" class="btn btn-{{ data?.cancelColor || 'danger' }} btn-elevate mr-1" (click)="modal.dismiss()">
        {{ data?.cancelText || "GENERAL.BTN_CANCEL" | translate }}</button
      >&nbsp;
      <button type="button" class="btn btn-{{ data?.confirmColor || 'primary' }} btn-elevate" (click)="modal.close()">
        {{ data?.confirmText || "GENERAL.BTN_CONFIRM" | translate }}
      </button>
    </div>
  </div>
</div>
