import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs'
import {
  GroupingState,
  IFilterView,
  IGroupingView,
  ISearchView,
  ISortView,
  PaginatorState,
  SortState,
} from 'src/app/_metronic/shared/crud-table'
import { UserService } from '../collaborateurs/_services'
import { ClosedModalComponent } from '../communities/gerer/closed-modal/closed-modal.component'
import { AdminParkingService } from './admin-parking.service'
import { ModalCalendarComponent } from './modal-calendar/modal-calendar.component'
import { ModalEditParkingComponent } from './modal-edit-parking/modal-edit-parking.component'
import { ModalEmplacementComponent } from './modal-emplacement/modal-emplacement.component'
import { ModalReservationComponent } from './modal-reservation/modal-reservation.component'

@Component({
  selector: 'app-admin-parking',
  templateUrl: './admin-parking.component.html',
  styleUrls: ['./admin-parking.component.scss'],
})
export class AdminParkingComponent implements OnInit, ISortView, IFilterView, IGroupingView, ISearchView {
  searchGroup: UntypedFormGroup
  filterGroup: UntypedFormGroup
  grouping: GroupingState
  sorting: SortState
  paginator: PaginatorState
  private subscriptions: Subscription[] = []
  isLoading: boolean
  typeFilter: any = 0
  listSites: any[] = []
  siteId: any = 0

  tableOption: any = []
  parckingAbonnement = false
  constructor(
    public adminParkingService: AdminParkingService,
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    public userService: UserService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.adminParkingService.GetSitesList().subscribe(res => {
      this.listSites = res
      //this.listCibles[0] = 5
      //this.listCibleIds[0] = this.listSites[0].ids
    })
    this.filterForm()
    this.searchForm()
    this.userService.getEntrepriseAbonnement().subscribe(data => {
      for (let i = 0; i < data.length; i++) {
        this.tableOption.push(data[i].code_option)
        this.cdr.detectChanges()
      }

      if (this.tableOption.includes('MPAR')) {
        this.parckingAbonnement = true
        this.adminParkingService.fetch()
        this.cdr.detectChanges()
      } else {
        this.closedModal()
      }

      this.cdr.detectChanges()
    })

    this.grouping = this.adminParkingService.grouping
    this.paginator = this.adminParkingService.paginator
    this.sorting = this.adminParkingService.sorting
    const sb = this.adminParkingService.isLoading$.subscribe(res => (this.isLoading = res))
    this.subscriptions.push(sb)
  }

  closedModal() {
    this.modalService.open(ClosedModalComponent)
  }
  paginate(paginator: PaginatorState) {
    this.adminParkingService.patchState({ paginator })
  }
  searchForm(): void {
    this.searchGroup = this.fb.group({
      searchTerm: [''],
    })
    const searchEvent = this.searchGroup.controls.searchTerm.valueChanges
      .pipe(
        /*
      The community can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator,
      we are limiting the amount of server requests emitted to a maximum of one every 150ms
      */
        debounceTime(150),
        distinctUntilChanged()
      )
      .subscribe(val => this.search(val.toLowerCase()))
    this.subscriptions.push(searchEvent)
  }
  search(searchTerm: string): void {
    this.adminParkingService.patchState({ searchTerm })
  }
  filterForm(): void {
    this.filterGroup = this.fb.group({
      status: [''],
      type: [''],
      searchTerm: [''],
    })
    this.subscriptions.push(this.filterGroup.controls.status.valueChanges.subscribe(() => this.filter()))
    this.subscriptions.push(this.filterGroup.controls.type.valueChanges.subscribe(() => this.filter()))
  }
  filter(): void {
    const filter = {}
    const status = this.filterGroup.get('code').value
    if (status) {
      filter['code'] = status
    }

    this.adminParkingService.patchState({ filter })
  }
  sort(column: string): void {
    const sorting = this.sorting
    const isActiveColumn = sorting.column === column
    if (!isActiveColumn) {
      sorting.column = column
      sorting.direction = 'asc'
    } else {
      sorting.direction = sorting.direction === 'asc' ? 'desc' : 'asc'
    }
    this.adminParkingService.patchState({ sorting })
  }

  openModalCreate() {
    const modalRef = this.modalService.open(ModalEmplacementComponent)

    modalRef.result.then(
      () => this.adminParkingService.fetch(),
      () => {}
    )
  }

  openModalReserve() {
    const modalRef = this.modalService.open(ModalReservationComponent)

    modalRef.result.then(
      () => this.adminParkingService.fetch(),
      () => {}
    )
  }

  openModalCalendar(parkId: any) {
    const modalRef = this.modalService.open(ModalCalendarComponent, {
      size: 'xl',
    })

    modalRef.componentInstance.parkId = parkId

    modalRef.result
      .then
      //() => this.eventService.fetch(),
      //() => {}
      ()
  }

  deleteParking(parkId: any) {
    this.adminParkingService.deleteParking(parkId).subscribe(res => this.adminParkingService.fetch())
  }

  openModalEdit(parking: any) {
    const modalRef = this.modalService.open(ModalEditParkingComponent)
    modalRef.componentInstance.parking = parking

    modalRef.result.then(
      () => this.adminParkingService.fetch(),
      () => {}
    )
  }
}
