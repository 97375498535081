export default {
  ADD_BUILDING: 'Ajouter un bâtiment',
  ADD_SITE: 'Ajouter un site',
  ADD_FLOOR: 'Ajouter un étage',
  CHOOSE_FLOOR: 'Choisir un étage',
  CHOOSE_SITE: 'Choisir un site',
  CHOOSE_BUILDING: 'Choisir un bâtiment',
  BUILDING_NAME: 'Nom du bâtiment',
  RESV_RULES: {
    LIST: 'Règles de réservation',
    ADD: 'Créer une nouvelle règle',
    NAME: 'Nom de la règle',
    VALUE: 'Valeur appliquée',
    TARGET: 'Cible de la règle',
    MAX_RESV_PER_DAY: 'Maximum de personnes qui peuvent réserver par jour',
    MIN_RESV_PER_DAY: 'Minimum de personnes qui doivent être présentes par jour',
    MAX_RESV_PER_WEEK: "Maximum de jours qu'une personne peut réserver par semaine",
    MIN_RESV_PER_WEEK: "Minimum de jours qu'une personne doit réserver par semaine",
    EXCLUDE_ABSENCE: 'Exclure les absences (congés, maladie, etc.)',
    TARGET_LABELS: ['', 'par utilisateur ', 'par équipe ', 'par service ', 'par site ', 'pour tout le monde'],
    TARGETS: ['', 'Utilisateur', 'Équipe', 'Service', 'Site', 'Tout le monde'],
    APPLIED: 'Appliquée',
    CHOOSE: 'Choisissez une règle à configurer :',
    CONFIG: 'Appliquer la règle pour : ',
    NBR_PPL: 'Nombre de personnes : ',
    NBR_DAYS: 'Nombre de jours : ',
    CHOICE: 'Choix de la règle',
    CONFIGURE: 'Configurer la règle',
  },
  GRAPHICAL_ELEMENTS: 'Éléments Graphiques',
  RESERVE_SLOT: '📅 Réserver un créneau',
  FLOOR_COVERING: 'Revêtement du sol',
  OFFICES: 'Bureaux',
  WALLS: 'Murs',
  FURNITURE: 'Mobillier',
  ACCESSIBILITY_AND_DECORATION: 'Accéssibilité et décoration',
  SETTINGS: 'Paramètres',
  HORI: 'Horizontal',
  VERT: 'Vertical',
  CHOOSE_THEME: 'Choisissez un thème',
  ACCESSED_BY: 'Accès par',
  MEETING_ROOM: 'Salle de réunion',
  RESERV_BY_ADMIN: 'Réservable que par un admin',
  OBJECT_CODE: "Code de l'objet",
  ADD_VISITOR: 'Réserver un siège pour un visiteur',
  FNAME: 'Prénom',
  LNAME: 'Nom',
  EMAIL: 'Email',
  PHONE: 'Téléphone',
  PLEASE_ENTER_FIRST_NAME: 'Veuillez entrer un prénom',
  PLEASE_ENTER_LAST_NAME: 'Veuillez entrer un nom',
  MANAGE_RESERVATIONS_ADMIN: {
    ERRORS: {
      DATE_START: 'Veuillez choisir une date de début',
      DATE_END: 'Veuillez choisir une date de fin',
      INVALID_DATES: 'Veuillez choisir une date de fin supérieure à la date de début',
      DURATION_TOO_LONG: 'La période de réservation ne peut pas dépasser 45 jours',
      APPLY_JOB: "Une erreur est survenue lors de l'application du job",
      OVERLAP: 'Il existe déjà une exécution en cours qui chevauche la période choisie',
    },
    AUTO_FLEXI_JOB_CREATED: 'La génération de réservation automatique a été lancée avec succès',
    AUTO_FLEXI_JOB_APPLIED: 'Les réservations générées automatiquement ont été appliquées avec succès',
  },
  MAX_SEATS: 'Nombre de sièges maximum',
}
