import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core'
import { quizService } from '../_services/quiz.service'
import { DatePipe } from '@angular/common'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-associer-strategie',
  templateUrl: './associer-strategie.component.html',
  styleUrls: ['./associer-strategie.component.scss'],
})
export class AssocierStrategieComponent implements OnInit {
  disStrategies: any
  id: any
  selectedStrat: any
  date_fin: any
  date_debut: any
  formGroup: UntypedFormGroup
  isDisabled = true

  @Input() public QuizData
  constructor(
    public modal: NgbActiveModal,
    private listejeuxService: quizService,
    private datePipe: DatePipe,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    public cdr: ChangeDetectorRef
  ) {
    this.cdr = cdr
    this.formGroup = this.fb.group({
      dateDebut: [
        this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm'),
        Validators.compose([Validators.nullValidator, Validators.required]),
      ],
      dateFin: [
        this.datePipe.transform('', 'yyyy-MM-ddTHH:mm'),
        Validators.compose([Validators.nullValidator, Validators.required]),
      ],
      selectedStrat: [this.selectedStrat, Validators.compose([Validators.required])],
    })
  }

  ngOnInit() {
    // le service pour obtenir la liste des stratègies non associé au jeux sélectionné
    let game_id = this.QuizData.game_id
    this.listejeuxService.getdisStrategies(game_id).subscribe(data => {
      this.disStrategies = data
      this.cdr.detectChanges()
    })
  }
  // obtenir l'id de stratègie et l'affecté à la variable selectedStrat
  selectOption(game_id) {}

  onSubmit() {
    const formData = this.formGroup.value
    let strat_id = this.selectedStrat
    let game_id = this.QuizData.game_id
    let entreprise_id = this.QuizData.entreprise_id
    let date_debut = formData.dateDebut
    let date_fin = formData.dateFin
    let selectedStrat = formData.selectedStrat
    /* 
    check if strategy was already associated before with this game 
    if true update 
    else insert 
    */
    this.listejeuxService.getAssocHistory(strat_id, game_id).then(data => {
      let isassoc = data[0].isassoc
      // si le jeux a été déja associé avant avec la stratègie sélectionné alors faire le
      // update de Strategy_associated column : 1
      if (isassoc == 1) {
        this.listejeuxService.updateAssocStrategy(strat_id, game_id, date_debut, date_fin)
        this.toastr.success('le jeu a été réassocié à la stratégie avec succès')
        this.modal.close()
      }
      // si le jeux n'a jamais été associé avant alors on va insérer un nouveau ligne dans
      // le tableau assoc_strat_to_games
      else {
        this.listejeuxService.insertAssocStrategy(
          strat_id,
          game_id,
          entreprise_id,
          date_debut,
          date_fin
        )
        this.modal.close()
        this.toastr.success('le jeu a été associé à la stratégie avec succès')
      }
    })
  }

  isControlValid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName]
    return control.valid && (control.dirty || control.touched)
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName]
    return control.invalid && (control.dirty || control.touched)
  }

  controlHasError(validation, controlName): boolean {
    const control = this.formGroup.controls[controlName]
    return control.hasError(validation) && (control.dirty || control.touched)
  }

  isControlTouched(controlName): boolean {
    const control = this.formGroup.controls[controlName]
    return control.dirty || control.touched
  }

  isDateInvalid(controlName1, controlName2): boolean {
    const dated = this.formGroup.controls[controlName1]
    const datef = this.formGroup.controls[controlName2]
    if (datef.value >= dated.value) {
      return datef.invalid
    } else {
      this.formGroup.setErrors({ invalid: true })
      return datef.valid
    }
  }

  close() {
    this.modal.close()
  }
}
