<div class="modal-ouvert" id="blockOutsideClick">
  <div class="modal-body">
    Ce module n'est pas inclus dans votre abonnement.
    <ng-container>
      <img style="width: 410px; margin-top: 0%" src="./assets/closed.svg" />
    </ng-container>
  </div>
  <!--
  <div class="modal-footer">
    <button type="button" class="btn btn-light btn-elevate mr-2" (click)="modal.dismiss()">{{ "GENERAL.BTN_CANCEL" | translate }}</button>
    <ng-container *ngIf="community && formGroup">
      <button type="submit" class="btn btn-primary btn-elevate" (click)="save()" [disabled]="formGroup.invalid">
        {{ "GENERAL.BTN_SAVE" | translate }}
      </button>
    </ng-container>
  </div>
-->
  <div class="modal-footer">
    <div>
      <button type="button" class="btn btn-light btn-elevate mr-2" (click)="sortir()">Sortir</button>
      <button type="button" class="btn btn-primary btn-elevate" (click)="ok()">Demander à être rappelé</button>
    </div>
  </div>
</div>
