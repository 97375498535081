import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Inject, Injectable, OnDestroy } from '@angular/core'
import getBrowserFingerprint from 'get-browser-fingerprint'
import { BehaviorSubject, Observable, forkJoin } from 'rxjs'
import { map } from 'rxjs/operators'
import { TableSurveys } from 'src/app/_metronic/shared/crud-table/services/table.surveys.service'
import { getSessionFromLocalStorage, getUserUuidFromLocalStorage } from 'src/app/utils/getSessionFromLocalStorage'
import { environment } from '../../../../environments/environment'
import { ITableState, TableResponseModel } from '../../../_metronic/shared/crud-table'
import { baseFilter } from '../../../common/helpers/http-extenstions'
import { Survey } from './survey.model'

@Injectable({
  providedIn: 'root',
})
export class SurveyService extends TableSurveys<Survey> implements OnDestroy {
  currentSubject: BehaviorSubject<Survey>
  isLoadingSubject: BehaviorSubject<boolean>

  get currentValue(): Survey {
    return this.currentSubject.value
  }

  set currentValue(survey: Survey) {
    this.currentSubject.next(survey)
  }

  // protected authLocalStorageToken = ${environment.appVersion}-${environment.USERDATA_KEY};
  API_URL = `${environment.apiUrlQSP}/survey/list`
  constructor(@Inject(HttpClient) http) {
    super(http)
    this.isLoadingSubject = new BehaviorSubject<boolean>(false)
    this.currentSubject = new BehaviorSubject<Survey>(undefined)
  }

  find(tableState: ITableState): Observable<TableResponseModel<Survey>> {
    const params = new HttpParams().set('userId', getUserUuidFromLocalStorage())
    const headers = new HttpHeaders({
      token: localStorage.getItem('QSP_AUTH_TOKEN'),
    })
    return this.http
      .get<any>(`${this.API_URL}`, {
        headers,
        params,
      })
      .pipe(
        map((response: Survey[]) => {
          const filteredResult = baseFilter(response, tableState)
          const result: TableResponseModel<Survey> = {
            items: filteredResult.items,
            total: filteredResult.total,
          }
          return result
        })
      )
  }

  deleteItems(ids: number[] = []): Observable<any> {
    const tasks$ = []
    ids.forEach(id => {
      tasks$.push(this.delete(id))
    })
    return forkJoin(tasks$)
  }

  fileUpload(file: FormData) {
    const sessionid = this.getAuthFromLocalStorage()

    const params = new HttpParams().set('sessionid', sessionid)
    let urlphoto = `${environment.apiUrl}/todo/api/upload`
    return this.http.post(urlphoto, file, { params })
  }

  submitResults(results: any, targetId: string, surveyId: string, isAnonymous: boolean) {
    const sessionid = getSessionFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.post(
      environment.apiUrl + '/survey/results',
      {
        results: results.questions,
        fingerprint: getBrowserFingerprint(),
        targetId: targetId,
        surveyId: surveyId,
      },
      isAnonymous
        ? {}
        : {
            params,
          }
    )
  }

  deleteSurveyDataById(id: string) {
    const sessionid = getSessionFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.delete(environment.apiUrl + '/survey/' + id, {
      params,
    })
  }
  //post choices of a question to generate a report for it
  generateQuestionReport(results: any) {
    const sessionid = getSessionFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid)
    //send the choices in an object
    return this.http.post(environment.apiUrl + '/survey/report', results, { params })
  }
  //send surveyId in body to generate a report for it
  generateSurveyReport(surveyDetails: any) {
    const sessionid = getSessionFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid)

    return this.http.post(environment.apiUrl + '/survey/report/survey', surveyDetails, { params })
  }
  // get the report of a question
  getQuestionReport(questionId: any) {
    const sessionid = getSessionFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get(environment.apiUrl + '/survey/report/' + questionId, { params })
  }
  getSurveyReport(targetId: any) {
    const sessionid = getSessionFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get(environment.apiUrl + '/survey/report/survey/' + targetId, { params })
  }
  //save the question report
  saveQuestionReport(questionDetails: any) {
    const sessionid = getSessionFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.put(environment.apiUrl + '/survey/report/save', questionDetails, { params })
  }
  saveSurveyReport(surveyDetails: any) {
    const sessionid = getSessionFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.put(environment.apiUrl + '/survey/report/survey/save', surveyDetails, { params })
  }
  //get report status after sending target_id in params
  getReportStatus(targetId: any) {
    const sessionid = getSessionFromLocalStorage()
    const params = new HttpParams().set('sessionid', sessionid)
    return this.http.get(environment.apiUrl + '/survey/report/status/' + targetId, { params })
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe())
  }
}
