export default {
  ALERT: {
    MyAlerts: 'My Alerts',
    TYPE: 'Type',
    NOMEN: 'Name of entity',
  },
  COMM: {
    TYPE: 'Type',
    DESC: 'description',
  },
  NOTE: {
    NOTE: 'NOTE',
    COMMENTAIRE: 'COMMENT',
  },
  STRATEGIE: {
    NOM: 'STRATEGY NAME',
    NOMPARAMETRE: 'SET NAME',
    EVENT: 'EVENT',
    TXT: 'Transversal teams to which the user belongs: ',
  },
  CARD: {
    BTNACTIF: 'Active',
    BTNDESACTIF: 'Disabled',
    BTNALUMNI: 'Alumni',
    ACCOUNTSTATUS: 'Account status',
    VALID: 'Active',
    ENATT: 'Awaiting registration',
    TAUXCOMP: 'Profile completion rate',
    RDVTERMINE: 'Number of appointments completed',
    Email: 'Email',
    Fonction: 'Works',
    LISTCONGE: 'List of holidays',
    LISTPLANNING: 'Travel schedule list',
    Presentation: 'Presentation',
    HISTORIQUERDV: 'Appointment history',
    LISTCOMMENT: 'List of notes and comments',
    STRATEGIE: 'Strategies that target this collaborater',
    DISPO: 'Informed availabilities',
    Communities: 'communities',
    Alertes: 'Alerts',
    FORMATIONS: 'Trainings',
    COMPETENCES: 'Skills',
  },
}
