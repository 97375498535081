<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">{{ "ADMINPARKING.modalReserve.title" | translate }}</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="formGroup">
      <div class="row" style="margin-top: 10px; display: flex; justify-content: center">
        <mat-radio-group [(ngModel)]="choice" formControlName="reservedFor">
          <mat-radio-button *ngFor="let O of options" [value]="O">
            {{ O }}
          </mat-radio-button>
        </mat-radio-group>
        <div class="invalid-feedback" *ngIf="controlHasError('required', 'reservedFor')">
          {{ "EVENT.wizardTwo.frequency" | translate }}{{ "CONTROLEDESAISIE.required" | translate }}
        </div>
      </div>

      <div class="row" style="margin-top: 10px" *ngIf="choice == 'Collaborateur'">
        <div class="col-lg-4" style="margin-top: 10px">
          <label>{{ "ADMINPARKING.modalReserve.input1" | translate }}</label>
        </div>
        
        <div class="col-lg-8">
          <ng-multiselect-dropdown
            class="form-control form-control-lg form-control-solid"
            [placeholder]="''"
            [settings]="dropdownSettings"
            [data]="dropdownList"
            (onDeSelect)="onItemDeSelect($event)"
            (onSelect)="onItemSelect($event)"
            [(ngModel)]="selectedItems"
            formControlName="parkingFor"
            #dropdown
          >
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="row" style="margin-top: 10px" *ngIf="choice == 'Externe'">
        <div class="col-lg-4" style="margin-top: 10px">
          <label for="titre">{{ "ADMINPARKING.modalReserve.input1" | translate }}</label>
        </div>
        <div class="col-lg-8">
          <input
            id="code"
            class="form-control"
            type="text"
            formControlName="guest"
            [class.is-invalid]="isControlInvalid('guest')"
            [class.is-valid]="isControlValid('guest')"
          />

          <div class="invalid-feedback" *ngIf="controlHasError('required', 'guest')">
            {{ "ADMINPARKING.modalReserve.input1" | translate }}{{ "CONTROLEDESAISIE.required" | translate }}
          </div>
          <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'guest')">
            {{ "ADMINPARKING.modalReserve.input1" | translate }}{{ "EVENT.control.threeCharMin" | translate }}
          </div>
          <div class="invalid-feedback" *ngIf="controlHasError('maxlength', 'guest')">
            {{ "ADMINPARKING.modalReserve.input1" | translate }}{{ "CONTROLEDESAISIE.max" | translate }}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4" style="margin-top: 40px">
          <label>{{ "EVENT.wizardTwo.time" | translate }}</label>
        </div>
        <div class="col-lg-8">
          <ngb-timepicker [(ngModel)]="startTime" formControlName="time" style="width: 100%"></ngb-timepicker>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4" style="margin-top: 40px">
          <label>heure de fin</label>
        </div>
        <div class="col-lg-8">
          <ngb-timepicker [(ngModel)]="endTime" formControlName="endTime" style="width: 100%"></ngb-timepicker>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-primary" (click)="closeModal()"><i class="fas fa-times"></i>{{ "IDEA.add.button1" | translate }}</button>
    <button
      class="btn btn-primary"
      [disabled]="formGroup.controls['reservedFor'].invalid && (formGroup.controls['parkingFor'].invalid || formGroup.controls['guest'].invalid)"
      (click)="reserveParking()"
    >
      <span>{{ "ADMINPARKING.modalReserve.button1" | translate }}</span>
    </button>
  </div>
</div>
