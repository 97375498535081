<div class="modal-content">
  <div class="modal-header">
      <div class="modal-title h4" id="example-modal-sizes-title-lg">
          <!--pop-up header block-->
          <div>
              <span class="popUpTitle">{{"assocStrat.assoc" | translate }}</span>
          </div>
      </div>
  </div>
  


<!-- modification de date de début et de fin d'un stratégie -->
<form action="#" class="form form-label-right" (ngSubmit)="onSubmit()" [formGroup]="formGroup">

<!--sélection d'un stratégie-->
<div>  
  <select (change)="selectOption($event.target.value)" [(ngModel)]="selectedStrat" class="form-control form-control-lg form-control-solid" name="selectedStrat"
  [class.is-invalid]="isControlInvalid('selectedStrat')" formControlName="selectedStrat">
    <option *ngFor="let row of disStrategies" [value]="row.param_strat_uuid">
        {{row.nom}}
       </option> 
  </select>
</div>  

<br>



  <div >
      <label>{{"assocStrat.dateD" | translate }}</label>    
        <input type="datetime-local" name="dateDebut" class="form-control form-control-lg form-control-solid"  [class.is-invalid]="isControlInvalid('dateDebut')"
        [class.is-valid]="isControlValid('dateDebut')" formControlName="dateDebut"/>       
        <div class="valid-feedback" *ngIf="isControlValid('dateDebut')">{{ "MYACTVITY.EDIT.Date" | translate }}{{ "CONTROLEDESAISIE.VALID" | translate }}</div>
        <div class="invalid-feedback" *ngIf="controlHasError('required', 'dateDebut')">{{ "MYACTVITY.EDIT.Date" | translate }}{{ "CONTROLEDESAISIE.required" | translate }}</div>
      <label>{{"assocStrat.dateF" | translate }}</label>
    
        <input type="datetime-local" name="dateFin" class="form-control form-control-lg form-control-solid"  [class.is-invalid]="isControlInvalid('dateFin')"
        [class.is-valid]="isControlValid('dateFin')" formControlName="dateFin"/> 
        <div style="margin-left:5px;" class="valid-feedback" *ngIf="isControlValid('dateFin')">{{ "MYACTVITY.EDIT.Date" | translate }}{{ "CONTROLEDESAISIE.VALID" | translate }}</div>
        <div style="margin-left:5px;" class="invalid-feedback" *ngIf="controlHasError('required', 'dateFin')">{{ "MYACTVITY.EDIT.Date" | translate }}{{ "CONTROLEDESAISIE.required" | translate }}</div>
        <div style="margin-left:5px;" class="valid-feedback" *ngIf="isDateInvalid('dateDebut','dateFin')">{{ "CONTROLEDESAISIE.logicDate" | translate }}</div>                    
  </div>


<!-- annuler ou valider le process d'assocation d'un stratégie--> 
  <div class="modal-footer">
          <div class="card-toolbar">
          <a class="btn btn-light btn-elevate" (click)="close()">{{"assocStrat.annuler" | translate }}</a>
          </div>
           <div class="card-toolbar" style="float:right">
          <button class="btn btn-primary" [disabled]="formGroup.invalid" type="submit">{{"assocStrat.validate" | translate }}</button>
      </div>   
  </div>
</form>
</div> 

