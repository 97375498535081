import { DatePipe } from '@angular/common'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Component, Input, OnInit } from '@angular/core'
import { MatOptionSelectionChange } from '@angular/material/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { ToastrService } from 'ngx-toastr'
import { forkJoin } from 'rxjs'
import {
  getEntIdFromLocalStorage,
  getSessionFromLocalStorage,
  getUserUuidFromLocalStorage,
} from 'src/app/utils/getSessionFromLocalStorage'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-survey-activate-modal',
  templateUrl: './survey-activate-modal.component.html',
  styleUrls: ['./survey-activate-modal.component.scss'],
})
export class SurveyActivateModalComponent implements OnInit {
  constructor(
    public modal: NgbActiveModal,
    private datePipe: DatePipe,
    private http: HttpClient,
    private toastr: ToastrService
  ) {}

  isReady = false
  isValid = false

  surveys: any[] = []
  targets
  @Input() formtype?: 'survey' | 'quiz' | 'poll' = 'survey'
  targetTitle = ''
  dynamicText = {
    survey: {
      title: 'Cibler une enquête publiée',
      name: 'Enquête',
      invalidMsg: 'Merci de publier une enquête avant de pouvoir faire des ciblages.',
    },
    quiz: {
      title: 'Cibler un quiz publié',
      name: 'Quiz',
      invalidMsg: 'Merci de publier un quiz avant de pouvoir faire des ciblages.',
    },
    poll: {
      title: 'Cibler un sondage publié',
      name: 'Sondage',
      invalidMsg: 'Merci de publier un sondage avant de pouvoir faire des ciblages.',
    },
  }

  changeTargetType2(targetType2) {
    this.targetType2 = targetType2
    if (this.targetType === undefined) this.targetType = 0
    if (targetType2 === '5') {
      this.anonymity = 1
      this.targetType = 0
      this.dateEnd = '2100-01-01T00:00'
    } else {
      this.dateEnd = this.datePipe.transform(Date.now() + 86400000, 'yyyy-MM-ddTHH:mm')
    }
  }

  ngOnInit(): void {
    const params = new HttpParams().set('userId', getUserUuidFromLocalStorage())
    const httpHeaders = new HttpHeaders({
      token: localStorage.getItem('QSP_AUTH_TOKEN'),
    })

    const surveys$ = this.http.get<any>(environment.apiUrlQSP + '/survey/list?published=1&formtype=' + this.formtype, {
      params,
      headers: httpHeaders,
    })
    const targets$ = this.http.get<any>(environment.apiUrl + '/entreprise/sites/services/equipes', {
      params: new HttpParams().set('sessionid', getSessionFromLocalStorage()),
    })

    forkJoin<[any, any]>([surveys$, targets$]).subscribe(([surveys, targets]) => {
      this.surveys = surveys as any
      this.targets = targets
      this.isReady = true
    })
  }

  get targetName() {
    switch (this.targetType) {
      case '1':
        return 'sites'
      case '2':
        return 'services'
      case '3':
        return 'equipes'
      case '4':
        return 'eqtrans'
      default:
        break
    }
  }

  readonly targetTypes = [
    { value: 0, viewValue: 'Tous' },
    { value: 1, viewValue: 'Par site' },
    { value: 2, viewValue: 'Par service' },
    { value: 3, viewValue: 'Par équipe' },
    { value: 4, viewValue: 'Par équipe transverse' },
  ]
  dateNow = this.datePipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm')

  surveyId: string
  targetType?: number | string
  target?: number | string
  targetType2?: number | string
  dateStart: Date | string = this.dateNow
  dateEnd: Date | string = this.datePipe.transform(Date.now() + 86400000, 'yyyy-MM-ddTHH:mm')
  isAnonymous = true
  requireUserConnected = false
  anonymity = 1 // 1: not-anonymous, 2: anonymous with user connected, 3: anonymous without user connected
  errMsg = ''
  get minDateEnd() {
    return this.datePipe.transform(new Date(this.dateStart).getTime() + 86400000, 'yyyy-MM-ddTHH:mm')
  }

  setErrMsg(msg: string) {
    this.errMsg = msg
    setTimeout(() => {
      this.errMsg = ''
    }, 5000)
  }

  str = JSON.stringify

  dateForInput(date) {
    if (!date) {
      return ''
    }
    return this.datePipe.transform(new Date(date).getTime(), 'yyyy-MM-ddTHH:mm')
  }

  selectSurveyTarget(e: MatOptionSelectionChange) {
    this.targetType = e.source.value
  }

  activateSurvey() {
    const { targetType, targetType2, target, surveyId, dateStart, dateEnd, anonymity } = this
    const params = new HttpParams().set('sessionid', getSessionFromLocalStorage())
    if (this.targetTitle === '') {
      this.setErrMsg('Merci de donner un titre à votre ciblage')
      // this.toastr.error('Merci de donner un titre à votre ciblage')
    } else if (targetType === undefined && targetType2 === undefined) {
      this.setErrMsg('Merci de choisir une cible pour votre enquête')
      // this.toastr.error('Merci de choisir la cible de votre enquête')
    } else if (targetType != 0 && target === undefined) {
      this.setErrMsg('Merci de choisir une cible')
      // this.toastr.error('Merci de choisir la cible')
    } else if (surveyId === undefined) {
      this.setErrMsg('Merci de choisir une enquête')
      // this.toastr.error('Merci de choisir une enquête')
    } else if (dateStart === undefined) {
      this.setErrMsg('Merci de choisir la date de déclenchement')
      // this.toastr.error('Merci de choisir la date de déclenchement')
    } else if (dateEnd === undefined) {
      this.setErrMsg('Merci de choisir la date de clôture')
      // this.toastr.error('Merci de choisir la date de clôture')
    } else if (new Date(dateStart) >= new Date(dateEnd)) {
      this.setErrMsg('La date de clôture doit être supérieure à la date de déclenchement')
      // this.toastr.error('Merci de choisir la date de clôture')
    } else {
      this.http
        .post(
          environment.apiUrl + '/survey/' + surveyId + '/target',
          [
            this.targetTitle,
            surveyId,
            targetType,
            targetType2 || 0,
            target || getEntIdFromLocalStorage(),
            new Date(dateStart),
            new Date(dateEnd),
            anonymity === 1 ? 0 : 1, // isAnonymous
            anonymity === 3 ? 0 : 1, // requireUserConnected,
            this.formtype,
          ],
          { params }
        )
        .subscribe(
          () => {
            this.toastr.success('Opération réussie')
            this.modal.close()
          },
          () => {
            this.toastr.error('Un problème est survenu')
          }
        )
    }
  }
}
