<div class="card card-custom gutter-b">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">{{ "Ciblage" | translate }}</h3>
    </div>
    <div class="card-toolbar" *ngIf="isAdmin">
      <button type="button" class="btn btn-primary" (click)="activateSurveyTarget()" *ngIf="enqueteAbonnement">
        {{ "Activer un ciblage" | translate }}
      </button>
    </div>
  </div>
  <div class="card-body" style="min-height: 60vh" *ngIf="enqueteAbonnement">
    <ng-container *ngIf="grouping.getSelectedRowsCount()">
      <div class="form">
        <div class="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
          <div class="col-xl-12">
            <div class="form-group form-group-inline"></div>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- end: Grouping -->

    <!-- begin:Table -->
    <div class="table-responsive angular-bootstrap-table">
      <table class="table table-head-custom table-vertical-center overflow-hidden">
        <thead>
          <th>N°</th>
          <th *ngIf="this.formtype === 'survey'">
            {{ "Nom de l'enquête" | translate }}
            <app-sort-icon column="name" [activeColumn]="sorting.column" [activeDirection]="sorting.direction" (sort)="sort($event)"></app-sort-icon>
          </th>
          <th *ngIf="this.formtype === 'quiz'">
            Nom de Quiz
            <app-sort-icon column="name" [activeColumn]="sorting.column" [activeDirection]="sorting.direction" (sort)="sort($event)"></app-sort-icon>
          </th>
          <th *ngIf="this.formtype === 'poll'">
            Nom de sondage
            <app-sort-icon column="name" [activeColumn]="sorting.column" [activeDirection]="sorting.direction" (sort)="sort($event)"></app-sort-icon>
          </th>
          <th>
            {{ "Statistiques" | translate }}
            <app-sort-icon
              column="nb_membres"
              [activeColumn]="sorting.column"
              [activeDirection]="sorting.direction"
              (sort)="sort($event)"
            ></app-sort-icon>
          </th>

          <th>Cible</th>

          <th>
            Date de déclenchement
            <app-sort-icon column="date" [activeColumn]="sorting.column" [activeDirection]="sorting.direction" (sort)="sort($event)"></app-sort-icon>
          </th>
          <th>
            Date de clôture
            <app-sort-icon column="date" [activeColumn]="sorting.column" [activeDirection]="sorting.direction" (sort)="sort($event)"></app-sort-icon>
          </th>

          <th>Actions</th>
        </thead>
        <tbody>
          <tr
            *ngFor="let target of surveyService.items$ | async; index as index"
            [ngStyle]="{ 'pointer-events': target.published ? 'none' : 'unset' }"
          >
            <td>
              {{ index + 1 }}
            </td>
            <td style="max-width: 100px">
              {{ target?.survey?.title || "Sans Titre" }}
            </td>

            <td>
              <div class="d-flex flex-column">
                <span class="mb-3">{{ target.nbTargets || 0 }} Personnes ciblées </span>
                <span>{{ target.nbResults || 0 }} Réponses</span>
              </div>
            </td>
            <td>
              {{ targetsDesc[target.targetType][target.targetType2] }}
              {{ target.targetName ? '"' + target.targetName + '"' : "" }}
            </td>
            <td>
              <input
                [value]="dateForInput(target.dtStart)"
                [defaultValue]="dateForInput(target.dtStart)"
                type="datetime-local"
                class="form-control form-control-sm form-control-solid"
                style="max-width: 160px"
                disabled
              />
            </td>
            <td>
              <input
                [value]="dateForInput(target.dtEnd)"
                [defaultValue]="dateForInput(target.dtEnd)"
                type="datetime-local"
                class="form-control form-control-sm form-control-solid"
                style="max-width: 160px"
                disabled
              />
            </td>

            <td>
              <a
                style="pointer-events: all !important"
                *ngIf="target.anonymous && !target.require_user_connected"
                class="btn btn-success btn-sm mr-5"
                (click)="copyToClip(target.id, target.survey_id)"
              >
                Partager
              </a>
              <a
                style="pointer-events: all !important"
                *ngIf="!target.closed && !target.deleted"
                class="btn btn-warning btn-sm mr-5"
                (click)="closeSurveyTargeting(target.id)"
              >
                Clôturer
              </a>
              <a
                [routerLink]="'/survey/results/survey/' + target.survey_id + '/target/' + target.id"
                style="pointer-events: all !important"
                class="btn btn-secondary btn-sm text-light btn-hover-primary mr-5"
              >
                Résultats
              </a>
              <a
                style="pointer-events: all !important"
                *ngIf="target.closed"
                class="btn btn-danger btn-sm"
                (click)="deleteSurveyTargeting(target.id)"
              >
                Supprimer
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- end: Table -->
    <app-paginator
      class="d-flex justify-content-between align-items-center flex-wrap"
      [paginator]="paginator"
      [isLoading]="isLoading"
      (paginate)="paginate($event)"
    ></app-paginator>
  </div>
</div>
