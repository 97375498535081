export default {
  lien: {
    list: {
      title: 'List of links between entities',
      btn_link_team: 'Add link between teams',
      btn_link_service: 'Add link between services',
      equipe: 'Teams',
      service: 'Services',
      tous: 'All',
      type: 'Kind',
      entite: 'Entity',
      organigrame: 'Schema',
      Representant: 'Representing',
      nimorte: 'anything',
      Ambassadeur: 'Ambassador',
      Responsable: 'Responsible',
      ServiceLie: 'Related service',
      EquipeLie: 'Team linked',
    },
    equipe: {
      title: 'Add team link',
      title1: ' team link',
      equipe: 'Select team',
      nameEquipe: 'Team name',
      representant: 'Select representatives',
      nameRep: 'Representatives',
      existe: 'Link already exists',
    },
    service: {
      title: 'Add service link',
      title1: ' service link',
      equipe: 'Select service',
      nameEquipe: 'Service name',
      representant: 'Select representatives',
      nameRep: 'Representatives',
    },
    DELETE: {
      TITLE: '  Remove link',
      DESC_1: ' Remove of link ...',
      DESC_2: 'Are you sure you want to permanently remove this link??',
      BTN_DELETE: 'Delete',
      BTN_CANCEL: 'Cancel',
    },
  },
  onboarding: {
    title: 'Onboarding process',
    heure: 'time',
    btn_add: 'New rule',
    TYPE: 'KIND',
    nom: 'Name',
    organigrame: 'Schema',
    Cible: 'Target type',
    evenement: 'event',
    Periorite: 'Priority',
    Haut: 'High',
    Moyen: 'Medium',
    Bas: 'Low',
  },
  newOnboarding: {
    title: 'Create rule',
    event: 'Event',
    collab: 'With whom to organize it',
    periorite: 'Rule Priority',
    Cible: 'Home Entity Type',
    Site: 'Site',
    Service: 'Service',
    Equipe: 'Team',
    acc: 'Host entity',
    horaire: 'Event Schedule',
    ques1: '1) Select the host entity of the new collaborator...',
    ques2: '2) Define the type of event and with whom to organize it...',
    ques3: '3) Customize the rule...',
    ques4: "2) Details of the document",
  },
  DELETE: {
    TITLE: '  Remove onboarding',
    DESC_1: ' Remove of onboarding ...',
    DESC_2: 'Are you sure you want to permanently remove this rule??',
    BTN_DELETE: 'Delete',
    BTN_CANCEL: 'Cancel',
  },
}
