import ADMINPARKING from './adminParking/en'
import AUTH from './auth/en'
import CALENDAR from './calendar/en'
import CHAT from './chat/en'
import COMMUNITY from './community/en'
import DOCUMENT from './document/en'
import EVENT from './event/en'
import FICHEPOSTE from './ficheposte/en'
import FORMATIONS from './formations/en'
import GENERAL from './general/en'
import IDEA from './idea/en'
import LEAVE from './leave/en'
import NEWSLETTER from './newsletter/en'
import ONBOARDING from './onboarding/en'
import OSPLANNER from './osplanner/en'
import QUESTION_REPONSE from './question-reponse/en'
import SURVEY from './surveys/en'
import TRANSPORT from './transport/en'
import USER_PROFILE from './user-profile/en'
import USERDETAILS from './user/en'

// USA
export const locale = {
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    CV: {
      UPLOADED_BY: 'Upladed by',
      HIST_DOC: 'Document History',
      DETAILSCANDIDATE: 'Candidate details',
      METIER: 'Job',
      POST: 'Applied Position ',
      CORRESPONDANCE: 'Correspondence',
      MAJ: 'Update',
      VOIR: 'View',
      PROFIL: 'CANDIDATE PROFILE',
      ANNEXP: 'Years of experience',
      OFFER: 'Add a Job Offer',
      VOIRDETAILS: 'Details and contract modification',
      TYPECONTRACT: 'Contract Type',
      NOMENTREPRISE: 'Company Name',
      ADRESSEENTREPRISE: 'Company Address',
      MOTIFDEBUTCONTRAT: 'Reason for Contract Start',
      Temps: 'Time',
      NOMBREHEURES: 'Number of Hours',
      CATEGORIE: 'Socio-Professional Category',
      Matricule: 'Employee ID',
      MOTIFFINCONTRAT: 'Reason for Contract End',
      GROUPECONGE: 'Leave Group',
      ADDCONTRAT: 'Add a contract',
      CONTRAT: 'Contract',
      MESOFFRESEMPLOI: 'My Job Offers',
      Titre: 'Title',
      FICHEPOSTE: 'Job Description',
      DATEDEBUT: 'Start Date',
      DATEFIN: 'End Date',
      CANDIDATURERECU: 'Number of Applications Received',
      PUBLIER: 'Publish the offer',
      COPIER: 'Copy the portal link',
      NAVIGATE: 'Open the portal page',
    },

    MENU: {
      ET: 'and',
      ENREGISTRER: 'Save',
      PAUSE: 'Pause',
      TRAVAIL: 'Work',
      DURRETRAVAIL: 'Work Duration',
      HEURETRAVAIL: 'Hours Worked',
      DETAILS: 'Details',

      DUREE: 'Duration',
      JOUR: 'Day',
      NOM: 'Name',
      PRENOM: 'First Name',
      IMAGE: 'Image',
      NONVALID: 'Not Validated',
      ENATTENTE: 'Pending',
      VALIDER: 'Validate',
      SUIVITEMPS: 'Time tracking',
      SELECTUSER: 'choose the collaborator',
      ADDSUIVITEMPS: 'Declare a date',
      ADDSUIVITEMPSSUCCESS: 'date added successfully',
      DATEFIN: 'End time',
      SUIVITEMPSMSG:
        'Are you sure you want to end your day? By confirming, the time will be recorded and you will no longer be able to restart the counter.',
      TEMPS: 'Time',
      GESTIONTEMPS: 'Time',
      PARAMETRE: 'Settings',
      FLEXISPACE: 'Flexispace',
      TASKS: 'My tasks',
      MY_FLEXISPACE: 'My Flexispace',
      MESCOLLEGUES: 'My colleagues',
      Annuaire: 'Directory',
      MESCONGES: 'Time off',
      MY_EVENT: 'My events',
      Events: 'Events',
      Useridea: 'Ideas box',
      AdminIdea: 'Ideas',
      Attributions: 'Attributions',

      Entretiens: 'Interviews',
      PREONBOARDING: 'Pre-boarding & Onboarding',
      SUIVRE: 'Follow',
      FORMATIONDASH: 'Training',
      PLANNIFIERDASH: 'To plan',
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
      BUILDER: 'builder',
      USER_PROFIL: 'User Profil',
      COLLAB: 'Collaborators',
      ENTRE: 'Enterprise',
      SITE: 'Site',
      SERVICE: 'Service',
      EQUIPE: 'Team',
      EQUIPETR: 'Transverse team',
      ACTIVITY: 'Activity',
      MATERIAL: 'Materials',
      MY_ACTIVITY: 'My Activities',
      STRATEGIE: 'Self service',
      COMMUNITY: 'Community',
      MY_COMMUNITIES: 'My Communities',
      REPORTING: 'Reporting',
      ALERTS: 'Alerts',
      GERER: 'Manage',
      Catalogue: 'Catalog',
      GERERMAJ: 'MANAGE',
      RENSEIGNER: 'ASK ABOUT',
      Enquetes: 'Surveys',
      Quizs: 'Quizzes',
      Sondages: 'Polls',
      Cibler: 'Activate',
      AGIR: 'ACT and ORGANIZE',
      RECRUTER: 'RECRUIT',
      OFFRES: 'Job Offers',
      FICHES_POSTE: 'Job Descriptions',
      Communications: 'Communications',
      Alertes_des_collaborateurs: 'Collaborators alerts',
      ANALYZE: 'ANALYZE AND ANTICIPATE',
      Prev: 'Forcasts',
      FORMATIONS: 'Trainings',
      onboarding: 'My onboarding',
      LIEN: 'Link between entity',
      GENERAL: 'General',
      STRATEGIES: 'Strategies',
      AIDE: 'HELP',
      MANAGE: 'Gérer',
      NEWS: 'News ✨',
      CONTACT: 'Contact support',
      DOCUMANTATION: 'Support and Documentation',
      JEUX: 'Games',

      CAMPAGNES: 'Campaigns',
      ListeDesJeux: 'Games List',
      sondage: 'Survey',
      MES_QUESTIONS: 'My Questions',
      RECEIVED_QUESTIONS: 'Questions received',
      MES_FORMATIONS: 'My trainings',
      INTEGRATION_EXTERNE: 'External integration',
      MODELISATION: 'Space Management',
      OSP_RULES: 'Rules Management',
      OSP_RESV_ADMIN: 'Reservations Management',
      ATTENTEVALIDATION: 'Waiting for validation',
    },
    AUTH,
    USER: {
      DETAILS: {
        PERSO_INFO: 'Personal Informations',
        presentation: 'Presentation',
        CHANGE_PASS: 'Change password',
        CONGE: 'Holiday',
        NOM: 'Last Name',
        PRENOM: 'First Name',
        AVAIL: 'Availabilities',
        PLANNING: 'Travel schedule',
        PLANNING_WORK_MODE: 'Work modes',
        succ: 'Profil changed successfully',
        allow: 'Allowed file types',
        btn: 'Update',
        COLLAB: 'Collaborator',
        ENTRE: 'Enterprise',
        EQUIPE: 'Team',
        SITE: 'Site',
        SERVICE: 'Service',
        RESERVATION: 'the maximum number of bookable meeting for this month has been reached.',
        FORMATIONS: 'Trainings',
        COMPETENCES: 'Skills',
      },
    },
    EuipeTransverse: {
      Gerer: {
        Nom: 'New  Transverse Team',
        List: 'Transverse Team list',
      },
      DELETE: {
        TITLE: ' Delete transverse team',
        DESC_1: 'deletion transverse team ...',
        DESC_2: 'Are you sure to permanently delete this transverse team ?',
        BTN_DELETE: 'Delete',
        BTN_CANCEL: 'Cancel',
      },
    },
    COLLAB: {
      DETAILS: {
        TITLE: 'Collaborators list',
        NEW_COLLABORATOR: 'New collaborator',
        inscription: 'Registration by link',
        SEARCH: 'Search ',
        FIELD: 'in all fields',
        PRENOM: 'FIRST NAME',
        NOM: 'Last name',
        Responsable: 'Responsible',
        Fonction: 'Function',
        Site: 'Site',
        Service: 'Service',
        Equipe: 'Team',
        ACTIONS: 'ACTIONS',
        IMG: 'Picture',
        IMPORT_COLLABS_BTN: 'Import',
        IMPORT_COLLABS_FROM_FILE_TITLE: 'Adding employees from a file',
        IMPORT_COLLABS_FROM_FILE_DESC: 'Please import a .csv, .xls, or xlsx file following the format: ',
        IMPORT_COLLABS_FROM_FILE_DESC_FORMAT: 'email, first name, last name, function, service',
        TAG_AS_AMBASSADOR: 'Tag as ambassador',
        MARKED_AS_AMBASSADOR: 'was marked as an ambassador',
        UNMARKED_AS_AMBASSADOR: 'was unmarked as an ambassador',
        ACTIF: 'Active',
        ALUMNI: 'Alumni',
        DESACTIVER: 'Disabled',
        EN_ATTENTE: 'waiting for registration',
        SITE: 'Site',
        FILTRE: 'Filter',
        ALL: 'All',
        TITREALUMNI: 'Transfer to the list of alumni',
        SOUSTITREALUMNI: 'Transfer to the list of alumni ...',
        BODYALUMNI2: 'confirmation of the effective date of departure.',
        BODYALUMNI: 'Are you sure you want to deactivate the user account and transfer it to the list of alumni?',
        TITREACTIF: 'Reactivate account',
        SOUSTITREACTIF: 'Reactivation account...',
        BODYACTIF: 'Are you sure you want to reactivate this account?',
        CANCEL: 'Cancel',
        SAVE: 'save',
        ENVOIEMAILBIENVENUE: 'Configure the sending of Welcome emails',
        ENVOYERMAILBIENVENUEINSCRIT: 'Send the welcome email automatically to each new registrant',
        PASENVOYERMAILBIENVENUEINSCRIT: 'Do not send a welcome email automatically',
        ENTREPRISESTRUCTURE: 'Company structure',
        MONEQUIPE: 'My team',
        CONFIRMATIONEMAIL: 'Confirmation of sending welcome email',
        MSGCONFIRMATIONEMAIL:
          'Would you like to send a welcome email to collaborators who have already been added but have not received it yet?',
        OUI: 'Yes',
        NON: 'No',
        SENDEMAILSALLCOLLABS:
          'Welcome emails will be sent to all collaborators who have already been added and have not received the email.',
        SENDEMAILSALLCOLLABS2: 'Only new collaborators added from this point on will receive the welcome email.',
        SUCCESSPARAMETRAGE: 'Configuration completed successfully',
        ERRPARAMETRAGE: 'Error during configuration',

        ListCollegues: {
          LIST: 'List of colleagues',
          p1: 'If you want to be able to offer appointments to your colleagues, you must accept the option allowing your colleagues to book an appointment with you',
          Profil: 'Profile',
          p2: 'You must agree to be visible to others in order to access the directory.',
          p3: 'Your company must activate the directory option for collaborators',
          interet: 'Interest:',
        },
      },

      EDIT: {
        Edit: 'Edit',
        Create: 'Add',
        customer: 'collaborator',
        Nom: 'Last Name',
        Name: 'Name',
        Prenom: 'First Name',
        FIrstName: 'First Name',
        fonction: 'Function',
        fon: 'Function',
        Email: 'Professional email',
        mail: 'Personal email',
        password: 'Password',
        m2p: 'Password',
        Equipe: 'Select team',
        Role: 'Select role',
        ADMIN: 'Administrator',
        USER: 'Collaborator',
        BTN_SAVE: 'Save',
        BTN_SAVEBROUILLON: 'Save as draft',
        BTN_SAVEINTERNE: 'Save and publish internally',
        BTN_SAVEPORTAIL: 'Save and publish to the portal',
        BTN_CANCEL: 'Cancel',
        ERR: 'This email already exists. Please use another email',
        ERR1: 'This email invalid. Please enter a valid email',
        dateentree: 'Date of entry',
        dateSortie: 'Date of departure',
      },
      SELFSERVICE: {
        TITLE: 'Consult Availability',
      },
      RECONNAISSANCE: {
        TITRE: 'Send Recognition',
        CHOIXIMAGE: 'Choose an image',
        MSGTEXT: 'Message of recognition',
        BTN_SEND: 'Send',
        ADDSUCCESS: 'Recognition added successfully',
        SELECTIMAGESUCCES: 'Image selected successfully!',
        ERREURSELECTIMAGESUCCES: 'Please select an image and write a message',
      },
      RESERVER: {
        TITLE: ' Booking Activity',
        DESC_1: 'Booking Activity ...',
        DESC_2: 'Are you sure you want to book  this Activity ?',
        BTN_DELETE: 'Booking',
        BTN_CANCEL: 'Cancel',
      },
      DELETE: {
        TITLE: ' Deactivate account',
        DESC_1: 'Deactivate account...',
        DESC_2: 'Are you sure you want to deactivate the user account?',
        BTN_NETOYER: 'Clean',
        BTN_DELETE: 'Desactivate',
        BTN_CANCEL: 'Cancel',
      },
    },
    ANNUAIRE: {
      TITLE: 'Directory',
      p0: 'To take into account the changes it is necessary to refresh the application',
      button: 'Refresh application',
      p: 'Activate the directory for collaborators',
      p100: 'To take into account the changes it is necessary to click on button save after filling in the fields below',

      p1: 'Activate communities for collaborators',
      p2: 'Activate events for collaborators',
      p3: 'Activate the questions and answers for the collaborators',
      ponboarding: 'Activate onboarding ',
      Annuaire: 'Appear in the directory',
      Accept: 'Accept to receive appointment proposals from colleagues',
      abn: 'Your subscription is',
      parametre_msg1:
        'Manage your modules here by activating or deactivating them according to your subscription to adapt the platform to your needs',
    },
    MYACTVITY: {
      status: {
        att: 'Waiting for your confirmation',
        att1: 'Waiting for confirmation of',
        att2: 'Waiting for confirmation of two collaborators',
        rdvConfirme: 'The appointment is confirmed',
        rdvDecline: 'You declined the appointment',
        rdvDecline1: ' declined the appointment',
        annule: 'Canceled',
        terminer: 'Finish',
      },
      DETAILS: {
        detail: 'Appointment details',
        restoDetail: 'Restaurant details',
        Lieu: 'Location',
        ACTIVITY_DETAILS: 'Activity Details',
        COFFE: 'Cofee',
        LUNCH: 'Lunch',
        RESTAURANT: 'Restaurant',
        BACK: 'Back',
        CANCEL: 'Cancel',
        ACCEPT: 'Accept',
        REFUSE: 'Refuse',
        TERMINER: 'Finish and Rate',
        ACTIVITY_TYPE: 'Activity type',
        MADE_BY: 'Made By',
        ACTIVITY_STATUS: 'Activity status',
        MEETING_IS_WITH: 'Collaborater',
        MY_STATUS: 'My status',
        MY_COMMENT: 'My comment',
        MY_COMMENT2: 'Collaborater comment',
        RESTAURANT_NAME: 'Restaurant name',
        JOIN: 'Join',
        LEAVE: 'Leave',
        SEND: 'Send',
        WELCOME: 'chat with ',
        ACCEPTED: 'Accepted',
        REFUSED: 'Refused',
        CANCELED: 'Canceled',
        CREATED: 'Created',
        ATTENTE: 'Need your confirmation',
        FINISHED: 'Finished',
        RATING: ' My Rating',
        RAITING2: 'collaborater Rating',
        FILE: 'Upload your file',
        RATE_COMMENT: 'Rate',
        DEATAILLS: 'Details',
        note: 'My rating',
        newMess: 'Communicate with your collaborator',
        disponible: ' List available',
        avecQui: 'With whom?',
        Avis: 'Notice',
        ser: 'Service: ',
        Avis1: 'Notice:',
        Nom: 'Name: ',
        Adresse: 'Address: ',
        Horraires: 'Hours: ',
      },
      MENU: {
        TLF: 'Phone',
        Cuisine: 'Kitchen',
        Services: 'Services',
        Livraison: 'Delivery',
        Self: 'Self service',
        tearace: 'terrace',
        vegetariens: 'Suitable for vegetarians',
        bio: 'Bio products',
        Halal: 'Halal dishes',
        gluten: 'Gluten-free dishes',
        diabetiques: 'Dishes for diabetics',
        vegans: 'Suitable for vegans',
      },
      note: {
        head: 'Did your date go well ?',
        body: 'Leave a note',
        comnt: 'Leave a comment',
      },
      LIST: {
        TITLE: 'Manage your team meetings',
        BTN_ADD: 'New Activity',
        SEARCH: ' Search',
        FIELD: ' in all fields',
        start_date: 'Start date',
        status_global: ' Global status',
        user_1: 'Collaborater 1',
        status_1: 'Status 1',
        user_2: 'Collaborater 2',
        status_2: 'Status 2',
        ACTIONS: 'Actions',
      },
      EDIT: {
        TITLEADD: 'Activity',

        TITLEMODIF: 'Activity',
        CREATE: 'Create',
        UPDATE: 'Update',
        TITLE: 'Activity',
        Date: 'Start date',
        Restaurant: 'Enter restaurant name',
        USER_1: 'Select guest 1',
        User1: 'Guest 1',
        USER_2: 'Select guest 2',
        User2: 'Guest 2',
        Description: 'Description',
        type: 'Type of appointment',
        caffee: 'Coffee',
        meal: 'Meal',
        BTN_SAVE: 'Save',
        BTN_CANCEL: 'Cancel',
        ERRERRDV: 'Meeting exists for users on this date',
      },
      DELETE: {
        TITLE: ' Delete meet',
        DESC_1: 'meet is deleting...',
        DESC_2: 'Are you sure to permanently delete this meet ?',
        BTN_DELETE: 'Delete',
        BTN_CANCEL: 'Cancel',
      },
      ACTIVITY: {
        TITLE: 'List of meeting',
        SEARCH: ' Search',
        FIELD: ' in all fields',
        start_date: 'start date',
        Disponible: 'List of available people',
      },
      RATING: {
        TITLE: 'Did your date go well ?',
        COMMENT: 'Leave a Comment',
        NOTE: 'Leave a note',
        no: 'Note',
        BTN_SAVE: 'Save',
        BTN_CANCEL: 'Cancel',
        BTN_SAVESEND: 'Save and send',
      },
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields',
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted',
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted',
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated',
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created',
        },
      },
    },
    SITE: {
      DETAILS: {},
      LIST: {
        TITLE: 'List of sites',
        BTN_ADD: 'New site',
        SEARCH: 'Search ',
        FIELD: 'in all fields',
        NAME: 'Name',
        ADDRESS: 'Address',
        VILLE: 'City',
        PAYS: 'Country',
        ACTIONS: 'Actions',
      },
      EDIT: {
        res: 'Responsible',
        ressite: 'Responsible site',

        Edit: 'Edit',
        Create: 'Create new site',
        SITE: 'site',
        Nom: 'Site name',
        Name: 'Name',
        Address: 'Address',
        add: 'Address',
        ville: 'City',
        city: 'City',
        pays: 'Country',
        country: 'Country',
        BTN_SAVE: 'Save',
        BTN_CANCEL: 'Cancel',
        AMBASSADEUR: 'Site ambassador',
      },
      DELETE: {
        TITLE: '  Delete Site',
        DESC_1: 'Site is deleting...',
        DESC_2: 'Are you sure to permanently delete this Site?',
        BTN_DELETE: 'Delete',
        BTN_CANCEL: 'Cancel',
      },
    },
    INDICATOR: {
      DELETE: {
        TITLE: '  Delete Indicator',
        DESC_1: ' indicator deleting ...',
        DESC_2: 'Are you sure to permanently delete this indicator?',
        BTN_DELETE: 'Delete',
        BTN_CANCEL: 'Cancel',
      },
    },
    GROUP: {
      DETAILS: {},
      LIST: {
        TITLE: 'List of teams',
        BTN_ADD: 'New Team',
        SEARCH: 'Search ',
        FIELD: 'in all fields',
        NAME: 'Team name',
        MSG1: 'WARNING, you cannot delete it,',
        MSG2: 'Employees are attached to this team!',
        NBR: 'N° MEMBERS',
        SERVICE: 'Service name',
        ACTIONS: 'Actions',
        USERS: 'Users',
        AFFECTER: 'Affect',
        MODIFIEREQUIPE: 'EDIT',
        SUPPRIMEREQUIPE: 'Delete',
        HISTORIQUE: 'History',
      },
      EDIT: {
        Edit: 'Edit',
        Create: 'Create',
        GROUP: 'Team',
        GROUPREQUIS: ' Team name',
        Service: 'Select Service',
        BTN_SAVE: 'Save',
        BTN_CANCEL: 'Cancel',
        NOMGROUP: 'Team name',
        GROUPedit: 'Team',
        RESPONSABLE_EQUIPE: 'Responsible team',
        AMBASSADEUR_EQUIPE: 'Team ambassador',
      },
      EDITTRANSVERSE: {
        Edit: 'Edit',
        Create: 'Create',
        GROUP: ' Transverse Team',
        GROUPREQUIS: ' Team name',
        Service: 'Select Service',
        BTN_SAVE: 'Save',
        BTN_CANCEL: 'Cancel',
      },
      DELETE: {
        TITLE: 'Delete Team',
        DESC_1: 'Team is deleting...',
        DESC_2: 'Are you sure to permanently delete this Team?',
        BTN_DELETE: 'Delete',
        BTN_CANCEL: 'Cancel',
      },
    },
    SERVICE: {
      LIST: {
        TITLE: 'List of services',
        BTN_ADD: 'New service',
        SEARCH: 'Search ',
        FIELD: 'in all fields',
        NAME: 'Service name',
        SITE: 'Site name',
        ACTIONS: 'Actions',
      },
      EDIT: {
        Edit: 'Edit',
        Create: 'Create new service',
        SERVICE: 'Service',
        NAME: 'Service name',
        SITE: 'Select Site',
        si: 'Site',
        BTN_SAVE: 'Save',
        BTN_CANCEL: 'Cancel',
        RESP_SERVICE: 'Responsible service',
        AMBASSADEUR_SERVICE: 'Service ambassador',
      },
      DELETE: {
        TITLE: '  Delete service',
        DESC_1: 'Service is deleting...',
        DESC_2: 'Are you sure to permanently delete this service?',
        BTN_DELETE: 'Delete',
        BTN_CANCEL: 'Cancel',
      },
    },
    USER_PROFILE,
    DASHBOARD: {
      MYACTIVITY: 'Overall Involvement Rate',
      RDVFINICHED: 'Appointments completed',
      RDVREFUSED: 'Appointment refused',
      NOTEMOY: 'Average grade',
      RDVACCEPTED: 'Appointment Accepted',
    },
    ONBOARDINGTEXT: {
      SUIVREONB: 'Monitoring of onboarding_Offboarding',
      ADDBUDDYONB: 'Add buddy',
      ADDFORMATIONONB: 'Add training rule',
      ADDACCEUILONB: 'Add welcome rule',
      ADDTACHEONB: 'Add task rule',
      ADDENQUETEONB: 'Add survey rule',
      ADDDEBRIEFONB: 'Add debriefing rule',
      ADDRDVONB: 'Add appointment',
      ADDVIDEOONB: 'Add video',
      ACTIVERONB: 'Activate onboarding',
      DESACTIVERONB: 'Deactivate onboarding',
      ACTIFOFFBOARDING: 'Activate offboarding',
      DESACTIFOFFBOARDING: 'Deactivate offboarding',
      ADDDOCONB: 'Add a document',
      TELECHARDERDOCONB: 'Download',
      VOIRDOCONB: 'View document',
      PLUSONB: 'More',
      MOINSONB: 'Less',
      TELECHARGEDOC: 'Download document',
      TOUTONB: 'All',
      FAITONB: 'Done',
      AFAIREONB: 'To do',
      INDEXERONB: 'Index',
    },

    APIEXTERNE: {
      description: ' Generate an external API key for your company',
      descriptionsupprimer: 'Delete the external API key for your company',
      button: 'Generate key',
      buttonsupprimer: 'Delete key',
    },
    DASHBOARD1: {
      MESRDV: 'My appointments',
      MESRDVonboarding: 'Your appointments',

      ABSCENCERDV: 'No appointment scheduled',
      VOIR: 'See all',
      EQUIPE: 'Your team',
      ABSEQUIPE: 'No members in your team at the moment.',
      BUDDY: 'Your buddy',
      ABSBUDDY: 'No buddy',
      INFORMATIONSCONTACT: 'Contact information',
      ABSINFORMATIONSCONTACT: 'Not yet existing',
      ACCEUILPREMIERJOUR: 'The person who will welcome you',
      ABSACCEUILPREMIERJOUR: 'The person who will welcome you has not yet been assigned.',
      INCONNU: 'Unknown',
      Compte: 'Countdown',
      Jours: 'days',
      Heures: 'hours',
      Minutes: 'minutes',
      Precedent: 'Previous',
      Suivant: 'Next',
      CONGRAT: 'Send congratulations',
      ANNIVERSAIRE: 'Today is the birthday of',
      CARTEDEJOUR: 'Your cards of the day',
      ABSCENCERECRUE: 'No new arrivals',
      DEMANDECAFE: 'Offer him a coffee',
      RECONNAISSANCE: 'Recognition',
      RDVPROCHAIN: 'Next meeting',
      WITH: 'With',
      Restaurant: 'Restaurant',
      GROWTITLE: 'Grow your career',
      GROWMSG1: 'Share moments of conviviality with your colleagues and expand your network!',
      GROWMSG2: 'Well done 👏 your network is growing, keep increasing your visibility!',
      GROWMSG3: 'Congratulations 👏 you have developed your network well, keep maintaining it!',
      POTENTIELTITLE: 'The potential of your profile',
      POTENTIELMSG1: ' Fill in your profile to break the ice more easily.',
      POTENTIELMSG2: 'Congratulations 👏 your profile is at its full potential!',
      POTENTIELMSG3: 'You are at position ',
      POTENTIELMSG4: '  of the tops miameurs!',
      POTENTIELMSG5: 'Thank you for making appointments to increase your position!',

      PROCHAINRDV: 'Next meetings',
      ABSRESTAURANT: 'Location to be determined.',
      ABSRDV: 'No appointment scheduled',
      ABSRDV2: "You don't have an appointment scheduled at the moment",
      ABSNOTIF: "You don't have any notifications at the moment",

      ABSEQUIPEMOIS: 'No top teams for this month',
      ABSMIAMEURSSEMAINE: 'No top miameurs this week',
      ABSRDV3: 'No top services for this month',
      ABSEVENT: 'No event planned',
      ABSEVENT2: "You don't have  events planned at the moment",
      ABSIDEA: 'No idea to plan',
      ABSIDEA2: "You don't have an idea to plan at the moment",

      TOPSERVICES: 'Top services of the Month',
      ABSSERVICE: 'Ranking coming soon',
      RDV: 'meeting',
      TOP_MIAM_SEMAINE: 'Top miameurs of the week',
      JOURSFERIES: 'Upcoming holidays',
      TOP_MOIS_EQUIPE: 'Top Teams of the Month',
      EVENNEMENT: 'Events',
      IDEA: 'Collaborator idea',
      PARTICIPATION: 'Employee participation',
      BAROMETRE0: 'Next action:',
      BAROMETRE: 'Run the Barometer',
      GO: "Let's go",
      COLLABS: 'Collaborators',
      ACTIVE: 'Active',
      GENRE: 'Gender',
      SORTIE: 'Exits',
      ENTREE: 'Entries',
      ALERT: 'Alerts',
      QUESTION: 'Pending questions',
      IDEAOUVERTES: 'Numbers of open idea',
      DETAILS: 'more details',
      SELECT: 'Select site here',
      PARTICIPATIONSER: 'Participation by service',
      ABSSERVICEdash: 'no services for this site',
      EVOLUTIONQVCT: 'Evolution of the QVCT',
      BAROMETREDASH: 'Barometer QVCT',
      BAROMETRERELATION: 'relationship',
      BAROMETRECONTENU: 'Content',
      BAROMETRESANTE: 'Health',
      BAROMETREEGALITE: 'Equality',
      BAROMETREMANAGEMENT: 'Management',
      BAROMETRECOMPETENCES: 'Skills',
      RendezvousDASH: 'Meeting',
      EvenementsDASH: 'Events',
      NewsletterDASH: 'Newsletter',
      FormationDASH: 'Training',
      ideeDASH: 'Idea',
      SAVEDASH: 'Save',
      BIENVENUERECRU: 'Welcome to the new recruits',
      BIENVENUERECRU1: 'We welcome ',
      BIENVENUERECRU2: 'which integrates our company into the service ',
      BIENVENUERECRU3: 'See more ',
      BIENVENUERECRU4: 'Offer him a coffee',
      PARRAINEPERSONNE: 'The people you sponsor',
      ABSPARRAINEPERSONNE: 'No sponsored person',
      ANNIF: 'Upcoming birthdays',
      ABSANNIF: 'You have no upcoming birthdays at the moment',
      FERIER: 'Upcoming holidays',
      TEMPSTRAVAILLE: 'Time worked',
      PAUSEDEPEPUIS: 'On break since',
      COMMENCER: 'Start my day',
      REPREND: 'I resume',
      PAUSE: 'I take a break',
      TERMINERJOURNE: 'End my day',
      BIENVENUE: 'Welcome',
      BIENVENUEEQUIPE: 'We are delighted to have you on our team!',
      COMPLETEPROFIL: 'Complete your profile',
      VOICI: 'Here are your actions to take:',
      VOIRVIDEO: 'Watch the welcome video',
      SUIVREFORMATION: 'Follow the requested training',
      COMMENCERONB: 'Start',
    },
    PARAMETRENOTIFICATION: {
      PARAMETRENOTIF: 'Setting notification preferences',
      NOTIFEMAIL: 'Receive email notifications',
      NOTIFSLACK: 'Receive slack notifications',
      NOTIFTEAMS: 'Receive teams notifications',
    },

    INDICATEUR: {
      BLOCK: ' block flag value ',
      TITLE:
        'To take into account changes, it is necessary to click on the save button after filling in the fields below',
      CONFLITS: 'Conflict rate',
      GESTIONCONFLIS: 'Conflict management rate',
      SATISFACTION: 'Satisfaction rate',
      ENGAGEMENT: 'Engagement rate',
      AUTONOMIE: 'Autonomy rate',
      VARIETETACHE: 'Task variety rate',
      PRODUCTIVITE: 'Productivity rate',
      ERREUR: 'Error rate',
      SANTE: 'Health absenteeism rate',
      STRESS: 'Professional stress rate',
      BIENETRE: 'Well-being index',
      BURNOUT: 'Burnout rate',
      MOBILITE: 'Professional mobility rate',
      PROMOTION: 'Internal promotion rate',
      SCORE: 'Global score',
    },
    DASHBOARD2: {
      RDVACC: 'RDVS Accepted',
      RDVRED: 'RDVS Refused',
      RSVCAN: 'RDVS Cancelled',
    },
    WIZARDS: {
      taille: 'What is the size of your company: ',
      Bienvenue: 'Welcome',
      ADD: 'Address',
      ADDSIEGE: 'Address of the registered office',
      ACCOUNTTYPE: 'Type of account',
      Organization: 'Organization',
      Service: 'Service',
      Siege: 'Seat',
      Team: 'Team',
      Ended: 'Ended',
      CYTOA: 'Choose your type of account',
      IFYOUNEED: 'If you need more information you can consult',
      help: 'Online help',
      Société: 'Company',
      Équipe: 'Team',
      GRP: 'Friends team',
      ENTER: 'Enter your company details',
      NOMSOS: 'Your company name',
      SILNOMSOS: 'Please enter your company name.',
      Country: 'Country',
      Ville: 'City',
      ENTERCITY: 'Please enter your city.',
      ENTEREQ: 'Enter your team details',
      ENTERNOMEQ: 'Name of your team',
      SILENTERNOMEQ: 'Please type your team name.',
      ENTERGRP: 'Enter the details of your team of friends',
      ENTERADD: 'Please type your address',
      ENterGRPNAME: 'Name of your team',
      SILENTERNOMSos: 'Please enter your company name.',
      ServiceDetails:
        'To provide an intelligent and personalized service for each collaborator we need to know the position of each one in the company.',
      nomService: 'What department are you attached to?',
      SILService: 'Please type in your service.',
      DETAILSDESER: 'Details of your service',
      NOMSERVICE: 'Service name',
      TAPEREQ:
        'To provide an intelligent and personalized service for each collaborator we need to know the position of each one in the company.',
      NomEQ: 'what team are you attached to?',
      SILEQ: 'Please type your team.',
      DetailsEQ: 'Your team details',
      REVIEW: 'Review your Details and Submit',
      FELICITATION: 'Congratulations you are ready to go',
      REQ1: 'Now add employees to: your company',
      REQ2: 'members of your team',
      REQ3: 'members of your team',
      Previous: 'Previous',
      Next: 'Next Step',
      Equipe_Trans: 'Transversal team that leads the follow-up',
      SITE_Talend: 'Site of beneficiary employees',
      Submit: 'Submit',
    },
    DISPO: {
      EMI: {
        title: 'How can I help you ?',
        send: 'Send',
        historique: 'My History',
        poser: 'Ask the question',
        p: "Please note that the wizard's answers are for informational purposes and cannot replace the advice of an expert. WeMiam does not bear any responsibility with regard to the application or not of the advice and indications provided by the AI which feeds this assistant. If you need more specific answers or assistance, our experts are available to help you.",
        contacter: 'Contact an Expert',
        question: 'My questions',
        placeholderquestion:
          'It is important to specify your request so that we can provide you with an adequate response. Please give us more details about what you would like so that we can understand your needs and assist you in the best possible way.',
      },
      demander: 'Ask ',
      btn: "I'm available",
      btnDej: 'Lunch',
      btnAfter: 'After work',
      alert: 'You are already registered for this event.',
      att: 'Warning',
    },
    STRATEGIE: {
      GERER: {
        list: 'List of strategies',
        new: 'new strategy',
        icons: 'icons',
        NAME: 'NAME',
        DESCRIPTION: 'DESCRIPTION',
      },
      EDIT: {
        DETAILSSTRATEGIE: 'Strategy Details',
        EDIT: 'Edit strategy',
        event_id: 'Event',
        nom: 'Event name',
        HEURE: 'Generation time',
        jour_mois: 'Day of month',
        jour_semaine: 'Weekday',
        type_frequence: 'Generation frequency',
        equipe_cible1: 'Team that benefits from the Service',
        equipe_cible2: 'Team that provides its calendar',
        site_cible: ' Site concerned',
        service_cible: 'Service concerned',
        equipetrans_cible: 'Transverse team concerned',
        delai: 'Notice period',
        delai_minimum: 'Minimum time between two reservations from the same member',
        nbre_mois: 'Number of reservations authorized per month',
        jours: 'Every day',
        mois: 'Every month',
        semaines: 'Every week',
        datedemarrage: 'Starting date',
        type: 'Event type',
        dej: 'Lunch',
        petitdej: 'Breakfast',
        after: 'Afterwork',
        diner: 'Dinner',
      },
      DELETE: {
        TITLE: '  Delete strategy',
        DESC_1: 'Strategy is deleting...',
        DESC_2: 'Are you sure to permanently delete this strategy?',
        BTN_DELETE: 'Delete',
        BTN_CANCEL: 'Cancel',
      },
      ACTIVATE: {
        TITLE: 'Activate strategy',
        TITLE1: 'Deactivate strategy',
        DESC_1: 'Are you sure to permanently activate this strategy?',
        DESC_2: 'Are you sure to permanently deactivate this strategy?',

        BTN_ACTIVATE: 'Activate',
        BTN_DESACTIVATE: 'Desactivate',
        BTN_CANCEL: 'Cancel',
      },
      WIZARDS: {
        taille: 'What is the size of your company: ',
        Param: 'Set up your strategy',
        ACCOUNTTYPE: 'Type of strategy',
        Organization: 'Details',
        Service: 'Release date',
        Ended: 'Ended',
        CYTOA: 'Choose your strategy',
        IFYOUNEED: 'If you need more information you can consult',
        ENTER: 'Customize your strategy',
        help: 'Online help',
        Société: 'Society',
        Detailssp: 'Specific details',
        Previous: 'Previous',
        FREQUENCY: 'Frequency',
        TYPEEVENT: 'Event type',
        JOUR: 'Generation day',
        HOUR: 'Activity time',
        site: 'Targeted collaborators',
        plus: 'When should this strategy start?',
        demarrage: 'Starting date',
        Next: 'Next step',
        Submit: 'Submit',
        FELICITATION: 'Congratulations, your strategy is ready!',
        name: 'Name',
        duree_min: 'Minimum time between two activities with the same employee (in month)',
        duree_min1: 'Minimum duration',
        duree_max: 'Maximum time between two activities with the same employee (in month)',
        duree_max1: 'Maximum duration',
        delaiPrev: 'Minimum notice period (In days)',
        delaiPrev1: 'Notice period',
        nbrAct: 'Maximum number of activities per week',
        nbrAct1: 'Number of activities',
        typeAct: 'Type of activities',
        pas: 'no specific setting for this policy',
        delaiMin: 'Minimum time between two reservations from the same member',
        nbrAu: 'Number of authorized reservations per month',
        list1: 'Scope of employees who benefit from self-service',
        list2: 'Self-service team',
        list3: 'Cross-functional team proposing its schedule',
      },
      DETAILS: {
        nettoyage: 'Strategy cleaning',
        reginerer: 'Generate strategy',
        Refresh: 'Refresh',
        generer: 'Generate',
        strategieCible: 'Strategy',
        Collaborateur: 'Collaborater',
        Status: 'Status',
        ListRDV: 'Appointment list',
        ListUSER: 'List of beneficiary users',
        ListUSER1: 'List of users open their calendar',
        DESC_1: 'Cancel all appointments that are not completed regardless of their status',
        DESC_2: 'Regenerate appointments and send invitations to employees',
        DESC_3: 'Are you sure you want to launch now',
        minute: 'Appointment regeneration may take up to a minute',
        BTN_NETOYER: 'Clean',
      },
    },
    CONTROLEDESAISIE: {
      dateInFuture: 'The date must not be in the future.',
      startBeforeEnd: 'The start date must be earlier than the end date.',
      sameDate: 'The start date and end date cannot be the same.',
      VALID: ' was entered correct',
      required: ' is required',
      min: ' should have at least 3 symbols',
      min2: ' should have at least 2 symbols',
      max: ' should have maximum 500 symbols',
      email: ' format is incorrect',
      datemin: ' should have a number greater than 1',
      datemax: ' should have a number less than 32',
      notemin: ' should have a score greater than or equal to 0',
      notemax: ' should have a score lower than 6',
      notmatch: " didn't match",
      semaineMin: ' should have a number of days greater than 1',
      semaineMax: ' should have a number of days less than 60',
      semaineMin1: 'should have a number of weeks greater than 1',
      semaineMax1: ' should have a number of weeks less than 5',
      monthMin: 'should have a number of months greater than 1',
      monthMax: '',
      logicDate: 'end date must be superior to start date',
      minvalue: ' should have a number greater than 0',
      maxvalue: ' should have a number less than 100',
    },
    GERER: {
      Title: 'Your organization',
      IMAGE: 'Picture',
      nom: 'Name',
      pays: 'Pays',
      ville: 'City',
      update: 'update',
    },
    motdepasse: {
      titre1: 'Change Password',
      titre2: 'Change your account password',
      btnSave: 'Update',
      btnCancel: 'Cancel',
      err1: 'Configure user passwords to expire periodically. Users will need warning that their passwords are going to expire,',
      err2: ' or they might inadvertently get locked out of the system!',
      succes: 'Password changed successfully',
      verify: 'Verify your current password',
      currentpwd: 'Current Password',
      newpass: 'New Password',
      verifypass: 'Verify Password',
      passwordconfirm: 'Password confirmation',
    },
    disponibilite: {
      dispo: 'Availability',
      demander: 'Ask ',
      new: 'New availability',
      Search: 'Search for a date',
      all: 'in all fields',
      jour: 'Day',
      EVENT: 'Event',
    },
    deleteDispo: {
      title: 'Delete availability',
      msg1: 'Availability is deleting...',
      msg2: 'Are you sure to permanently delete this Availability?',
      btnCancel: 'Cancel',
      btnDelete: 'Delete',
    },
    createDisponibilite: {
      title: 'Create disponibility',
      new: 'New disponibility',
      date: 'Day',
      créneaux: 'Slots',
    },
    status: {
      created: 'created',
      accepted: 'accepted',
      refused: 'refused',
      finished: 'finished',
      canceled: 'canceled',
      notified: 'notified',
    },
    acceptMyAct: {
      title: 'Activity accept',
      info: 'Are you sure to accept this Activity?',
      btnCancel: 'Cancel',
      btnAccept: 'Accept',
    },
    EditMyAct: {
      Edit: 'Edit',
      Create: 'Create',
      comment: 'comment/rate',
      labelComment: 'Comment',
      valid1: 'Comment was entered correct',
      valid2: 'Comment should have at least 3 symbols',
      valid3: 'Comment should have maximum 10000 symbols',
      btnCancel: 'Cancel',
      btnSave: 'Save',
    },
    refuseMyAct: {
      title: 'Activity refuse',
      info: 'Are you sure to refuse this Activity ?',
      btnCancel: 'Cancel',
      btnAccept: 'Refuse',
    },
    cancelAct: {
      title: 'Activity cancel',
      info: 'Are you sure to cancel this Activity ?',
      btnCancel: 'Cancel',
      btnAccept: 'Save',
    },
    dialogInsc: {
      insc: 'Registration by link',
      btn: 'Copy',
    },
    jour: {
      importe: 'Any',
      dimanche: 'Sunday',
      lundi: 'Monday',
      mardi: 'Tuesday',
      mercredi: 'Wednesday',
      jeudi: 'Thursday',
      vendredi: 'Friday',
      samedi: 'Saturday',
    },
    typeDispo: {
      petitDej: 'Breakfast',
      dej: 'Lunch',
      afterwork: 'Afterwork',
      diner: 'Dinner',
    },
    editDispo: {
      Edit: 'Edit',
      Create: 'Create',
      dispo: 'Availablity',
      err1: 'Check your data ,',
      err2: 'Ouups! availability already exists!',
      selectDay: 'Select day',
      day: 'Day',
      selectType: 'Select type',
      type: 'Type',
      Cancel: 'Cancel',
      Save: 'Save',
    },
    ListeDesJeux: {
      ListJeux: 'Games list',
      jeuxAssoc: 'associated games',
      pasAssoc: 'non associated',
      joueurs: 'players',
    },
    gameOne: {
      ListJeux: 'games list',
      gereAssoc: 'manage associated strategies ',
      rein: 'reset stats',
      ListJeuxM: 'return',
      gereAssocM: 'manage',
      reinM: 'reset',
      desc: 'game complete description',
      points: 'points',
      numFois: 'number of times played',
      temp: 'average game time',
      collab: 'most active employees',
      date: 'date of last attendance',
      numDePart: 'number of entries',
      serviceActif: 'most active services',
      taux: 'participation rate',
      derparticipation: 'last participation date',
    },
    jeux: {
      play: 'play',
      played: 'finished',
      title: 'Game title :',
      quit: 'quit',
      actif: 'disabled',
      timer: 'ends in',
      timerDays: 'days',
    },
    popupstart: {
      liste: 'Strategies list',
      assoc: 'associate',
      dissoc: 'dissociate',
      activer: 'enable',
      des: 'disable',
      garde: 'keep the results secret until the end of the game',
    },
    assocStrat: {
      assoc: 'associate with a strategy',
      dateD: 'start date',
      dateF: 'end date',
      validate: 'validate',
      annuler: 'cancel',
    },
    sondage: {
      title: 'Surveys list',
      icon: 'ICÔNES',
      nom: 'NAME',
      description: 'DESCRIPTION',
      action: 'ACTIONS',
    },
    reset: {
      resettitle: 'are you sure you want to delete the statistics for this game ?',
      resetdesc:
        'WARNING: This will delete game statistics including score, time played, number of times played for all players belonging to the company',
      confirm: 'confirm',
    },
    wsregister: {
      wsr: 'GAME INTERFACE IS TEMPORARILY UNAVAILABLE !',
      wsolution:
        'it appears that game interface is not accessible for now, please be patient until the problem is fixed or you can try to solve this problem by reconnecting to our website. thank you for your understanding',
      annuler: 'cancel',
      déconnecter: 'deconnect',
    },
    HAUTPOTENTIEL: {
      head: 'Mark "High Potential"',
      body: 'Tag end date',
      comnt: 'Leave a comment',
    },
    DETAG: {
      TITLE: ' Delete collaborator tag',

      DESC_2: "Are you sure you want to permanently delete this collaborator's tag?",
      BTN_DELETE: 'Delete',
      BTN_CANCEL: 'Cancel',
    },
    SUIVIPAR: {
      head: 'Mark "Special follow-up"',
      body: 'Tag end date',
      comnt: 'Leave a comment',
    },
    DETAGSUIVIPAR: {
      TITLE: ' Delete collaborator tag',

      DESC_2: "Are you sure you want to permanently delete this collaborator's tag?",
      BTN_DELETE: 'Delete',
      BTN_CANCEL: 'Cancel',
    },
    presentation: {
      PERSO_INFO: 'Personal Informations',
      presentation: 'Presentation',
      parag: 'Presentation',
      date: 'Birthday',
      hobies: 'List of selected hobbies',
      AVAIL: 'Availabilities',
      succ: 'Profil changed successfully',
      allow: 'Allowed file types',
      btn: 'Update',
      COLLAB: 'Collaborator',
      ENTRE: 'Enterprise',
      EQUIPE: 'Team',
      SITE: 'Site',
      SERVICE: 'Service',
      msg: 'Pour valider votre profil cliquer sur la bouton mise à jour',
    },
    toastr: {
      evnt: 'You are registered for this event.',
      link: 'The link was copied correctly.',
      key: 'The key was copied correctly.',
      gnrl: 'Your give save successfully',
      pres_err: 'Be sure to select a video of size less than 10M!',
      pres_err1: 'Be sure to select a video!',
      pres_success: 'Your give save successfully',
      idea: 'No idea is being validated',
      eventinscrit: 'You are registered for an event',
      eventinscritdate: 'for the date of',
      titreevent: 'Event',
      absabonnementevent: 'Events are available in a higher offer',
      absabonnementidea: 'Ideas are available in a higher offer',
    },
    Notification: {
      newnotification: 'New Notification',
      rendezvosmeeting: 'Meeting',
    },
    ANNONCE: {
      NVLANN: 'New announcement',
      ANN: 'Announcement',
      LISTANNONCE: 'List of announcements',
      MODIFANNONCE: 'Edit announcement',
      SUPPRIMERANNONCE: 'Delete announcement',
    },
    CONGE: {
      conge: 'Holiday',
      new: 'New holiday',
      dateDebut: ' Start date',
      dateFin: ' End date',
      EDIT: {
        NEW: 'New Leave',
        date_début: 'Start date',
        debut_ms: 'Beginning',
        date_fin: 'End date',
        fin_ms: 'End',
      },
      DELETE: {
        TITLE: ' Delete holiday',

        DESC_2: 'Are you sure you want to permanently delete this holiday?',
      },
    },
    PRESENTAION: {
      info: 'Information for ',
      VIDEO: 'Video',
      Presentation: 'Presentation',
      HOBIES: 'Hobbies',
      NOTAVAILBLE: ' not available',
    },
    Planning: {
      LIST: {
        TITLE: 'Shift',
        SITE: 'Site ',
        DT_BEBUT: 'Starting date',
        DT_FIN: 'End date',
        BTN: 'New Shift',
      },
      EDIT: {
        TITLE: 'Create Shift',
        TITLE1: 'Update Shift',
        SITE: 'Select Site',
        DT_BEBUT: 'Start date',
        DT_FIN: 'End date',
      },
      DELETE: {
        TITLE: 'Delete the schedule',
        CONTENT: 'delete shift...',

        CONTENT2: 'Are you sure you want to permanently delete this shift ?',
      },
    },
    TopBar: {
      ESPACE1: 'Administrator Space',
      ESPACE2: 'Employee Space',
    },
    CHAT,
    COMMUNITY,
    GENERAL,
    CALENDAR,
    USERDETAILS,
    ONBOARDING,
    QUESTION_REPONSE,
    EVENT,
    FORMATIONS,
    NEWSLETTER,
    SURVEY,
    OSPLANNER,
    IDEA,
    ADMINPARKING,
    TRANSPORT,
    DOCUMENT,
    FICHEPOSTE,
    LEAVE,
  },
}
