import { HttpClient, HttpParams } from '@angular/common/http'
import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { embedDashboard } from '@superset-ui/embedded-sdk'
import { getSessionFromLocalStorage } from 'src/app/utils/getSessionFromLocalStorage'
import { environment } from 'src/environments/environment'
import { UserService } from '../../collaborateurs/_services'
import { ClosedModalComponent } from '../../communities/gerer/closed-modal/closed-modal.component'

@Component({
  selector: 'app-survey-reporting',
  templateUrl: './survey-reporting.component.html',
  styleUrls: ['./survey-reporting.component.scss'],
})
export class SurveyReportingComponent implements AfterViewInit {
  surveyAbonnement = false
  constructor(
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    public userService: UserService,
    private modalService: NgbModal
  ) {}

  ngAfterViewInit(): void {
    this.userService.getEntrepriseAbonnement().subscribe(data => {
      const tableOption = []
      for (let i = 0; i < data.length; i++) {
        tableOption.push(data[i].code_option)
        this.cdr.detectChanges()
      }

      if (tableOption.includes('MENQ')) {
        this.surveyAbonnement = true
        this.loadSupersetDashboards()
        this.cdr.detectChanges()
      } else {
        this.closedModal()
      }

      this.cdr.detectChanges()
    })
  }
  closedModal() {
    this.modalService.open(ClosedModalComponent)
  }
  showDashboard = false
  loadSupersetDashboards() {
    const sessionid = getSessionFromLocalStorage()
    const dashboardId = environment.supersetDashIds.surveys
    const params = new HttpParams().set('sessionid', sessionid).set('dashboardId', dashboardId)

    embedDashboard({
      id: dashboardId,
      supersetDomain: environment.supersetServer,
      mountPoint: document.getElementById('superset-container'),
      fetchGuestToken: () =>
        new Promise(resolve =>
          this.http
            .post(environment.apiUrl + '/superset/guest-token', {}, { params })
            .subscribe(({ token }: { token: string }) => {
              resolve(token)
            })
        ),
      dashboardUiConfig: { hideTitle: true },
    })
      .then(() => {
        document.getElementsByTagName('iframe')[0].style.width = '100%'
        document.getElementsByTagName('iframe')[0].style.height = '100%'
        document.getElementsByTagName('iframe')[0].style.border = 'none'
        setTimeout(() => {
          this.showDashboard = true
          this.cdr.detectChanges()
        }, 500)
      })
      .catch(err => {
        console.log(err)
        document.getElementsByTagName('iframe')[0].style.width = '100%'
        document.getElementsByTagName('iframe')[0].style.height = '100%'
      })
  }
}
