import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Subscription } from 'rxjs'
import { GroupingState, ITableState, PaginatorState, SortState } from 'src/app/_metronic/shared/crud-table'
import { getUserUuidFromLocalStorage } from 'src/app/utils/getSessionFromLocalStorage'
import { environment } from 'src/environments/environment'

const DEFAULT_STATE: ITableState = {
  filter: {},
  paginator: new PaginatorState(),
  sorting: new SortState(),
  searchTerm: '',
  grouping: new GroupingState(),
  entityId: undefined,
}

@Injectable({
  providedIn: 'root',
})
export class MySurveysService<T> {
  // Private fields
  private _items$ = new BehaviorSubject<T[]>([])
  private _isLoading$ = new BehaviorSubject<boolean>(false)
  private _isFirstLoading$ = new BehaviorSubject<boolean>(true)
  private _tableState$ = new BehaviorSubject<ITableState>(DEFAULT_STATE)
  private _errorMessage = new BehaviorSubject<string>('')
  private _subscriptions: Subscription[] = []
  protected authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`

  // Getters
  get items$() {
    return this._items$.asObservable()
  }
  get isLoading$() {
    return this._isLoading$.asObservable()
  }
  get isFirstLoading$() {
    return this._isFirstLoading$.asObservable()
  }
  get errorMessage$() {
    return this._errorMessage.asObservable()
  }
  get subscriptions() {
    return this._subscriptions
  }
  // State getters
  get paginator() {
    return this._tableState$.value.paginator
  }
  get filter() {
    return this._tableState$.value.filter
  }
  get sorting() {
    return this._tableState$.value.sorting
  }
  get searchTerm() {
    return this._tableState$.value.searchTerm
  }
  get grouping() {
    return this._tableState$.value.grouping
  }

  protected http: HttpClient
  // protected cdr: ChangeDetectorRef;

  constructor(http: HttpClient) {
    this.http = http
  }

  public fetch({ all }: { all: boolean } = { all: false }, type: any) {
    this._isLoading$.next(true)
    this._errorMessage.next('')
    let sessionid = this.getAuthFromLocalStorage()
    const params = new HttpParams()
      .set('userId', getUserUuidFromLocalStorage())
      .set('sessionid', sessionid)
      .set('all', all.toString())
      .set('formtype', type)
    const httpHeaders = new HttpHeaders({
      token: localStorage.getItem('QSP_AUTH_TOKEN'),
    })

    const request = this.http
      .get<any>(environment.apiUrlQSP + '/survey/list', {
        params,
        headers: httpHeaders,
      })
      .subscribe(surveys => {
        const surveysMaps = new Map()
        surveys.forEach(survey => {
          surveysMaps.set(survey.id, survey)
        })
        this.http.get<any>(environment.apiUrl + '/survey/targets/user', { params }).subscribe(surveyTargets => {

          surveyTargets.forEach(surveyTarget => {
            surveyTarget.survey = surveysMaps.get(surveyTarget.survey_id)
          })
          surveyTargets = surveyTargets.filter(surveyTarget => surveyTarget.survey)
          this._items$.next(surveyTargets)
          this._isLoading$.next(false)
        })
      })
    this._subscriptions.push(request)
  }

  public setDefaults() {
    this.patchStateWithoutFetch({ filter: {} })
    this.patchStateWithoutFetch({ sorting: new SortState() })
    this.patchStateWithoutFetch({ grouping: new GroupingState() })
    this.patchStateWithoutFetch({ searchTerm: '' })
    this.patchStateWithoutFetch({
      paginator: new PaginatorState(),
    })
    this._isFirstLoading$.next(true)
    this._isLoading$.next(true)
    this._tableState$.next(DEFAULT_STATE)
    this._errorMessage.next('')
  }

  // Base Methods
  public patchState(patch: Partial<ITableState>, type) {
    this.patchStateWithoutFetch(patch)
    this.fetch({ all: true }, type)
  }

  public patchStateWithoutFetch(patch: Partial<ITableState>) {
    const newState = Object.assign(this._tableState$.value, patch)
    this._tableState$.next(newState)
  }
  protected setAuthFromLocalStorage(auth: any): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (1 === 1) {
      //auth sessionid ta3 user

      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth))

      return true
    }
    return false
  }

  protected getAuthFromLocalStorage(): any {
    try {
      const authData = JSON.parse(localStorage.getItem(this.authLocalStorageToken))
      return authData
    } catch (error) {
      window.location.reload()
      console.error(error)
      return undefined
    }
  }
}
