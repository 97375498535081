import { Component } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Building } from '../../utils/types'
import { OsModelingService } from '../os-modeling-service.service'

@Component({
  selector: 'app-new-building-modal',
  templateUrl: './new-building-modal.component.html',
  styleUrls: ['./new-building-modal.component.scss'],
})
export class NewBuildingModalComponent {
  constructor(public modal: NgbActiveModal, public svc: OsModelingService) {}

  buildingName: string = ''
  address: string = ''
  buildings: Building[] = []
  siteId: number
  errMsg = ''
  isLoading = false

  addBuilding() {
    if (this.buildings.find(b => b.name === this.buildingName)) {
      setTimeout(() => {
        this.errMsg = ''
      }, 5000)
      return (this.errMsg = 'Il existe déjà un bâtiment avec ce nom')
    }

    const building = new Building(this.buildingName, this.address, this.siteId)
    this.isLoading = true
    this.svc.saveBuilding(building).subscribe({
      next: () => {
        this.isLoading = false
        this.modal.close(building)
      },
      error: () => {
        this.isLoading = false
      },
    })
  }
}
