<div>
  <div class="modal-header modal-title h4">{{ "OSPLANNER.ADD_BUILDING" | translate }}</div>

  <div class="modal-body">
    <ng-container>
      <div style="display: grid; align-items: center; grid-template-columns: auto auto; row-gap: 25px">
        <span>{{ "OSPLANNER.BUILDING_NAME" | translate }}:</span>
        <input class="form-control form-control-sm" type="text" class="form-control" [(ngModel)]="buildingName" />
        <span>{{ "GENERAL.ADDRESS" | translate }}:</span>
        <input class="form-control form-control-sm" type="text" class="form-control" [(ngModel)]="address" />
      </div>
      <br />
      <div *ngIf="errMsg" class="alert-danger text-center py-3">{{ errMsg }}</div>
    </ng-container>
  </div>

  <div class="modal-footer">
    <div style="display: flex">
      <button type="button" class="btn btn-danger btn-elevate mr-1" (click)="modal.dismiss()">
        {{ "GENERAL.BTN_CANCEL" | translate }}
      </button>
      <button [disabled]="isLoading" type="button" class="btn btn-primary btn-elevate" (click)="addBuilding()">
        {{ "GENERAL.BTN_ADD" | translate }}
        <div *ngIf="isLoading" class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </button>
    </div>
  </div>
</div>
