import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { OsModelingService, SiteT } from '../../os-modeling/os-modeling-service.service'
import { Building } from '../../utils/types'

@Component({
  selector: 'app-osp-autoflexi-popup',
  templateUrl: './osp-autoflexi-popup.component.html',
  styleUrls: ['./osp-autoflexi-popup.component.scss'],
})
export class OspAutoflexiPopupComponent implements AfterViewInit, OnInit {
  constructor(
    public modal: NgbActiveModal,
    private t: TranslateService,
    private svc: OsModelingService,
    private toastr: ToastrService
  ) {}
  ngOnInit(): void {
    this.fetch()
    setInterval(() => {
      this.fetch()
    }, 10000)
  }

  fetch() {
    this.svc.getAutoFlexiJobs().subscribe({
      next: jobs => {
        this.dataSource = new MatTableDataSource<Job>(jobs)
        this.jobs = jobs
      },
    })
  }

  jobs: Job[]

  getJobStatus(job_status) {
    return {
      0: 'En attente',
      1: 'En cours',
      2: 'Terminé',
      3: 'Appliqué',
      '-1': 'Erreur',
    }[job_status]
  }

  isLoading = false
  errMsg = ''

  mode: 'full' | 'delta' = 'full'
  dateNow = new Date().toISOString().split('T')[0]
  dateStart: string = new Date().toISOString().split('T')[0]
  timeStart: string = '09:00'
  timeEnd: string = '17:00'
  dateEnd: string = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
  @Input() buildingId: string
  @Input() siteId: number
  @Input() sites: SiteT[] = []
  @Input() buildings: Building[] = []

  get siteBuildings() {
    return this.buildings.filter(building => building.site_id === this.siteId)
  }

  get isFormValid() {
    return this.dateStart && this.dateEnd
  }

  get durationInDays() {
    return Math.round((new Date(this.dateEnd).getTime() - new Date(this.dateStart).getTime()) / (1000 * 3600 * 24))
  }

  get workingHours() {
    const start = this.timeStart.split(':')
    const end = this.timeEnd.split(':')
    const hours = parseInt(end[0], 10) - parseInt(start[0], 10) + ''
    const minutes = parseInt(end[1], 10) - parseInt(start[1], 10) + ''
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`
  }

  submit() {
    if (this.verifForm() && this.isValidNewJobPeriod()) {
      this.isLoading = true
      this.errMsg = ''
      const body = {
        mode: this.mode,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        timeStart: this.timeStart,
        timeEnd: this.timeEnd,
        siteId: this.siteId,
        buildingId: this.buildingId,
      }
      this.svc.autoFlexiNewJob(body).subscribe({
        next: () => {
          this.toastr.success(this.t.instant('OSPLANNER.MANAGE_RESERVATIONS_ADMIN.AUTO_FLEXI_JOB_CREATED'))
          this.modal.close()
        },
        error: err => {
          console.error(err)
          this.errMsg = "Une erreur s'est produite"
          this.isLoading = false
        },
      })
    }
  }

  isValidNewJobPeriod() {
    // function returns true if the new job period is valid (no overlap with existing jobs) and false otherwise
    // verify that the new job has a period that does not overlap with an existing job (if the time interval doesn't overlap, the date interval doesn't overlap either)
    const newJobStart = new Date(this.dateStart).getTime()
    const newJobEnd = new Date(this.dateEnd).getTime()
    const newJobTimeStart = parseInt(this.timeStart.split(':')[0], 10) * 60 + parseInt(this.timeStart.split(':')[1], 10)
    const newJobTimeEnd = parseInt(this.timeEnd.split(':')[0], 10) * 60 + parseInt(this.timeEnd.split(':')[1], 10)

    // now we just check if the time doesn't overlap then we skip the date check
    const runningJobs = this.jobs.filter(job => job.job_status === 1)
    for (const job of runningJobs) {
      const jobStart = new Date(job.dt_start).getTime()
      const jobEnd = new Date(job.dt_end).getTime()
      const jobTimeStart = parseInt(job.time_start.split(':')[0], 10) * 60 + parseInt(job.time_start.split(':')[1], 10)
      const jobTimeEnd = parseInt(job.time_end.split(':')[0], 10) * 60 + parseInt(job.time_end.split(':')[1], 10)
      if (newJobTimeStart < jobTimeEnd && newJobTimeEnd > jobTimeStart) {
        // time overlap so we check the date. if it overlaps too return false else continue
        if (newJobStart < jobEnd && newJobEnd > jobStart) {
          this.errMsg = this.t.instant('OSPLANNER.MANAGE_RESERVATIONS_ADMIN.ERRORS.OVERLAP')
          return false
        }
      }
    }
    return true
  }

  verifForm() {
    this.errMsg = ''
    if (!this.dateStart) {
      this.errMsg = this.t.instant('OSPLANNER.MANAGE_RESERVATIONS_ADMIN.ERRORS.DATE_START')
      return false
    }
    if (!this.dateEnd) {
      this.errMsg = this.t.instant('OSPLANNER.MANAGE_RESERVATIONS_ADMIN.ERRORS.DATE_END')
      return false
    }
    if (new Date(this.dateEnd).getTime() < new Date(this.dateStart).getTime()) {
      this.errMsg = this.t.instant('OSPLANNER.MANAGE_RESERVATIONS_ADMIN.ERRORS.INVALID_DATES')
      return false
    }
    if (this.durationInDays > 45) {
      this.errMsg = this.t.instant('OSPLANNER.MANAGE_RESERVATIONS_ADMIN.ERRORS.DURATION_TOO_LONG')
      return false
    }
    return true
  }

  @ViewChild('preventFocus') preventfocus: ElementRef

  ngAfterViewInit() {
    this.preventfocus.nativeElement.focus() // workaround used to prevent autofocus on the first input field in the modal
    this.dataSource.paginator = this.paginator
  }

  displayedColumns: string[] = [
    'site_name',
    'dt_start',
    'dt_end',
    'time_start',
    'time_end',
    'job_status',
    'job_dt_start',
    'exec_mode',
    'actions',
  ]
  dataSource
  selectedJobId
  @ViewChild(MatPaginator) paginator: MatPaginator

  applyJob(id) {
    this.selectedJobId = id
    this.svc.applyAutoFlexiJob(id).subscribe({
      next: () => {
        this.toastr.success(this.t.instant('OSPLANNER.MANAGE_RESERVATIONS_ADMIN.AUTO_FLEXI_JOB_APPLIED'))
        this.modal.close()
      },
      error: err => {
        console.error(err)
        this.toastr.error(this.t.instant('OSPLANNER.MANAGE_RESERVATIONS_ADMIN.ERRORS.APPLY_JOB'))
        this.selectedJobId = null
      },
    })
  }
}

interface Job {
  site_id: number
  site_name: string
  dt_start: string
  dt_end: string
  time_start: string
  time_end: string
  job_status: number
}
