import { Component } from '@angular/core';

@Component({
  selector: 'app-os-modeling-page',
  templateUrl: './os-modeling-page.component.html',
  styleUrls: ['./os-modeling-page.component.scss']
})
export class OsModelingPageComponent {

}
