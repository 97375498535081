<div class="modal-header">
  <div class="modal-title h3">
    {{ dynamicText[formtype].title | translate }}
  </div>
</div>
<div class="modal-body px-15 py-10" style="height: 60vh">
  <div *ngIf="isReady && surveys.length">
    <div class="row">
      <div class="col-lg-6 mb-15">
        <div class="d-flex align-items-center">
          <p class="mr-5">Titre du ciblage:</p>

          <input
            [(ngModel)]="targetTitle"
            class="form-control form-control-lg form-control-solid"
            style="width: 200px; cursor: text !important"
            placeholder="Titre du ciblage"
          />
        </div>
      </div>
      <div class="col-lg-6 mb-15">
        <div class="d-flex align-items-center">
          <p class="mr-5">
            {{ dynamicText[formtype].name }}:
            <i
              style="cursor: pointer"
              class="fas fa-exclamation-circle ms-2 fs-7"
              ngbTooltip="Seules les enquêtes publiés peuvent être utilisées."
            ></i>
          </p>

          <select style="cursor: pointer; width: 200px" [(ngModel)]="surveyId" class="form-control form-control-lg form-control-solid">
            <option *ngFor="let survey of surveys; let i = index" [value]="survey.id">
              <b>{{ i + 1 }}.</b> {{ survey.title || "sans titre" }}
            </option>
          </select>
        </div>
      </div>
      <div class="col"></div>
    </div>

    <div class="row mb-15">
      <div class="col">
        <mat-radio-group aria-label="Select an option" [(ngModel)]="anonymity">
          <mat-radio-button [value]="1">Non-Anonyme</mat-radio-button>
          <mat-radio-button [value]="2" *ngIf="targetType2 !== '5'">Anonyme</mat-radio-button>
          <mat-radio-button [value]="3" *ngIf="targetType2 !== '5'">Anonyme Total</mat-radio-button>
        </mat-radio-group>
      </div>
      <!-- <div class="col-4 d-flex align-items-center justify-content-between">
      <p>Réponses anonymes</p>
      <div class="custom-control custom-switch custom-switch-lg mx-5">
        <input
          type="checkbox"
          [(ngModel)]="isAnonymous"
          class="custom-control-input bg-primary"
          name="anonymousSurveySwitch"
          id="anonymousSurveySwitch"
          [checked]="false"
        />
        <label class="custom-control-label" for="anonymousSurveySwitch"></label>
      </div>
    </div>
    <div *ngIf="isAnonymous" class="col-4 d-flex align-items-center justify-content-between">
      <p>Résultats détaillés</p>
      <i
        style="cursor: pointer"
        class="fas fa-exclamation-circle ms-2 fs-7"
        ngbTooltip="Pour avoir une vision fine des statistiques, les utilisateurs doivent être connectés."
      ></i>
      <div class="custom-control custom-switch custom-switch-lg mx-5">
        <input
          type="checkbox"
          [(ngModel)]="requireUserConnected"
          class="custom-control-input bg-primary"
          name="requireUserConnected"
          id="requireUserConnectedSurveySwitch"
          [checked]="false"
        />
        <label class="custom-control-label" for="requireUserConnectedSurveySwitch"></label>
      </div>
    </div> -->
    </div>

    <div class="row mb-15" *ngIf="targetType2 !== '5'">
      <div class="col-7">
        <div class="d-flex align-items-center justify-content-between">
          <p>Cible:</p>
          <select style="cursor: pointer; width: 200px" [(ngModel)]="targetType" class="form-control form-control-lg form-control-solid">
            <option *ngFor="let targetType of targetTypes" [value]="targetType.value">
              {{ targetType.viewValue }}
            </option>
          </select>
        </div>
      </div>
      <div class="col">
        <div *ngIf="targetType && targetType != 0" class="d-flex align-items-center">
          <select style="cursor: pointer; width: 200px" [(ngModel)]="target" class="form-control form-control-lg form-control-solid">
            <option *ngFor="let target of targets?.[targetName]" [value]="target.value">
              {{ target.viewValue }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row mb-15 d-flex">
      <div class="col d-flex align-items-center justify-content-between">
        <!-- <p></p> -->

        <mat-radio-group aria-label="Select an option" [ngModel]="targetType2" (ngModelChange)="changeTargetType2($event)">
          <mat-radio-button value="0">Tout le monde</mat-radio-button>
          <mat-radio-button value="1">Ambassadeurs</mat-radio-button>
          <mat-radio-button value="2">Haut Potentiel</mat-radio-button>
          <mat-radio-button value="3">Suivi Particulier</mat-radio-button>
          <mat-radio-button value="4">Alumni</mat-radio-button>
          <mat-radio-button value="5">On/Off-boarding</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="row mb-15 d-flex align-items-center justify-content-left">
      <div class="col-md-6 d-flex align-items-center mb-5">
        <p class="mr-5">Date de début:</p>
        <input
          [ngModel]="dateStart"
          (ngModelChange)="dateStart = $event"
          [defaultValue]="dateNow"
          type="datetime-local"
          class="form-control form-control-lg form-control-solid"
          style="max-width: 200px"
          [min]="dateNow"
        />
      </div>
      <div class="col-md-6 d-flex align-items-center mb-5" *ngIf="targetType2 != 5">
        <p class="mr-5">Date de clôture:</p>
        <input
          [ngModel]="dateEnd"
          (ngModelChange)="dateEnd = $event"
          [defaultValue]="dateNow"
          type="datetime-local"
          class="form-control form-control-lg form-control-solid"
          style="max-width: 200px"
          [min]="minDateEnd"
        />
      </div>
    </div>
  </div>
  <br /><br />

  <div style="width: 100%; height: 50px; display: flex; align-items: center; justify-content: center">
    <div *ngIf="!isReady" class="spinner spinner-lg spinner-success"></div>
    <div *ngIf="isReady && !surveys.length">
      {{ dynamicText[formtype].invalidMsg }}
    </div>
  </div>
</div>
<div class="modal-footer justify-content-between" style="position: relative; z-index: 5">
  <span style="flex: 1 1 auto" style="max-width: 70%">
    <p *ngIf="errMsg" class="alert-danger text-center" style="padding: 8px; border-radius: 5px; opacity: 0.8; margin: 0 50px">
      {{ errMsg }}
    </p>
  </span>

  <div>
    <button type="button" class="btn btn-light btn-lg btn-elevate mr-1" (click)="modal.dismiss()">
      {{ "GENERAL.BTN_CANCEL" | translate }}</button
    >&nbsp;
    <button type="button" class="btn btn-primary btn-lg btn-elevate" (click)="activateSurvey()">
      {{ "GENERAL.BTN_CONFIRM" | translate }}
    </button>
  </div>
</div>
