export default {
  DETAILS: {
    TERMS_OF_USE: "Conditions d'utilisation",
    PRIVACY_POLICY: 'Politique de confidentialité',
  },
  GENERAL: {
    OR: 'Ou',
    SUBMIT_BUTTON: 'Soumettre',
    NO_ACCOUNT: 'Ne pas avoir de compte?',
    SIGNUP_BUTTON: 'Registre',
    FORGOT_BUTTON: 'Mot de passe oublié',
    BACK_BUTTON: 'Back',
    PRIVACY: 'Privacy',
    LEGAL: 'Legal',
    CONTACT: 'Contact',
  },
  LOGIN: {
    SLOGAN: 'Nourrir le bonheur de travailler ensemble',
    WELCOME: 'Bienvenue',
    TITLE: 'Créer un compte',
    BUTTON: 'Se connecter',
    NEW_HERE: 'Connectez vous à votre compte EmyeHR',
    MESSAGE: 'Utilisez votre compte et votre mot de passe pour continuer',
    MESSAGE1: ' votre compte est desactivé merci de contacter le responsable',
    ERROR: 'Les informations de connexion sont incorrectes',
    PASSWORD: 'Mot de passe',
    FORGOTPASS: 'Mot de passe oublié ?',
    SIGNUP_BUTTON: "S'inscrire",
  },
  FORGOT: {
    TITLE: 'Mot de passe oublié?',
    DESC: 'Entrez votre e-mail pour réinitialiser votre mot de passe',
    mailEx:
      'Si votre mail est enregistré chez nous vous recevrez bientôt un mail avec les instructions de réinitialisation.',
    SUCCESS: 'Your account has been successfully reset.',
    CANCEL: 'Annuler',
    EMAIL_IS_CORRECT: 'Email est correcte!',
    MSG_SUCCESS_01: 'Un Email avec les instruction de récupération a été envoyé',
    GOT_IT: 'Continue',
  },
  REGISTER: {
    TITLE: "S'inscrire",
    DESC: 'Entrez vos coordonnées pour créer votre compte',
    PASSWORD: 'Mot de passe',
    CONFIRMPASS: 'Confirmez le mot de passe',
    MESSAGECONFIRMPASS: '« Mot de passe » et « Confirmer le mot de passe » ne correspondaient pas.',
    AGREE: "J'accepte",
    TERMS: 'les termes et conditions',
    CANCEL: 'Annuler',
    Nom: 'Nom',
    Prenom: 'Prénom',
    ERR: "Cet email existe déjà. Merci d'utiliser un autre email ou essayez de récupérer un nouveau mot de passe, si vous l'avez oublié.",
    ERR1: 'Ce jeton est déjà expiré.',
    MAILERR: "Cet e-mail n'est pas professionnel. Merci d'utiliser un e-mail professionnel.",
    SUBMIT: " S'inscrire",
  },
  ACTIVE: {
    TITLE: 'Compte Actif ?',
    DESC: 'Entrez votre code pour activer votre compte',
    CANCEL: 'Annuler',
    ERROR: 'Les détails du code sont incorrects',
    CODE: 'code de validation',
  },
  INPUT: {
    EMAIL: 'Email',
    FULLNAME: 'Fullname',
    PASSWORD: 'Mot de passe',
    CONFIRM_PASSWORD: 'Confirm Password',
    USERNAME: "Nom d'utilisateur",
  },
  VALIDATION: {
    INVALID: "{{name}} n'est pas valide",
    REQUIRED: '{{name}} est requis',
    MIN_LENGTH: '{{name}} minimum length is {{min}}',
    AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
    NOT_FOUND: 'The requested {{name}} is not found',
    INVALID_LOGIN: 'The login detail is incorrect',
    REQUIRED_FIELD: 'Required field',
    MIN_LENGTH_FIELD: 'Minimum field length:',
    MAX_LENGTH_FIELD: 'Maximum field length:',
    INVALID_FIELD: 'Field is not valid',
    INVALID_EMAIL: 'Email est invalid',
  },
}
