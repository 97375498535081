export default {
  MY_QUESTIONS: {
    MY_QUESTIONS: 'Mes questions',
    ADD_QUESTION: 'Poser la question',
    NO_QUESTIONS:
      "Vous n'avez posé aucune question. Si vous avez une question, n'hésitez pas à en ajouter une en cliquant sur le bouton ci-dessus 😊",
    PREVIOUS: 'Précédent',
    NEXT: 'Suivant',
    PAGINATION: 'Pagination',
    PAGE: 'page',
    YOU_ON_PAGE: 'Vous êtes sur la page',
  },
  RECEIVED_QUESTIONS: {
    NO_QUESTIONS: "Votre équipe n'a reçu aucune question pour le moment.",
    NO_QUESTIONS_SEARCH: 'Aucune question trouvée.',
  },
  QUESTION_CARD: {
    TEAM: 'Équipe',
    TRANSVERSE_TEAM: 'Équipe transverse',
    STATUS: 'Statut',
    CANCELED: 'Annulé',
    CANCEL: 'Annuler',
    ANSWER: 'Répondre',
    ANSWERED: 'Répondu',
    NOT_ANSWERED: 'Non répondu',
    ATTACHED_FILE: 'Fichier joint',
    ASKED_QUESTION: 'Question posée',
    TRANSFER: 'Transférer à une autre équipe',
    PRIORITY: 'Priorité',
    PRORITY_LEVELS: {
      NORMAL: 'Normale',
      TOP_PRIORITY: 'Priortaire',
      URGENT: 'Urgente',
    },
  },
  ADD_QUESTION: {
    ADD_QUESTION: 'Ajouter une question',
    TITLE: 'Objet de la question',
    TITLE_REQUIRED: "L'Objet est requis",
    TEAM_QUESTION: "L'équipe destinataire de la question",
    TEAM_REQUIRED: "L'équipe est requise",
    QUESTION: 'Question',
    QUESTION_REQUIRED: 'La question est requise',
    QUESTION_MINLENGTH: 'La question doit comporter au moins 20 caractères',
    QUESTION_MAXLENGTH: 'La question dépasse la longueur maximale de 300 caractères',
    CANCEL: 'Fermer',
    INPROGRESS: 'En cours...',
    ASK: 'Poser',
    UPLOAD_SUCCESS: 'Fichier téléchargé avec succès',
    UPLOAD_FAILED: "Échec de l'envoi",
    FILE_TYPE_ERROR: "Le type de fichier n'est pas autorisé",
    GPT_ERROR: "Il semble qu'il y ait une erreur. Veuillez réessayer",
  },
  CANCEL_QUESTION: {
    CONFIRM_CANCEL_QUESTION: 'Êtes-vous sûr de vouloir annuler cette question?',
    CONFIRM: 'Confirmer',
    CANCEL: 'Fermer',
  },
  DROPDOWN: {
    TEAM_SELECTION: 'Sélectionnez les équipes à contacter',
    CANCEL_SELECTION: 'Annuler la sélection',
    ALL_SELECTION: 'Tout sélectionner',
    NO_EXISTING_TEAM: "Pas d'équipe existante",
  },
  UPLOAD_FILE: {
    SELECT_FILE: 'Sélectionner un fichier',
    CLEAR: 'Effacer le fichier',
    COMPLETED: 'Terminé !',
  },
  NO_REPONSE: {
    QUESTION: 'Question',
    NO_RESPONSE: 'Vous avez annulé cette question',
    CANCEL: 'Fermer',
  },
  TRANSFER_QUESTION: {
    MODAL_HEADER: 'Transférer cette question à une autre équipe',
    LABEL: "Transférer la question à l'équipe",
    CANCEL: 'Fermer',
    SUBMITTING: 'Transfert en cours',
    TRANSFER: 'Transférer',
  },
  ANSWER_QUESTION: {
    QUESTION: 'Question',
    ANSWER: 'Répondre',
    ANSWER_REQUIRED: 'Réponse est requise',
    ANSWER_MINLENGTH: 'La réponse doit comporter au moins 10 caractères',
    SEND: 'Envoyer',
    CANCEL: 'Fermer',
    EMOTION_STATE: "État d'esprit",
    STRESS: 'Stress',
    COLERE: 'Colère',
    FRUSTRATION: 'Frustration',
    EMI_FETCHED_DOCS: 'Documents utilisés dans la réponse:',
    ATTACH_FILE: 'Attacher des documents',
    SAVE_RESPONSE: 'Votre réponse a été sauvegardée',
    NO_DOCS: 'Pas de documents relatifs à cette question',
    GENERATING_ANSWER: 'Génération de la réponse...',
    SENTIMENT_ANALYSIS: {
      ANGER_LEVELS: {
        NOT_ANGRY: 'Pas en colère',
        LITTLE_ANGRY: 'Un peu en colère',
        ANGRY: 'En colère',
        VERY_ANGRY: 'Très en colère',
      },
      STRESS_LEVELS: {
        NOT_STRESSED: 'Pas stressé',
        LITTLE_STRESSED: 'Un peu stressé',
        STRESSED: 'Stressé',
        VERY_STRESSED: 'Très stressé',
      },
      FRUSTRATION_LEVELS: {
        NOT_FRUSTRATED: 'Pas frustré',
        LITTLE_FRUSTRATED: 'Un peu frustré',
        FRUSTRATED: 'Frustré',
        VERY_FRUSTRATED: 'Très frustré',
      },
    },
  },
  QUESTION_DETAILS: {
    QUESTION: 'Question',
    RESPONSE: 'Réponse',
    POSTED_ON: 'Posté le',
    POSTED_BY: 'Posté par',
    RESPONDED_ON: 'Répondu le',
    RESPONDED_BY: 'Répondu par',
    QUESTION_FILE: 'Fichier de question',
    RESPONSE_FILE: 'Fichier de réponse',
    RECIPIENT: 'Destinataire',
    ATTACHED_FILE: 'Fichier joint',
    CANCEL: 'Fermer',
  },
  SEARCH_FILTER: {
    SEARCH: 'Recherche...',
    FILTER_BY: 'Filtrer par statut',
    ANSWERED: 'Répondu',
    NOTANSWERED: 'Non répondu',
    CANCELED: 'Annulé',
    CLEAR_FILTER: 'Réinitialiser',
  },
  SETTINGS: {
    SAVE: 'Sauvegarder',
    RESET: 'Réinitialiser',
    SUCCESS_MESSAGE: 'Choix des équipes à contacter effectué avec succès',
    ERROR_MESSAGE: "Erreur lors de l'ajout du choix des équipes à contacter",
    MSG1:'Sélectionnez les équipes qui pourront recevoir et répondre aux questions de vos collaborateurs dans le module Q&R.',
  },
}
